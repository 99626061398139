import TYPES from '../actions/types';
import { getTotal } from '../utils';

const INITIAL_STATE = {
  serviceTotal: null,
  votersTotal: null,

  detailService: null,
  detailVoter: null,
  detailVoterDistrict: null,

  totalPendingTask: 0,

  getDetailsError: false,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.getDetailService:
      return {
        ...state,
        detailService: action.payload.data,
        serviceTotal: getTotal(action.payload.data),
        getDetailsError: false,
      }

    case TYPES.getDetailVoter:
      return {
        ...state,
        detailVoter: action.payload.data,
        votersTotal: getTotal(action.payload.data),
        getDetailsError: false,
      }

    case TYPES.getDetailVoterDistrict:
      return {
        ...state,
        detailVoterDistrict: action.payload.data,
        getDetailsError: false,
      }

    case TYPES.getTotalPendingTask:
      return {
        ...state,
        totalPendingTask: action.payload.total,
        getDetailsError: false,
      }

    case TYPES.getDetailsError:
      return { ...state, getDetailsError: true };

    default:
      return state;
  }
}
