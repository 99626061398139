import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Anchor, Text, Box } from 'grommet';

import { ErrorForm } from '../../../../components';
import { TitleField, InputPassword } from '../../../../components/form';
import { SimpleDetail, Hr } from '../../../../components/detail';
import visibleWidth from '../../../../constants/layer-width';

import { updatePass, toggleFormChangePassLayer } from '../../actions/form-change-pass';
import { changeValues } from '../../../../actions/form';

class FormChangePass extends Component {
  handlePost = formParams => {
    const {
      updatePass,
    } = this.props;

    updatePass({ formParams });
  };

  render() {
    const {
      id,
      password,
      name,
      email,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      toggleFormChangePassLayer,
    } = this.props;


    if(!toggleLayer || toggleLayer !== 'changePass')
      return null;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={toggleFormChangePassLayer}
            onEsc={toggleFormChangePassLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              overflow='auto'
              flex
            >
              <Box
                margin={{
                  top: 'large',
                  bottom: 'small',
                  horizontal: 'large'
                }}
                flex={{ shrink: 0 }}
              >
                <Text size='xlarge' textAlign='center'>
                  Trocar senha
                </Text>
              </Box>

              <Box flex overflow='auto'>
                <SimpleDetail
                  title='Nome'
                  value={name}
                />

                <SimpleDetail
                  title='E-mail'
                  value={email}
                />

                <Hr />

                <Box
                  margin={{
                    vertical: 'small',
                    horizontal: 'large'
                  }}
                  flex={{ shrink: 0 }}
                >
                  <TitleField text='Nova senha' required />

                  <InputPassword
                    value={password}
                    onChange={event =>
                      changeValues({ fieldName: 'password', value: event.target.value }, 'formChangeValues')
                    }
                  />

                  <ErrorForm errorsList={postDataError.errors} fieldName='password' />
                </Box>
              </Box>

              <Box
                direction='row'
                pad={{ vertical: 'medium' }}
                justify='center'
                align='center'
                gap='small'
              >
                <Box width='xsmall'>
                  <Anchor
                    color='dark-3'
                    label='Cancelar'
                    disabled={ loadingPostForm }
                    onClick={toggleFormChangePassLayer}
                  />
                </Box>

                <Box width='small'>
                  <Button
                    color='brand'
                    primary
                    label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                    disabled={ loadingPostForm }
                    onClick={() =>
                      this.handlePost({
                        id,
                        password,
                      })
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

const mapStateToProps = ({ layerReducer, formCreateUpdateCopyGeneric }) => ({
  id: formCreateUpdateCopyGeneric.id,
  password: formCreateUpdateCopyGeneric.password,
  name: formCreateUpdateCopyGeneric.name,
  email: formCreateUpdateCopyGeneric.email,

  watingServerResponse: formCreateUpdateCopyGeneric.watingServerResponse,
  getServerPresponseError: formCreateUpdateCopyGeneric.getServerPresponseError,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeValues,
    updatePass,
    toggleFormChangePassLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormChangePass);
