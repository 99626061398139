import TYPES from './types';
import { summaryManifestation } from '../../../api/manifestation';

export const getSummary = () =>
  summaryManifestation()
    .then(response => {
      return {
        type: TYPES.summaryData,
        payload: response,
      }
    })
    .catch(() => ({ type: TYPES.summaryRequestError }))
