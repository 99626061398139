import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { list } from '../../../api/common';

export const loadingSearchAppointmentsToggle = () => ({
  type: TYPES.loadFormSearchAppointment,
});

export const searchAppointments = params => {
  return dispatch => {
    dispatch(loadingSearchAppointmentsToggle());
    list(params, 'compromisso.php')
      .then(response =>
        dispatch({
          type: TYPES.searchAppointmentSuccess,
          payload: response,
        })
      )
      .then(() => dispatch(loadingSearchAppointmentsToggle()))
      .catch(() => dispatch({ type: TYPES.searchError }))
  }
};

export const clearFieldsFormSearch = () => ({
  type: TYPES.clearFormSearchFields,
});

export const setDefaultPageRules = () => ({
  type: TYPES.setDefaultPage,
});

export const openFormSearchLayer = () => {
  analytics.registerEvent({
    category: analytics.categories.appointment,
    action: analytics.actions.appointmentSearch,
  });

  return { type: COMMON_TYPES.toggleLayer, payload: 'search' }
};

export const hideLayer = () => ({
  type: COMMON_TYPES.toggleLayer, payload: null
});

export const goToNextPage = params => {
  const pageNumber = params.page + 1;

  return dispatch => {
    dispatch(searchAppointments({ ...params, page: pageNumber }));
    dispatch({
      type: TYPES.paginationFormSearch,
      payload: pageNumber
    })
  }
}

export const goToPrevPage = params => {
  const pageNumber = params.page - 1;

  return dispatch => {
    dispatch(searchAppointments({ ...params, page: pageNumber }));
    dispatch({
      type: TYPES.paginationFormSearch,
      payload: pageNumber
    })
  }
}
