import TYPES from '../actions/types';

const INITIAL_STATE = {
  tagId: '1',
  filters: null,
  startPrintLine: '1',
  startPrintColumn: '1',
  printItens: 'nameAddressZipcode',
  loadingPostForm: false,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    // fields
    case TYPES.changeFormGenerateTagField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.setDataFormGenerateTag:
      return {
        ...state,
        filters: action.payload,
      }

    case TYPES.postLoadingFormGenerateTag:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    default:
      return state;
  }
}
