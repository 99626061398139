import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { create as c, update as u, details } from '../../../api/common';
import { getTemplate } from '../../../api/legislative-processes';
import { showNotificationLayer } from '../../../actions/notification';

const loadingPost = () => ({
  type: TYPES.postLoadingFormPrepareDoc,
});

const searchDetails = id =>
  details(id, 'legislative.processe.prepare.doc.php')
    .then(response => ({
      type: TYPES.setDataFormPrepareDoc,
      payload: response.data,
    }))

const searchTemplates = () =>
  getTemplate()
    .then(response => ({
      type: TYPES.listTemplarePrepareDoc,
      payload: response,
    }))

export const update = params => {
  return dispatch => {
    dispatch(loadingPost());

    u(params.formParams, 'legislative.processe.prepare.doc.php')
      .then(() => {
        dispatch(loadingPost());
        dispatch(toggleFormPrepareDocLayer());

        dispatch(
          showNotificationLayer('Salvo com sucesso!')
        );
      })
      .catch(responseFail => {
        dispatch(loadingPost());

        dispatch({
          type: TYPES.postFormPrepareDocError,
          payload: responseFail.response,
        });
      })
  }
}

export const create = params => {
  return dispatch => {
    dispatch(loadingPost());

    c(params.formParams, 'legislative.processe.prepare.doc.php')
      .then(() => {
        dispatch(loadingPost());
        dispatch(toggleFormPrepareDocLayer());

        dispatch(
          showNotificationLayer('Salvo com sucesso!')
        );
      })
      .catch(responseFail => {
        dispatch(loadingPost());

        dispatch({
          type: TYPES.postFormPrepareDocError,
          payload: responseFail.response,
        });
      })
  }
};

export const loadTemplate = params => {
  return dispatch => {
    dispatch({
      type: TYPES.setDataFormPrepareDoc,
      payload: params,
    })
  }
};

export const toggleFormPrepareDocLayer = params => {
  if(params && params.id) {
    analytics.registerEvent({
      category: analytics.categories.legislative,
      action: analytics.actions.legislativeCreateDoc,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'prepareDoc' },
      searchDetails(params.id),
      searchTemplates()
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: TYPES.clearFormPrepareDoc },
  ];
};
