import React, { Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

export const RequireAuth = (ComposedComponent, redirectPath = '/') => {
  class RequireAuthComponent extends Component {
    constructor(props) {
      super(props);
      this.checkAuth();
    }

    async checkAuth() {
      const {
        userLogged: { token },
        history
      } = this.props;

      const isValid = new Date().getTime() < new Date(token.expires).getTime();

      /* if not is valid, means what token expires, so redirect to the website */
      if(!isValid) {
        history.push(redirectPath);
      }
    };

    render() {
      const {
        userLogged: { user },
      } = this.props;

      if(!user) return null;

      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = ({ authReducer: { authenticated } }) => ({
    userLogged: authenticated
  });

  return withRouter(connect(mapStateToProps, null)(RequireAuthComponent));
}

//export default RequireAuth;
