import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Button, TextInput, Text, ResponsiveContext, Keyboard, Anchor } from 'grommet';

import { ErrorForm, HeaderHtml } from '../../../components';

import { changeEmail, getAccountForgotPassword } from '../actions/forgot-password';

import urlRoutes from '../../../config/url-routes';

class ForgotPassword extends Component {

  handlePost = formParams => {
    const { getAccountForgotPassword } = this.props;
    getAccountForgotPassword({ formParams });
  };

  render() {
    const {
     loadingPostForm,
     mail,

     changeEmail,

     postDataSuccess,
     postDataError,
    } = this.props;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <HeaderHtml title='Recuperar senha' />

            <Box
              height='100vh'
              align='center'
              justify='center'
              gap='small'
              margin={{ horizontal: 'small' }}
              flex={{ shrink: 0 }}
            >
              <Box
                round='small'
                width='medium'
                pad={{
                  vertical: 'large',
                  horizontal: size === 'small' ? 'large' : 'medium',
                }}
                gap={ size === 'small' ? 'large' : 'small' }
                background='white'
                flex={{ shrink: 0 }}
                border
              >
                <Text
                  size='xsmall'
                  color='brand'
                  textAlign='center'
                  margin={{ bottom: 'small' }}
                >
                  <Anchor href={urlRoutes.site}>
                    Gerencia Meu Mandato
                  </Anchor>
                </Text>

                <Text size='large' textAlign='center'>
                  Esqueci minha senha
                </Text>


                {/* success send mail */}
                {postDataSuccess && (
                  <Box
                    gap='small'
                    flex={{ shrink: 0 }}
                  >
                    <Text
                      size='small'
                      color='dark-5'
                      textAlign='center'
                      margin={{ bottom: size === 'small' ? 'medium' : 'small' }}
                    >
                      Enviamos um e-mail com instruções para criar uma nova senha.
                    </Text>

                    <Text
                      size='small'
                      color='status-warning'
                      textAlign='center'
                      margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                    >
                      Se você não encontrar o e-mail na sua caixa de entrada, verifique a caixa de spam.
                    </Text>
                  </Box>
                )}


                {/* form get account */}
                {!postDataSuccess && (
                  <Box
                    gap='small'
                    flex={{ shrink: 0 }}
                  >
                    <Text
                      size='small'
                      color='dark-5'
                      textAlign='center'
                      margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                    >
                      Digite seu e-mail para nos ajudar a identificá-lo
                    </Text>

                    <Box width='medium'>
                      <Keyboard onEnter={() => this.handlePost({ mail })}>
                        <TextInput
                          placeholder='E-mail'
                          value={mail}
                          onChange={ event => changeEmail(event.target.value) }
                        />
                      </Keyboard>

                      <ErrorForm errorsList={postDataError.errors} />
                    </Box>

                    <Box width='medium'>
                      <Button
                        primary
                        label={ !loadingPostForm ? 'Continuar' : 'Carregando...' }
                        disabled={ loadingPostForm }
                        onClick={() => {
                          this.handlePost({ email: mail })
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>

              <Box
                width='medium'
                direction='row'
                justify='between'
                pad={{ vertical: 'small', horizontal: 'xsmall' }}
              >
                <Box>
                  <Anchor
                    size='small'
                    label='Já tenho uma conta'
                    color='dark-3'
                    href={urlRoutes.login}
                  />
                </Box>

                <Box justify='end' gap='small' direction='row'>
                  <Anchor
                    size='small'
                    label='Ajuda'
                    color='dark-3'
                    href={urlRoutes.supportSite}
                  />

                  <Anchor
                    size='small'
                    label='Termos'
                    color='dark-3'
                    href={urlRoutes.termsOfUse}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ authReducer: { forgotPassword } }) => ({
  mail: forgotPassword.mail,
  loadingPostForm: forgotPassword.loadingPostForm,
  postDataSuccess: forgotPassword.postDataSuccess,
  postDataError: forgotPassword.postDataError,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changeEmail,
  getAccountForgotPassword,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
