import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Button, Text, ResponsiveContext, Anchor } from 'grommet';

import { Placeholder, ErrorForm, HeaderHtml } from '../../../components';
import { InputPassword } from '../../../components/form';

import { changePassword, getToken, resetPassword } from '../actions/reset-password';

import urlRoutes from '../../../config/url-routes';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    const { identity } = this.props.match.params;
    const { getToken } = this.props;

    getToken(identity);
  }

  handlePost = formParams => {
    const { resetPassword } = this.props;
    resetPassword({ formParams });
  };

  render() {
    const {
      history,
      password,
      confirmPassword,
      loadingPostForm,
      tokenRecoverPassword,
      changePassword,
      postDataSuccess,
      postDataError,
    } = this.props;

    if(tokenRecoverPassword === null) {
      return (
        <Placeholder
          title='Carregando...'
          message='Aguarde que estamos preparando as coisas :)'
        />
      );
    }

    if(!tokenRecoverPassword.exists || !tokenRecoverPassword.isValid) {
      return (
        <Placeholder
          title='Ops! Token expirou ou não existe'
          message='Caso ainda precise recuperar sua senha, clique no botão abaixo.'
          button={{
            label: 'Recuperar senha',
            action: () => history.push(urlRoutes.forgotPassword),
          }}
        />
      );
    }

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <HeaderHtml title='Redefina sua senha' />

            <Box
              height='100vh'
              align='center'
              justify='center'
              gap='small'
              margin={{ horizontal: 'small' }}
              flex={{ shrink: 0 }}
            >
              <Box
                round='small'
                width='medium'
                pad={{
                  vertical: 'large',
                  horizontal: size === 'small' ? 'large' : 'medium',
                }}
                gap={ size === 'small' ? 'large' : 'small' }
                background='white'
                flex={{ shrink: 0 }}
                border
              >
                <Text
                  size='xsmall'
                  color='brand'
                  textAlign='center'
                  margin={{ bottom: 'small' }}
                >
                  <Anchor href={urlRoutes.site}>
                    Gerencia Meu Mandato
                  </Anchor>
                </Text>


                {/* success password changeded */}
                {postDataSuccess && (
                  <Box
                    gap='small'
                    flex={{ shrink: 0 }}
                  >
                    <Text size='large' textAlign='center'>
                      Senha alterada!
                    </Text>

                    <Text
                      size='small'
                      color='dark-5'
                      textAlign='center'
                      margin={{ bottom: size === 'small' ? 'medium' : 'small' }}
                    >
                      Utilize sua nova senha para acessar o sistema.
                    </Text>
                  </Box>
                )}


                {/* form get account */}
                {!postDataSuccess && (
                  <Box
                    gap='small'
                    flex={{ shrink: 0 }}
                  >
                    <Text size='large' textAlign='center'>
                      Redefina sua senha
                    </Text>

                    <Text
                      size='small'
                      color='dark-5'
                      textAlign='center'
                      margin={{ bottom: size === 'small' ? 'medium' : 'small' }}
                    >
                      Digite sua nova senha
                    </Text>

                    <Box width='medium'>
                      <InputPassword
                        value={password}
                        placeholder='Senha *'
                        onChange={ event => changePassword(event.target.value) }
                      />

                      <ErrorForm errorsList={postDataError.errors} />
                    </Box>

                    <Box width='medium'>
                      <Button
                        primary
                        label={ !loadingPostForm ? 'Redefinir senha' : 'Carregando...' }
                        disabled={ loadingPostForm }
                        onClick={() => {
                          this.handlePost({
                            token: tokenRecoverPassword.i,
                            password,
                            confirmPassword
                          })
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>

              <Box
                width='medium'
                direction='row'
                justify='between'
                pad={{ vertical: 'small', horizontal: 'xsmall' }}
              >
                <Box>
                  <Anchor
                    size='small'
                    label='Já tenho uma conta'
                    color='dark-3'
                    onClick={() => history.push(urlRoutes.login)}
                  />
                </Box>

                <Box justify='end' gap='small' direction='row'>
                  <Anchor
                    size='small'
                    label='Ajuda'
                    color='dark-3'
                    href={urlRoutes.supportSite}
                  />

                  <Anchor
                    size='small'
                    label='Termos'
                    color='dark-3'
                    href={urlRoutes.termsOfUse}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ authReducer: { resetPassword } }) => ({
  password: resetPassword.password,
  confirmPassword: resetPassword.confirmPassword,
  tokenRecoverPassword: resetPassword.tokenRecoverPassword,
  loadingPostForm: resetPassword.loadingPostForm,
  postDataError: resetPassword.postDataError,
  postDataSuccess: resetPassword.postDataSuccess,
});

const mapdispatchToProps = dispatch => bindActionCreators({
  changePassword,
  getToken,
  resetPassword,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapdispatchToProps)(ResetPassword)
);
