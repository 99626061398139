import axiosGlobalInstance from '../http-instance';

export default params => {
  return axiosGlobalInstance({
    method: 'POST',
    url: '/pagamento.php',
    data: params,
    headers: {
      action: 'createBillingBankSlip',
      authorized: true,
    }
  });
}
