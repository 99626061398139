import TYPES from './types/login';
import { accounts, login } from '../../../api/login';
import { setCookie, encryptAes } from '../../../utils';
import secretsData from '../../../constants/secrets';

const loadingPostLogin = () => ({
  type: TYPES.loadingFormPostToggle,
});

export const changeField = value => ({
  type: TYPES.changeField,
  payload: value,
});

export const getAccounts = params => {
  return dispatch => {
    dispatch(loadingPostLogin());

    accounts(params.formParams)
      .then(({ data }) => {
        dispatch(loadingPostLogin());

        if(data.length > 1) {
          dispatch({
            type: TYPES.fillAccounts,
            payload: data,
          });
        }
        else {
          dispatch({
            type: TYPES.fillSelectedAccount,
            payload: data[0],
          });
        }
      })
      .catch(responseFail => {
        dispatch(loadingPostLogin());
        dispatch({
          type: TYPES.postError,
          payload: responseFail.response,
        });
      });
  }
};

export const loginSystem = params => {
  return dispatch => {
    dispatch(loadingPostLogin());

    login(params.formParams)
      .then(({ data }) => {
        const crypt = encryptAes(data);
        const expires = new Date(data.token.expires);

        setCookie(
          secretsData.cookie,
          crypt,
          {
            expires,
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            path: '/',
          }
        );

        // TODO remover quando todo sistema estiver migrado
        setCookie(
          secretsData.cookieOld,
          data.token.value,
          {
            expires,
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            path: '/',
          }
        );
      })
      .then(() => { dispatch(loadingPostLogin()); params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostLogin());
        dispatch({
          type: TYPES.postError,
          payload: responseFail.response,
        });
      });
  }
};

export const selectAccountLogin = params => ({
  type: TYPES.fillSelectedAccount,
  payload: params,
});
