import TYPES from '../actions/types';
import { deleteRow, insertRow, updateRow } from '../utils';

const INITIAL_STATE = {
  loadingFormSearch: false,
  dataRows: [],
  getListError: false,
};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.searchSuccess:
      return {
        ...state,
        dataRows: action.payload.data.data,
        getListError: false,
      };

    case TYPES.searchError:
      return {
        ...state,
        getListError: true,
        loadingFormSearch: !state.loadingFormSearch,
      }

    case TYPES.loadingFormSearch:
      return {
        ...state,
        getListError: false,
        loadingFormSearch: !state.loadingFormSearch,
      };

    case TYPES.updateItemListingManagerSuccess:
      return {
        ...state,
        dataRows: updateRow({
          object: action.payload.data,
          key: action.payload.data.id,
          items: state.dataRows,
        }),
      }

    case TYPES.insertItemListingManagerSuccess:
      return {
        ...state,
        dataRows: insertRow({
          object: action.payload.data,
          items: state.dataRows,
        }),
      }

    case TYPES.deleteItemListingManagerSuccess:
      return {
        ...state,
        dataRows: deleteRow({
          key: action.payload.id,
          items: state.dataRows,
        }),
      }

    default:
      return state;
  }
};
