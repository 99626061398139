import TYPES from '../actions/types';
import { formatDataFormEdit, defaultDataReducer } from '../utils';

const INITIAL_STATE = {
  ...defaultDataReducer(),

  isEditing: false,
  getDataFormEditError: false,

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    // fields
    case TYPES.changeFormManagerField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    // state, city
    case TYPES.changeFormManagerStateCity:
      return {
        ...state,
        stateId: action.payload.stateId,
        cityId: action.payload.id,
      }

    // address, districtId, cityId, stateId, zipcode
    case TYPES.setAddressAfterSearch:
      return {
        ...state,
        address: `${action.payload.addressType} ${action.payload.address}`,
        districtId: action.payload.districtId,
        cityId: action.payload.cityId,
        stateId: action.payload.stateId,
        zipcode: action.payload.zipCode,
        district: action.payload.district,
        city: action.payload.city,
        state: action.payload.state,
        withLocation: true,
      }

    // clear all address
    case TYPES.clearAllAddress:
      return {
        ...state,
        address: null,
        addressNumber: null,
        additionalAddress: null,
        districtId: null,
        cityId: null,
        stateId: null,
        zipcode: null,
        district: null,
        city: null,
        state: null,
      }

    // telephones
    case TYPES.addTelephoneFormManager:
      return {
        ...state,
        telephone: [ ...state.telephone, action.payload ],
      }

    case TYPES.removeTelephoneFormManager:
      return {
        ...state,
        telephone: state.telephone.filter(item => item !== action.payload)
      }

    case TYPES.changeFormManagerTelephone:
      const telephones = state.telephone;
      const telephoneUpdated = {
        ...state.telephone[action.payload.index],
        [action.payload.item]: action.payload.value,
      }

      telephones.splice(action.payload.index, 1, telephoneUpdated);

      return {
        ...state,
        telephone: [ ...telephones ],
      }

    // relatives
    case TYPES.addRelativesFormManager:
      return {
        ...state,
        relatives: [ ...state.relatives, action.payload ]
      }

    case TYPES.removeRelativesFormManager:
      return {
        ...state,
        relatives: state.relatives.filter(item => item !== action.payload)
      }

    // responsibles
    case TYPES.addResponsiblesFormManager:
      return {
        ...state,
        responsible: [ ...state.responsible, action.payload ]
      }

    case TYPES.removeResponsiblesFormManager:
      return {
        ...state,
        responsible: state.responsible.filter(item => item !== action.payload)
      }

    case TYPES.loadingFormManagerEdit:
      return {
        ...state,
        isEditing: true,
      }

    case TYPES.loadingFormManagerEditError:
      return {
        ...state,
        getDataFormEditError: true,
      }

    case TYPES.loadDataEdit:
      return {
        ...state,
        ...formatDataFormEdit(action.payload.data),
      }

    case TYPES.clearFormCreate:
      return { ...state, ...INITIAL_STATE }

    case TYPES.postFormManagerError:
      return { ...state, postDataError: action.payload.data };

    case TYPES.loadingPostFormCreate:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    default:
      return state;
  }
}
