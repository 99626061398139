import { list } from '../../../api/common';
import { showNotificationLayer } from '../../../actions/notification';
import { downloadCsv } from '../../../components';
import { formattedDateTime } from '../../../utils';

export const generateCsv = params =>
  dispatch => {
    dispatch(
      showNotificationLayer('Gerando Excel. Aguarde...')
    );

    list(params, 'expense.php')
      .then(({ data: { data } }) => {
        downloadCsv({
          data,
          specifications: {
            reference: { name: 'Ref.', transform: (value) => value ?? '' },
            dateExpense: { name: 'Data da despesa', transform: (value) => value ?? '' },
            categoryName: { name: 'Categoria', transform: (value) => value ?? '' },
            statusName: { name: 'Status', transform: (value) => value ?? '' },
            value: { name: 'Valor', transform: (value) => value ?? '' },
            isRefund: { name: 'Reembolso', transform: (value) => value ? 'Sim' : 'Não' },
            name: { name: 'Nome', transform: (value) => value ?? '' },
            nationalRegister: { name: 'CPF/CNPJ', transform: (value) => value ?? '' },
            telephone: { name: 'Telefone', transform: (value) => value ?? '' },
            description: { name: 'Descrição', transform: (value) => value ? value.replace(/\n/gm, '') : '' },
          },
          fileName: `listagem-despesa-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.csv`,
        })
      })
      .catch(() =>
        dispatch(
          showNotificationLayer('Ops... não conseguimos gerar o Excel.')
        )
      );
  }
