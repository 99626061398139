import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import RoutesMap from './routes-map';

export default function Routes() {
  return (
    <Router>
      <Switch>
        {
          RoutesMap.map((route, index) => {
            return (
              <Route key={index} path={route.path}>
                {route.component}
              </Route>
            );
          })
        }
      </Switch>
    </Router>
  )
}
