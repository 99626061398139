import { saveAs } from 'file-saver';

const generateHtml = data => data.text

export const printOut = (args = {}) => {
  const { data, fileName } = args;
  const actionResultHtml = generateHtml(data);
  const blob = new Blob([actionResultHtml], { type: 'text/html;charset=utf-8' });

  saveAs(blob, fileName);
}
