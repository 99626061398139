export default object => ({
  newClient: !object.billing,

  identifier: object.identifier,
  clientId: object.clientId,
  clientName: object.name,
  openInvoice: object.openInvoice,
  value: object.product.value,

  inputBillingName: object.billing && object.billing.name ? object.billing.name : '',
  inputBillingDocument: object.billing && object.billing.document ? object.billing.document : '',
  period: '1',
});
