import TYPES from './types/auth';
import { getCookie, decryptAes } from '../../../utils';

export const loadUserLoggedByLocalStorage = () => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return ({
    type: TYPES.loadStateWithLocalStorage,
    payload: decipher,
  });
};
