import React, { useState } from 'react';
import { Select } from 'grommet';

const SelectSearch = props => {
  const { options, ...rest } = props;
  const [matched, setOptions] = useState(options);

  return (
    <Select
      options={matched}
      onClose={() => setOptions(options)}
      onSearch={text => {
        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        const exp = new RegExp(escapedText, 'i');

        setOptions(options.filter(({ name }) => exp.test(name)));
      }}

      {...rest}
    />
  )
}

export default SelectSearch;
