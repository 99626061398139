import crypto from 'crypto-js';
import secretsData from '../constants/secrets';
import jsonParse from './json-parse';

export default (value) => {
  try {
    if(!value) return value;

    const bytes = crypto.AES.decrypt(value, secretsData.encryt);
    const decryptedData = bytes.toString(crypto.enc.Utf8);

    return jsonParse(decryptedData);
  }
  catch {
    return null;
  }
}
