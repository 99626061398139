import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Box, Button } from 'grommet';
import { FormClose } from 'grommet-icons';

import { Placeholder } from '../../../../components';
import { SimpleDetail, Hr, TitleDetail } from '../../../../components/detail';

import { formattedDateTime, formattedCurrency } from '../../../../utils';
import visibleWidth from '../../../../constants/layer-width';

import { toggleDetailsLayer } from '../../actions/details-invoice';

class DetailsInvoice extends Component {
  printBankSlip = () => {
    const {
      detail: {
        bankSlipUrl
      }
    } = this.props;

    window.open(bankSlipUrl, '_blank');
  };

  render() {
    const {
      detail,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'details') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleDetailsLayer}
              onEsc={toggleDetailsLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{
                  top: size === 'small' ? 'xlarge' : 'large',
                  bottom: size === 'small' ? 'large' : null,
                }}
                overflow='auto'
                flex
              >
                {!detail ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {detail ? (
                  <>
                    <Box
                      direction='row'
                      justify='between'
                      align='baseline'
                      margin={{ horizontal: 'large' }}
                    >
                      <TitleDetail value='Detalhes da fatura' />

                      <Button
                        plain
                        title='Fechar'
                        icon={<FormClose />}
                        onClick={toggleDetailsLayer}
                      />
                    </Box>


                    <Box flex overflow='auto'>
                      {detail.isPaid === 0 && (
                        <Box
                          margin={{ top: 'xsmall', bottom: 'none', horizontal: 'large' }}
                          pad={{ bottom: 'small' }}
                          flex={{ shrink: 0 }}
                        >
                          <Button
                            primary
                            label='Imprimir fatura'
                            onClick={this.printBankSlip}
                            className='primary-button'
                          />
                        </Box>
                      )}

                      <SimpleDetail
                        title='Tipo'
                        value={detail.serviceName}
                      />

                      <SimpleDetail
                        title='Status'
                        value={detail.statusName}
                      />

                      <Hr />

                      {detail.periodStart && detail.periodEnd && (<SimpleDetail
                        title='Período da cobrança'
                        value={`${formattedDateTime(detail.periodStart)} até ${formattedDateTime(detail.periodEnd)}`}
                      />)}

                      <SimpleDetail
                        title='Vencimento'
                        value={formattedDateTime(detail.dueDate)}
                      />

                      <Hr />

                      {detail.payDay && (<SimpleDetail
                        title='Data de pagamento'
                        value={formattedDateTime(detail.payDay)}
                      />)}

                      <SimpleDetail
                        title='Valor'
                        value={formattedCurrency(detail.value)}
                      />
                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsInvoice);
