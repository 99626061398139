import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, TextInput, TextArea } from 'grommet';

import { TitleField } from '../../../../components/form';
import { ErrorForm } from '../../../../components';
import MessageTemplate from '../message-template/message-template';

import { changeField } from '../../actions/form-create';

function ComposeMessage(props) {
  const {
    type,
    composerMessage,
    postDataError,

    changeField,
  } = props;

  const isEmail = type === '1';

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box margin={{ bottom: 'medium' }}>
            <Text size='large'>
              Crie a mensagem dessa campanha
            </Text>
          </Box>

          <Box
            direction={size !== 'small' ? 'row' : 'column'}
          >
            {/* coluna 1 - message */}
            <Box
              margin={{ right: 'large' }}
              width={size !== 'small' ? '50vw' : '100vw'}
            >
              {isEmail && (
                <Box direction='row'>
                  <Box margin={{ vertical: 'small', right: 'small' }} width='large'>
                    <TitleField text={'Assunto'} required />

                    <TextInput
                      value={composerMessage.subject}
                      onChange={event => changeField(event.target.value, 'changeFormCreateComposeMessageSubject')}
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='message-subject' />
                  </Box>

                  <Box margin={{ vertical: 'small' }} width='medium'>
                    <TitleField text={'Saudação'} required />

                    <TextInput
                      value={composerMessage.greeting}
                      onChange={event => changeField(event.target.value, 'changeFormCreateComposeMessageGreeting')}
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='message-greeting' />
                  </Box>
                </Box>
              )}

              <Box margin={{ vertical: 'small' }}>
                <TitleField text={'Conteúdo'} required />

                <TextArea
                  rows={4}
                  resize='vertical'
                  value={composerMessage.content}
                  onChange={event => changeField(event.target.value, 'changeFormCreateComposeMessageContent')}
                />

                <ErrorForm errorsList={postDataError.errors} fieldName='message-content' />
              </Box>

              {isEmail && (
                <Box margin={{ vertical: 'small' }}>
                  <TitleField text={'Assinatura'} required />

                  <TextInput
                    value={composerMessage.signature}
                    onChange={event => changeField(event.target.value, 'changeFormCreateComposeMessageSignature')}
                  />

                  <ErrorForm errorsList={postDataError.errors} fieldName='message-signature' />
                </Box>
              )}
            </Box>

            {/* coluna 2 - message */}
            <Box
              margin={{
                bottom: 'small',
                left: size !== 'small' && size !== 'medium' ? 'large' : 'none'
              }}
              width={
                size !== 'small' ? '40vw' : '100vw'
              }
            >
              <MessageTemplate
                typeId={type}
                subject={composerMessage.subject}
                greeting={composerMessage.greeting}
                content={composerMessage.content}
                signature={composerMessage.signature}
              />
            </Box>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ campaignsReducer: { formCreate } }) => ({
  type: formCreate.type,
  composerMessage: formCreate.composerMessage,
  postDataError: formCreate.postDataError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    changeField,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposeMessage);
