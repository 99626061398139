import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import { create as c, update as u } from '../../../api/common';
import { showNotificationLayer } from '../../../actions/notification';
import { formatFormCreate, formatFormEdit } from '../utils';

const loadingPostManager = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const update = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    u(params.formParams, 'legislative.processe.status.php')
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(hideFormManagerLayer());

        dispatch({
          type: TYPES.updateItemListingManagerSuccess,
          payload: response,
        });

        dispatch(
          showNotificationLayer(`O status ${response.data.name} foi atualizado!`)
        );
      })
      .catch(responseFail => {
        dispatch(loadingPostManager());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
};

export const create = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    c(params.formParams, 'legislative.processe.status.php')
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(hideFormManagerLayer());

        dispatch({
          type: TYPES.insertItemListingManagerSuccess,
          payload: response,
        });

        dispatch(
          showNotificationLayer(`O status ${response.data.name} foi criado!`)
        );
      })
      .catch(responseFail => {
        dispatch(loadingPostManager());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
};

export const showFormManagerLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
      { type: COMMON_TYPES.formGetWatingResponse, payload: true },
      {
        type: COMMON_TYPES.formInitializeValues,
        payload: formatFormEdit(params),
      },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
    { type: COMMON_TYPES.formInitializeValues, payload: formatFormCreate() },
    { type: COMMON_TYPES.formGetWatingResponse, payload: false },
  ];
};

export const hideFormManagerLayer = () => [
  { type: COMMON_TYPES.toggleLayer, payload: null },
  { type: COMMON_TYPES.formClearValues },
];
