import React from 'react';
import { ResponsiveContext, Box, Text, Meter } from 'grommet';
import { formattedNumber } from '../../utils';

const Statistic = ({ title, color, amount, total }) => {
  const getPercent = (amount, totalItens) => {
    if(totalItens) {
      return amount / totalItens;
    }

    return 0;
  }

  const percent = getPercent(amount, total);
  const showGraphic = getPercent(amount, total) * 100;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          margin={{
            top: size === 'small' ? 'small' : 'small',
            bottom: size === 'small' ? 'small' : 'small',
            horizontal: 'large'
          }}
          pad={{ bottom: 'small' }}
          flex={{ shrink: 0 }}
          gap='xsmall'
        >
          <Box
            direction='row'
            justify='between'
          >
            <Text
              size='small'
              color='dark-3'
            >
              {title}
            </Text>

            <Text
              size='small'
              color='dark-5'
              title={`Total ${amount}`}
            >
              {formattedNumber(percent, 'percent', 2)}
            </Text>
          </Box>

          <Box>
            <Meter
              max={100}
              background='light-2'
              size='large'
              type='bar'
              thickness='small'
              values={[
                {
                  color,
                  value: showGraphic,
                  label: title
                }
              ]}
            />
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Statistic;
