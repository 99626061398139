const updateRow = ({ object, key, items }) => {
  const {
    statusId,
    statusName,
  } = object;

  return items.map(item =>
    item.id === key ? { ...item, statusId, statusName } : item
  );
}

export default updateRow;
