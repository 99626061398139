import TYPES from '../actions/types';
import { formatLoadData } from '../utils';

const INITIAL_STATE = {
  loadingPostForm: false,
  postDataSuccess: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.changeInputField:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      }

    case TYPES.useLicense:
      return {
        ...state,
        ...formatLoadData(action.payload.data),
      }

    case TYPES.formPostSuccess:
      return { ...state, postDataSuccess: true }

    case TYPES.formPostError:
      return { ...state, postDataError: action.payload.data }

    case TYPES.loadFormPostToggle:
      return { ...state, loadingPostForm: !state.loadingPostForm }

    default:
      return state;
  }
}
