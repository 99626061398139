import TYPES from './types';
import { getCustomer, getCommunicationBalance } from '../../../api/client';

export const searchDetails = params =>
  getCustomer(params)
    .then(response => {
      return {
        type: TYPES.detailsData,
        payload: response,
      }
    })
    .catch(() => ({ type: TYPES.detailsError }))

export const searchCommunicationBalance = () =>
  getCommunicationBalance()
    .then(response => {
      return {
        type: TYPES.communicationBalanceData,
        payload: response.data,
      }
    })
    .catch(() => ({ type: TYPES.detailsError }))
