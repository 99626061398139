import React from 'react';
import { Text } from 'grommet';

function DescriptionField({ text }) {
  return (
    <Text size='xsmall' color='dark-4' textAlign='start'>
      {text}
    </Text>
  )
}

export default DescriptionField;
