import { combineReducers } from 'redux';
import dashboardReducer from './dashboard';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';
import formChangeStatusReducer from './form-change-status';

const manifestationReducer = combineReducers({
  dashboard: dashboardReducer,
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
  formChangeStatus: formChangeStatusReducer,
});

export default manifestationReducer;
