import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';
import { remove as d } from '../../../api/common';
import { formatDataFormDelete } from '../utils';

const loadingPostDelete = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const remove = params => {
  return dispatch => {
    dispatch(loadingPostDelete());

    d(params.formParams.id, 'legislative.processe.php')
      .then(() => {
        dispatch(loadingPostDelete());

        dispatch(showNotificationLayer(
          'O documento foi removido!')
        );
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostDelete());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
};

export const toggleFormDeleteLayer = params => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.legislative,
      action: analytics.actions.legislativeDelete,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'delete' },
      { type: COMMON_TYPES.formInitializeValues, payload: formatDataFormDelete(params) },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
};
