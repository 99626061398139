import TYPES from '../actions/types';
import { formatDataFormDelete } from '../utils';

const INITIAL_STATE = {
  id: null,
  reference: null,
  endDate: null,
  actionToDelete: null,

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.setDataFormDelete:
      return {
        ...state,
        ...formatDataFormDelete(action.payload),

        postDataError: {
          message: '',
          errors: []
        },
      }

    case TYPES.loadingFormDelete:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formPostDeleteError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
