const updateRow = ({ object, key, items }) => {
  const {
    typeName: type,
    statusName: status,
    status: statusId,
    type: typeId,
    number,
    year,
    statusColor,
    menu,
  } = object;

  return items.map(item =>
    item.id === key ? { ...item, type, typeId, number, year, status, statusColor, statusId, menu } : item
  );
}

export default updateRow;
