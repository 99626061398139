import axiosGlobalInstance from '../http-instance';

export default params => {
  return axiosGlobalInstance({
    method: 'POST',
    url: '/login.php',
    data: params,
    headers: {
      action: 'logout',
    }
  });
}
