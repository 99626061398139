export default object => ({
  id: null,
  sourceId: object.sourceId,
  source: object.source,
  status: object.status,
  description: object.description,
  taskDate: object.taskDate,
  participants: object.participants.map(a => a.userId),

  isCopy: true,
  isEditing: false,
});
