import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, TextInput, Select, RadioButton } from 'grommet';

import { TitleField, CalendarDropButton } from '../../../../components/form';
import { ErrorForm } from '../../../../components';
import selectHourOptions from '../../constants/select-hour-options';

import { changeField, changeCampaignType } from '../../actions/form-create';

function Settings(props) {
  const {
    type,
    name,
    dateStart,
    hourStart,
    postDataError,

    changeField,
    changeCampaignType,
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box margin={{ bottom: 'medium' }}>
            <Text size='large'>
              Dados da campanha
            </Text>
          </Box>

          <Box
            direction={size !== 'small' && size !== 'medium' ? 'row' : 'column-reverse'}
          >
            {/* coluna 1 - settings */}
            <Box
              margin={{ right: 'large' }}
              width={size !== 'small' && size !== 'medium' ? '50vw' : '100vw'}
            >
              <Box margin={{ vertical: 'small' }}>
                <TitleField text={'Nome da campanha'} required />

                <TextInput
                  maxLength={100}
                  value={name}
                  onChange={event => changeField(event.target.value, 'changeFormCreateName')}
                />

                <ErrorForm errorsList={postDataError.errors} fieldName='name' />
              </Box>

              <Box margin={{ vertical: 'small' }}>
                <Box
                  border={{ color: 'light-5' }}
                  round='xxsmall'
                >
                  <Box
                    border={{ color: 'light-5', side: 'bottom' }}
                    pad={{ vertical: 'small', horizontal: 'medium' }}
                  >
                    <RadioButton
                      name='type'
                      label='Enviar essa campanha por SMS'
                      value='2'
                      checked={type === '2'}
                      onChange={event => changeCampaignType(event.target.value)}
                    />
                  </Box>

                  <Box pad={{ vertical: 'small', horizontal: 'medium' }}>
                    <RadioButton
                      name='type'
                      label={
                        <Box>
                          <Text>Enviar essa campanha por e-mail</Text>
                        </Box>
                      }
                      value='1'
                      checked={type === '1'}
                      onChange={event => changeCampaignType(event.target.value)}
                    />
                  </Box>
                </Box>

                <ErrorForm errorsList={postDataError.errors} fieldName='type' />
              </Box>

              <Box direction='row'>
                <Box
                  margin={{ vertical: 'small', right: 'small' }}
                  width='50vw'
                >
                  <TitleField text={'Enviar no dia'} required />

                  <CalendarDropButton
                    date={dateStart}
                    onSelect={(selectedDate) => changeField(selectedDate, 'changeFormCreateDateStart')}
                  />

                  <ErrorForm errorsList={postDataError.errors} fieldName='dateStart' />
                </Box>

                <Box
                  margin={{ vertical: 'small' }}
                  width='50vw'
                >
                  <TitleField text={'Horário'} required />

                  <Select
                    options={selectHourOptions}
                    value={hourStart}
                    labelKey='name'
                    valueKey={{ key: 'id', reduce: true }}
                    onChange={({ value: nextValue }) => changeField(nextValue, 'changeFormCreateHourStart') }
                    emptySearchMessage='Selecione uma opção'
                  />

                  <ErrorForm errorsList={postDataError.errors} fieldName='hourStart' />
                </Box>
              </Box>
            </Box>

            {/* coluna 2 - settings */}
            <Box
              margin={{
                bottom: 'small',
                left: size !== 'small' && size !== 'medium' ? 'large' : 'none'
              }}
              width={
                size !== 'small' &&
                size !== 'medium' ? '40vw' : '100vw'
              }
            >
              <Text size='small' color='dark-5' margin={{ bottom: 'medium' }}>
                Alcance seus eleitores de maneira simples e rápida. Crie sua campanha em poucos passos.
              </Text>

              <Box
                border={{ color: 'status-warning', side: 'left', size: 'medium' }}
                round='xsmall'
                pad='medium'
                background='accent-4'
                flex={{ shrink: 0 }}
              >
                <Text size='small' color='dark-1'>
                  Na data de envio da campanha é importante ter saldo suficiente para o disparo. Consulte seu saldo na Área do cliente.
                </Text>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ campaignsReducer: { formCreate } }) => ({
  type: formCreate.type,
	name: formCreate.name,
	dateStart: formCreate.dateStart,
  hourStart: formCreate.hourStart,

  postDataError: formCreate.postDataError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    changeField,
    changeCampaignType,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
