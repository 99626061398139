import { saveAs } from 'file-saver';

const transformData = (object, specifications) =>
  Object.keys(specifications).reduce((acc, key) => {
    const { name, transform } = specifications[key];
    acc[name] = transform(object[key]);

    return acc;
  }, {})

const convertArrayOfObjectsToCSV = data => {
  if (data == null || !data.length) {
    return null;
  }

  var result, keys, columnSeparator = ';', lineSeparator = '\n', quoteStrings='"';

  keys = Object.keys(data[0]);

  result = '';
  result += keys.join(columnSeparator);
  result += lineSeparator;

  data.forEach(function(item) {
    keys.forEach(function(key) {
      result += quoteStrings + item[key] + quoteStrings + columnSeparator;
    });

    result += lineSeparator;
  });

  return result;
}

export const downloadCsv = (args = {}) => {
  const { data, specifications, fileName } = args;

  const dataTransform = data.map(object => transformData(object, specifications));
  const result = convertArrayOfObjectsToCSV(dataTransform);
  const csv = '\uFEFF' + result;
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  saveAs(blob, fileName);
}
