const updateRow = ({ object, key, items }) => {
  const {
    question,
    endDate,
    statusName: status,
    status: statusId,
  } = object;

  return items.map(item =>
    item.id === key ? { ...item, question, endDate, status, statusId } : item
  );
}

export default updateRow;
