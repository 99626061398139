import { list } from '../../../api/common';
import { printOut } from '../components/print-result/print-result';
import { formattedDateTime } from '../../../utils';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';

const _command = params =>
  dispatch => {
    dispatch(
      showNotificationLayer('Gerando arquivo. Aguarde...')
    );

    list(params, 'compromisso.php')
      .then(({ data: { data } }) => {
        printOut({
          list: data,
          fileName: `listagem-compromisso-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.html`,
        });
      })
      .catch(() =>
        dispatch(
          showNotificationLayer('Ops... não conseguimos gerar o arquivo.')
        )
      );
  }

export const generatePrintSearch = params => {
  //ga
  analytics.registerEvent({
    category: analytics.categories.appointment,
    action: analytics.actions.appointmentPrint,
  });

  return _command(params);
};
