import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';
import { changeStatusService } from '../../../api/service';

const loadingPost = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const changeStatus = params => {
  return dispatch => {
    dispatch(loadingPost());

    changeStatusService(params.formParams)
      .then(response => {
        dispatch(loadingPost());
        dispatch(toggleFormChangeStatusLayer());

        dispatch({
          type: TYPES.updateItemListingChangeStatus,
          payload: response,
        });

        dispatch(
          showNotificationLayer(`O status do atendimento ${response.data.reference} foi alterado!`)
        )
      })
      .catch(responseFail => {
        dispatch(loadingPost());
        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
}

export const toggleFormChangeStatusLayer = params => {
  if(params && params.id) {
    analytics.registerEvent({
      category: analytics.categories.service,
      action: analytics.actions.serviceChangeStatus,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'changeStatus' },
      {
        type: COMMON_TYPES.formInitializeValues,
        payload: {
          treatmentId: params.id,
          statusCurrentId: params.statusId,
          status: params.status,
          statusColor: params.statusColor,
          reference: params.reference,
        },
      },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
};
