import axiosGlobalInstance from '../http-instance';

export default identity => {
  return axiosGlobalInstance({
    method: 'GET',
    url: '/conta.php',
    params: { i: identity },
    headers: { action: 'check_token_recover_password' }
  });
}
