const TYPES = ({
  generateExcel: 'GENERATE_CSV_TASK',

  // list
  loadingFormSearch: 'LOADING_SEARCH_TASK_FORM_TOGGLE',
  searchSuccess: 'SEARCH_TASK_SUCCESS',
  searchError: 'SEARCH_TASK_LIST_ERROR',

  // form create/update/copy
  updateItemListingManagerSuccess: 'MANAGER_TASK_SUCCESS_UPDATE_LISTING',

  // form search
  changeFormSearchField: 'CHANGE_SEARCH_TASK_FORM_FIELD',
  setDefaultPage: 'SET_SEARCH_TASK_DEFAULT_PAGE',
  initializeValueFormSearch: 'INITIALIZE_VALUES_TASK_FORM_SEARCH',
  paginationFormSearch: 'SEARCH_TASK_PAGINATION',
});

export default TYPES;
