import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { Trash, AddCircle, StatusInfo, ShareOption } from 'grommet-icons';

import { Placeholder, ErrorsSearches, Tag } from '../../../../components';
import { toggleFormDeleteLayer } from '../../actions/form-delete';
import { showFormManagerLayer } from '../../actions/form-manager';

const SearchResult = (props) => {
  const {
    loadingFormSearch,
    dataRows,
    getListError,

    toggleFormDeleteLayer,
    showFormManagerLayer,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches />;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '100px' }}
          >
            <Box
              pad='small'
              width='xlarge'
              gap='medium'
            >
              {/* Cabeçalho */}
              <Box
                direction='row'
                gap='xsmall'
                align='baseline'
              >
                <Text size='large' color='dark-5'>Integração com</Text>
                <Text size='xlarge' color='dark-1'>Google Agenda</Text>
              </Box>

              {/* Conteúdo */}
              <Box
                gap='medium'
                direction={ size === 'small' || size === 'medium' ? 'column' : 'row' }
                justify='between'
              >

                {/* Primeira coluna */}
                <Box
                  width={
                    size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge'
                  }
                  gap='medium'
                >
                  <Text color='dark-2' size='small'>
                    Integre de forma fácil e rápida o módulo de compromissos com o Google Agenda.
                  </Text>

                  <Box
                    direction='row'
                    gap={ size === 'small' ? 'large' : 'medium' }
                    margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                  >
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <AddCircle size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Adicione
                      </Text>

                      <Text size='small' color='dark-2'>
                        Configure em poucos passos. É fácil, seguro e rápido!
                      </Text>
                    </Box>

                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <Trash size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Remova
                      </Text>

                      <Text size='small' color='dark-2'>
                        Fácil de configurar, fácil de remover.
                      </Text>
                    </Box>
                  </Box>

                  <Box
                    direction='row'
                    gap={ size === 'small' ? 'large' : 'medium' }
                    margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                  >
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <StatusInfo size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Status
                      </Text>

                      <Text size='small' color='dark-2'>
                        Acompanhe em tempo real o status da integração.
                      </Text>
                    </Box>

                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <ShareOption size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Integre com mais de uma conta
                      </Text>

                      <Text size='small' color='dark-2'>
                        Você pode integrar com mais de uma conta Google. Aqui você têm liberdade!
                      </Text>
                    </Box>
                  </Box>

                  <Button
                    primary
                    label='Adicionar integração'
                    color='neutral-1'
                    onClick={() => showFormManagerLayer() }
                  />
                </Box>

                {/* Separador coluna */}
                <Box
                  width={ size === 'large' || size === 'xlarge' ? 'xsmall' : 'xxsmall'}
                />

                {/* Segunda coluna */}
                <Box
                  gap='small'
                  width={ size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge' }
                >
                  {dataRows.length && (
                    <Box
                      round='xsmall'
                      border={{ color: 'light-4' }}
                      background='white'
                    >
                      {dataRows.map((row) => (
                          <Box
                            key={row.id}
                            align='center'
                            direction='row'
                            pad={{
                              horizontal: size === 'small' ? 'large' : 'medium',
                              vertical: size === 'small' ? 'medium' : 'xsmall'
                            }}
                            border={{ color: 'light-3', side: 'bottom' }}
                            justify='between'
                            gap='large'
                          >
                            <Box
                              align='start'
                            >
                              <Text color='dark-2' size='small'>{row.userName}</Text>

                              <Box
                                gap='xsmall'
                                direction='row'
                                justify='between'
                              >
                                <Text
                                  title={row.calendarId}
                                  color='dark-4'
                                  size='xsmall'
                                  truncate
                                >
                                  {row.calendarId}
                                </Text>

                                <Tag
                                  text={row.isValid ? 'Habilitada' : 'Com falha' }
                                  textSize='xsmall'
                                  background={row.isValid ? 'status-ok' : 'status-critical'}
                                />
                              </Box>
                            </Box>

                            <Box
                              align='end'
                            >
                              <Button
                                title='Excluir'
                                icon={<Trash color='status-error' size='medium' />}
                                onClick={() => { toggleFormDeleteLayer(row) }}
                              />
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ settingsIntegrationCalendarReducer: { searchResult } }) => ({
  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleFormDeleteLayer,
    showFormManagerLayer,
  }, dispath)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResult)
);
