import TYPES from '../actions/types';

const INITIAL_STATE = {
  loading: false,
  success: null,
};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.startIntegration:
      return {
        ...state,
        success: action.payload.success,
        loading: action.payload.loading,
      };

    case TYPES.loadStartIntegration:
      return {
        ...state,
        loading: !state.loading,
        success: null,
      };

    default:
      return state;
  }
};
