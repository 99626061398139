import React from 'react';
import { Box, ResponsiveContext, Anchor } from 'grommet';
import { LinkNext } from 'grommet-icons';

function EmptyCard({ link }) {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          round='small'
          width={ size === 'small' ? 'large' : 'small' }
          pad={{
            vertical: size === 'small' ? 'large' : 'medium',
            horizontal: size === 'small' ? 'large' : 'medium',
          }}
          border={{ color: 'dark-5', style: 'dashed' }}
          align='center'
          justify='center'
          gap='small'
        >
          <LinkNext color='dark-5' />

          <Anchor
            label={link.label}
            align='center'
            color='dark-3'
            size='small'
            onClick={ () => link.action() }
          />
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default EmptyCard;
