import axiosGlobalInstance from '../http-instance';

export default params => {
  const formattedParams = {
    token: params.token,
    password: params.password,
    passwordConfirm: params.confirmPassword,
  }

  return axiosGlobalInstance({
    method: 'POST',
    url: '/conta.php',
    data: formattedParams,
    headers: { action: 'change_password' }
  });
}
