import TYPES from './types';
import { listCalendars } from '../../../api/integration';

export const findPendingCalendars = () => {
  return dispatch => {
    dispatch({
      type: TYPES.loadingListCalendars,
    });

    listCalendars()
      .then(response => {
        dispatch([
          { type: TYPES.loadingListCalendars },
        ])

        dispatch([
          {
            type: TYPES.listCalendars,
            payload: response.data,
          }
        ])
      })
      .catch(() => {
        dispatch([
          { type: TYPES.errorListCalendars },
          { type: TYPES.loadingListCalendars },
        ]);
      })
  }
}
