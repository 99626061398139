import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  ResponsiveContext,
  Button,
  Layer,
  Anchor,
  TextInput,
  Box, Select,
  Text,
  MaskedInput,
  CheckBoxGroup,
  RadioButtonGroup
} from 'grommet';
import { Phone, FormClose } from 'grommet-icons';

import { Placeholder, ErrorForm } from '../../../../components';
import { TitleField, DescriptionField, InputPassword } from '../../../../components/form';
import { TitleDetail, Hr } from '../../../../components/detail';

import { update, create, hideFormManagerLayer } from '../../actions/form-manager';
import { changeValues } from '../../../../actions/form';

import visibleWidth from '../../../../constants/layer-width';
import selectJobRoleOptions from '../../../../constants/select-job-role-options';
import selectRulesOptions from '../../../../constants/select-rules-options';

class FormCreate extends Component {
  handlePost = formParams => {
    const { create, update } = this.props;

    if(formParams.id) {
      update({ formParams });
    }
    else {
      create({ formParams });
    }
  };

  render() {
    const {
      officeOptions,

      id,
      name,
      email,
      password,
      jobRoleId,
      officeId,
      profileId,
      telephone,
      rules,

      getServerPresponseError,
      watingServerResponse,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      hideFormManagerLayer,
    } = this.props;


    if(!toggleLayer || toggleLayer !== 'manager')
      return null;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={hideFormManagerLayer}
            onEsc={hideFormManagerLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{ top: 'large', bottom: 'xsmall' }}
              overflow='auto'
              flex
            >
              {watingServerResponse && !id && !getServerPresponseError ? (
                <Placeholder
                  title='Carregando...'
                  message='Aguarde que estamos preparando as coisas :)'
                />
              ) : null}

              {getServerPresponseError ? (
                <Placeholder
                  title='Ops! Algo deu errado :('
                  message='Estamos constrangidos por isso.'
                  button={{
                    label: 'Fechar',
                    action: hideFormManagerLayer,
                  }}
                />
              ) : null}

              {!getServerPresponseError && (id || !watingServerResponse) ? (
                <>
                  <Box
                    direction='row'
                    justify='between'
                    align='baseline'
                    margin={{ horizontal: 'large' }}
                  >
                    <TitleDetail value={id ? 'Editar usuário' : 'Novo usuário'} />

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={hideFormManagerLayer}
                    />
                  </Box>


                  <Box flex overflow='auto'>
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Box direction='row' gap='small'>
                        { /* Nome */ }
                        <Box width='medium'>
                          <TitleField text='Nome' required />

                          <TextInput
                            maxLength={60}
                            value={name ?? ''}
                            onChange={event =>
                              changeValues({ fieldName: 'name', value: event.target.value }, 'formChangeValues')
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='name' />
                        </Box>

                        { /* Telefone */ }
                        <Box width='medium'>
                          <TitleField text='Telefone' />

                          <MaskedInput
                            value={telephone ?? ''}
                            placeholder='00 0000 0000'
                            icon={<Phone />}
                            reverse={true}
                            onChange={event =>
                              changeValues({ fieldName: 'telephone', value: event.target.value }, 'formChangeValues')
                            }
                            mask={[
                              { fixed: '(' },
                              { length: 2, regexp: /\d/ },
                              { fixed: ')' },
                              { fixed: ' ' },
                              { length: 4, regexp: /\d/ },
                              { fixed: '-' },
                              { length: [4, 5], regexp: /\d/ }
                            ]}
                          />
                        </Box>
                      </Box>
                    </Box>


                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Box direction='row' gap='small'>
                        { /* Cargo */ }
                        <Box width='medium'>
                          <TitleField text='Cargo' required />

                          <Select
                            options={selectJobRoleOptions}
                            value={jobRoleId ?? ''}
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            emptySearchMessage='Selecione uma opção'
                            onChange={({ value: nextValue }) =>
                              changeValues({ fieldName: 'jobRoleId', value: nextValue }, 'formChangeValues')
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='jobRoleId' />
                        </Box>

                        { /* Escritório */ }
                        <Box width='medium'>
                          <TitleField text='Escritório' required />

                          <Select
                            options={officeOptions}
                            value={officeId ?? ''}
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            emptySearchMessage='Selecione uma opção'
                            onChange={({ value: nextValue }) =>
                              changeValues({ fieldName: 'officeId', value: nextValue }, 'formChangeValues')
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='officeId' />
                        </Box>
                      </Box>
                    </Box>

                    <Hr />


                    {/* E-mail */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      direction={ size === 'small' ? 'column' : 'row' }
                      align='stretch'
                      flex={{ shrink: 0 }}
                      gap='small'

                    >
                      <Box
                        width={ size === 'small' ? '100vw' : '50vw'}
                        gap='xxsmall'
                      >
                        <TitleField text='E-mail' required />

                        <DescriptionField
                          text='E-mail de acesso ao sistema.'
                        />
                      </Box>

                      <Box
                        width={ size === 'small' ? '100vw' : '50vw'}
                      >
                        <TextInput
                          maxLength={60}
                          value={email ?? ''}
                          onChange={event =>
                            changeValues({ fieldName: 'email', value: event.target.value }, 'formChangeValues')
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='email' />
                      </Box>
                    </Box>


                    {/* Senha */}
                    {!id && (
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        direction={ size === 'small' ? 'column' : 'row' }
                        align='stretch'
                        flex={{ shrink: 0 }}
                        gap='small'
                      >
                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                          gap='xxsmall'
                        >
                          <TitleField text='Senha' required />

                          <DescriptionField
                            text='Senha de acesso ao sistema. Digite uma senha de pelo menos 8 digitos.'
                          />
                        </Box>

                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                        >
                          <InputPassword
                            maxLength={30}
                            value={password ?? ''}
                            onChange={event =>
                              changeValues({ fieldName: 'password', value: event.target.value }, 'formChangeValues')
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='password' />
                        </Box>
                      </Box>
                    )}

                    <Hr />

                    {/* Perfil de acesso */}
                    <Box
                      margin={{
                        vertical: size === 'small' ? 'medium' : 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Qual será o perfil de acesso?' required />

                      <Box
                        round='xxsmall'
                        pad={{
                          vertical: 'small',
                        }}
                        gap='xsmall'
                      >
                        <RadioButtonGroup
                          name='type'
                          value={profileId ?? ''}
                          options={
                            [
                              { label: 'Administrador', id: '1', value: '1' },
                              { label: 'Básico', id: '2', value: '2' },
                            ]
                          }
                          onChange={event => {
                            changeValues({ fieldName: 'profileId', value: event.target.value }, 'formChangeValues')
                          }}
                        >
                          {(option, { checked }) => {
                            let background = null;
                            let borderColor = 'dark-5';

                            if(checked) {
                              background = 'brand';
                              borderColor = 'brand';
                            }

                            return (
                              <Box
                                direction='row'
                                gap='small'
                                justify='center'
                                align='center'
                              >
                                <Box
                                  width={'20px'}
                                  height={'20px'}
                                  background={background}
                                  round='xxsmall'
                                  border={{
                                    color: borderColor
                                  }}
                                />
                                <Text color='dark-2' size='small'>{option.label}</Text>
                              </Box>
                            )
                          }}
                        </RadioButtonGroup>
                      </Box>

                      <ErrorForm errorsList={postDataError.errors} fieldName='profileId' />
                    </Box>


                    {/* Acessos */}
                    {profileId === '2' && <Box
                      margin={{
                        vertical: size === 'small' ? 'medium' : 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Defina as permissões desse usuário' required />

                      <Box
                        round='xxsmall'
                        pad={{
                          vertical: 'small',
                        }}
                        gap='xsmall'
                      >
                        <CheckBoxGroup
                          labelKey='label'
                          valueKey='id'
                          value={rules}
                          disabled={profileId === '1'}
                          onChange={({ value: nextValue }) => {
                            changeValues({ fieldName: 'rules', value: nextValue }, 'formChangeValues');
                          }}
                          options={
                            selectRulesOptions.map(row => ({
                              name: `${row.category} - ${row.name}`,
                              id: row.id,
                            }))
                          }
                        >
                          {(option, { checked }) => {
                            let background = null;
                            let borderColor = 'dark-5';

                            if(checked) {
                              background = 'brand';
                              borderColor = 'brand';
                            }

                            return (
                              <Box
                                direction='row'
                                gap='small'
                                justify='center'
                                align='center'
                              >
                                <Box
                                  width={'20px'}
                                  height={'20px'}
                                  background={background}
                                  round='xxsmall'
                                  border={{
                                    color: borderColor
                                  }}
                                />
                                <Text color='dark-2' size='small'>{option.name}</Text>
                              </Box>
                            )
                          }}
                        </CheckBoxGroup>
                      </Box>
                    </Box>}
                  </Box>


                  <Box
                    direction='row'
                    pad={{ vertical: 'medium' }}
                    justify='center'
                    align='center'
                    gap='small'
                  >
                    <Box width='xsmall'>
                      <Anchor
                        color='dark-3'
                        label='Cancelar'
                        disabled={ loadingPostForm }
                        onClick={hideFormManagerLayer}
                      />
                    </Box>

                    <Box width='small'>
                      <Button
                        color='brand'
                        primary
                        label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                        disabled={ loadingPostForm }
                        onClick={() =>
                          this.handlePost({
                            id,
                            name,
                            email,
                            password,
                            jobRoleId,
                            officeId,
                            profileId,
                            telephone,
                            rules: profileId === '1' ? selectRulesOptions.map(a => a.id) : rules,
                          })
                        }
                      />
                    </Box>
                  </Box>
                </>
              ) : null}
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

const mapStateToProps = ({ selectOptionsReducer, layerReducer, formCreateUpdateCopyGeneric }) => ({
  officeOptions: selectOptionsReducer.officeOptions,

  id: formCreateUpdateCopyGeneric.id,
  name: formCreateUpdateCopyGeneric.name,
  email: formCreateUpdateCopyGeneric.email,
  password: formCreateUpdateCopyGeneric.password,
  jobRoleId: formCreateUpdateCopyGeneric.jobRoleId,
  officeId: formCreateUpdateCopyGeneric.officeId,
  profileId: formCreateUpdateCopyGeneric.profileId,
  telephone: formCreateUpdateCopyGeneric.telephone,
  rules: formCreateUpdateCopyGeneric.rules,

  watingServerResponse: formCreateUpdateCopyGeneric.watingServerResponse,
  getServerPresponseError: formCreateUpdateCopyGeneric.getServerPresponseError,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    update,
    create,
    changeValues,
    hideFormManagerLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);
