import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Text, Box, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';

import { ErrorForm, Tag } from '../../../../components';
import { TitleDetail } from '../../../../components/detail';

import { searchServices } from '../../actions/form-search';
import { remove, toggleFormDeleteLayer } from '../../actions/form-delete';

import visibleWidth from '../../../../constants/layer-width';

class FormDelete extends Component {
  callbackPostSuccess = () => {
    const {
      searchServices,
      toggleFormDeleteLayer,
    } = this.props;

    searchServices();
    toggleFormDeleteLayer();
  };

  handlePost = formParams => {
    const { remove } = this.props;
    remove({
      formParams,
      callbackSuccess: this.callbackPostSuccess
    });
  };

  render() {
    const {
      id,
      reference,
      status,
      statusColor,
      category,
      origin,

      toggleLayer,
      loadingPostForm,
      postDataError,
      toggleFormDeleteLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'delete') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormDeleteLayer}
              onEsc={toggleFormDeleteLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Remover atendimento' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={toggleFormDeleteLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  <Box
                    flex={{ shrink: 0 }}
                    margin={{ horizontal: 'large' }}
                    gap='xsmall'
                  >
                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-2'>Referência</Text>
                      <Text size='medium' color='dark-4'>{reference}</Text>
                    </Box>

                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-2'>Status</Text>

                      <Tag
                        text={status}
                        background={statusColor}
                      />
                    </Box>

                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-2'>Categoria</Text>
                      <Text size='medium' color='dark-4'>{category}</Text>
                    </Box>

                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-2'>Origem</Text>
                      <Text size='medium' color='dark-4'>{origin}</Text>
                    </Box>

                    <Box
                      margin={{
                        top: 'medium'
                      }}
                    >
                      <Text
                        size='small'
                        textAlign='start'
                        color='status-warning'
                      >
                        Após a confirmação o atendimento será PERMANENTEMENTE excluido.
                      </Text>
                    </Box>

                    <Box
                      flex={{ shrink: 0 }}
                    >
                      <ErrorForm errorsList={postDataError.errors} />
                    </Box>
                  </Box>
                </Box>


                {/* botoes acao */}
                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      disabled={ loadingPostForm }
                      onClick={toggleFormDeleteLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='status-error'
                      primary
                      label={ !loadingPostForm ? 'SIM, Remover': 'Removendo...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ servicesReducer: { remove }, layerReducer }) => ({
  id: remove.id,
  reference: remove.reference,
  status: remove.status,
  statusColor: remove.statusColor,
  category: remove.category,
  origin: remove.origin,

  loadingPostForm: remove.loadingPostForm,
  postDataError: remove.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    remove,
    searchServices,
    toggleFormDeleteLayer,
  }, dispatch);

export const Delete = connect(mapStateToProps, mapDispatchToProps)(FormDelete);
