import { saveAs } from 'file-saver';
import { formattedDateTime } from '../../../../utils';

const generateHtml = data => {
  const {
    typeName,
    number,
    year,
    statusName,
    areaInterestName,
    region,
    proceeding,
    createdAt,
    updatedAt,
    menu,
    authors,
  } = data;

  return `<!DOCTYPE html>
    <html>
      <head>
        <title>${typeName}</title>

        <style>
          * { margin: 0; padding: 0; }

          body { margin: 0; padding: 0; font-size: 13px; color: #333333; font-family: sans-serif; }

          h1 { font-size: 18px; font-weight: normal; }

          table { border-collapse: collapse; width:100%; }
          table tr th { text-align: left; text-transform: uppercase; }

          table tr td,
          table tr th { border-bottom: 1px solid #cccccc; border-top: 0px solid #cccccc; padding: 10px 0px; }

          .content { padding: 16px; }
          .result-content { margin: 10px 0 10px 0; }
          </style>
      </head>

      <body>
        <div class='content'>
          <h1>${typeName}</h1>

          <div class='result-content'>
            <table>
              <tr>
                <th colspan='4'>Dados</th>
              </tr>

              <tr>
                <th width='15%'>Número</th>
                <td width='40%'>${number || ''}</td>

                <th width='15%'>Ano</th>
                <td width='30%'>${year || ''}</td>
              </tr>

              <tr>
                <th width='15%'>Em tramitação</th>
                <td width='40%'>${proceeding ? 'Sim' : 'Não'}</td>

                <th width='15%'>Status</th>
                <td width='30%'>${statusName}</td>
              </tr>

              <tr>
                <th width='15%'>Área de interesse</th>
                <td width='40%'>${areaInterestName || ''}</td>

                <th width='15%'>Região</th>
                <td width='30%'>${region || ''}</td>
              </tr>

              <tr>
                <th width='15%'>Autores</th>
                <td colspan='3'>${authors || ''}</td>
              </tr>

              <tr>
                <th width='15%'>Cadastro</th>
                <td width='40%'>${formattedDateTime(createdAt, 'ddd, DD MMM YYYY')}</td>

                <th width='15%'>Atualização</th>
                <td width='30%'>${formattedDateTime(updatedAt, 'ddd, DD MMM YYYY')}</td>
              </tr>

              <tr>
                <th width='15%'>Assunto</th>
                <td width='40%' colspan='4'>
                  ${menu}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </body>
    </html>`;
}

export const printOut = (args = {}) => {
  const { data, fileName } = args;
  const actionResultHtml = generateHtml(data);
  const blob = new Blob([actionResultHtml], { type: 'text/html;charset=utf-8' });

  saveAs(blob, fileName);
}
