import { formattedDateTime } from '../../../utils';

export default appointment => ({
  id: null,
  subject: appointment.subject,
  date: appointment.startDate,
  description: appointment.description,
  hourStart: formattedDateTime(appointment.startDate, 'HH:mm'),
  hourEnd: formattedDateTime(appointment.endDate, 'HH:mm'),
  participant: appointment.participants.map(a => a.userId),
  requesterName: appointment.requester.name,
  requesterPhone: appointment.requester.telephone,
  location: appointment.location.local,
  notify: appointment.notify,
  integrate: appointment.integrate,

  isCopy: true,
  isEditing: false,
});
