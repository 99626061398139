import React from 'react';
import { ResponsiveContext, Box, Text, Anchor, Image } from 'grommet';
import urlRoutes from '../../../../config/url-routes';

import logo from '../../../../assets/logomarca.png';

function Header() {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          pad={{
            horizontal: 'medium',
            vertical: size === 'small' ? 'medium' : 'small',
          }}
          direction={ size === 'small' ? 'column' : 'row' }
          justify='center'
          border={{
            color: 'light-3',
            size: 'xsmall',
            side: 'bottom',
          }}
          margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
          background='white'
        >
          <Box
            direction={ size !== 'small' ? 'row' : 'column' }
            align='center'
            width='xlarge'
            justify='between'
          >
            <Box><Image src={logo} fill /></Box>

            {size !== 'small' && (
              <Box>
                <Text size='xsmall'>Já possui uma conta?</Text>
                <Anchor label='Acessar Minha Conta' href={urlRoutes.login} />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Header;
