import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { ResponsiveContext, Button, Box, Anchor } from 'grommet';

import ComposeMessage from './message';
import Settings from './settings';
import Recipients from './recipients';

import { clearForm, create } from '../../actions/form-create';
import { clearListVoters, clearCost } from '../../actions/search-voters';

import { formattedDateTime } from '../../../../utils';
import urlRoutes from '../../../../config/url-routes';

class CampaignsCreate extends Component {
  callbackPostSuccess = () => {
    const {
      history,
      clearForm,
    } = this.props;

    clearForm();

    history.push(urlRoutes.campaignListUrl);
  };

  handleCancel = () => {
    const {
      history,
      clearForm,
      clearListVoters,
      clearCost,
    } = this.props;

    clearForm();
    clearListVoters();
    clearCost();
    history.push(urlRoutes.campaignListUrl);
  };

  handlePost = formParams => {
    const { create } = this.props;
    create({ formParams, callbackSuccess: this.callbackPostSuccess });
  };

  render() {
    const {
      type,
      name,
      dateStart,
      hourStart,
      composerMessage,
      searchVoters,
      voters,

      loadingPostForm,
      getCostLoading,
    } = this.props;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width='xlarge'
            >
              {/* Settings */}
              <Settings />

              <Box
                border={{ color: 'light-3', side: 'bottom' }}
                margin={{ top: 'medium', bottom: 'medium' }}
              />

              {/* Recipients */}
              <Recipients />

              <Box
                border={{ color: 'light-3', side: 'bottom' }}
                margin={{ top: 'medium', bottom: 'medium' }}
              />

              {/* message */}
              <ComposeMessage />

              <Box
                align='center'
                margin={{ top: 'large' }}
              >
                <Box direction='row'>
                  <Box pad={{ right: 'xsmall', bottom: 'small' }} width='small' justify='center' align='center'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      disabled={ loadingPostForm }
                      onClick={this.handleCancel}
                    />
                  </Box>

                  <Box pad={{ right: 'xsmall', bottom: 'small' }} width='medium'>
                    <Button
                      color='brand'
                      primary
                      label={!loadingPostForm ? 'Salvar': 'Salvando...'}
                      disabled={loadingPostForm || getCostLoading}
                      onClick={() => {
                        this.handlePost({
                          type,
                          name,
                          dateStart: formattedDateTime(dateStart),
                          hourStart,
                          composerMessage,
                          searchVoters,
                          voters,
                        });
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ campaignsReducer: { formCreate, cost } }) => ({
  type: formCreate.type,
	name: formCreate.name,
	dateStart: formCreate.dateStart,
  hourStart: formCreate.hourStart,
  composerMessage: formCreate.composerMessage,
  searchVoters: formCreate.searchVoters,
  voters: formCreate.voters,

  getCostLoading: cost.getCostLoading,

  loadingPostForm: formCreate.loadingPostForm,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    clearForm,
    create,
    clearListVoters,
    clearCost,
  }, dispath)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignsCreate));
