import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';
import { formatFormPass } from '../utils';
import { showNotificationLayer } from '../../../actions/notification';
import { changePass } from '../../../api/settings-user';

const loadingPostManager = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const updatePass = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    changePass(params.formParams)
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(toggleFormChangePassLayer());

        dispatch(
          showNotificationLayer(`O usuário ${response.data.name} foi atualizado!`)
        );
      })
      .catch(responseFail => {
        dispatch(loadingPostManager());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
};

export const toggleFormChangePassLayer = params => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.user,
      action: analytics.actions.userChangeStatus,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'changePass' },
      {
        type: COMMON_TYPES.formInitializeValues,
        payload: formatFormPass(params),
      },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
};
