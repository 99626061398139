import TYPES from '../actions/types';
import paginationDefault from '../../../constants/pagination';

const INITIAL_STATE = {
  type: null,
  status: null,
  areaInterest: null,
  year: null,
  number: null,
  menu: null,
  region: null,

  page: paginationDefault.page,
  limit: paginationDefault.limit,
}

export default function(state = INITIAL_STATE, action) {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    // fields
    case TYPES.changeFormSearchField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.setDefaultPage:
      return {
        ...state,
        page: paginationDefault.page,
        limit: paginationDefault.limit,
      };

    case TYPES.clearFormSearchFields:
      return {
        ...state,
        type: null,
        status: null,
        areaInterest: null,
        year: null,
        number: null,
        menu: null,
        region: null,
      }

    case TYPES.paginationFormSearch:
      return { ...state, page: action.payload };

    default:
      return state;
  }
}
