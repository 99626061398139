import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FormManager from '../components/form-manager/form-manager';
import Header from '../../../components/header/header';

import { getGroupsOptions } from '../../../actions/select-options';
import { loadDataEdit, clearForm } from '../actions/form-create';

class VotersEdit extends Component {
  constructor(props) {
    super(props);

    const { id } = this.props.match.params;
    const { loadDataEdit, clearForm } = this.props;

    clearForm();
    loadDataEdit({ id });
  }

  componentDidMount() {
    const {
      getGroupsOptions,
    } = this.props;

    getGroupsOptions();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <FormManager />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    clearForm,
    loadDataEdit,
    getGroupsOptions,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(VotersEdit);
