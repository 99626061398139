import TYPES from '../actions/types';
import { formatDataFormComment } from '../utils';

const INITIAL_STATE = {
  id: null,
  date: null,
  note: null,
  type: null,
  number: null,
  year: null,

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    case TYPES.changeFormCommentField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.setDataFormComment:
      return {
        ...state,
        ...formatDataFormComment(action.payload),

        postDataError: {
          message: '',
          errors: []
        },
      }

    case TYPES.postLoadingFormComment:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.postFormCommentError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
