import { showNotificationLayer } from '../../../actions/notification';
import { listVoterPrint } from '../../../api/voter';
import { formattedDateTime } from '../../../utils';
import { saveAs } from 'file-saver';

export const generatePrintList = params =>
  dispatch => {
    dispatch(
      showNotificationLayer('Gerando arquivo. Aguarde...')
    );

    listVoterPrint(params)
      .then(response => {
        saveAs(
          response.data,
          `listagem-eleitores-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.pdf`
        );
      })
      .catch(() =>
        dispatch(
          showNotificationLayer('Ops... não conseguimos gerar o arquivo.')
        )
      );
  }
