export default object => ({
  identifier: object.identifier,

  clientId: object.clientId,
  clientStatus: object.product.status,

  openInvoice: object.openInvoice,

  inputBillingName: object.billing && object.billing.name ? object.billing.name : null,
  inputBillingDocument: object.billing && object.billing.document ? object.billing.document : null,
});
