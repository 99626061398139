import TYPES from '../actions/types';

const INITIAL_STATE = {
  toggleLayer: null,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.toggleLayer:
      return { ...state, toggleLayer: action.payload }

    default:
      return state;
  }
}
