import TYPES from './types';
import {
  detailsServices,
  detailsVoters,
  detailsVotersDistricts,
  totalPendingTask,
} from '../../../api/dashboard';

export const getServices = () =>
  detailsServices()
    .then(response => {
      return {
        type: TYPES.getDetailService,
        payload: response,
      }
    })
    .catch(() => ({ type: TYPES.getDetailsError }))

export const getVoters = () =>
  detailsVoters()
    .then(response => {
      return {
        type: TYPES.getDetailVoter,
        payload: response,
      }
    })
    .catch(() => ({ type: TYPES.getDetailsError }))

export const getVotersDistricts = () =>
  detailsVotersDistricts()
    .then(response => {
      return {
        type: TYPES.getDetailVoterDistrict,
        payload: response,
      }
    })
    .catch(() => ({ type: TYPES.getDetailsError }))

export const getTotalPendingTask = () =>
  totalPendingTask()
    .then(({ data }) => ({
        type: TYPES.getTotalPendingTask,
        payload: data,
    }))
    .catch()
