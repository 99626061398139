
const TYPES = ({
  clearAllForm: 'CLEAR_FORGOT_PASSWORD_FORM',
  changeEmail: 'CHANGE_FORGOT_PASSWORD_FORM_EMAIL',
  loadFormPostToggle: 'LOAD_FORGOT_PASSWORD_FORM_TOGGLE',

  formPostSuccess: 'POST_FORGOT_PASSWORD_FORM_SUCCESS',
  formPostError: 'POST_FORGOT_PASSWORD_FORM_ERROR',
});

export default TYPES;
