import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  ResponsiveContext,
  Button,
  Layer,
  TextInput,
  Box,
  Anchor,
  RadioButtonGroup,
  Text
 } from 'grommet';
import { FormClose } from 'grommet-icons';

import { TitleField, CalendarDropButton } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { searchCampaigns, clearFieldsFormSearch, hideLayer, setDefaultPageRules, changeField } from '../../actions/form-search';

import paginationDefault from '../../../../constants/pagination';
import visibleWidth from '../../../../constants/layer-width';

import selectCampaignStatusOptions from '../../../../constants/select-campaign-status-options';
import selectCampaignTypeOptions from '../../../../constants/select-campaign-type-options';

import { formattedDateTime } from '../../../../utils';

class FormSearch extends Component {
  searchAndSetPagination = formParams => {
    const { searchCampaigns, hideLayer, setDefaultPageRules } = this.props;

    setDefaultPageRules();
    searchCampaigns(formParams);
    hideLayer();
  };

  render() {
    const {
      name,
      type,
      dateSend,
      status,

      toggleLayer,
      hideLayer,
      clearFieldsFormSearch,
      changeField,
    } = this.props;

    if(toggleLayer && toggleLayer === 'search') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideLayer}
              onEsc={hideLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Filtrar' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={hideLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Nome da campanha' />

                    <TextInput
                      value={name ?? ''}
                      onChange={event => changeField(event.target.value, 'changeFormSearchName')}
                    />
                  </Box>

                  {/* Data de envio */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Data de envio' />

                    <CalendarDropButton
                      date={dateSend ?? ''}
                      onSelect={(selectedDate) => changeField(selectedDate, 'changeFormSearchDateSend')}
                    />
                  </Box>


                  {/* Tipo */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Tipo' />

                      {type && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeField(null, 'changeFormSearchType')
                        }
                      />)}
                    </Box>

                    <Box
                      round='xxsmall'
                      pad={{
                        vertical: 'small',
                      }}
                      gap='xsmall'
                    >
                      <RadioButtonGroup
                        name='type'
                        value={type ?? ''}
                        options={
                          selectCampaignTypeOptions.map(({ name, id }) => ({
                            label: name,
                            id: `type_${id}`,
                            value: id.toString(),
                          }))
                        }
                        onChange={event => {
                          changeField(event.target.value, 'changeFormSearchType')
                        }}
                      >
                        {(option, { checked }) => {
                          let background = null;
                          let borderColor = 'dark-5';

                          if(checked) {
                            background = 'brand';
                            borderColor = 'brand';
                          }

                          return (
                            <Box
                              direction='row'
                              gap='small'
                              justify='center'
                              align='center'
                            >
                              <Box
                                width={'20px'}
                                height={'20px'}
                                background={background}
                                round='xxsmall'
                                border={{
                                  color: borderColor
                                }}
                              />
                              <Text color='dark-2' size='small'>{option.label}</Text>
                            </Box>
                          )
                        }}
                      </RadioButtonGroup>
                    </Box>
                  </Box>


                  {/* Status */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Status' />

                      {status && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeField(null, 'changeFormSearchStatus')
                        }
                      />)}
                    </Box>

                    <Box
                      round='xxsmall'
                      pad={{
                        vertical: 'small',
                      }}
                      gap='xsmall'
                    >
                      <RadioButtonGroup
                        name='status'
                        value={status ?? ''}
                        options={
                          selectCampaignStatusOptions.map(({ name, id }) => ({
                            label: name,
                            id: `status_${id}`,
                            value: id.toString(),
                          }))
                        }
                        onChange={event => {
                          changeField(event.target.value, 'changeFormSearchStatus')
                        }}
                      >
                        {(option, { checked }) => {
                          let background = null;
                          let borderColor = 'dark-5';

                          if(checked) {
                            background = 'brand';
                            borderColor = 'brand';
                          }

                          return (
                            <Box
                              direction='row'
                              gap='small'
                              justify='center'
                              align='center'
                            >
                              <Box
                                width={'20px'}
                                height={'20px'}
                                background={background}
                                round='xxsmall'
                                border={{
                                  color: borderColor
                                }}
                              />
                              <Text color='dark-2' size='small'>{option.label}</Text>
                            </Box>
                          )
                        }}
                      </RadioButtonGroup>
                    </Box>
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  margin={{ horizontal: 'large' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='small'>
                    <Button
                      color='dark-3'
                      label='Limpar'
                      onClick={() => clearFieldsFormSearch()}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label='Buscar'
                      onClick={() =>
                        this.searchAndSetPagination({
                          name,
                          type,
                          dateSend: formattedDateTime(dateSend),
                          status,
                          page: paginationDefault.page,
                          limit: paginationDefault.limit,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ campaignsReducer: { formSearch }, layerReducer }) => ({
  name: formSearch.name,
  type: formSearch.type,
  dateSend: formSearch.dateSend,
  status: formSearch.status,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    searchCampaigns,
    clearFieldsFormSearch,
    setDefaultPageRules,
    hideLayer,
    changeField,
  }, dispatch);

export const Search = connect(mapStateToProps, mapDispatchToProps)(FormSearch);
