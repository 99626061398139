import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Text, Box, RadioButton, TextInput, Select, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';

import { TitleField } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';

import { changeField, createTag, closeGenerateTagLayer } from '../../actions/form-generate-tag';

class FormGenerateTag extends Component {
  handlePost = formParams => {
    const { createTag } = this.props;
    createTag(formParams);
  };

  render() {
    const {
      tagOptions,

      tagId,
      filters,
      startPrintLine,
      startPrintColumn,
      printItens,
      loadingPostForm,

      changeField,
      toggleLayer,
      closeGenerateTagLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'createTag') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={closeGenerateTagLayer}
              onEsc={closeGenerateTagLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Configure a emissão das etiquetas' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={closeGenerateTagLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      border={{ color: 'status-warning', side: 'left', size: 'medium' }}
                      round='xsmall'
                      pad='medium'
                      background='accent-4'
                    >
                      <Text size='small' color='dark-1'>
                        Antes de imprimir certifique-se de que a impressora esta configurada para o tamanho de papel "Carta".
                      </Text>
                    </Box>
                  </Box>


                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Etiqueta' required />

                    <Select
                      options={tagOptions}
                      value={tagId ?? ''}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      onChange={({ value: nextValue }) => changeField({ fieldName: 'tagId', value: nextValue }) }
                    >
                      {({ name, perLine, perPage }) => {
                        return (
                          <Box pad={{ horizontal: 'small', vertical: 'small' }}>
                            <Text size='medium' color='dark-1'>{name}</Text>
                            <Text size='xsmall' color='dark-5'>{perLine} por linha</Text>
                            <Text size='xsmall' color='dark-5'>{perPage} por página</Text>
                          </Box>
                        )
                      }}
                    </Select>
                  </Box>


                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' gap='small'>
                      <Box width='50vw'>
                        <TitleField text='Imprimir a partir da linha' required />

                        <TextInput
                          type='number'
                          min={0}
                          max={10}
                          value={startPrintLine}
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'startPrintLine', value: nextValue })
                          }
                        />
                      </Box>

                      <Box width='50vw'>
                        <TitleField text='Imprimir a partir da coluna' required />

                        <TextInput
                          type='number'
                          min={0}
                          max={3}
                          value={startPrintColumn}
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'startPrintColumn', value: nextValue })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>


                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Quais informações serão impressas na etiqueta' required />

                    <Box
                      gap='small'
                      direction='row'
                      margin={{ vertical: 'small' }}
                    >
                      <Box
                        width='50vw'
                        border={{ color: printItens === 'nameAddressZipcode' ? 'brand' : 'light-5' }}
                        background={{ color: printItens === 'nameAddressZipcode' ? 'light-2' : null }}
                        round='xsmall'
                      >
                        <RadioButton
                          name='name'
                          value='nameAddressZipcode'
                          checked={printItens === 'nameAddressZipcode'}
                          onChange={event => changeField({ fieldName: 'printItens', value: event.target.value })}
                        >
                          {({ checked }) => (
                            <Box
                              justify='center'
                              height={{ min: 'xsmall' }}
                              pad={{ vertical: 'small', horizontal: 'medium' }}
                            >
                              <Text size='small' color='dark-3'>Nome</Text>
                              <Text size='small' color='dark-3'>Endereço</Text>
                            </Box>
                          )}
                        </RadioButton>
                      </Box>

                      <Box
                        width='50vw'
                        border={{ color: printItens === 'nameAddressZipcodeDateNow' ? 'brand' : 'light-5' }}
                        background={{ color: printItens === 'nameAddressZipcodeDateNow' ? 'light-2' : null }}
                        round='xsmall'
                      >
                        <RadioButton
                          name='name'
                          value='nameAddressZipcodeDateNow'
                          checked={printItens === 'nameAddressZipcodeDateNow'}
                          onChange={event => changeField({ fieldName: 'printItens', value: event.target.value })}
                        >
                          {({ checked }) => (
                            <Box
                              justify='center'
                              height={{ min: 'xsmall' }}
                              pad={{ vertical: 'small', horizontal: 'medium' }}
                            >
                              <Text size='small' color='dark-3'>Nome</Text>
                              <Text size='small' color='dark-3'>Endereço</Text>
                              <Text size='small' color='dark-3'>Data atual</Text>
                            </Box>
                          )}
                        </RadioButton>
                      </Box>

                      <Box
                        width='50vw'
                        border={{ color: printItens === 'nameAddressZipcodeBirthday' ? 'brand' : 'light-5' }}
                        background={{ color: printItens === 'nameAddressZipcodeBirthday' ? 'light-2' : null }}
                        round='xsmall'
                      >
                        <RadioButton
                          name='name'
                          value='nameAddressZipcodeBirthday'
                          checked={printItens === 'nameAddressZipcodeBirthday'}
                          onChange={event => changeField({ fieldName: 'printItens', value: event.target.value })}
                        >
                          {({ checked }) => (
                            <Box
                              justify='center'
                              height={{ min: 'xsmall' }}
                              pad={{ vertical: 'small', horizontal: 'medium' }}
                            >
                              <Text size='small' color='dark-3'>Nome</Text>
                              <Text size='small' color='dark-3'>Endereço</Text>
                              <Text size='small' color='dark-3'>Aniversário</Text>
                            </Box>
                          )}
                        </RadioButton>
                      </Box>
                    </Box>
                  </Box>

                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                  flex={{ shrink: 0 }}
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={closeGenerateTagLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ? 'Criar': 'Criando...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          tagId,
                          filters,
                          startPrintLine,
                          startPrintColumn,
                          printItens,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ votersReducer: { formGenerateTag, selectOptions }, layerReducer }) => ({
  tagOptions: selectOptions.tagOptions,

  tagId: formGenerateTag.tagId,
  filters: formGenerateTag.filters,
  startPrintLine: formGenerateTag.startPrintLine,
  startPrintColumn: formGenerateTag.startPrintColumn,
  printItens: formGenerateTag.printItens,

  loadingPostForm: formGenerateTag.loadingPostForm,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeField,
    createTag,
    closeGenerateTagLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormGenerateTag);
