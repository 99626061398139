import axiosGlobalInstance from '../http-instance';

export default code => {
  return axiosGlobalInstance({
    method: 'GET',
    url: '/compromisso.public.php',
    params: { code },
    headers: { action: 'getDetails', authorized: true }
  });
};
