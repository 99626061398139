import TYPES from '../actions/types';

const INITIAL_STATE = {
  //id: 0,
  //reference: '',
  //status: '',
  //statusColor: '',
  //category: '',
  //origin: '',

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.setDataFormDelete:
      return action.payload ?
        {
          ...state,
          ...action.payload,
          postDataError: { message: '', errors: [] },
        } : INITIAL_STATE;

    case TYPES.loadingFormDelete:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formPostDeleteError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
