
const TYPES = ({
  clearAllForm: 'CLEAR_ONBORDING_FORM',
  changeField: 'CHANGE_ONBORDING_FORM_FIELD',
  loadFormPostToggle: 'LOAD_CREATE_TRIAL_FORM_TOGGLE',
  formPostSuccess: 'POST_CREATE_TRIAL_FORM_SUCCESS',
  formPostError: 'POST_CREATE_TRIAL_FORM_ERROR',
});

export default TYPES;
