import TYPES from '../actions/types';

const INITIAL_STATE = {
  totalVoters: 0,
  costUnit: 0,
  costTotal: 0,
  costBankSlip: 0,

  getCostLoading: false,
  getCostError: false,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.getCostSuccess:
      return { ...state, ...action.payload.data }

    case TYPES.getCostClearResult:
      return { ...state, ...INITIAL_STATE }

    case TYPES.getCostError:
      return { ...state, getCostError: true };

    case TYPES.getVotersAndCostLoading:
      return { ...state, getCostLoading: !state.getCostLoading };

    default:
      return state;
  }
}
