const selectColorOptions = [
  { id: '#e6c300', name: 'Ouro' },
  { id: '#cc0000', name: 'Coração' },
  { id: '#e62200', name: 'Tomate' },
  { id: '#e69500', name: 'Tangerina' },
  { id: '#f0b828', name: 'Banana' },
  { id: '#37a329', name: 'Manjericão' },
  { id: '#96c33c', name: 'Sálvia' },
  { id: '#006dcc', name: 'Pavão' },
  { id: '#084391', name: 'Mirtilo' },
  { id: '#6585e7', name: 'Lavanda' },
  { id: '#72418b', name: 'Uva' },
  { id: '#f35b58', name: 'Flamingo' },
  { id: '#5d5d56', name: 'Grafite' },
];

export default selectColorOptions;
