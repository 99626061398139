import axios from 'axios';
import addInterceptorResponse from './interceptor-response';

let axiosInstance;

const axiosGlobalInstance = () => {
  if(!axiosInstance) {
    axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_HOST_API,
      timeout: 15000,
    });

    addInterceptorResponse(axiosInstance);
  }

  return axiosInstance;
}

export default axiosGlobalInstance();
