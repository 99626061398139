import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default params => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);
  const formData = new FormData();

  formData.append("file", params);

  return axiosGlobalInstance({
    method: 'POST',
    url: '/object.php',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      token: decipher?.token?.value,
      action: 'create',
    }
  });
}
