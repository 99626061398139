import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextArea, Anchor, TextInput, MaskedInput, Box, Select } from 'grommet';
import { Phone, FormClose } from 'grommet-icons';

import { Placeholder, ErrorForm } from '../../../../components';
import { TitleField, CalendarDropButton } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { searchExpenses } from '../../actions/form-search';
import { update, create, hideFormManagerLayer } from '../../actions/form-manager';
import { changeValues } from '../../../../actions/form';

import visibleWidth from '../../../../constants/layer-width';

import selectYesNoOptions from '../../../../constants/select-yesno-options';
import selectExpenseCategory from '../../../../constants/select-expense-category';
import selectExpenseStatus from '../../../../constants/select-expense-status';

import { formattedDateTime } from '../../../../utils';

class FormCreate extends Component {
  callbackPostSuccess = () => {
    const {
      searchExpenses,
      hideFormManagerLayer,
    } = this.props;

    searchExpenses();
    hideFormManagerLayer();
  };

  handlePost = formParams => {
    const { isEditing, create, update } = this.props;

    if(isEditing && formParams.id) {
      update({ formParams });
    }
    else {
      create({
        formParams,
        callbackSuccess: this.callbackPostSuccess
      });
    }
  };

  render() {
    const {
      id,
      categoryId,
      statusId,
      value,
      isRefund,
      name,
      nationalRegister,
      telephone,
      dateRefund,
      dateExpense,
      description,

      getServerPresponseError,
      watingServerResponse,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      hideFormManagerLayer,
    } = this.props;


    if(!toggleLayer || toggleLayer !== 'manager')
      return null;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={hideFormManagerLayer}
            onEsc={hideFormManagerLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{ top: 'large', bottom: 'xsmall' }}
              overflow='auto'
              flex
            >
              {watingServerResponse && !id && !getServerPresponseError ? (
                <Placeholder
                  title='Carregando...'
                  message='Aguarde que estamos preparando as coisas :)'
                />
              ) : null}

              {getServerPresponseError ? (
                <Placeholder
                  title='Ops! Algo deu errado :('
                  message='Estamos constrangidos por isso.'
                  button={{
                    label: 'Fechar',
                    action: hideFormManagerLayer,
                  }}
                />
              ) : null}

              {!getServerPresponseError && (id || !watingServerResponse) ? (
                <>
                  <Box
                    direction='row'
                    justify='between'
                    align='baseline'
                    margin={{ horizontal: 'large' }}
                  >
                    <TitleDetail
                      value={id ? 'Editar despesa' : 'Nova despesa'}
                    />

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={hideFormManagerLayer}
                    />
                  </Box>


                  <Box flex overflow='auto'>
                    {/* nome/terceiro/prestador */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Nome' />

                      <TextInput
                        maxLength={100}
                        value={name ?? ''}
                        onChange={event =>
                          changeValues({ fieldName: 'name', value: event.target.value }, 'formChangeValues')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='name' />
                    </Box>

                    {/* cpf/cnpj/telefone */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Box direction='row' gap='small'>
                        <Box width='large'>
                          <TitleField text='CPF ou CNPJ' />

                          <TextInput
                            maxLength={20}
                            value={nationalRegister ?? ''}
                            onChange={event =>
                              changeValues({ fieldName: 'nationalRegister', value: event.target.value }, 'formChangeValues')
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='nationalRegister' />
                        </Box>

                        <Box width='large'>
                          <TitleField text='Telefone' />

                          <MaskedInput
                            value={telephone ?? ''}
                            placeholder='00 0000 0000'
                            icon={<Phone />}
                            reverse={true}
                            onChange={event =>
                              changeValues({ fieldName: 'telephone', value: event.target.value }, 'formChangeValues')
                            }
                            mask={[
                              { fixed: '(' },
                              { length: 2, regexp: /\d/ },
                              { fixed: ')' },
                              { fixed: ' ' },
                              { length: 4, regexp: /\d/ },
                              { fixed: '-' },
                              { length: [4, 5], regexp: /\d/ }
                            ]}
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='telephone' />
                        </Box>
                      </Box>
                    </Box>


                    <Box
                      border={{ color: 'light-3', side: 'bottom' }}
                      margin={{ top: 'medium', bottom: 'medium' }}
                    />

                    {/* Data despesa */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Data da despesa' required />

                      <CalendarDropButton
                        date={dateExpense ?? ''}
                        onSelect={(selectedDate) =>
                          changeValues({ fieldName: 'dateExpense', value: selectedDate }, 'formChangeValues')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='dateExpense' />
                    </Box>

                    {/* categoria/valor */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Box direction='row' gap='small'>
                        <Box width='large'>
                          <TitleField text='Categoria' required />

                          <Select
                            options={selectExpenseCategory}
                            value={categoryId ?? ''}
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            emptySearchMessage='Selecione uma opção'
                            onChange={({ value: nextValue }) =>
                              changeValues({ fieldName: 'categoryId', value: nextValue }, 'formChangeValues')
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='categoryId' />
                        </Box>

                        <Box width='large'>
                          <TitleField text='Valor' required />

                          <MaskedInput
                            value={value ?? ''}
                            placeholder='R$ 0,00'
                            reverse={true}
                            onChange={event =>
                              changeValues({ fieldName: 'value', value: event.target.value }, 'formChangeValues')
                            }
                            mask={[
                              { fixed: 'R$ ' },
                              { regexp: /^[0-9]{1,6}$/ },
                              { fixed: ',' },
                              { regexp: /^[0-9]{1,2}$/ },
                            ]}
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='value' />
                        </Box>
                      </Box>
                    </Box>

                    {/* Reembolso/Data previsão pagamento */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Box direction='row' gap='small'>
                        <Box width='large'>
                          <TitleField text='Reembolso' required />

                          <Select
                            options={selectYesNoOptions}
                            value={isRefund ?? ''}
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            emptySearchMessage='Selecione uma opção'
                            onChange={({ value: nextValue }) =>
                              changeValues({ fieldName: 'isRefund', value: nextValue }, 'formChangeValues')
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='isRefund' />
                        </Box>

                        <Box width='large'>
                          {isRefund && (
                            <>
                              <TitleField text='Data previsão pagamento' />

                              <CalendarDropButton
                                date={dateRefund ?? ''}
                                title='Informe a data prevista para o reembolso dessa despesa'
                                onSelect={(selectedDate) =>
                                  changeValues({ fieldName: 'dateRefund', value: selectedDate }, 'formChangeValues')
                                }
                              />

                              <ErrorForm errorsList={postDataError.errors} fieldName='dateRefund' />
                            </>
                          )}
                        </Box>
                      </Box>
                    </Box>


                    {/*status*/}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Status' />

                      <Select
                        options={selectExpenseStatus}
                        value={statusId ?? ''}
                        labelKey='name'
                        valueKey={{ key: 'id', reduce: true }}
                        emptySearchMessage='Selecione uma opção'
                        onChange={({ value: nextValue }) =>
                          changeValues({ fieldName: 'statusId', value: nextValue }, 'formChangeValues')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='statusId' />
                    </Box>


                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Descrição' />

                      <TextArea
                        rows={4}
                        resize='vertical'
                        maxLength={2000}
                        value={description ?? ''}
                        onChange={event =>
                          changeValues({ fieldName: 'description', value: event.target.value }, 'formChangeValues')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='description' />
                    </Box>
                  </Box>


                  <Box
                    direction='row'
                    pad={{ vertical: 'medium' }}
                    justify='center'
                    align='center'
                    gap='small'
                  >
                    <Box width='xsmall'>
                      <Anchor
                        color='dark-3'
                        label='Cancelar'
                        disabled={ loadingPostForm }
                        onClick={hideFormManagerLayer}
                      />
                    </Box>

                    <Box width='small'>
                      <Button
                        color='brand'
                        primary
                        label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                        disabled={ loadingPostForm }
                        onClick={() =>
                          this.handlePost({
                            id,
                            categoryId,
                            statusId,
                            value,
                            isRefund,
                            name,
                            nationalRegister,
                            telephone,
                            dateRefund: formattedDateTime(dateRefund),
                            dateExpense: formattedDateTime(dateExpense),
                            description,
                          })
                        }
                      />
                    </Box>


                  </Box>
                </>
              ) : null}
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

const mapStateToProps = ({
  layerReducer,
  formCreateUpdateCopyGeneric,
 }) => ({
  id: formCreateUpdateCopyGeneric.id,
  categoryId: formCreateUpdateCopyGeneric.categoryId,
  statusId: formCreateUpdateCopyGeneric.statusId,
  value: formCreateUpdateCopyGeneric.value,
  isRefund: formCreateUpdateCopyGeneric.isRefund,
  name: formCreateUpdateCopyGeneric.name,
  nationalRegister: formCreateUpdateCopyGeneric.nationalRegister,
  telephone: formCreateUpdateCopyGeneric.telephone,
  dateRefund: formCreateUpdateCopyGeneric.dateRefund,
  dateExpense: formCreateUpdateCopyGeneric.dateExpense,
  description: formCreateUpdateCopyGeneric.description,
  isEditing: formCreateUpdateCopyGeneric.isEditing,

  watingServerResponse: formCreateUpdateCopyGeneric.watingServerResponse,
  getServerPresponseError: formCreateUpdateCopyGeneric.getServerPresponseError,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    update,
    create,
    changeValues,
    searchExpenses,
    hideFormManagerLayer,
  }, dispatch);

export const Create = connect(mapStateToProps, mapDispatchToProps)(FormCreate);
