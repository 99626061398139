const updateRow = ({ object, key, items }) => {
  const {
    description,
    taskDate,
    status,
  } = object;

  return items.map(item =>
    item.id === key ? { ...item, description, taskDate, status } : item
  );
}

export default updateRow;
