const statusColor = ({
  1: 'status-warning', // Aguardando
  2: 'neutral-2', // Em andamento
  4: 'status-ok', // Concluída
  6: 'dark-2', // Cancelada
  8: 'dark-4', // Cancelada sem saldo
  9: 'neutral-1', // Concluída parcialmente
});

export default statusColor;
