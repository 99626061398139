import TYPES from '../actions/types';

const INITIAL_STATE = {
  detail: null,
  communicationBalance: null,
  getDetailError: false,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.detailsData:
      return {
        ...state,
        detail: action.payload.data,
        getDetailError: false,
      };

    case TYPES.communicationBalanceData:
      return {
        ...state,
        communicationBalance: action.payload,
        getDetailError: false,
      };

    case TYPES.detailsError:
      return { ...state, getDetailError: true };

    default:
      return state;
  }
}
