import { combineReducers } from 'redux';
import detailsReducer from './details';
import todoReducer from './todo';

const dashboardSystemReducer = combineReducers({
  details: detailsReducer,
  todo: todoReducer,
});

export default dashboardSystemReducer;
