const TYPES = ({
  getOfficeOptions: 'GET_OFFICES_QUERIES',
  getStatusServiceOptions: 'GET_STATUS_SERVICE_QUERIES',
  getOriginServiceOptions: 'GET_ORIGIN_SERVICE_QUERIES',
  getCategoryServiceOptions: 'GET_CATEGORY_SERVICE_QUERIES',
  getUsersOptions: 'GET_USERS_QUERIES',
  getGroupsOptions: 'GET_GROUPS_QUERIES',
  getVotersOptions: 'GET_VOTERS_QUERIES',
  getDocumentTypeOptions: 'GET_DOCUMENT_TYPES_QUERIES',
  getAuthorsOptions: 'GET_AUTHORS_QUERIES',
  getStatusLegislativeProcessOptions: 'GET_STATUS_LEGISLATIVE_PROCESS_QUERIES',
  getAreaInterestLegislativeProcessOptions: 'GET_INTEREST_LEGISLATIVE_PROCESS_QUERIES',
  getIntegrationUserOptions: 'GET_INTEGRATION_USER_QUERIES',

  toggleLayer: 'TOGGLE_LAYER',

  formInitializeValues: 'FORM_INITIALIZE_VALUES_GENERIC',
  formChangeValues: 'FORM_CHANGE_VALUES_GENERIC',
  formClearValues: 'FORM_CLEAR_VALUES_GENERIC',
  formPostResultError: 'FORM_POST_RESULT_ERROR_GENERIC',
  formPostResultSuccess: 'FORM_POST_RESULT_SUCCESS_GENERIC',
  formPostWatingResponse: 'FORM_POST_WATING_SERVER_RESPONSE_GENERIC',
  formGetWatingResponse: 'FORM_GET_WATING_SERVER_RESPONSE_GENERIC',
  formGetResultError: 'FORM_GET_RESULT_ERROR_GENERIC',

  detailsGetResult: 'GET_DETAILS_GENERIC',
  detailsGetResultError: 'GET_DETAILS_ERROR_GENERIC',

  showNotification: 'SHOW_NOTIFICATION',
  hideNotification: 'HIDE_NOTIFICATION',
});

export default TYPES;
