import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FormCreate from '../components/form-manager/form-manager';
import FormChangePass from '../components/form-change-pass/form-change-pass';
import FormChangeStatus from '../components/form-change-status/form-change-status';
import SearchResult from '../components/search-result/search-result';
import Communication from '../components/communication/communication';
import Header from '../../../components/header/header';

import { search, setDefaultPageRules } from '../actions/form-search';
import { getOfficeOptions } from '../../../actions/select-options';

class SettingsUser extends Component {
  componentDidMount() {
    const {
      search,
      setDefaultPageRules,
      getOfficeOptions,
    } = this.props;

    getOfficeOptions();
    setDefaultPageRules();
    search();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <FormChangePass />
        <FormChangeStatus />
        <FormCreate />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    search,
    setDefaultPageRules,
    getOfficeOptions,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsUser);
