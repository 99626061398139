
import TYPES from './types';

export const hideNotificationLayer = () => ({
  type: TYPES.hideNotification,
});

export const showNotificationLayer = (params) => ({
  type: TYPES.showNotification,
  payload: params,
});
