import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default () => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'GET',
    url: 'dashboard.php',
    params: { ac: 'getDetailsVotersDistrict' },
    headers: {
      token: decipher?.token?.value,
      action: 'getDetailsVotersDistrict',
    },
  });
}
