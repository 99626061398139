import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import { showNotificationLayer } from '../../../actions/notification';
import { create as c } from '../../../api/common';

const loadingPost = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const create = params => {
  return dispatch => {
    dispatch(loadingPost());

    c(params.formParams, 'integration.calendar.php')
      .then(response => {
        dispatch(loadingPost());
        dispatch(hideFormManagerLayer());

        dispatch({
          type: TYPES.insertItemList,
          payload: response.data,
        });

        dispatch(showNotificationLayer('Integração adicionada!'));
      })
      .catch(responseFail => {
        dispatch(loadingPost());
        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
};

export const showFormManagerLayer = () => {
  return { type: COMMON_TYPES.toggleLayer, payload: 'manager' }
};

export const hideFormManagerLayer = () => [
  { type: TYPES.cleanListCalendars },
  { type: COMMON_TYPES.toggleLayer, payload: null },
  { type: COMMON_TYPES.formClearValues },
];
