const selectRulesOptions = [
  { id: 'create_voter', name: 'Cadastrar', category: 'Eleitor' },
  { id: 'edit_voter', name: 'Editar', category: 'Eleitor' },
  { id: 'delete_voter', name: 'Excluir', category: 'Eleitor' },
  { id: 'print_voter', name: 'Imprimir dados', category: 'Eleitor' },
  { id: 'export_voter', name: 'Exportar para Excel', category: 'Eleitor' },
  { id: 'create_label', name: 'Gerar etiqueta', category: 'Eleitor' },

  { id: 'create_treatment', name: 'Cadastrar', category: 'Atendimento' },
  { id: 'edit_treatment', name: 'Editar', category: 'Atendimento' },
  { id: 'delete_treatment', name: 'Excluir', category: 'Atendimento' },
  { id: 'print_search_treatment', name: 'Imprimir dados', category: 'Atendimento' },
  { id: 'create_commet_treatment', name: 'Cadastrar andamento', category: 'Atendimento' },
  { id: 'export_treatment', name: 'Exportar para Excel', category: 'Atendimento' },

  { id: 'create_appointment', name: 'Cadastrar', category: 'Agenda' },
  { id: 'edit_appointment', name: 'Editar', category: 'Agenda' },
  { id: 'delete_appointment', name: 'Excluir', category: 'Agenda' },
  { id: 'export_appointment', name: 'Exportar para Excel', category: 'Agenda' },
  { id: 'print_appointment', name: 'Imprimir dados', category: 'Agenda' },

  { id: 'create_campaign', name: 'Cadastrar comunicação SMS/E-mail', category: 'Campanhas' },
  { id: 'cancel_campaign', name: 'Cancelar envio de SMS/E-mail', category: 'Campanhas' },

  { id: 'create_call', name: 'Cadastrar', category: 'Telefonemas' },
  { id: 'edit_call', name: 'Editar', category: 'Telefonemas' },
  { id: 'delete_call', name: 'Excluir', category: 'Telefonemas' },
  { id: 'print_call', name: 'Imprimir e exportar dados', category: 'Telefonemas' },

  { id: 'create_legislative_process', name: 'Cadastrar', category: 'Documentos' },
  { id: 'edit_legislative_process', name: 'Alterar', category: 'Documentos' },
  { id: 'delete_legislative_process', name: 'Excluir', category: 'Documentos' },
  { id: 'print_legislative_process', name: 'imprimir e exportar dados', category: 'Documentos' },
  { id: 'comment_legislative_process', name: 'Comentar, adicionar andamento', category: 'Documentos' },

  { id: 'create_expense', name: 'Cadastrar', category: 'Despesa' },
  { id: 'edit_expense', name: 'Alterar', category: 'Despesa' },
  { id: 'delete_expense', name: 'Excluir', category: 'Despesa' },
  { id: 'print_expense', name: 'Exportar dados', category: 'Despesa' },

  { id: 'create_survey', name: 'Cadastrar', category: 'Enquete' },
  { id: 'edit_survey', name: 'Alterar', category: 'Enquete' },
  { id: 'delete_survey', name: 'Excluir enquetes e respostas', category: 'Enquete' },

  { id: 'status_manifestation', name: 'Trocar status', category: 'Participativa' },
  { id: 'delete_manifestation', name: 'Excluir', category: 'Participativa' },
  { id: 'print_manifestation', name: 'Exportar', category: 'Participativa' },

  { id: 'access_analytic', name: 'Acesso ao módulo', category: 'Analytics' },
  { id: 'access_election', name: 'Acesso ao módulo', category: 'Eleições' },
]

export default selectRulesOptions;
