import { saveAs } from 'file-saver';
import { formattedDateTime } from '../../../../utils';

const generateHtml = data => {
  const {
    electorId,
    name,
    mail,
    individualRegistration,
    dateOfBirth,
    maritalStatusName,
    professionName,
    schoolName,
    note,
    withLocation,
    telephones,
    voterRegistration,
    electoralZone,
    votingSection,
    address: {
      address,
      number,
      addressAdditional,
      districtName,
      cityName,
      stateName,
      zipCode,
    }
  } = data;

  return `<!DOCTYPE html>
    <html>
      <head>
        <title>${electorId}</title>

        <style>
          * { margin: 0; padding: 0; }

          body { margin: 0; padding: 0; font-size: 13px; color: #333333; font-family: sans-serif; }

          h1 { font-size: 18px; font-weight: normal; }

          table { border-collapse: collapse; width:100%; }
          table tr th { text-align: left; text-transform: uppercase; }

          table tr td,
          table tr th { border-bottom: 1px solid #cccccc; border-top: 0px solid #cccccc; padding: 10px 0px; }

          .content { padding: 16px; }
          .result-content { margin: 10px 0 10px 0; }
        </style>
      </head>

      <body>
        <div class='content'>
          <h1>${name ?? ''}</h1>

          <div class='result-content'>
            <table>
              <tr>
                <th colspan='4'>Dados do eleitor</th>
              </tr>

              <tr>
                <th width='15%'>Nome</th>
                <td width='40%'>${name ?? ''}</td>

                <th width='15%'>CPF</th>
                <td width='30%'>${individualRegistration ?? ''}</td>
              </tr>

              <tr>
                <th width='15%'>Nascimento</th>
                <td width='40%' colspan='4'>${dateOfBirth ? formattedDateTime(dateOfBirth) : ''}</td>
              </tr>
              <tr>
                <th width='15%'>Estado Civil</th>
                <td width='40%'>${maritalStatusName ?? ''}</td>

                <th width='15%'>Profissão</th>
                <td width='30%'>${professionName ?? ''}</td>
              </tr>

              <tr>
                <th width='15%'>Endereço</th>
                <td width='40%'>${withLocation ? `${address}, ${number} ${addressAdditional}` : ''}</td>

                <th width='15%'>Bairro</th>
                <td width='30%'>${districtName ?? ''}</td>
              </tr>

              <tr>
                <th width='15%'>Cidade</th>
                <td width='40%'>${withLocation ? `${cityName} - ${stateName}` : ''}</td>

                <th width='15%'>CEP</th>
                <td width='30%'>${zipCode ?? ''}</td>
              </tr>

              <tr>
                <th width='15%'>Local de votação</td>
                <td width='40%' colspan='4'>${schoolName ?? ''}</td>
              </tr>

              <tr>
                <th width='15%'>Título de eleitor</td>
                <td width='40%' colspan='4'>${voterRegistration ?? ''}</td>
              </tr>

              <tr>
                <th width='15%'>Zona eleitoral</td>
                <td width='40%' colspan='4'>${electoralZone ?? ''}</td>
              </tr>

              <tr>
                <th width='15%'>Seção eleitoral</td>
                <td width='40%' colspan='4'>${votingSection ?? ''}</td>
              </tr>

              <tr>
                <th width='15%'>Telefone(s)</th>
                <td width='40%'>${telephones.map(a => `${a.typeName ?? ''} ${a.number}`)}</td>

                <th width='15%'>E-mail</th>
                <td width='40%'>${mail ?? ''}</td>
              </tr>

              <tr>
                <th width='15%'>Obervação</th>
                <td width='40%' colspan='4'>${note ?? ''}</td>
              </tr>
            </table>
          </div>

          <!--<div class='result-content'>
            <table>
              <tr>
                <th colspan='7'>Atendimento(s)</th>
              </tr>
              <tr>
                <th width='5%'>Ref.</th>
                <th width='50%'>Descrição</th>
                <th width='10%'>Status</th>
                <th width='15%'>Categoria</th>
                <th width='15%'>Origem</th>
                <th width='5%'>Cadastro</th>
              </tr>
              <tr>
                <td width='5%'>AT-000</td>
                <td width='50%'>description</td>
                <td width='10%'>status</td>
                <td width='15%'>category</td>
                <td width='15%'>type</td>
                <td width='5%'>date</td>
              </tr>
            </table>
          </div>-->
        </div>
      </body>
    </html>`;
}

export const printOut = (args = {}) => {
  const { data, fileName } = args;
  const actionResultHtml = generateHtml(data);
  const blob = new Blob([actionResultHtml], { type: 'text/html;charset=utf-8' });

  saveAs(blob, fileName);
}
