import { combineReducers } from 'redux';
import detailsReducer from './details';
import searchInvoiceReducer from './invoices';
import recalculateReducer from './recalculate';

const customerAreaReducer = combineReducers({
  details: detailsReducer,
  searchInvoice: searchInvoiceReducer,
  recalculate: recalculateReducer,
});

export default customerAreaReducer;
