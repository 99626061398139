import TYPES from './types';
import { create as c, update as u, details } from '../../../api/common';

const searchDetails = id =>
  details(id, 'eleitor.php')
    .then(response => ({
        type: TYPES.loadDataEdit,
        payload: response,
      })
    )
    .catch(() => ({ type: TYPES.loadingFormManagerEditError }))

const loadingPostCreate = () => ({
  type: TYPES.loadingPostFormCreate,
});

export const changeField = (value) => ({
  type: TYPES.changeFormManagerField,
  payload: value,
});

export const changeStateAndCity = (value) => ({
  type: TYPES.changeFormManagerStateCity,
  payload: value,
});

export const setAddressAfterSearch = params => ({
  type: TYPES.setAddressAfterSearch,
  payload: params,
});

export const clearAllAddress = () => ({
  type: TYPES.clearAllAddress,
});

export const clearForm = () => ({
  type: TYPES.clearFormCreate,
});

// telefones
export const addTelephone = params => ({
  type: TYPES.addTelephoneFormManager,
  payload: params,
});

export const removeTelephone = params => ({
  type: TYPES.removeTelephoneFormManager,
  payload: params,
});

export const changeFieldsTelephone = (value) => ({
  type: TYPES.changeFormManagerTelephone,
  payload: value,
});

// relatives
export const addRelative = params => ({
  type: TYPES.addRelativesFormManager,
  payload: params,
});

export const removeRelative = params => ({
  type: TYPES.removeRelativesFormManager,
  payload: params,
});

// responsibles
export const addResponsibles = params => ({
  type: TYPES.addResponsiblesFormManager,
  payload: params,
});

export const removeResponsibles = params => ({
  type: TYPES.removeResponsiblesFormManager,
  payload: params,
});

export const loadDataEdit = params => {
  if(params && params.id) {
    return [
      { type: TYPES.loadingFormManagerEdit },
      searchDetails(params.id),
    ]
  }
};

export const create = params => {
  return dispatch => {
    dispatch(loadingPostCreate());

    c(params.formParams, 'eleitor.php')
      .then(() => { dispatch(loadingPostCreate()); })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostCreate());

        dispatch({
          type: TYPES.postFormManagerError,
          payload: responseFail.response
        });

      })
  }
};

export const update = params => {
  return dispatch => {
    dispatch(loadingPostCreate());

    u(params.formParams, 'eleitor.php')
      .then(response => {
        dispatch(loadingPostCreate());
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostCreate());
        dispatch({
          type: TYPES.postFormManagerError,
          payload: responseFail.response
        });
      })
  }
};
