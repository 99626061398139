import React from 'react';
import { Box, Text, Avatar } from 'grommet';
import { User } from 'grommet-icons';

import './message-template.css';

function MessageTemplate({ typeId, subject, greeting, content, signature }) {
  const typeEmail = typeId.toString() === '1';

  if(typeEmail) {
    return (
      <Box
        round='xsmall'
        border={{ color: 'light-3', size: 'xsmall' }}
        elevation='xsmall'
      >
        <Box
          pad={{ horizontal: 'medium', vertical: 'small' }}
          direction='row'
          background={{ color: 'light-3', opacity: 'strong' }}
        >
          <div className='rounded' />
          <div className='rounded' />
          <div className='rounded' />
        </Box>

        <Box pad={{ vertical: 'small' }}>
          <Box
            border={{ size: 'xsmall', color: 'light-6', side: 'bottom' }}
            pad={{ horizontal: 'medium' }}
          >
            <Text margin={{ bottom: 'small' }}>
              {!subject ? 'Assunto' : subject}
            </Text>
          </Box>

          <Box pad={{ horizontal: 'medium', vertical: 'medium' }}>
            <Text>
              {!greeting ? 'Saudação' : greeting}
            </Text>

            <Box
              height={{ min: '150px' }}
              pad={{ vertical: 'medium' }}
            >
              <Text margin='none' size='small'>{!content ? 'Conteúdo' : content}</Text>
            </Box>
            <Text margin={{ top: 'medium' }}>{!signature ? 'Assinatura' : signature}</Text>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      round='xsmall'
      elevation='xsmall'
      border={{ color: 'light-3', size: 'xsmall' }}
      pad={{ horizontal: 'medium', vertical: 'medium' }}
    >
      <Box
        round='xsmall'
        pad={{ horizontal: 'small', vertical: 'small' }}
        margin={{ bottom: 'small', top: 'large' }}
        background='light-3'
      >
        <Text size='small'>
          {!content ? 'Conteúdo' : content}
        </Text>
      </Box>

      <Avatar background='accent-4'>
        <User size='medium' color='white' />
      </Avatar>
    </Box>
  );
}

export default MessageTemplate;
