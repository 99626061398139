import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { UserNew, Transaction, Edit, AddCircle, Secure } from 'grommet-icons';

import { Placeholder, ErrorsSearches } from '../../../../components';
import { showFormManagerLayer } from '../../actions/form-manager';
import { toggleFormChangeStatusLayer } from '../../actions/form-change-status';
import { toggleFormChangePassLayer } from '../../actions/form-change-pass';

const SearchResult = (props) => {
  const {
    loadingFormSearch,
    dataRows,
    getListError,

    showFormManagerLayer,
    toggleFormChangeStatusLayer,
    toggleFormChangePassLayer,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches />;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '100px' }}
          >
            <Box
              pad='small'
              width='xlarge'
              gap='medium'
            >
              {/* Cabeçalho */}
              <Box
                direction='row'
                gap='xsmall'
                align='baseline'
              >
                <Text size='large' color='dark-5'>Configure os</Text>
                <Text size='xlarge' color='dark-1'>usuários</Text>
              </Box>

              {/* Conteúdo */}
              <Box
                gap='medium'
                direction={ size === 'small' || size === 'medium' ? 'column' : 'row' }
                justify='between'
              >
                {/* Primeira coluna */}
                <Box
                  width={
                    size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge'
                  }
                  gap='medium'
                >
                  <Text color='dark-2' size='small'>
                    Cadastre os assessores, chefe de gabinete, etc. que terão acesso ao sistema.
                  </Text>

                  <Box
                    direction='row'
                    gap={ size === 'small' ? 'large' : 'medium' }
                    margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                  >
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <AddCircle size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Adicione
                      </Text>

                      <Text size='small' color='dark-2'>
                        Adicione em poucos passos. É fácil, seguro e rápido!
                      </Text>
                    </Box>

                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <Transaction size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Ativar / Inativar
                      </Text>

                      <Text size='small' color='dark-2'>
                        Um usuário deixou a equipe, é só inativar.
                      </Text>
                    </Box>
                  </Box>

                  <Box
                    direction='row'
                    gap={ size === 'small' ? 'large' : 'medium' }
                    margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                  >
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <Secure size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Permissões
                      </Text>

                      <Text size='small' color='dark-2'>
                        Defina de forma fácil o que cada usuário pode fazer no sistema.
                      </Text>
                    </Box>

                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    />
                  </Box>

                  <Button
                    primary
                    label='Adicionar usuário'
                    color='neutral-1'
                    onClick={() => showFormManagerLayer() }
                  />
                </Box>

                {/* Separador coluna */}
                <Box
                  width={ size === 'large' || size === 'xlarge' ? 'xsmall' : 'xxsmall'}
                />

                {/* Segunda coluna */}
                <Box
                  gap='small'
                  width={ size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge' }
                >
                  <Box
                    round='xsmall'
                    border={{ color: 'light-4' }}
                    background='white'
                  >
                    {dataRows.map((row) => {
                      return (
                        <Box
                          key={row.id}
                          align='center'
                          direction='row'
                          pad={{
                            horizontal: size === 'small' ? 'large' : 'medium',
                            vertical: size === 'small' ? 'medium' : 'xsmall'
                          }}
                          border={{ color: 'light-3', side: 'bottom' }}
                          justify='between'
                          gap='small'
                        >
                          <Box
                            direction='row'
                            gap={size === 'small' ? 'medium' : 'large'}
                            align='baseline'
                          >
                            <Box
                              title={ row.status ? 'Ativo' : 'Inativo' }
                              width='1em'
                              height='0.5em'
                              round='medium'
                              background={{
                                color: row.status ? 'status-ok' : 'status-error'
                              }}
                            />

                            <Box
                              direction='column'
                              align='start'
                            >
                              <Text color='dark-2' size='small'>{row.name}</Text>
                              <Text color='dark-2' size='xsmall'>{row.profile}</Text>
                            </Box>
                          </Box>

                          <Box
                            direction='row'
                            align='center'
                            justify='end'
                            gap='xsmall'
                          >
                            <Button
                              icon={<Edit color='dark-3' size='medium' />}
                              onClick={() => { showFormManagerLayer(row) }}
                            />

                            <Button
                              title='Trocar senha'
                              icon={<UserNew color='dark-3' size='medium' />}
                              onClick={() => { toggleFormChangePassLayer(row) }}
                            />

                            <Button
                              title={row.status ? 'Inativar' : 'Reativar'}
                              icon={<Transaction color='dark-3' size='medium' />}
                              onClick={() => { toggleFormChangeStatusLayer(row) }}
                            />
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ settingsUserReducer: { searchResult } }) => ({
  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    showFormManagerLayer,
    toggleFormChangeStatusLayer,
    toggleFormChangePassLayer,
  }, dispath)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResult)
);
