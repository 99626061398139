import React from 'react';
import { Box, Text, ResponsiveContext, Button, Paragraph } from 'grommet';
import { StatusCritical } from 'grommet-icons';

export const ErrorsSearches = (props) => {
  const {
    title = 'Ops! Algo deu errado :(',
    message = 'Estamos constrangidos por isso.',
    button = null
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='column'
          align='center'
          justify='center'
          margin={{ top: 'medium' }}
          height='100vh'
        >
          <Box
            align='center'
            width='medium'
            pad={ size === 'small' ? 'large' : 'small' }
          >
            <StatusCritical size='large' color='status-critical' />

            <Box margin={{ top: 'medium' }}>
              <Text size='xlarge' textAlign='center'>{title}</Text>
            </Box>

            <Box margin={{ top: 'small' }}>
              <Paragraph size='small' color='dark-5' textAlign='center'>
                {message}
              </Paragraph>
            </Box>

            {button && (
              <Box margin={{ top: 'large' }}>
                <Button
                  label={button.label}
                  primary={true}
                  onClick={() => { button.action() }}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}

    </ResponsiveContext.Consumer>
  )
}
