import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Anchor, Box } from 'grommet';

import urlRoutes from '../../config/url-routes'

class Home extends Component {
  render() {
    return (
      <div>
        <Box><Anchor label='Sistema' href={urlRoutes.login} /></Box>
        <Box><Anchor label='Site' href={urlRoutes.site} /></Box>
        <Box><Anchor label='Trial' href={urlRoutes.onboardingStartUrl} /></Box>
      </div>
    )
  }
}

export default withRouter(Home);
