import { combineReducers } from 'redux';
import searchResultReducer from './search-result';
import formStartReducer from './form-start';
import findCalendarsReducer from './find-calendars';

const settingsIntegrationCalendarReducer = combineReducers({
  searchResult: searchResultReducer,
  formStart: formStartReducer,
  findCalendars: findCalendarsReducer,
});

export default settingsIntegrationCalendarReducer;
