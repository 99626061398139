import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Anchor, TextInput, Text, Box, Select } from 'grommet';
import { FormClose } from 'grommet-icons';

import { Placeholder, ErrorForm } from '../../../../components';
import { TitleField } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { update, create, hideFormManagerLayer } from '../../actions/form-manager';
import { changeValues } from '../../../../actions/form';

import selectColorOptions from '../../../../constants/select-color-options';
import visibleWidth from '../../../../constants/layer-width';

class FormCreate extends Component {
  handlePost = formParams => {
    const { create, update } = this.props;

    if(formParams.id) {
      update({ formParams });
    }
    else {
      create({ formParams });
    }
  };

  render() {
    const {
      id,
      name,
      color,

      getServerPresponseError,
      watingServerResponse,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      hideFormManagerLayer,
    } = this.props;


    if(!toggleLayer || toggleLayer !== 'manager')
      return null;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={hideFormManagerLayer}
            onEsc={hideFormManagerLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{ top: 'large', bottom: 'xsmall' }}
              overflow='auto'
              flex
            >
              {watingServerResponse && !id && !getServerPresponseError ? (
                <Placeholder
                  title='Carregando...'
                  message='Aguarde que estamos preparando as coisas :)'
                />
              ) : null}

              {getServerPresponseError ? (
                <Placeholder
                  title='Ops! Algo deu errado :('
                  message='Estamos constrangidos por isso.'
                  button={{
                    label: 'Fechar',
                    action: hideFormManagerLayer,
                  }}
                />
              ) : null}

              {!getServerPresponseError && (id || !watingServerResponse) ? (
                <>
                  <Box
                    direction='row'
                    justify='between'
                    align='baseline'
                    margin={{ horizontal: 'large' }}
                  >
                    <TitleDetail value={id ? 'Editar status' : 'Novo status'} />

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={hideFormManagerLayer}
                    />
                  </Box>


                  <Box flex overflow='auto'>
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Nome' required />

                      <TextInput
                        maxLength={50}
                        value={name ?? ''}
                        onChange={event =>
                          changeValues({ fieldName: 'name', value: event.target.value }, 'formChangeValues')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='name' />
                    </Box>

                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Cor' required />

                      <Select
                        emptySearchMessage='Selecione uma opção'
                        options={selectColorOptions}
                        value={color ?? ''}
                        labelKey='name'
                        valueKey={{ key: 'id', reduce: true }}
                        onChange={({ value: nextValue }) =>
                          changeValues({ fieldName: 'color', value: nextValue }, 'formChangeValues')
                        }
                      >
                        {({ id, name }) => {
                          return (
                            <Box
                              pad={{ horizontal: 'small', vertical: 'small' }}
                              direction='row'
                              gap='small'
                              align='center'
                            >
                              <Box
                                width='1em'
                                height='1em'
                                background={id}
                                round='xsmall'
                                elevation='xsmall'
                              />

                              <Text size='medium' color='dark-1'>{name}</Text>
                            </Box>
                          )
                        }}
                      </Select>

                      <ErrorForm errorsList={postDataError.errors} fieldName='color' />
                    </Box>
                  </Box>

                  <Box
                    direction='row'
                    pad={{ vertical: 'medium' }}
                    justify='center'
                    align='center'
                    gap='small'
                  >
                    <Box width='xsmall'>
                      <Anchor
                        color='dark-3'
                        label='Cancelar'
                        disabled={ loadingPostForm }
                        onClick={hideFormManagerLayer}
                      />
                    </Box>

                    <Box width='small'>
                      <Button
                        color='brand'
                        primary
                        label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                        disabled={ loadingPostForm }
                        onClick={() =>
                          this.handlePost({
                            id,
                            name,
                            color,
                          })
                        }
                      />
                    </Box>


                  </Box>
                </>
              ) : null}
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

const mapStateToProps = ({ layerReducer, formCreateUpdateCopyGeneric }) => ({
  id: formCreateUpdateCopyGeneric.id,
  name: formCreateUpdateCopyGeneric.name,
  color: formCreateUpdateCopyGeneric.color,

  watingServerResponse: formCreateUpdateCopyGeneric.watingServerResponse,
  getServerPresponseError: formCreateUpdateCopyGeneric.getServerPresponseError,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    update,
    create,
    changeValues,
    hideFormManagerLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);
