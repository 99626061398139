import TYPES from '../actions/types';
import { formatDataFormEdit } from '../utils';

const INITIAL_STATE = {
  id: null,
  question: null,
  thanksText: null,
  endDate: null,
  color: null,
  options: [],

  isEditing: false,
  getDataFormEditError: false,

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.changeFormManagerField:
      if(!action.payload.nested) {
        return {
          ...state,
          [action.payload.fieldName]: action.payload.value,
        }
      }

      const list = state.options.map((item, index) => {
        if(index === action.payload.index) {
          return {
            ...item,
            [action.payload.fieldName]: action.payload.value,
          }
        }

        return item;
      })

      return { ...state, [action.payload.nested]: list }

    case TYPES.loadingFormManagerEdit:
      return {
        ...state,
        isEditing: true,
      }

    case TYPES.loadingFormManagerEditError:
      return {
        ...state,
        getDataFormEditError: true,
      }

    case TYPES.loadDataEdit:
      return {
        ...state,
        ...formatDataFormEdit(action.payload.data),
      }

    case TYPES.clearFormManager:
      return {
        ...state,
        ...INITIAL_STATE,
      }

    case TYPES.addOptFormManager:
      return {
        ...state,
        options: [ ...state.options, action.payload ],
      }

    case TYPES.removeOptFormManager:
      return {
        ...state,
        options: state.options.filter(item => item !== action.payload)
      }

    case TYPES.loadingPostFormManager:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formManagerPostError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
