import React from 'react';
import { Box, Button, Text, ResponsiveContext } from 'grommet';
import { FormPrevious, FormNext } from 'grommet-icons';
import paginationDefault from '../../constants/pagination';
import { formattedNumber } from '../../utils';

export const Pagination = (props) => {
  const {
    nextPage,
    prevPage,
    totalOfRows,
    filters,
  } = props;

  if (totalOfRows <= paginationDefault.limit) return null;

  const disableNextPageButton = !(
    filters.page * paginationDefault.limit < totalOfRows
  );

  const disablePrevPageButton = filters.page === 1;
  const totalPages = Math.ceil(totalOfRows / paginationDefault.limit);

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            align='center'
            justify='center'
            pad={{ top: size === 'small' ? 'large' : 'medium' }}
            gap={ size === 'small' ? 'medium' : 'small' }
          >
            <Box>
              <Button
                primary
                size='small'
                color='dark-3'
                icon={<FormPrevious size='small' />}
                onClick={() => prevPage(filters)}
                disabled={disablePrevPageButton}
              />
            </Box>

            <Box>
              <Text size='small' color='dark-1'>
                {formattedNumber(filters.page)} de {formattedNumber(totalPages)}
              </Text>
            </Box>

            <Box>
              <Button
                primary
                size='small'
                color='dark-3'
                icon={<FormNext size='small' />}
                onClick={() => nextPage(filters)}
                disabled={disableNextPageButton}
              />
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}
