import { combineReducers } from 'redux';
import formCancelReducer from './form-cancel';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';
import formCreateReducer from './form-create';
import selectOptionsReducer from './select-options';
import costReducer from './cost';

const campaignsReducer = combineReducers({
  cancel: formCancelReducer,
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
  formCreate: formCreateReducer,
  selectOptions: selectOptionsReducer,
  cost: costReducer,
});

export default campaignsReducer;
