import { combineReducers } from 'redux';
import formManagerReducer from './form-manager';
import removeReducer from './form-delete';
import formGenerateTagReducer from './form-generate-tag';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';
import selectOptionsReducer from './select-options';
import formCreateServiceReducer from './form-create-service';

const votersReducer = combineReducers({
  formManager: formManagerReducer,
  remove: removeReducer,
  formGenerateTag: formGenerateTagReducer,
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
  selectOptions: selectOptionsReducer,
  formCreateService: formCreateServiceReducer,
});

export default votersReducer;
