import TYPES from '../actions/types';

const INITIAL_STATE = {
  list: [],
  loading: false,
  error: false,
};

export default function(state = INITIAL_STATE, action) {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    case TYPES.listCalendars:
      return {
        ...state,
        list: action.payload.data,
      }

    case TYPES.errorListCalendars:
      return {
        ...state,
        error: true,
      }

    case TYPES.loadingListCalendars:
      return {
        ...state,
        loading: !state.loading,
      }

    case TYPES.cleanListCalendars:
      return INITIAL_STATE;

    default:
      return state;
  }
}
