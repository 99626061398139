import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Details from '../components/details/details';
import FormSearch from '../components/form-search/form-search';
import FormDelete from '../components/form-delete/form-delete';
import FormCreate from '../components/form-manager/form-manager';
import SearchResult from '../components/search-result/search-result';
import Communication from '../components/communication/communication';
import Header from '../../../components/header/header';

import { search } from '../actions/form-search';
import { getUsersOptions } from '../../../actions/select-options';

import paginationDefault from '../../../constants/pagination';

class Tasks extends Component {
  componentDidMount() {
    const {
      getUsersOptions,
      search,

      status,
      startTaskDate,
      endTaskDate,
      participants,
    } = this.props;

    getUsersOptions();

    search({
      status,
      startTaskDate,
      endTaskDate,
      participants,

      page: paginationDefault.page,
      limit: paginationDefault.limit,
    });
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <FormSearch />
        <FormDelete />
        <FormCreate />
        <Details />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ taskReducer: { formSearch }, authReducer: { authenticated } }) => ({
  userLogged: authenticated,

  status: formSearch.status,
  startTaskDate: formSearch.startTaskDate,
  endTaskDate: formSearch.endTaskDate,
  participants: formSearch.participants,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getUsersOptions,
    search,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
