import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Details } from '../components/details/details';
import { Search } from '../components/form-search/form-search';
import { SearchResult } from '../components/search-result/search-result';
import { Cancel } from '../components/form-cancel/form-cancel';
import { Communication } from '../components/communication/communication';

import Header from '../../../components/header/header';

import { searchCampaigns, setDefaultPageRules } from '../actions/form-search';

import { formattedDateTime } from '../../../utils';

import paginationDefault from '../../../constants/pagination';

class Campaigns extends Component {
  componentDidMount() {
    const {
      searchCampaigns,
      setDefaultPageRules,

      name,
      type,
      dateSend,
      status,
    } = this.props;

    setDefaultPageRules();

    searchCampaigns({
      name,
      type,
      dateSend: formattedDateTime(dateSend),
      status,
      page: paginationDefault.page,
      limit: paginationDefault.limit,
    });
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <Search />
        <Details />
        <Cancel />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ campaignsReducer: { formSearch }, authReducer: { authenticated } }) => ({
  userLogged: authenticated,

  name: formSearch.name,
  type: formSearch.type,
  dateSend: formSearch.dateSend,
  status: formSearch.status,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    searchCampaigns,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
