import { saveAs } from 'file-saver';
import { formattedDateTime } from '../../../../utils';

const generateHtml = data => {
  const {
    service: {
      reference,
      statusName,
      categoryName,
      originName,
      officeName,
      createdAt,
      updatedAt,
      requestDate,
      completionDate,
      description,
      progress,
    },
    voter: {
      name,
      mail,
      telephones,
      address: {
        address,
        number,
        addressAdditional,
        districtName,
        cityName,
        stateName,
        zipCode,
      }
    },
  } = data;

  const history = (rows) => {
    if(!rows || rows.length <= 0)
      return null;

    return `<div class='result-content'>
      <table>
        <tr>
          <th colspan='7'>Andamento(s)</th>
        </tr>

        <tr>
          <th width='15%'>Data</th>
          <th width='40%'>Responsável</th>
          <th width='50%'>Descrição</th>
        </tr>

        ${rows.map(({ progressDate, userResponsibleName, description }) => {
          return `<tr>
            <td width='15%'>${formattedDateTime(progressDate)}</td>
            <td width='40%'>${userResponsibleName}</td>
            <td width='50%'>${description}</td>
          </tr>`
        })}
      </table>
    </div>`;
  }

  return `<!DOCTYPE html>
    <html>
      <head>
        <title>${reference}</title>

        <style>
          * { margin: 0; padding: 0; }

          body { margin: 0; padding: 0; font-size: 13px; color: #333333; font-family: sans-serif; }

          h1 { font-size: 18px; font-weight: normal; }

          table { border-collapse: collapse; width:100%; }
          table tr th { text-align: left; text-transform: uppercase; }

          table tr td,
          table tr th { border-bottom: 1px solid #cccccc; border-top: 0px solid #cccccc; padding: 10px 0px; }

          .content { padding: 16px; }
          .result-content { margin: 10px 0 10px 0; }
          </style>
      </head>

      <body>
        <div class='content'>
          <h1>${reference}</h1>

          <div class='result-content'>
            <table>
              <tr>
                <th colspan='4'>Dados do eleitor</th>
              </tr>

              <tr>
                <th width='15%'>Nome</th>
                <td width='40%'>${name}</td>

                <th width='15%'>E-mail</th>
                <td width='40%'>${mail || ''}</td>
              </tr>

              <tr>
                <th width='15%'>Telefone(s)</th>
                <td colspan='3'>${telephones.map(f => `${f.typeName || ''} ${f.number}`)}</td>
              </tr>

              <tr>
                <th width='15%'>Endereço</th>
                <td width='40%'>${address || ''}</td>

                <th width='15%'>Número/Complemento</th>
                <td width='30%'>${number || ''} ${addressAdditional || ''}</td>
              </tr>

              <tr>
                <th width='15%'>Bairro</th>
                <td width='30%'>${districtName || ''}</td>

                <th width='15%'>Cidade</th>
                <td width='40%'>${cityName || ''}</td>
              </tr>

              <tr>
                <th width='15%'>Estado</th>
                <td width='40%'>${stateName || ''}</td>

                <th width='15%'>CEP</th>
                <td width='30%'>${zipCode || ''}</td>
              </tr>
            </table>
          </div>

          <div class='result-content'>
            <table>
              <tr>
                <th colspan='4'>Dados do atendimento</th>
              </tr>

              <tr>
                <th width='15%'>Referência</th>
                <td width='40%'>${reference}</td>

                <th width='15%'>Status</th>
                <td width='30%'>${statusName}</td>
              </tr>

              <tr>
                <th width='15%'>Categoria</th>
                <td width='40%'>${categoryName}</td>

                <th width='15%'>Origem</th>
                <td width='30%'>${originName}</td>
              </tr>

              <tr>
                <th width='15%'>Escritório</th>
                <td colspan='3'>${officeName}</td>
              </tr>

              <tr>
                <th width='15%'>Data de solicitação</th>
                <td width='40%'>${formattedDateTime(requestDate) ?? ''}</td>

                <th width='15%'>Data de conclusão</th>
                <td width='30%'>${formattedDateTime(completionDate) ?? ''}</td>
              </tr>

              <tr>
                <th width='15%'>Data de cadastro</th>
                <td width='40%'>${formattedDateTime(createdAt)}</td>

                <th width='15%'>Úlima atualização</th>
                <td width='30%'>${formattedDateTime(updatedAt)}</td>
              </tr>

              <tr>
                <th width='15%'>Descrição</th>
                <td width='40%' colspan='4'>
                  ${description}
                </td>
              </tr>
            </table>
          </div>

          ${history(progress) ?? ''}

        </div>
      </body>
    </html>`;
}

export const printOut = (args = {}) => {
  const { data, fileName } = args;
  const actionResultHtml = generateHtml(data);
  const blob = new Blob([actionResultHtml], { type: 'text/html;charset=utf-8' });

  saveAs(blob, fileName);
}
