import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { Notes, Emoji } from 'grommet-icons';

import { Placeholder } from '../../../../components';
import { formattedDateTime, formattedCurrency } from '../../../../utils';
import { toggleDetailsLayer } from '../../actions/details-invoice';

function SearchResult(props) {
  const {
    loadingFormSearch,
    dataRows,
    totalOfRows,
    getListError,

    toggleDetailsLayer,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return (
      <Placeholder
        title='Ops! Algo deu errado :('
        message='Estamos constrangidos por isso.'
      />
    );
  }

  if(!dataRows.length) {
    return (
      <Placeholder
        icon={<Emoji size='large' color='dark-2' />}
        title='Você não possui faturas'
      />
    );
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width='xlarge'
            >
              <Box
                align='center'
                direction='row'
                justify='between'
                margin={{ bottom: 'small' }}
              >
                <Box
                  direction={size === 'small' ? 'column' : 'row'}
                  align={size === 'small' ? 'baseline' : 'center'}
                  gap={size === 'small' ? null : 'small'}
                >
                  <Text size='xlarge'>{totalOfRows}</Text>

                  <Text
                    size={size === 'small' ? 'xsmall' : 'medium'}
                    color='dark-6'
                  >
                    Faturas e compras
                  </Text>
                </Box>
              </Box>

              <Box
                direction='row'
                pad={{ vertical: 'medium' }}
                border={{ side: 'bottom', color: 'light-5' }}
                gap='small'
              >
                {size !== 'small' && size !== 'medium' && (<Box width='30vw'>
                  <Text color='dark-2'>Tipo</Text>
                </Box>)}

                {size !== 'small' && <Box width='40vw'>
                  <Text color='dark-2'>Período</Text>
                </Box>}

                {size !== 'small' && size !== 'medium' && (<Box width='30vw'>
                  <Text color='dark-2'>Vencimento</Text>
                </Box>)}

                {size !== 'small' && (<Box width='30vw'>
                  <Text color='dark-2'>Pagamento</Text>
                </Box>)}

                {size !== 'small' && <Box width='30vw'>
                  <Text color='dark-2'>Valor</Text>
                </Box>}

                {size !== 'small' && <Box width='20vw'>
                  <Text color='dark-2'>Status</Text>
                </Box>}

                <Box width='20vw' />
              </Box>

              {dataRows.filter(a => a.serviceId !== '2').map(row => {
                return (
                  <Box
                    key={row.id}
                    align='center'
                    direction='row'
                    pad={{ vertical: size === 'small' ? 'small' : 'xsmall' }}
                    border={{ side: 'bottom', color: 'light-5' }}
                    gap='small'
                  >
                    {size !== 'small' && size !== 'medium' && (<Box width='30vw'>
                      <Text color='dark-2' size='small'>{row.serviceName}</Text>
                    </Box>)}

                    <Box width='40vw'>
                      {row.periodStart && row.periodEnd && (
                        <Text color='dark-2' size='small'>
                          {formattedDateTime(row.periodStart)} até {formattedDateTime(row.periodEnd)}
                        </Text>
                      )}
                    </Box>

                    {size !== 'small' && size !== 'medium' && (<Box width='30vw'>
                      <Text color='dark-2' size='small'>{formattedDateTime(row.dueDate)}</Text>
                    </Box>)}

                    {size !== 'small' && (<Box width='30vw'>
                      <Text color='dark-2' size='small'>{formattedDateTime(row.payDay)}</Text>
                    </Box>)}

                    <Box width='30vw'>
                      <Text color='dark-2' size='small'>{formattedCurrency(row.value)}</Text>
                    </Box>

                    <Box width='20vw'>
                      <Text color='dark-2' size='small'>{row.statusName}</Text>
                    </Box>

                    <Box align='end' width='20vw'>
                      <Box direction='row'>
                        <Button
                          title='Abrir detalhes'
                          icon={<Notes color='dark-3' size='medium' />}
                          onClick={() => { toggleDetailsLayer(row) }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ customerAreaReducer: { searchInvoice } }) => ({
  loadingFormSearch: searchInvoice.loadingFormSearch,
  dataRows: searchInvoice.dataRows,
  totalOfRows: searchInvoice.totalOfRows,
  getListError: searchInvoice.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleDetailsLayer,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
