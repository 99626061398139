import TYPES from './types';
import { listCities, listDistricts } from '../../../api/location';

export const searchCityOptions = params => {
  return dispatch => {
    dispatch({ type: TYPES.getCityOptionsLoad });

    listCities(params.name)
      .then(response => {
        dispatch({
          type: TYPES.getCityOptions,
          payload: response.data.map(result => {
            const {
              id,
              name,
              stateAbbrev
            } = result;

            return {
              id,
              name: `${name}/${stateAbbrev}`,
            }
          }),
        })
      })
      .then(() => dispatch({ type: TYPES.getCityOptionsLoad }))
      .catch(() => dispatch({ type: TYPES.getCityOptionsLoad }))
  }
}

export const searchDistrictOptions = params => {
  return dispatch => {
    dispatch({ type: TYPES.getDistrictOptionsLoad });

    listDistricts(params)
      .then(response => {
        dispatch({
          type: TYPES.getDistrictOptions,
          payload: response.data.data.map(result => {
            const {
              id,
              district,
            } = result;

            return {
              id,
              name: district,
            }
          }),
        })
      })
      .then(() => dispatch({ type: TYPES.getDistrictOptionsLoad }))
      .catch(() => dispatch({ type: TYPES.getDistrictOptionsLoad }))
  }
}
