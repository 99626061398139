import COMMON_TYPES from '../../../actions/types';
import { details } from '../../../api/common';

const searchDetails = params =>
  details(params.id, 'expense.php')
    .then(response => {
      return {
        type: COMMON_TYPES.detailsGetResult,
        payload: response,
      }
    })
    .catch(() => ({ type: COMMON_TYPES.detailsGetResultError }))

export const toggleDetailsLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'details' },
      searchDetails(params),
    ]
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.detailsGetResult, payload: { data: null } }
  ];
};
