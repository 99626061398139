import TYPES from '../actions/types';

const INITIAL_STATE = {
  tagOptions: [],
  groupOptions: [],

  cityOptions: [],
  cityOptionsLoad: false,

  districtOptions: [],
  districtOptionsLoad: false,

  addressOptions: [],
  addressOptionsLoad: false,

  schoolOptions: [],
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TYPES.getTagOptions:
      return { ...state, tagOptions: action.payload };

    case TYPES.getGroupOptions:
      return { ...state, groupOptions: action.payload };


    case TYPES.getCityOptions:
      return { ...state, cityOptions: action.payload };

    case TYPES.getCityOptionsLoad:
      return { ...state, cityOptionsLoad: !state.cityOptionsLoad }


    case TYPES.getDistrictOptions:
      return { ...state, districtOptions: action.payload };

    case TYPES.getDistrictOptionsLoad:
      return { ...state, districtOptionsLoad: !state.districtOptionsLoad }


    case TYPES.getAddressOptions:
      return { ...state, addressOptions: action.payload };

    case TYPES.getAddressOptionsLoad:
      return { ...state, addressOptionsLoad: !state.addressOptionsLoad }

    case TYPES.getSchoolOptions:
      return { ...state, schoolOptions: action.payload };

    default:
      return state;
  }
}
