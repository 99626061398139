import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  ResponsiveContext,
  Button,
  Text,
  Layer,
  TextArea,
  Anchor,
  Box,
  CheckBoxGroup,
  Select,
} from 'grommet';
import { FormClose } from 'grommet-icons';

import { Placeholder, ErrorForm } from '../../../../components';
import { TitleField, DescriptionField, CalendarDropButton } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { search } from '../../actions/form-search';
import { update, create, hideFormManagerLayer } from '../../actions/form-manager';
import { changeValues } from '../../../../actions/form';

import statusOptions from '../../constants/status-options';
import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

class FormCreate extends Component {
  callbackPostSuccess = () => {
    const {
      search,
      hideFormManagerLayer,
    } = this.props;

    search();
    hideFormManagerLayer();
  };

  handlePost = formParams => {
    const { isEditing, create, update } = this.props;

    if(isEditing && formParams.id) {
      update({ formParams });
    }
    else {
      create({
        formParams,
        callbackSuccess: this.callbackPostSuccess
      });
    }
  };

  render() {
    const {
      usersOptions,

      id,
      status,
      description,
      taskDate,
      participants,
      isEditing,

      getServerPresponseError,
      watingServerResponse,
      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      hideFormManagerLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'manager') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideFormManagerLayer}
              onEsc={hideFormManagerLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                {watingServerResponse && !id && !getServerPresponseError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {getServerPresponseError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: hideFormManagerLayer,
                    }}
                  />
                ) : null}

                {!getServerPresponseError && (id || !watingServerResponse) ? (
                  <>
                    <Box
                      direction='row'
                      justify='between'
                      align='baseline'
                      margin={{ horizontal: 'large' }}
                    >
                      <TitleDetail
                        value={isEditing && id ? 'Editar tarefa' : 'Nova tarefa'}
                      />

                      <Button
                        plain
                        title='Fechar'
                        icon={<FormClose />}
                        onClick={hideFormManagerLayer}
                      />
                    </Box>


                    <Box flex overflow='auto'>
                      {/* Responsáveis */}
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Responsáveis' required />

                        <Box
                          round='xxsmall'
                          pad={{
                            vertical: 'small',
                          }}
                          gap='xsmall'
                        >
                          <CheckBoxGroup
                            name='participants'
                            value={participants ?? []}
                            options={
                              usersOptions
                                .filter(a => a.status || participants?.some(userId => userId === a.id))
                                .map(({ name, id }) => ({
                                    l: name,
                                    value: id,
                                  })
                                )
                            }
                            onChange={({ value: nextValue }) => {
                              changeValues({ fieldName: 'participants', value: nextValue }, 'formChangeValues')
                            }}
                          >
                            {(option, { checked }) => {
                              let background = null;
                              let borderColor = 'dark-5';

                              if(checked) {
                                background = 'brand';
                                borderColor = 'brand';
                              }

                              return (
                                <Box
                                  direction='row'
                                  gap='small'
                                  justify='center'
                                  align='center'
                                >
                                  <Box
                                    width='20px'
                                    height='20px'
                                    background={background}
                                    round='xxsmall'
                                    border={{
                                      color: borderColor
                                    }}
                                  />
                                  <Text color='dark-2' size='small'>{option.l}</Text>
                                </Box>
                              )
                            }}
                          </CheckBoxGroup>
                        </Box>

                        <ErrorForm errorsList={postDataError.errors} fieldName='participants' />
                      </Box>

                      {/* Data tarefa */}
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        direction={ size === 'small' ? 'column' : 'row' }
                        align='stretch'
                        flex={{ shrink: 0 }}
                        gap='small'
                      >
                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                          gap='xxsmall'
                        >
                          <TitleField
                            text='Data'
                            required
                          />

                          <DescriptionField
                            text='Defina quando deseja ser lembrado.'
                          />
                        </Box>

                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                        >
                          <CalendarDropButton
                            date={taskDate ?? ''}
                            onSelect={(selectedDate) =>
                              changeValues({ fieldName: 'taskDate', value: selectedDate }, 'formChangeValues')
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='taskDate' />
                        </Box>
                      </Box>

                      {/*status*/}
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Status' required />

                        <Select
                          options={statusOptions}
                          value={status ?? ''}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeValues({ fieldName: 'status', value: nextValue }, 'formChangeValues')
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='status' />
                      </Box>

                      {/*descricao*/}
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Descrição' required />

                        <TextArea
                          rows={4}
                          resize='vertical'
                          maxLength={200}
                          value={description ?? ''}
                          onChange={event =>
                            changeValues({ fieldName: 'description', value: event.target.value }, 'formChangeValues')
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='description' />
                      </Box>
                    </Box>


                    <Box
                      direction='row'
                      pad={{ vertical: 'medium' }}
                      justify='center'
                      align='center'
                      gap='small'
                    >
                      <Box width='xsmall'>
                        <Anchor
                          color='dark-3'
                          label='Cancelar'
                          disabled={ loadingPostForm }
                          onClick={hideFormManagerLayer}
                        />
                      </Box>

                      <Box width='small'>
                        <Button
                          color='brand'
                          primary
                          label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                          disabled={ loadingPostForm }
                          onClick={() =>
                            this.handlePost({
                              id,
                              status,
                              description,
                              taskDate: formattedDateTime(taskDate),
                              participants,
                            })
                          }
                        />
                      </Box>
                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({
  layerReducer,
  selectOptionsReducer,
  formCreateUpdateCopyGeneric,
}) => ({
  usersOptions: selectOptionsReducer.usersOptions,

  id: formCreateUpdateCopyGeneric.id,
  status: formCreateUpdateCopyGeneric.status,
  description: formCreateUpdateCopyGeneric.description,
  taskDate: formCreateUpdateCopyGeneric.taskDate,
  participants: formCreateUpdateCopyGeneric.participants,
  isEditing: formCreateUpdateCopyGeneric.isEditing,

  watingServerResponse: formCreateUpdateCopyGeneric.watingServerResponse,
  getServerPresponseError: formCreateUpdateCopyGeneric.getServerPresponseError,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    update,
    create,
    changeValues,
    search,
    hideFormManagerLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);
