import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Details } from '../components/details/details';
import { Search } from '../components/form-search/form-search';
import { Delete } from '../components/form-delete/form-delete';
import { Create } from '../components/form-manager/form-manager';
import { SearchResult } from '../components/search-result/search-result';
import { Communication } from '../components/communication/communication';

import Header from '../../../components/header/header';

import { searchPolls, setDefaultPageRules } from '../actions/form-search';

import { formattedDateTime } from '../../../utils';

import paginationDefault from '../../../constants/pagination';

class Polls extends Component {
  componentDidMount() {
    const {
      searchPolls,
      setDefaultPageRules,

      reference,
      question,
      startDate,
      endDate,
    } = this.props;

    setDefaultPageRules();

    searchPolls({
      reference,
      question,
      startDate: formattedDateTime(startDate),
      endDate: formattedDateTime(endDate),
      page: paginationDefault.page,
      limit: paginationDefault.limit,
    });
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <Search />
        <Delete />
        <Create />
        <Details />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ surveyReducer: { formSearch }, authReducer: { authenticated } }) => ({
  userLogged: authenticated,

  reference: formSearch.reference,
  question: formSearch.question,
  startDate: formSearch.startDate,
  endDate: formSearch.endDate,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    searchPolls,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Polls);
