import { combineReducers } from 'redux';
import removeReducer from './form-delete';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';
import formManagerReducer from './form-manager';

const surveyReducer = combineReducers({
  remove: removeReducer,
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
  formManager: formManagerReducer,
});

export default surveyReducer;
