import TYPES from './types';
import { getInvoices } from '../../../api/client';

const loadingSearchToggle = () => ({
  type: TYPES.loadingFormSearch,
});

export const searchInvoices = params => {
  return dispatch => {
    dispatch(loadingSearchToggle());
    getInvoices(params)
      .then(response =>
        dispatch({
          type: TYPES.searchSuccess,
          payload: response,
        })
      )
      .then(() => dispatch(loadingSearchToggle()))
      .catch(() => dispatch({ type: TYPES.searchError }))
  }
};
