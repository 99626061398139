import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default ({ method, text }) => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'GET',
    url: 'localidade.php',
    headers: {
      token: decipher?.token?.value,
      action: method, // searchByZipcodeAndAddress or searchByZipcode
    },
    params: {
      q: text,
      // timestamp: new Date().getTime()
    },
  })
}
