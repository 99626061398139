import { combineReducers } from 'redux';
import authenticatedReducer from './auth';
import forgotPasswordReducer from './forgot-password';
import loginReducer from './login';
import resetPasswordReducer from './reset-password';

const authReducer = combineReducers({
  authenticated: authenticatedReducer,
  forgotPassword: forgotPasswordReducer,
  login: loginReducer,
  resetPassword: resetPasswordReducer,
});

export default authReducer;
