import crypto from 'crypto-js';
import secretsData from '../constants/secrets';
import jsonStringify from './json-stringify';

export default (value) => {
  if(!value)
    return value;

  const parsed = jsonStringify(value);
  const ciphertext = crypto.AES.encrypt(parsed, secretsData.encryt).toString();

  return ciphertext;
}
