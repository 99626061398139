import { logout } from '../../../api/login';
import { getCookie, decryptAes, setCookie } from '../../../utils';
import secretsData from '../../../constants/secrets';

export const logoutSystem = params => {
  return dispatch => {
    const userLoggedState = getCookie();
    const decipher = decryptAes(userLoggedState);

    if(decipher) {
      logout({ token: decipher.token.value })
        .then(() => {
          setCookie(
            secretsData.cookie,
            '',
            {
              expires: new Date(),
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
              path: '/',
            }
          );

          // TODO remover quando todo sistema estiver migrado
          setCookie(
            secretsData.cookieOld,
            '',
            {
              expires: new Date(),
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
              path: '/',
            }
          );
        })
        .then(() => { params.callbackSuccess(); })
    }
    else {
      params.callbackSuccess();
    }
  }
};
