import { list } from '../../../api/common';
import { downloadCsv } from '../../../components';
import { formattedDateTime } from '../../../utils';
import statusOptions from '../constants/status-options';
import { showNotificationLayer } from '../../../actions/notification';

export const generateCsv = params =>
  dispatch => {
    dispatch(
      showNotificationLayer('Gerando Excel. Aguarde...')
    );

    list(params, 'task.php')
      .then(({ data: { data } }) => {
        downloadCsv({
          data,
          specifications: {
            id: { name: 'Ref.', transform: (value) => value ?? '' },
            description: { name: 'Descrição', transform: (value) => value ? value.replace(/\n/gm, '') : '' },
            taskDate: { name: 'Data da tarefa', transform: (value) => value ?? '' },
            participants: { name: 'Participantes', transform: (value) => value ?? '' },
            status: { name: 'Status', transform: (value) => statusOptions.find(item => item.id === value)?.name ?? '' },
            createdAt: { name: 'Cadastro', transform: (value) => value ?? '' },
            updatedAt: { name: 'Atualização', transform: (value) => value ?? '' },
          },
          fileName: `listagem-tarefa-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.csv`,
        })
      })
      .catch(() =>
        dispatch(
          showNotificationLayer('Ops... não conseguimos gerar o Excel.')
        )
      );
  }
