const TYPES = ({
  // get voters and cost
  getVotersSucsess: 'GET_VOTERS_SUCCESS_CAMPAIGN',
  getCostSuccess: 'GET_COST_SUCCESS_MODULE_CAMPAIGN',
  getVotersAndCostError: 'GET_VOTERS_AND_COST_ERROR_CAMPAIGN',
  getCostClearResult: 'GET_COST_CLEAR_RESUL_MODULE_CAMPAIGN',
  getVotersAndCostLoading: 'GET_VOTERS_AND_COST_LOADING_MODULE_CAMPAIGN',
  clearVoters: 'CLEAR_VOTERS_CAMPAIGN',


  // form create
  changeFormCreateType: 'CHANGE_TYPE_CAMPAIGN_FORM_CREATE',
  changeFormCreateName: 'CHANGE_NAME_CAMPAIGN_FORM_CREATE',
  changeFormCreateDateStart: 'CHANGE_DATE_START_CAMPAIGN_FORM_CREATE',
  changeFormCreateHourStart: 'CHANGE_HOUR_START_CAMPAIGN_FORM_CREATE',
  changeFormCreateSearchVoterGenre: 'CHANGE_SEARCH_VOTER_GENRE_CAMPAIGN_FORM_CREATE',
  changeFormCreateSearchVoterCity: 'CHANGE_SEARCH_VOTER_CITY_CAMPAIGN_FORM_CREATE',
  changeFormCreateSearchVoterDistricts: 'CHANGE_SEARCH_VOTER_DISTRICTS_CAMPAIGN_FORM_CREATE',
  changeFormCreateSearchVoterGroups: 'CHANGE_SEARCH_VOTER_GROUPS_CAMPAIGN_FORM_CREATE',
  changeFormCreateSearchVoterMaritalStatus: 'CHANGE_SEARCH_VOTER_MARITAL_STATUS_CAMPAIGN_FORM_CREATE',
  changeFormCreateSearchVoterProfession: 'CHANGE_SEARCH_VOTER_PROFESSION_CAMPAIGN_FORM_CREATE',
  changeFormCreateComposeMessageSubject: 'CHANGE_COMPOSE_MESSAGE_SUBJECT_CAMPAIGN_FORM_CREATE',
  changeFormCreateComposeMessageGreeting: 'CHANGE_COMPOSE_MESSAGE_GREETING_CAMPAIGN_FORM_CREATE',
  changeFormCreateComposeMessageContent: 'CHANGE_COMPOSE_MESSAGE_CONTENT_CAMPAIGN_FORM_CREATE',
  changeFormCreateComposeMessageSignature: 'CHANGE_COMPOSE_MESSAGE_SIGNATURE_CAMPAIGN_FORM_CREATE',

  clearFormCreate: 'CLEAR_FORM_CREATE_CAMPAIGN',
  loadingPostFormCreate: 'LOADING_POST_FORM_CREATE_CAMPAIGN',
  postFormCreateCampaignError: 'POST_FORM_CREATE_CAMPAIGN_ERROR',

  searchByEmail: 'SEARCH_BY_EMAIL_CAMPAIGN',
  searchBySms: 'SEARCH_BY_SMS_CAMPAIGN',


  //options
  getCityOptions: 'GET_CITY_MODULE_CAMPAIGN',
  getCityOptionsLoad: 'GET_CITY_LOAD_MODULE_CAMPAIGN',
  getDistrictOptions: 'GET_DISTRICT_MODULE_CAMPAIGN',
  getDistrictOptionsLoad: 'GET_DISTRICT_LOAD_MODULE_CAMPAIGN',

  // list
  loadingFormSearch: 'LOADING_SEARCH_CAMPAIGN_FORM_TOGGLE',
  searchSuccess: 'SEARCH_CAMPAIGN_LIST_SUCCESS',
  searchError: 'SEARCH_CAMPAIGN_LIST_ERROR',

   // form search
  changeFormSearchName: 'CHANGE_SEARCH_CAMPAIGN_FORM_REF',
  changeFormSearchType: 'CHANGE_SEARCH_CAMPAIGN_FORM_NAME',
  changeFormSearchDateSend: 'CHANGE_SEARCH_CAMPAIGN_FORM_TELEPHONE',
  changeFormSearchStatus: 'CHANGE_SEARCH_CAMPAIGN_FORM_SUBJECT',
  setDefaultPage: 'SET_SEARCH_CAMPAIGN_DEFAULT_PAGE',
  clearFormSearchFields: 'CLEAR_FIELDS_CAMPAIGN_FORM',
  paginationFormSearch: 'SEARCH_CAMPAIGN_PAGINATION',

  // cancel
  loadingDataFormCancel: 'LOADING_DATA_FORM_CANCEL_CAMPAIGN',
  loadFormCancel: 'LOADING_POST_FORM_CANCEL_CAMPAIGN_TOGGLE',
  formCancelError: 'POST_FORM_CANCEL_CAMPAIGN_ERROR',
  formCancelSuccess: 'POST_FORM_CANCEL_CAMPAIGN_SUCCESS',
});

export default TYPES;
