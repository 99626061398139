import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { ResponsiveContext, Button, Box, Anchor } from 'grommet';

import { Placeholder } from '../../../../components';

import Settings from './settings';
import Address from './address';
import Contacts from './contacts';
import Additional from './additional';
import School from './school';
import Relatives from './relatives';
import Responsibles from './responsibles';

import { clearForm, create, update } from '../../actions/form-create';

import urlRoutes from '../../../../config/url-routes';

class FormManager extends Component {
  callbackPostSuccess = () => {
    const {
      history,
      clearForm,
    } = this.props;

    clearForm();

    history.push(urlRoutes.voterListUrl);
  };

  handleCancel = () => {
    const {
      history,
      clearForm,
    } = this.props;

    clearForm();
    history.push(urlRoutes.voterListUrl);
  };

  handlePost = formParams => {
    const { create, update } = this.props;

    if(formParams.id) {
      update({
        formParams,
        callbackSuccess: this.callbackPostSuccess
      });
    }
    else {
      create({
        formParams,
        callbackSuccess: this.callbackPostSuccess
      });
    }
  };

  render() {
    const {
      id,
      name,
      individualRegistration,
      nickName,
      dateOfBirth,
      genre,
      status,
      sonsIn,
      maritalStatusId,
      professionId,
      groupsId,
      note,
      email,
      allowCommunication,
      address,
      addressNumber,
      additionalAddress,
      districtId,
      cityId,
      stateId,
      zipcode,
      voterRegistration,
      electoralZone,
      votingSection,
      schoolId,
      withLocation,
      telephone,
      relatives,
      responsible,
      voteStatusId,

      getDataFormEditError,
      isEditing,

      loadingPostForm,
    } = this.props;

    if(isEditing && !id && !getDataFormEditError) {
      return (
        <Placeholder
          title='Carregando...'
          message='Aguarde que estamos preparando as coisas :)'
        />
      );
    }

    if(getDataFormEditError) {
      return (
        <Placeholder
          title='Ops! Algo deu errado :('
          message='Estamos constrangidos por isso.'
        />
      );
    }

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width='xlarge'
            >
              {/* Settings */}
              <Settings />

              <Box
                border={{ color: 'light-3', side: 'bottom' }}
                margin={{ top: 'medium', bottom: 'medium' }}
              />

              {/* Address */}
              <Address />

              <Box
                border={{ color: 'light-3', side: 'bottom' }}
                margin={{ top: 'medium', bottom: 'medium' }}
              />

              {/* Contacts */}
              <Contacts />

              <Box
                border={{ color: 'light-3', side: 'bottom' }}
                margin={{ top: 'medium', bottom: 'medium' }}
              />

              {/* School */}
              <School />

              <Box
                border={{ color: 'light-3', side: 'bottom' }}
                margin={{ top: 'medium', bottom: 'medium' }}
              />

              {/* Additional */}
              <Additional />

              <Box
                border={{ color: 'light-3', side: 'bottom' }}
                margin={{ top: 'medium', bottom: 'medium' }}
              />

              {/* Responsáveis */}
              <Responsibles />

              <Box
                border={{ color: 'light-3', side: 'bottom' }}
                margin={{ top: 'medium', bottom: 'medium' }}
              />

              {/* Relatives */}
              <Relatives />

              <Box
                align='center'
                margin={{ top: 'large' }}
              >
                <Box direction='row'>
                  <Box pad={{ right: 'xsmall', bottom: 'small' }} width='small' justify='center' align='center'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={this.handleCancel}
                    />
                  </Box>

                  <Box pad={{ right: 'xsmall', bottom: 'small' }} width='medium'>
                    <Button
                      color='brand'
                      primary
                      label={!loadingPostForm ? 'Salvar': 'Salvando...'}
                      disabled={loadingPostForm}
                      onClick={() => {
                        this.handlePost({
                          id,
                          name,
                          individualRegistration,
                          nickName,
                          dateOfBirth,
                          genre,
                          status,
                          sonsIn,
                          maritalStatusId,
                          professionId,
                          groupsId,
                          note,
                          email,
                          allowCommunication,
                          address,
                          addressNumber,
                          additionalAddress,
                          districtId,
                          cityId,
                          stateId,
                          zipcode,
                          voterRegistration,
                          electoralZone,
                          votingSection,
                          schoolId,
                          withLocation,
                          telephone,
                          relatives,
                          responsible,
                          voteStatusId,
                        });
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ votersReducer: { formManager } }) => ({
  id: formManager.id,
  name: formManager.name,
  individualRegistration: formManager.individualRegistration,
  nickName: formManager.nickName,
  dateOfBirth: formManager.dateOfBirth,
  genre: formManager.genre,
  status: formManager.status,
  sonsIn: formManager.sonsIn,
  maritalStatusId: formManager.maritalStatusId,
  professionId: formManager.professionId,
  groupsId: formManager.groupsId,
  note: formManager.note,
  email: formManager.email,
  allowCommunication: formManager.allowCommunication,
  address: formManager.address,
  addressNumber: formManager.addressNumber,
  additionalAddress: formManager.additionalAddress,
  districtId: formManager.districtId,
  cityId: formManager.cityId,
  stateId: formManager.stateId,
  zipcode: formManager.zipcode,
  voterRegistration: formManager.voterRegistration,
  electoralZone: formManager.electoralZone,
  votingSection: formManager.votingSection,
  schoolId: formManager.schoolId,
  withLocation: formManager.withLocation,
  telephone: formManager.telephone,
  relatives: formManager.relatives,
  responsible: formManager.responsible,
  voteStatusId: formManager.voteStatusId,

  isEditing: formManager.isEditing,
  getDataFormEditError: formManager.getDataFormEditError,
  loadingPostForm: formManager.loadingPostForm,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    clearForm,
    create,
    update,
  }, dispath)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormManager));
