import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Details } from '../components/details/details';
import { Search } from '../components/form-search/form-search';
import { Create } from '../components/form-manager/form-manager';
import { SearchResult } from '../components/search-result/search-result';
import { Delete } from '../components/form-delete/form-delete';
import { Communication } from '../components/communication/communication';
import Header from '../../../components/header/header';

import { searchPhonecalls, setDefaultPageRules } from '../actions/form-search';
import { getUsersOptions } from '../../../actions/select-options';

import { formattedDateTime } from '../../../utils';

import paginationDefault from '../../../constants/pagination';

class Phonecalls extends Component {
  componentDidMount() {
    const {
      searchPhonecalls,
      setDefaultPageRules,
      getUsersOptions,

      status,
      startDate,
      endDate,
      reference,
      subject,
    } = this.props;

    getUsersOptions();

    setDefaultPageRules();

    searchPhonecalls({
      status,
      startDate: formattedDateTime(startDate),
      endDate: formattedDateTime(endDate),
      reference,
      subject,
      page: paginationDefault.page,
      limit: paginationDefault.limit,
    });
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <Search />
        <Create />
        <Details />
        <Delete />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ phonecallsReducer: { formSearch }, authReducer: { authenticated } }) => ({
  userLogged: authenticated,

  reference: formSearch.reference,
  name: formSearch.name,
  telephone: formSearch.telephone,
  subject: formSearch.subject,
  status: formSearch.status,
  startDate: formSearch.startDate,
  endDate: formSearch.endDate,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    searchPhonecalls,
    setDefaultPageRules,
    getUsersOptions,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Phonecalls);
