const currency = 'BRL';
const locale = 'pt-BR';

export default (value, style = 'decimal', minimumFractionDigits = 0) => {
  if(!value || isNaN(value) || value === Infinity) {
    value = 0;
  }

  return new Intl.NumberFormat(locale, { style, currency, minimumFractionDigits }).format(value);
}
