import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Text, Layer, Box, Select, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';

import { TitleField, CalendarDropButton, SelectSearch } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { changeValues } from '../../../../actions/form';
import { search, initializeValueFormSearch, hideLayer, setDefaultPageRules } from '../../actions/form-search';

import statusOptions from '../../constants/status-options';
import paginationDefault from '../../../../constants/pagination';
import visibleWidth from '../../../../constants/layer-width';

class FormSearch extends Component {
  searchAndSetPagination = formParams => {
    const { search, hideLayer, setDefaultPageRules } = this.props;

    setDefaultPageRules();
    search(formParams);
    hideLayer();
  };

  render() {
    const {
      usersOptions,

      status,
      startTaskDate,
      endTaskDate,
      participants,

      toggleLayer,
      hideLayer,
      initializeValueFormSearch,
      changeValues,
    } = this.props;

    if(toggleLayer && toggleLayer === 'search') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideLayer}
              onEsc={hideLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Filtrar' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={hideLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  {/* status */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Status' />

                      {status && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeValues({ fieldName: 'status', value: null }, 'changeFormSearchField')
                        }
                      />)}
                    </Box>

                    <Select
                      options={statusOptions}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      value={status ?? ''}
                      onChange={({ value: nextValue }) =>
                        changeValues({ fieldName: 'status', value: nextValue }, 'changeFormSearchField')
                      }
                    />
                  </Box>

                  {/* participants */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Responsáveis' />

                      {participants?.length > 0 && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeValues({ fieldName: 'participants', value: [] }, 'changeFormSearchField')
                        }
                      />)}
                    </Box>

                    <SelectSearch
                      options={
                        usersOptions.filter(
                          a => a.status || participants?.some(userId => userId === a.id)
                        )
                      }
                      value={participants}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      multiple
                      closeOnChange={false}
                      emptySearchMessage='Selecione uma opção'
                      onChange={({ value: nextValue }) =>
                        changeValues({ fieldName: 'participants', value: nextValue }, 'changeFormSearchField')
                      }
                      valueLabel={
                        participants && participants.length > 1 ? (
                          <Box pad='small'>
                            <Text>{`${participants.length} selecionado(s)`}</Text>
                          </Box>
                        ) : null
                      }
                    />
                  </Box>

                  {/* Data tarefa */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Data da tarefa' />

                    <Box
                      direction='row'
                      gap='small'
                    >
                      <CalendarDropButton
                        date={startTaskDate ?? ''}
                        onSelect={(selectedDate) => changeValues({ fieldName: 'startTaskDate', value: selectedDate }, 'changeFormSearchField')}
                      />

                      <CalendarDropButton
                        date={endTaskDate ?? ''}
                        onSelect={(selectedDate) => changeValues({ fieldName: 'endTaskDate', value: selectedDate }, 'changeFormSearchField')}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  margin={{ horizontal: 'large' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='small'>
                    <Button
                      color='dark-3'
                      label='Limpar'
                      onClick={() =>
                        initializeValueFormSearch()
                      }
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label='Buscar'
                      onClick={() =>
                        this.searchAndSetPagination({
                          status,
                          startTaskDate,
                          endTaskDate,
                          participants,
                          page: paginationDefault.page,
                          limit: paginationDefault.limit,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ taskReducer: { formSearch }, layerReducer, selectOptionsReducer }) => ({
  usersOptions: selectOptionsReducer.usersOptions,

  status: formSearch.status,
  startTaskDate: formSearch.startTaskDate,
  endTaskDate: formSearch.endTaskDate,
  participants: formSearch.participants,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    search,
    initializeValueFormSearch,
    setDefaultPageRules,
    hideLayer,
    changeValues,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
