
const TYPES = ({
  useLicense: 'LOAD_INVOICE_CLIENT',
  openInvoice: 'LOAD_OPEN_INVOICE_CLIENT',

  changeInputField: 'CREATE_PAY_CHANGE_FIELD',

  loadFormPostToggle: 'LOAD_CREATE_BILLING_FORM_TOGGLE',
  formPostSuccess: 'POST_CREATE_BILLING_FORM_SUCCESS',
  formPostError: 'POST_CREATE_BILLING_FORM_ERROR',
});

export default TYPES;
