import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SearchResult from '../components/search-result/search-result';
import Header from '../../../components/header/header';

class SettingsList extends Component {
  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({}, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsList);
