import TYPES from '../actions/types';
import paginationDefault from '../../../constants/pagination';

const INITIAL_STATE = {
  name: null,
  type: null,
  dateSend: null,
  status: null,

  page: paginationDefault.page,
  limit: paginationDefault.limit,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.changeFormSearchName:
      return { ...state, name: action.payload }

    case TYPES.changeFormSearchType:
      return { ...state, type: action.payload }

    case TYPES.changeFormSearchDateSend:
      return { ...state, dateSend: action.payload }

    case TYPES.changeFormSearchStatus:
      return { ...state, status: action.payload }


    case TYPES.setDefaultPage:
      return {
        ...state,
        page: paginationDefault.page,
        limit: paginationDefault.limit,
      };

    case TYPES.clearFormSearchFields:
      return {
        ...state,
        name: null,
        type: null,
        dateSend: null,
        status: null,
      }

    case TYPES.paginationFormSearch:
      return { ...state, page: action.payload };

    default:
      return state;
  }
}
