import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Details from '../components/details-invoice/details-invoice';
import SearchResult from '../components/search-result/search-result';
import Header from '../../../components/header/header';

import { searchInvoices } from '../actions/invoices';

class CustomerAreaInvoices extends Component {
  componentDidMount() {
    const {
      searchInvoices,
    } = this.props;

    searchInvoices();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />
        <Details />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    searchInvoices,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAreaInvoices);
