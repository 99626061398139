const selectPhoneOperatorOptions = [
  { id: '3', name: 'Claro' },
  { id: '4', name: 'Tim' },
  { id: '1', name: 'Vivo' },
  { id: '2', name: 'Oi' },
  { id: '5', name: 'Nextel' },
  { id: '7', name: 'Porto' },
  { id: '8', name: 'Sercomtel' },
  { id: '6', name: 'Não informado' },
  { id: '', name: 'Não informado' },
];

export default selectPhoneOperatorOptions;
