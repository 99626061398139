import TYPES from './types';
import { postTrial } from '../../../api/client';

export const postCreateTrial = params => {
  return dispatch => {
    dispatch({ type: TYPES.loadFormPostToggle });

    postTrial(params.formParams)
      .then(response => {
        dispatch({
          type: TYPES.formPostSuccess,
          payload: response,
        });
      })
      .then(() => {
        params.callbackSuccess();
      })
      .then(() => {
        dispatch({ type: TYPES.loadFormPostToggle });
        dispatch({ type: TYPES.clearAllForm });
      })
      .catch(responseFail => {
        dispatch({ type: TYPES.loadFormPostToggle });
        dispatch({
          type: TYPES.formPostError,
          payload: responseFail.response
        });
      })
  }
}

export const changeField = params => ({
  type: TYPES.changeField,
  payload: params,
});
