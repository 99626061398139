import ReactGA from 'react-ga4';

const isEnable = !!process.env.REACT_APP_ANALYTICS_TRACKER_ID;

export const categories = ({
  appointment: 'Appointment',
  voter: 'Voter',
  service: 'Service',
  task: 'Task',
  customerArea: 'CustomerArea',
  legislative: 'LegislativeProcess',
  user: 'User',
  phonecall: 'Phonecall',
  expense: 'Expense',
});

export const actions = ({
  layerFakedoorSendSms: 'Open_fakedoor_send_sms',
  voterDetails: 'Open_voter_details',
  voterDelete: 'Open_voter_delete',
  voterReminder: 'Open_voter_reminder',
  voterNewService: 'Open_voter_new_service',
  voterSearch: 'Open_voter_search',
  voterGenerateTag: 'Open_voter_generate_tag',
  voterExport: 'Export_voter',
  voterPrint: 'Print_voter',

  taskDash: 'Open_task_dashboard',
  taskDetails: 'Open_task_details',
  taskSearch: 'Open_task_search',
  taskDelete: 'Open_task_delete',
  taskCopy: 'Open_task_copy',
  taskCreate: 'Open_task_create',
  taskEdit: 'Open_task_edit',

  serviceDetails: 'Open_service_details',
  serviceEdit: 'Open_service_edit',
  serviceCreate: 'Open_service_create',
  serviceChangeStatus: 'Open_service_change_status',
  serviceComment: 'Open_service_comment',
  serviceArchive: 'Open_service_archive',
  serviceReminder: 'Open_service_reminder',
  serviceDelete: 'Open_service_delete',
  serviceSearch: 'Open_service_search',
  serviceExport: 'Export_service',
  servicePrint: 'Print_service',

  appointmentDetails: 'Open_appointment_details',
  appointmentDelete: 'Open_appointment_delete',
  appointmentCopy: 'Open_appointment_copy',
  appointmentEdit: 'Open_appointment_edit',
  appointmentCreate: 'Open_appointment_create',
  appointmentSearch: 'Open_appointment_search',
  appointmentExport: 'Export_appointment',
  appointmentPrint: 'Print_appointment',

  customerAreaBuyCredit: 'Open_customer_area_buy_credit',

  legislativeDetails: 'Open_legislative_details',
  legislativeEdit: 'Open_legislative_edit',
  legislativeCreate: 'Open_legislative_create',
  legislativeExport: 'Export_legislative',
  legislativeChangeStatus: 'Open_legislative_change_status',
  legislativeComment: 'Open_legislative_comment',
  legislativeReminder: 'Open_legislative_reminder',
  legislativeDelete: 'Open_legislative_delete',
  legislativeSearch: 'Open_legislative_search',
  legislativeArchive: 'Open_legislative_archive',
  legislativeCreateDoc: 'Open_legislative_create_doc',
  legislativePrint: 'Print_legislative',
  legislativePrintDoc: 'Print_doc_legislative',

  userEdit: 'Open_user_edit',
  userCreate: 'Open_user_create',
  userChangeStatus: 'Open_user_change_status',
  userChangePass: 'Open_user_change_pass',

  phonecallEdit: 'Open_phonecall_edit',
  phonecallCreate: 'Open_phonecall_create',

  expenseCopy: 'Open_expense_copy',
  expenseEdit: 'Open_expense_edit',
  expenseCreate: 'Open_expense_create',
});

export function initialize() {
  if(!isEnable)
    return;

  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKER_ID);
};

export function registerEvent({ category, action, label, value }) {
  if(!isEnable)
    return;

  ReactGA.event({
    category, // video
    action, // play
    label, // Fall Campaign
    value, // 42
  });
};
