import axiosGlobalInstance from '../http-instance';

export default (params) => {
  return axiosGlobalInstance({
    method: 'GET',
    url: 'login.php',
    headers: {
      action: 'getAccount'
    },
    params,
  })
}
