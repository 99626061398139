import { combineReducers } from 'redux';
import removeReducer from './form-delete';
import searchResultReducer from './search-result';

const settingsOfficeReducer = combineReducers({
  remove: removeReducer,
  searchResult: searchResultReducer,
});

export default settingsOfficeReducer;
