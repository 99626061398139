import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';
import { remove as d } from '../../../api/common';

const loadingPostDeleteToggle = () => ({
  type: COMMON_TYPES.formPostWatingResponse,
});

export const remove = params => {
  return dispatch => {
    dispatch(loadingPostDeleteToggle());

    d(params.formParams.id, 'compromisso.php')
      .then(() => {
        dispatch(loadingPostDeleteToggle());

        dispatch(showNotificationLayer(
          `O compromisso AG-${params.formParams.id} foi removido!`
        ));
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostDeleteToggle());

        dispatch({
          type: TYPES.formPostDeleteError,
          payload: responseFail.response,
        });
      })
  }
};

export const toggleFormDeleteLayer = params => {
  if(params && params.id) {
    analytics.registerEvent({
      category: analytics.categories.appointment,
      action: analytics.actions.appointmentDelete,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'delete' },
      { type: COMMON_TYPES.formInitializeValues, payload: params },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
};
