const TYPES = ({
  generateExcel: 'GENERATE_CSV_EXPENSE',

  // list
  loadingFormSearch: 'LOADING_SEARCH_EXPENSE_FORM_TOGGLE',
  searchSuccess: 'SEARCH_EXPENSE_SUCCESS',
  searchError: 'SEARCH_EXPENSE_LIST_ERROR',

  // form create
  updateItemListingManagerSuccess: 'MANAGER_EXPENSE_SUCCESS_UPDATE_LISTING',

  // form search
  changeFormSearchField: 'CHANGE_SEARCH_EXPENSE_FORM_FIELD',
  setDefaultPage: 'SET_SEARCH_EXPENSE_DEFAULT_PAGE',
  clearFormSearchFields: 'CLEAR_FIELDS_EXPENSE_FORM',
  paginationFormSearch: 'SEARCH_EXPENSE_PAGINATION',
});

export default TYPES;
