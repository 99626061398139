import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextInput, Text, Box, Anchor, Select } from 'grommet';
import { FormClose } from 'grommet-icons';
import { Spinning } from 'grommet-controls';

import { TitleField, DescriptionField, CalendarDropButton, SelectSearch } from '../../../../components/form';
import { Hr, TitleDetail } from '../../../../components/detail';

import { searchServices, clearFieldsFormSearch, hideLayer, setDefaultPageRules, changeField } from '../../actions/form-search';
import { searchDistrictOptions } from '../../actions/select-options';

import paginationDefault from '../../../../constants/pagination';
import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

class FormSearch extends Component {
  searchAndSetPagination = formParams => {
    const { searchServices, hideLayer, setDefaultPageRules } = this.props;

    setDefaultPageRules();
    searchServices(formParams);
    hideLayer();
  };

  onSearchDistricts = text => {
    const {
      searchDistrictOptions
    } = this.props;

    if(text.length < 3) return;

    searchDistrictOptions({ name: text });
  };

  render() {
    const {
      documentTypeOptions,
      groupOptions,
      statusOptions,
      originOptions,
      categoryOptions,
      officeOptions,
      usersOptions,
      districtOptions,
      districtOptionsLoad,

      reference,
      electorReference,
      electorName,
      groupId,
      statusId,
      originId,
      categoryId,
      officeId,
      responsibleId,
      description,
      startCreatedDate,
      endCreatedDate,
      documentId,
      documentYear,
      documentNumber,
      districtIds,

      toggleLayer,
      hideLayer,
      clearFieldsFormSearch,
      changeField,
    } = this.props;


    if(toggleLayer && toggleLayer === 'search') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideLayer}
              onEsc={hideLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Filtrar' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={hideLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  {/* Referência/Status */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='large'>
                        <TitleField text='Referência' />

                        <TextInput
                          value={reference ?? ''}
                          onChange={event => changeField({ fieldName: 'reference', value: event.target.value }, 'changeFormSearchField')}
                        />
                      </Box>

                      <Box width='large'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Status' />

                          {statusId && (<Anchor
                            size='small'
                            label='limpar'
                            onClick={() =>
                              changeField({ fieldName: 'statusId', value: null }, 'changeFormSearchField')
                            }
                          />)}
                        </Box>

                        <SelectSearch
                          options={statusOptions}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={statusId ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'statusId', value: nextValue }, 'changeFormSearchField')
                          }
                        />
                      </Box>
                    </Box>
                  </Box>


                  {/* Origem/Categoria */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='large'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Origem' />

                          {originId && (<Anchor
                            size='small'
                            label='limpar'
                            onClick={() =>
                              changeField({ fieldName: 'originId', value: null }, 'changeFormSearchField')
                            }
                          />)}
                        </Box>

                        <SelectSearch
                          options={originOptions}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={originId ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'originId', value: nextValue }, 'changeFormSearchField')
                          }
                        />
                      </Box>

                      <Box width='large'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Categoria' />

                          {categoryId && (<Anchor
                            size='small'
                            label='limpar'
                            onClick={() =>
                              changeField({ fieldName: 'categoryId', value: null }, 'changeFormSearchField')
                            }
                          />)}
                        </Box>

                        <SelectSearch
                          options={categoryOptions}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={categoryId ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'categoryId', value: nextValue }, 'changeFormSearchField')
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* Escritório origem/Responsável */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='large'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Escritório origem' />

                          {officeId && (<Anchor
                            size='small'
                            label='limpar'
                            onClick={() =>
                              changeField({ fieldName: 'officeId', value: null }, 'changeFormSearchField')
                            }
                          />)}
                        </Box>

                        <SelectSearch
                          options={officeOptions}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={officeId ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'officeId', value: nextValue }, 'changeFormSearchField')
                          }
                        />
                      </Box>

                      <Box width='large'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Responsável' />

                          {responsibleId && (<Anchor
                            size='small'
                            label='limpar'
                            onClick={() =>
                              changeField({ fieldName: 'responsibleId', value: null }, 'changeFormSearchField')
                            }
                          />)}
                        </Box>

                        <SelectSearch
                          options={usersOptions}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={responsibleId ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'responsibleId', value: nextValue }, 'changeFormSearchField')
                          }
                        />
                      </Box>
                    </Box>
                  </Box>


                  {/* Cadastro */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Cadastrado em' />

                    <Box
                      direction='row'
                      gap='small'
                    >
                      <CalendarDropButton
                        date={startCreatedDate ?? ''}
                        onSelect={(selectedDate) => changeField({ fieldName: 'startCreatedDate', value: selectedDate }, 'changeFormSearchField')}
                      />

                      <CalendarDropButton
                        date={endCreatedDate ?? ''}
                        onSelect={(selectedDate) => changeField({ fieldName: 'endCreatedDate', value: selectedDate }, 'changeFormSearchField')}
                      />
                    </Box>
                  </Box>

                  {/* Descrição */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Descrição' />

                    <TextInput
                      value={description ?? ''}
                      onChange={event =>
                        changeField({ fieldName: 'description', value: event.target.value }, 'changeFormSearchField')
                      }
                    />
                  </Box>


                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Text weight='bold' size='xsmall' color='dark-2'>
                      BUSCAR POR DOCUMENTOS OFÍCIOS, MOÇÕES, ETC.
                    </Text>
                  </Box>


                  {/* documento tipo */}
                  <Box
                    margin={{
                      vertical: 'small',
                      horizontal: 'large'
                    }}
                    direction={ size === 'small' ? 'column' : 'row' }
                    align='stretch'
                    flex={{ shrink: 0 }}
                    gap='small'
                  >
                    <Box width={ size === 'small' ? '100vw' : '50vw'}>
                      <Box
                        direction='row'
                        align='center'
                        gap='xsmall'
                      >
                        <TitleField text='Tipo documento' />

                        {documentId && (<Anchor
                          size='small'
                          label='limpar'
                          onClick={() =>
                            changeField({ fieldName: 'documentId', value: null }, 'changeFormSearchField')
                          }
                        />)}
                      </Box>

                      <DescriptionField text='Busque por atendimentos que têm vinculo com ofícios, moções, etc.' />
                    </Box>

                    <Box
                      width={ size === 'small' ? '100vw' : '50vw'}
                    >
                      <SelectSearch
                        options={documentTypeOptions}
                        labelKey='name'
                        valueKey={{ key: 'id', reduce: true }}
                        value={documentId ?? ''}
                        emptySearchMessage='Selecione uma opção'
                        onChange={({ value: nextValue }) =>
                          changeField({ fieldName: 'documentId', value: nextValue }, 'changeFormSearchField')
                        }
                      />
                    </Box>
                  </Box>


                  {/* Número e ano documento */}
                  <Box
                    margin={{
                      vertical: 'small',
                      horizontal: 'large'
                    }}
                    direction={ size === 'small' ? 'column' : 'row' }
                    align='stretch'
                    flex={{ shrink: 0 }}
                    gap='small'
                  >
                    <Box width={ size === 'small' ? '100vw' : '50vw'}>
                      <TitleField text='Número, ano' />

                      <DescriptionField
                        text='Busque pelo ano e/ou número do documento.'
                      />
                    </Box>

                    <Box
                      width={ size === 'small' ? '100vw' : '50vw'}
                      direction='row'
                      gap='small'
                    >
                      <TextInput
                        type='number'
                        placeholder='Número'
                        min={0}
                        value={documentNumber ?? ''}
                        onChange={event =>
                          changeField({ fieldName: 'documentNumber', value: event.target.value }, 'changeFormSearchField')
                        }
                      />

                      <TextInput
                        type='number'
                        placeholder='Ano'
                        min={0}
                        value={documentYear ?? ''}
                        onChange={event =>
                          changeField({ fieldName: 'documentYear', value: event.target.value }, 'changeFormSearchField')
                        }
                      />
                    </Box>
                  </Box>


                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Text weight='bold' size='xsmall' color='dark-2'>
                      BUSCAR POR DADOS DO ELEITOR
                    </Text>
                  </Box>


                  {/* Referência/Nome */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='medium'>
                        <TitleField text='Referência' />

                        <TextInput
                          value={electorReference ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'electorReference', value: event.target.value }, 'changeFormSearchField')
                          }
                        />
                      </Box>

                      <Box width='large'>
                        <TitleField text='Nome' />

                        <TextInput
                          value={electorName ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'electorName', value: event.target.value }, 'changeFormSearchField')
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* Bairro do eleitor */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Bairro' />

                      {districtIds && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeField({ fieldName: 'districtIds', value: null }, 'changeFormSearchField')
                        }
                      />)}
                    </Box>

                    <Select
                      searchPlaceholder='Digite para buscar...'
                      emptySearchMessage=''
                      options={districtOptions}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      dropHeight='small'
                      value={districtIds ?? ''}
                      onChange={({ value: nextValue }) => changeField({ fieldName: 'districtIds', value: nextValue }, 'changeFormSearchField') }
                      onSearch={ text => this.onSearchDistricts(text) }
                      multiple
                      icon={
                        districtOptionsLoad ? (
                          <Spinning size='medium' color='brand' kind='pulse' />
                        ) : (
                          true
                        )
                      }
                      valueLabel={
                        districtIds && districtIds.length > 1 ? (
                          <Box pad='small'>
                            <Text>{`${districtIds.length} selecionado(s)`}</Text>
                          </Box>
                        ) : null
                      }
                    />
                  </Box>

                  {/* Grupo do eleitor */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Grupo' />

                      {groupId && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeField({ fieldName: 'groupId', value: null }, 'changeFormSearchField')
                        }
                      />)}
                    </Box>

                    <SelectSearch
                      options={groupOptions}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      value={groupId ?? ''}
                      emptySearchMessage='Selecione uma opção'
                      onChange={({ value: nextValue }) =>
                        changeField({ fieldName: 'groupId', value: nextValue }, 'changeFormSearchField')
                      }
                    />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  margin={{ horizontal: 'large' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='small'>
                    <Button
                      color='dark-3'
                      label='Limpar'
                      onClick={() => clearFieldsFormSearch()}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label='Buscar'
                      onClick={() =>
                        this.searchAndSetPagination({
                          reference,
                          electorReference,
                          electorName,
                          groupId,
                          statusId,
                          originId,
                          categoryId,
                          officeId,
                          responsibleId,
                          description,
                          startCreatedDate: formattedDateTime(startCreatedDate),
                          endCreatedDate: formattedDateTime(endCreatedDate),
                          documentId,
                          documentYear,
                          documentNumber,
                          districtIds,
                          page: paginationDefault.page,
                          limit: paginationDefault.limit,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ servicesReducer: { formSearch, selectOptions }, layerReducer, selectOptionsReducer }) => ({
  documentTypeOptions: selectOptionsReducer.documentTypeOptions,
  groupOptions: selectOptionsReducer.groupsOptions,
  statusOptions: selectOptionsReducer.statusServiceOptions,
  originOptions: selectOptionsReducer.originServiceOptions,
  categoryOptions: selectOptionsReducer.categoryServiceOptions,
  officeOptions: selectOptionsReducer.officeOptions,
  usersOptions: selectOptionsReducer.usersOptions,
  districtOptions: selectOptions.districtOptions,
  districtOptionsLoad: selectOptions.districtOptionsLoad,

  reference: formSearch.reference,
  electorReference: formSearch.electorReference,
  electorName: formSearch.electorName,
  groupId: formSearch.groupId,
  statusId: formSearch.statusId,
  originId: formSearch.originId,
  categoryId: formSearch.categoryId,
  officeId: formSearch.officeId,
  responsibleId: formSearch.responsibleId,
  description: formSearch.description,
  startCreatedDate: formSearch.startCreatedDate,
  endCreatedDate: formSearch.endCreatedDate,
  documentId: formSearch.documentId,
  documentYear: formSearch.documentYear,
  documentNumber: formSearch.documentNumber,
  districtIds: formSearch.districtIds,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    searchServices,
    clearFieldsFormSearch,
    setDefaultPageRules,
    hideLayer,
    changeField,
    searchDistrictOptions,
  }, dispatch);

export const Search = connect(mapStateToProps, mapDispatchToProps)(FormSearch);
