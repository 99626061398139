import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Details } from '../components/details/details';
import { Search } from '../components/form-search/form-search';
import { Delete } from '../components/form-delete/form-delete';
import { Create } from '../components/form-manager/form-manager';
import { SearchResult } from '../components/search-result/search-result';
import { Communication } from '../components/communication/communication';

import Header from '../../../components/header/header';

import { searchAppointments, setDefaultPageRules } from '../actions/form-search';
import { getUsersOptions, getIntegrationUserOptions } from '../../../actions/select-options';

import { formattedDateTime } from '../../../utils';

import paginationDefault from '../../../constants/pagination';

class Appointments extends Component {
  componentDidMount() {
    const {
      getUsersOptions,
      getIntegrationUserOptions,

      searchAppointments,
      setDefaultPageRules,

      startDate,
      endDate,
      reference,
      subject,
      participantId,
    } = this.props;

    getUsersOptions();
    getIntegrationUserOptions();

    setDefaultPageRules();

    searchAppointments({
      startDate: formattedDateTime(startDate),
      endDate: formattedDateTime(endDate),
      reference,
      subject,
      participantId,
      page: paginationDefault.page,
      limit: paginationDefault.limit,
    });
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <Search />
        <Delete />
        <Create />
        <Details />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ appointmentsReducer: { formSearch }, authReducer: { authenticated } }) => ({
  userLogged: authenticated,

  startDate: formSearch.startDate,
  endDate: formSearch.endDate,
  reference: formSearch.reference,
  subject: formSearch.subject,
  participantId: formSearch.participantId,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getUsersOptions,
    getIntegrationUserOptions,
    searchAppointments,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
