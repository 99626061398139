import TYPES from '../actions/types/auth';

const INITIAL_STATE = { };

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.loadStateWithLocalStorage:
      return { ...state, ...action.payload }

    default:
      return state;
  }
}
