import React from 'react';
import { Box, Calendar } from 'grommet';
import { Calendar as CalendarIcon } from 'grommet-icons';
import { MaskedInput } from 'grommet-controls';
import { isValidDate, formattedDateTime } from '../../utils';

const CalendarDropButton = props => {
  const {
    date,
    onSelect,
    placeholder,
    disabled,
    title
  } = props;

  const isValid = isValidDate(new Date(date));

  const maskedInputDate = isValid
    ? formattedDateTime(date, 'DD/MM/YYYY') //.toLocaleDateString()
    : date;

  const calendarDate = isValid
    ? new Date(date).toISOString()
    : new Date().toISOString();

  return (
    <MaskedInput
      value={maskedInputDate}
      dropIcon={<CalendarIcon />}
      placeholder={placeholder}
      disabled={disabled}
      title={title}
      dropContent={
        <Box pad='small'>
          <Calendar
            date={calendarDate}
            onSelect={onSelect}
            locale='pt-BR'
            size='small'
          />
        </Box>
      }
    />
  );
};

export default CalendarDropButton;
