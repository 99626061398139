import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default params => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'POST',
    url: '/atendimento.php',
    data: {
      ...params,
      changeObjectDocuments: true,
			changeObjectFiles: false,
    },
    headers: {
      token: decipher?.token?.value,
      action: 'cadastrar',
    }
  });
}
