
import TYPES from '../actions/types';

const INITIAL_STATE = {
  description: '',
  isOpen: false,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.showNotification:
      return {
        ...state,
        description: action.payload,
        isOpen: true,
      }

    case TYPES.hideNotification:
      return { ...state, ...INITIAL_STATE }

    default:
      return state;
  }
}
