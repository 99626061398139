const selectCampaignStatusOptions = [
  { id: 1, name: 'Aguardando' },
  { id: 6, name: 'Cancelada' },
  { id: 8, name: 'Cancelada sem saldo' },
  { id: 4, name: 'Concluída' },
  { id: 9, name: 'Concluída parcialmente' },
  { id: 2, name: 'Em andamento' },
];

export default selectCampaignStatusOptions;
