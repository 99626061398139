import urlRoutes from '../../config/url-routes';

const menuPrimary = [
  {
    label: 'Eleitores',
    click: urlRoutes.voterListUrl,
    present: ['xlarge', 'large', 'medium', 'small'],
  },
  {
    label: 'Atendimentos',
    click: urlRoutes.serviceListUrl,
    present: ['xlarge', 'large', 'medium', 'small'],
  },
  {
    label: 'Compromissos',
    click: urlRoutes.appointmentListUrl,
    present: ['xlarge'],
  },
  {
    label: 'Campanhas',
    click: urlRoutes.campaignListUrl,
    present: ['xlarge', 'large'],
  },
  {
    label: 'Despesas',
    click: urlRoutes.expensesListUrl,
    present: ['xlarge', 'large', 'medium'],
  },
  {
    label: 'Participativa',
    click: urlRoutes.manifestationsDashUrl,
    present: ['xlarge', 'large'],
  },
  {
    label: 'Enquetes',
    click: urlRoutes.pollsListUrl,
    present: ['xlarge', 'large'],
  },
];

const menuSecondary = [
  {
    label: 'Docs. Proposituras, Adm.',
    click: urlRoutes.legislativeProcessesListUrl,
  },
  {
    label: 'Ligação',
    click: urlRoutes.phonecallListUrl,
  },
  {
    label: 'Tarefas',
    click: urlRoutes.tasksListUrl,
  },
];

const menuAdmin = [
  {
    label: 'Área do cliente',
    click: urlRoutes.customerAreaUrl,
  },
  {
    label: 'Configurações',
    click: urlRoutes.settingsUrl,
  },
];

export { menuPrimary, menuSecondary, menuAdmin };
