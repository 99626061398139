import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';
import { createCommentLegislativeProcess } from '../../../api/legislative-processes';

const loadingPostCommentToggle = () => ({
  type: TYPES.postLoadingFormComment,
});

export const createComment = params => {
  return dispatch => {
    dispatch(loadingPostCommentToggle());

    createCommentLegislativeProcess(params.formParams)
      .then(() => {
        dispatch(toggleFormCommentLayer());
        dispatch(loadingPostCommentToggle());

        dispatch(
          showNotificationLayer('Comentário salvo com sucesso!')
        );
      })
      .catch(responseFail => {
        dispatch(loadingPostCommentToggle());

        dispatch({
          type: TYPES.postFormCommentError,
          payload: responseFail.response
        });
      })
  }
}

export const toggleFormCommentLayer = params => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.legislative,
      action: analytics.actions.legislativeComment,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'comment' },
      { type: TYPES.setDataFormComment, payload: params },
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
};
