import { getCookie, decryptAes, dateInterfere } from '../../../utils';

export default object => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  const taskDate = dateInterfere(new Date(), 5, 'days');

  return {
    id: null,
    sourceId: object.id,
    source: 'expense',
    status: 'pending',
    description: `Lembrete despesa ${object.reference}.`,
    taskDate,
    participants: [decipher?.user?.id],
  }
};
