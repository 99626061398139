const TYPES = ({

  // list
  loadingFormSearch: 'LOADING_SEARCH_SETTINGS_OFFICE_FORM_TOGGLE',
  searchSuccess: 'SEARCH_SETTINGS_OFFICE_SUCCESS',
  searchError: 'SEARCH_SETTINGS_OFFICE_LIST_ERROR',

  // form create
  updateItemListingManagerSuccess: 'MANAGER_SETTINGS_OFFICE_SUCCESS_UPDATE_LISTING',
  insertItemListingManagerSuccess: 'MANAGER_SETTINGS_OFFICE_SUCCESS_INSERT_LISTING',
  deleteItemListingManagerSuccess: 'MANAGER_SETTINGS_OFFICE_SUCCESS_DELETE_LISTING',

  // form search
  setDefaultPage: 'SET_SEARCH_SETTINGS_OFFICE_DEFAULT_PAGE',

  // delete
  setDataFormDelete: 'SET_STATUS_SETTINGS_OFFICE_FORM_ID',
  loadingFormDelete: 'LOADING_POST_FORM_DELETE_SETTINGS_OFFICE',
  formPostDeleteError: 'POST_FORM_DELETE_SETTINGS_OFFICE_ERROR',
});

export default TYPES;
