import React, { useState } from 'react';
import { Box, TextInput, Button } from 'grommet';
import { View, Hide } from 'grommet-icons';

const InputPassword = props => {
  const [revealPassword, setRevealPassword] = useState(false);

  return (
    <Box
      pad={{ left: 'xsmall' }}
      round={{'size': '4px'}}
      width='medium'
      direction='row'
      align='center'
      border
      fill='horizontal'
    >
      <TextInput
        plain
        type={revealPassword ? 'text' : 'password'}

        {...props}
      />

      <Button
        plain
        margin={{ right: 'small' }}
        title={revealPassword ? 'Esconder' : 'Exibir'}
        icon={revealPassword ? <View size='medium' /> : <Hide size='medium' />}
        onClick={() => setRevealPassword(!revealPassword)}
      />
    </Box>
  )
}

export default InputPassword;
