import TYPES from '../actions/types';

const INITIAL_STATE = {}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.openPublicDetails:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state;
  }
}
