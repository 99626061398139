import React, { useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ResponsiveContext, Box, Heading, Text } from 'grommet';

import { LoadingPage } from '../../../components';

import { startIntegration } from '../actions/form-start-integration';

const SettingsGoogleCallback = (props) => {
  const prevCalledRef = useRef();

  useEffect(() => {
    const { startIntegration } = props;

    //https://accounts.google.com/o/oauth2/v2/auth?client_id=926849313099-j1ogifkd6a54uijdpcdolb6dmrsmaa49.apps.googleusercontent.com&redirect_uri=http://localhost:3001/integration-calendar/callback&scope=https://www.googleapis.com/auth/calendar&response_type=code&state=eyJjbGllbnRJZCI6MX0=&access_type=offline&prompt=consent
    //http://localhost:3001/integration-calendar/callback?state=eyJjbGllbnRJZCI6MX0=&code=4/0AeanS0aAoRVxp2mZeSLTrtxQIdZy2H1mMh-4qOx6pOYo1M5X6fNC-afLwIVQrmLkwqkKvA&scope=https://www.googleapis.com/auth/calendar
    //http://localhost:3001/integration-calendar/callback?error=access_denied&state=eyJjbGllbnRJZCI6MX0%3D

    const query = new URLSearchParams(props.location.search);

    const code = query.get('code');
    const stateEncoded = query.get('state');
    const error = query.get('error');

    if((code || error) && stateEncoded && !prevCalledRef.current) {
      prevCalledRef.current = true;

      startIntegration({
        error,
        stateEncoded,
        code,
      });
    }

  });

  const { loading, success } = props;

  if(loading) {
    return <LoadingPage />;
  }

  if(success !== null) {
    return (
      <ResponsiveContext.Consumer>
        {size => (

          <Box direction='row' justify='center'>
            <Box
              direction={ size === 'small' ? 'column' : 'row' }
              width='xlarge'
            >
              <Box
                width='medium'
                fill='horizontal'
                pad={ size === 'small' ? 'large' : 'medium' }
                margin={{ right: size === 'small' ? 'none' : 'medium' }}
              >
                <Heading level='1' size='small'>
                  {success ? <>Sucesso</> : <>Ops, parece que algo falhou</>}
                </Heading>

                {success && (<Text>
                  Muito bom! A primeira parte da integração foi realizada.
                  Você já pode fechar essa aba e retornar ao sistema.
                </Text>)}

                {!success && (<Text>
                  Para continuar com a integração é preciso que a conta do Google
                  forneça o consentimento para criar eventos no calendário.
                </Text>)}
              </Box>

              <Box
                width='medium'
                fill='horizontal'
                pad={ size === 'small' ? 'large' : 'medium' }
              />
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  }

  return null;
}

const mapStateToProps = ({ settingsIntegrationCalendarReducer: { formStart } }) => ({
  loading: formStart.loading,
  success: formStart.success,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  startIntegration,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettingsGoogleCallback)
);
