import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Details } from '../components/details/details';
import { Search } from '../components/form-search/form-search';
import { Delete } from '../components/form-delete/form-delete';
import { Create } from '../components/form-manager/form-manager';
import { Reminder } from '../components/form-reminder/form-reminder';
import { SearchResult } from '../components/search-result/search-result';
import { Communication } from '../components/communication/communication';

import Header from '../../../components/header/header';

import { searchExpenses, setDefaultPageRules } from '../actions/form-search';
import { getUsersOptions } from '../../../actions/select-options';

import { formattedDateTime } from '../../../utils';

import paginationDefault from '../../../constants/pagination';

class Expenses extends Component {
  componentDidMount() {
    const {
      getUsersOptions,

      searchExpenses,
      setDefaultPageRules,

      reference,
      categoryId,
      statusId,
      startDateExpense,
      endDateExpense,
      recipient,
      isRefund,
    } = this.props;

    getUsersOptions();

    setDefaultPageRules();
    searchExpenses({
      reference,
      categoryId,
      statusId,
      startDateExpense: formattedDateTime(startDateExpense),
      endDateExpense: formattedDateTime(endDateExpense),
      recipient,
      isRefund,
      page: paginationDefault.page,
      limit: paginationDefault.limit,
    });
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <Search />
        <Delete />
        <Create />
        <Reminder />
        <Details />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ expenseReducer: { formSearch }, authReducer: { authenticated } }) => ({
  userLogged: authenticated,

  reference: formSearch.reference,
  categoryId: formSearch.categoryId,
  statusId: formSearch.statusId,
  startDateExpense: formSearch.startDateExpense,
  endDateExpense: formSearch.endDateExpense,
  recipient: formSearch.recipient,
  isRefund: formSearch.isRefund,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getUsersOptions,

    searchExpenses,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);
