import React from 'react';
import { Box, Text, Button, ResponsiveContext } from 'grommet';
import { Host } from 'grommet-icons';

export const EmptySearches = (props) => {
  const {
    title = 'Não encontramos nada :(',
    message = 'Tente mudar os filtros da sua consulta para encontrar o que procura.',
    buttonFilters = null,
    buttonCreate = null,
  } = props;

  const leftColumn = () => ({
    small: '90vw',
    medium: '20vw',
    large: '20vw',
    xlarge: '15vw'
  });

  const rightColumn = () => ({
    small: '90vw',
    medium: '30vw',
    large: '30vw',
    xlarge: '20vw'
  });

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          justify='center'
          align='center'
          height='100vh'
        >
          <Host size='large' color='status-critical' />

          <Box
            direction={size !== 'small' ? 'row' : 'column'}
            margin={{ vertical: 'large' }}
            gap='medium'
          >
            <Box width={leftColumn()[size]}>
              <Text
                size='xlarge'
                textAlign={size !== 'small' ? 'end' : 'center'}
              >
                {title}
              </Text>
            </Box>

            <Box width={rightColumn()[size]}>
              <Text
                size='small'
                color='dark-5'
                textAlign={size !== 'small' ? 'start' : 'center'}
              >
                {message}
              </Text>
            </Box>
          </Box>

          <Box direction='row' gap='small' align='center'>
            {buttonFilters && (
              <Button
                label={buttonFilters.label}
                color='dark-3'
                onClick={() => { buttonFilters.action() }}
              />
            )}

            {buttonFilters && buttonCreate && (<Text size='small' color='dark-5'>Ou</Text>)}

            {buttonCreate && (
              <Button
                primary
                label={buttonCreate.label}
                color='neutral-1'
                onClick={() => { buttonCreate.action() }}
              />
            )}

          </Box>
        </Box>

      )}
    </ResponsiveContext.Consumer>
  )
}
