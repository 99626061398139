export default () => ({
  id: null,
  subject: null,
  date: null,
  description: null,
  hourStart: null,
  hourEnd: null,
  participant: [],
  requesterName: null,
  requesterPhone: null,
  location: null,
  notify: false,
  integrate: false,

  isEditing: false,
  isCopy: false,
})
