import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { loadUserLoggedByLocalStorage } from '../../modules/auth/actions/auth';

export const FillLocalStorageUserLogged = (ComposedComponent, redirectPath = null) => {
  class FillLocalStorageUserLoggedComponent extends Component {
    constructor(props) {
      super(props);
      this.loadAuth();
    }

    async loadAuth() {
      const { loadUserLoggedByLocalStorage } = this.props;

      await loadUserLoggedByLocalStorage();
      await this.checkAuth();
    };

    async checkAuth() {
      const {
        userLogged: { user }
      } = this.props;

      /* if not exists user, means what localstorage os undefined or null, so redirect to the website */
      if(!user && redirectPath) {
        window.location = redirectPath;
      }
    };

    render() {
      const {
        userLogged: { user },
      } = this.props;

      if(!user && redirectPath) return null;

      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = ({ authReducer: { authenticated } }) => ({ userLogged: authenticated });
  const mapDispatchToProps = dispatch => bindActionCreators({ loadUserLoggedByLocalStorage }, dispatch);

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(FillLocalStorageUserLoggedComponent));
}
