import TYPES from '../actions/types';

const INITIAL_STATE = {
  loadingPostForm: false,
  postDataSuccess: false,
  postDataError: { message: '', errors: [] },
  dataSuccess: null,
}

export default function(state = INITIAL_STATE, action) {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    case TYPES.formChangeValues:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.formInitializeValues:
      return {
        ...state,
        ...action.payload,
      }

    case TYPES.formGetWatingResponse:
      return {
        ...state,
        watingServerResponse: action.payload,
      }

    case TYPES.formGetResultError:
      return {
        ...state,
        getServerPresponseError: true,
      }

    case TYPES.formClearValues:
      return {
        ...INITIAL_STATE,
      }

    case TYPES.formPostWatingResponse:
      return {
        ...state,
        loadingPostForm: !state.loadingPostForm,
      };

    case TYPES.formPostResultError:
      return {
        ...state,
        postDataError: action.payload,
      };

    case TYPES.formPostResultSuccess:
      return {
        ...state,
        dataSuccess: action.payload,
      }

    default:
      return state;
  }
}
