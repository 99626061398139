import TYPES from '../actions/types';
import paginationDefault from '../../../constants/pagination';

const INITIAL_STATE = {
  reference: null,
  name: null,
  telephone: null,
  subject: null,
  status: null,
  startDate: null,
  endDate: null,

  page: paginationDefault.page,
  limit: paginationDefault.limit,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.changeFormSearchFields:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value
      }

    case TYPES.setDefaultPage:
      return {
        ...state,
        page: paginationDefault.page,
        limit: paginationDefault.limit,
      };

    case TYPES.clearFormSearchFields:
      return {
        ...state,
        reference: null,
        name: null,
        telephone: null,
        subject: null,
        status: null,
        startDate: null,
        endDate: null,
      }

    case TYPES.paginationFormSearch:
      return { ...state, page: action.payload };

    default:
      return state;
  }
}
