import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default params => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'POST',
    url: '/legislative.processe.php',
    data: params,
    headers: {
      token: decipher?.token?.value,
      action: 'createComment',
    }
  });
}
