const formatWeekDay = (weekDay) => {
  const colors = new Map([
    [0, 'Domingo'],
    [1, 'Segunda-feira'],
    [2, 'Terça-feira'],
    [3, 'Quarta-feira'],
    [4, 'Quinta-feira'],
    [5, 'Sexta-feira'],
    [6, 'Sábado'],
 ]);

 return colors.get(weekDay) || '';
}

export default formatWeekDay;
