import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';

const Description = ({ title, value }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          margin={{
            top: size === 'small' ? 'small' : 'xsmall',
            bottom: size === 'small' ? 'xsmall' : 'none',
            horizontal: 'large'
          }}
          pad={{ bottom: 'small' }}
          flex={{ shrink: 0 }}
        >
          {title && (
            <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
              {title}
            </Text>
          )}

          {value ? (
            React.isValidElement(value) ? value : <Text size='small' color='dark-3'>{value}</Text>
          ) : null}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Description;
