import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Notification } from '../../../../components';
import { hideNotificationLayer } from '../../../../actions/notification';

function CommunicationComponent(props) {
  const {
    description,
    isOpen,
    hideNotificationLayer,
  } = props;

  return <Notification
    description={description}
    isOpen={isOpen}
    onClose={hideNotificationLayer}
  />
}

const mapStateToProps = ({ notificationReducer }) => ({
  description: notificationReducer.description,
  isOpen: notificationReducer.isOpen,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    hideNotificationLayer,
  }, dispatch);

export const Communication = connect(mapStateToProps, mapDispatchToProps)(CommunicationComponent);
