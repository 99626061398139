import { combineReducers } from 'redux';
import clientBillingReducer from './client-use-license';
import openInvoiceReducer from './open-invoice';

const invoiceReducer = combineReducers({
  clientBilling: clientBillingReducer,
  openInvoice: openInvoiceReducer,
});

export default invoiceReducer;
