import TYPES from '../actions/types';

const INITIAL_STATE = {
  treatmentId: 0,
  reference: '',

  date: null,
  responsibleId: null,
  comment: null,

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    // fields
    case TYPES.changeFormCommentField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.setDataFormComment:
      return {
        ...state,
        treatmentId: action.payload.id,
        reference: action.payload.reference,

        date: new Date(),
        responsibleId: null,
        comment: null,
        postDataError: { message: '', errors: [] },
      }

    case TYPES.postLoadingFormComment:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.postFormCommentError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
