import COMMON_TYPES from '../../../actions/types';

export const toggleDetailsLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'details' },
      { type: COMMON_TYPES.detailsGetResult, payload: { data: params } }
    ]
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.detailsGetResult, payload: { data: null } }
  ];
};
