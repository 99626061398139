import React, { useState } from 'react';
import { ResponsiveContext, Box, Text, Anchor } from 'grommet';
import { Copy } from 'grommet-icons';

const CopyUrl = ({ title, value }) => {
  const [copied, setCopied] = useState(false);

  const clipBoard = (text) => {
    navigator.clipboard.writeText(text)
    .then(() => setCopied(true))
    .catch(error => console.log(error));
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          margin={{
            top: size === 'small' ? 'small' : 'xsmall',
            bottom: size === 'small' ? 'xsmall' : 'none',
            horizontal: 'large'
          }}
          pad={{ bottom: 'small' }}
          flex={{ shrink: 0 }}
        >
          <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
            {title}
          </Text>

          <Box
            round='xsmall'
            border={{ color: 'light-4' }}
            background='white'
            pad={{
              horizontal: size === 'small' ? 'medium' : 'small'
            }}
            direction='row'
            align='center'
            justify='between'
          >
            <Text truncate title={value}>
              <Anchor
                label={value}
                href={value}
                size='small'
                target='_blank'
              />
            </Text>

            <Anchor
              title={copied ? 'Copiado para a área de transferência' : 'Copiar'}
              icon={<Copy color='dark-3' />}
              onClick={() => clipBoard(value)}
            />
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default CopyUrl;
