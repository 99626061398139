import { details } from '../../../api/common';
import { printOut } from '../components/print/print';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';

const _command = params =>
  async dispatch => {
    try
    {
      dispatch(
        showNotificationLayer('Gerando arquivo. Aguarde...')
      );

      const voterDetails = await details(params.id, 'eleitor.php');
      printOut({
        data: voterDetails.data,
        fileName: `eleitor-${voterDetails.data.electorId}.html`,
      });
    }
    catch(error)
    {
      dispatch(
        showNotificationLayer('Ops... não conseguimos gerar o arquivo.')
      )
    }
  }

export const generatePrint = params => {
  //ga
  analytics.registerEvent({
    category: analytics.categories.voter,
    action: analytics.actions.voterPrint,
  });

  return _command(params);
}
