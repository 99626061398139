const updateRow = ({ object, key, items }) => {
  const {
    description,
    statusName: status,
    statusColor,
  } = object;

  return items.map(item =>
    item.id === key ? { ...item, description, status, statusColor } : item
  );
}

export default updateRow;
