const selectOccupationOptions = [
  { id: 3, name: 'Acompanhante de Idosos' },
  { id: 2, name: 'Açougueiro' },
  { id: 4, name: 'Acupunturista' },
  { id: 1, name: 'Administrador de Banco de Dados' },
  { id: 5, name: 'Administrador de Empresas' },
  { id: 6, name: 'Administrador de Redes' },
  { id: 10, name: 'Administrador de Sistemas' },
  { id: 11, name: 'Administrador Hospitalar' },
  { id: 12, name: 'Administrador Público' },
  { id: 13, name: 'Advogado' },
  { id: 15, name: 'Aeronauta' },
  { id: 17, name: 'Agente Comunitário de Saúde' },
  { id: 18, name: 'Agente de Defesa Sanitária' },
  { id: 19, name: 'Agente de Viagens' },
  { id: 20, name: 'Agente Funerário' },
  { id: 21, name: 'Agente Penitenciário' },
  { id: 22, name: 'Agricultor' },
  { id: 23, name: 'Agrônomo' },
  { id: 24, name: 'Ajudante de Motorista' },
  { id: 25, name: 'Ajudante de Transportes' },
  { id: 26, name: 'Alfaiate' },
  { id: 28, name: 'Almoxarife' },
  { id: 29, name: 'Ambientalista' },
  { id: 30, name: 'Ambulante' },
  { id: 31, name: 'Analista Administrativo' },
  { id: 34, name: 'Analista Ambiental' },
  { id: 36, name: 'Analista Comercial' },
  { id: 37, name: 'Analista de Atendimento' },
  { id: 39, name: 'Analista de Banco' },
  { id: 40, name: 'Analista de Banco de Dados' },
  { id: 42, name: 'Analista de Cobrança' },
  { id: 43, name: 'Analista de Comércio Exterior' },
  { id: 44, name: 'Analista de Compras' },
  { id: 45, name: 'Analista de Comunicação' },
  { id: 46, name: 'Analista de Contabilidade' },
  { id: 47, name: 'Analista de Contas a Pagar e Receber' },
  { id: 48, name: 'Analista de Controladoria' },
  { id: 52, name: 'Analista de Controle de Qualidade' },
  { id: 53, name: 'Analista de Crédito e Cobrança' },
  { id: 54, name: 'Analista de Custos' },
  { id: 55, name: 'Analista de Departamento Pessoal' },
  { id: 56, name: 'Analista de Desenvolvimento' },
  { id: 59, name: 'Analista de Distribuição' },
  { id: 60, name: 'Analista de E-commerce' },
  { id: 62, name: 'Analista de Engenharia Elétrica' },
  { id: 61, name: 'Analista de Estoque' },
  { id: 64, name: 'Analista de Fiscal' },
  { id: 87, name: 'Analista de Informática' },
  { id: 88, name: 'Analista de Investimentos' },
  { id: 89, name: 'Analista de Logística' },
  { id: 91, name: 'Analista de Manutenção' },
  { id: 93, name: 'Analista de Marketing' },
  { id: 94, name: 'Analista de PCP' },
  { id: 95, name: 'Analista de Produção' },
  { id: 97, name: 'Analista de Publicidade e Propaganda' },
  { id: 98, name: 'Analista de Qualidade' },
  { id: 100, name: 'Analista de Recursos Humanos' },
  { id: 101, name: 'Analista de Redes' },
  { id: 103, name: 'Analista de Representação Comercial' },
  { id: 104, name: 'Analista de SAC' },
  { id: 106, name: 'Analista de Seguros' },
  { id: 107, name: 'Analista de Sistemas' },
  { id: 108, name: 'Analista de Suporte Técnico' },
  { id: 109, name: 'Analista de Suprimentos' },
  { id: 110, name: 'Analista de Tecnologia da Informação (TI)' },
  { id: 111, name: 'Analista de Telecomunicações' },
  { id: 112, name: 'Analista de Telemarketing ' },
  { id: 114, name: 'Analista de Tesouraria' },
  { id: 115, name: 'Analista de Transportes' },
  { id: 116, name: 'Analista de Treinamento' },
  { id: 118, name: 'Analista Econômico' },
  { id: 63, name: 'Analista Financeiro' },
  { id: 65, name: 'Analista Químico' },
  { id: 67, name: 'Angiologista' },
  { id: 68, name: 'Antropólogo' },
  { id: 69, name: 'Apicultor' },
  { id: 70, name: 'Apresentador' },
  { id: 71, name: 'Argumentista' },
  { id: 74, name: 'Arquiteto' },
  { id: 80, name: 'Arquivista' },
  { id: 81, name: 'Arrumadeira' },
  { id: 83, name: 'Artista Plástico' },
  { id: 85, name: 'Assessor de Imprensa' },
  { id: 122, name: 'Assistente Administrativo' },
  { id: 119, name: 'Assistente Comercial Interno' },
  { id: 120, name: 'Assistente de Almoxarifado' },
  { id: 121, name: 'Assistente de Arquitetura' },
  { id: 124, name: 'Assistente de Atendimento' },
  { id: 125, name: 'Assistente de Cobrança' },
  { id: 126, name: 'Assistente de Comércio Exterior' },
  { id: 127, name: 'Assistente de Compras' },
  { id: 128, name: 'Assistente de Comunicação' },
  { id: 129, name: 'Assistente de Contabilidade' },
  { id: 130, name: 'Assistente de Contas a Pagar e Receber' },
  { id: 131, name: 'Assistente de Controladoria' },
  { id: 132, name: 'Assistente de Controle de Qualidade' },
  { id: 133, name: 'Assistente de Cozinha' },
  { id: 134, name: 'Assistente de Crédito e Cobrança' },
  { id: 135, name: 'Assistente de Custos' },
  { id: 136, name: 'Assistente de Departamento Pessoal' },
  { id: 137, name: 'Assistente de Desenvolvimento' },
  { id: 139, name: 'Assistente de Distribuição' },
  { id: 140, name: 'Assistente de E-commerce' },
  { id: 141, name: 'Assistente de Enfermagem' },
  { id: 142, name: 'Assistente de Engenharia Elétrica' },
  { id: 143, name: 'Assistente de Estoque' },
  { id: 145, name: 'Assistente de Informática' },
  { id: 146, name: 'Assistente de Jornalismo' },
  { id: 148, name: 'Assistente de Logística' },
  { id: 150, name: 'Assistente de Manutenção' },
  { id: 152, name: 'Assistente de Marketing' },
  { id: 153, name: 'Assistente' },
  { id: 154, name: 'Assistente de Produção' },
  { id: 155, name: 'Assistente de Publicidade e Propaganda' },
  { id: 156, name: 'Assistente de Qualidade' },
  { id: 158, name: 'Assistente de Recursos Humanos' },
  { id: 159, name: 'Assistente de Representação Comercial' },
  { id: 160, name: 'Assistente de SAC' },
  { id: 161, name: 'Assistente de Seguros' },
  { id: 162, name: 'Assistente de Sistemas' },
  { id: 163, name: 'Assistente de Suporte Técnico' },
  { id: 164, name: 'Assistente de Suprimentos' },
  { id: 165, name: 'Assistente de Tecnologia da Informação (TI)' },
  { id: 166, name: 'Assistente de Telecomunicações' },
  { id: 167, name: 'Assistente de Telemarketing' },
  { id: 169, name: 'Assistente de Tesouraria' },
  { id: 170, name: 'Assistente de Transportes' },
  { id: 172, name: 'Assistente de Vendas' },
  { id: 144, name: 'Assistente Financeiro' },
  { id: 174, name: 'Assistente Fiscal' },
  { id: 175, name: 'Assistente Social' },
  { id: 177, name: 'Atendente' },
  { id: 181, name: 'Auditor de Qualidade' },
  { id: 182, name: 'Auditor Externo' },
  { id: 183, name: 'Auditor Interno' },
  { id: 197, name: 'Auxiliar de Escritório' },
  { id: 184, name: 'Auxiliar de Almoxarifado' },
  { id: 189, name: 'Auxiliar de Comércio Exterior' },
  { id: 190, name: 'Auxiliar de Contas a Pagar e Receber' },
  { id: 191, name: 'Auxiliar de Controle de Qualidade' },
  { id: 192, name: 'Auxiliar de Cozinha' },
  { id: 193, name: 'Auxiliar de Crédito e Cobrança' },
  { id: 194, name: 'Auxiliar de Custos' },
  { id: 200, name: 'Auxiliar de Limpeza' },
  { id: 203, name: 'Auxiliar de PCP' },
  { id: 204, name: 'Auxiliar' },
  { id: 205, name: 'Auxiliar de SAC' },
  { id: 206, name: 'Auxiliar de Segurança' },
  { id: 208, name: 'Auxiliar de Suprimentos' },
  { id: 210, name: 'Auxiliar de Transportes' },
  { id: 211, name: 'Auxiliar de Usinagem' },
  { id: 212, name: 'Auxiliar de Veterinária' },
  { id: 215, name: 'Auxiliar Fiscal' },
  { id: 216, name: 'Auxiliar Jurídico' },
  { id: 218, name: 'Babá' },
  { id: 219, name: 'Balconista' },
  { id: 220, name: 'Bancário' },
  { id: 217, name: 'Bartender' },
  { id: 222, name: 'Biblioteconista' },
  { id: 223, name: 'Biólogo' },
  { id: 224, name: 'Biomédico' },
  { id: 225, name: 'Bioquímico' },
  { id: 226, name: 'Biotecnólogo' },
  { id: 227, name: 'Bombeiro' },
  { id: 228, name: 'Borracheiro' },
  { id: 229, name: 'Cabeleireiro' },
  { id: 233, name: 'Carteiro' },
  { id: 235, name: 'Ceramista' },
  { id: 237, name: 'Cinegrafista' },
  { id: 240, name: 'Cobrador de Coletivo' },
  { id: 241, name: 'Comissário de Bordo' },
  { id: 242, name: 'Confeiteiro' },
  { id: 243, name: 'Conferente' },
  { id: 245, name: 'Consultor Contábil' },
  { id: 246, name: 'Consultor de Marketing' },
  { id: 247, name: 'Consultor de Moda' },
  { id: 248, name: 'Consultor de Qualidade' },
  { id: 249, name: 'Consultor de Vendas Externas' },
  { id: 250, name: 'Consultor de Viagens' },
  { id: 251, name: 'Contador' },
  { id: 252, name: 'Controlador de Tráfego' },
  { id: 253, name: 'Coordenador Administrativo' },
  { id: 254, name: 'Coordenador Comercial ' },
  { id: 256, name: 'Coordenador de Almoxarifado' },
  { id: 257, name: 'Coordenador de Arquitetura' },
  { id: 259, name: 'Coordenador de Atendimento' },
  { id: 260, name: 'Coordenador de Banco' },
  { id: 262, name: 'Coordenador de Cobrança' },
  { id: 263, name: 'Coordenador de Comércio Exterior' },
  { id: 264, name: 'Coordenador de Compras' },
  { id: 265, name: 'Coordenador de Comunicação' },
  { id: 266, name: 'Coordenador de Contabilidade' },
  { id: 267, name: 'Coordenador de Controladoria' },
  { id: 268, name: 'Coordenador de Controle de Qualidade' },
  { id: 269, name: 'Coordenador de Cozinha' },
  { id: 270, name: 'Coordenador de Crédito e Cobrança' },
  { id: 271, name: 'Coordenador de Custos' },
  { id: 272, name: 'Coordenador de Departamento Pessoal' },
  { id: 276, name: 'Coordenador de E-commerce' },
  { id: 277, name: 'Coordenador de Enfermagem' },
  { id: 278, name: 'Coordenador de Engenharia e Segurança do Trabalho' },
  { id: 280, name: 'Coordenador de Estoque' },
  { id: 282, name: 'Coordenador de Informática' },
  { id: 283, name: 'Coordenador de Jornalismo' },
  { id: 285, name: 'Coordenador de Logística' },
  { id: 287, name: 'Coordenador de Manutenção' },
  { id: 289, name: 'Coordenador de Marketing' },
  { id: 292, name: 'Coordenador de Publicidade e Propaganda' },
  { id: 293, name: 'Coordenador de Qualidade' },
  { id: 295, name: 'Coordenador de Recursos Humanos' },
  { id: 296, name: 'Coordenador de Representação Comercial' },
  { id: 303, name: 'Coordenador de Suprimentos' },
  { id: 304, name: 'Coordenador de Tecnologia da Informação' },
  { id: 305, name: 'Coordenador de Telecomunicações' },
  { id: 306, name: 'Coordenador de Telemarketing' },
  { id: 308, name: 'Coordenador de Tesouraraia' },
  { id: 309, name: 'Coordenador de Transportes' },
  { id: 312, name: 'Coordenador Econômico' },
  { id: 313, name: 'Coordenador Financeiro' },
  { id: 314, name: 'Coordenador Fiscal' },
  { id: 315, name: 'Coordenador' },
  { id: 316, name: 'Corretor de Seguros' },
  { id: 317, name: 'Cozinheiro' },
  { id: 319, name: 'Dentista' },
  { id: 320, name: 'Desenhista' },
  { id: 321, name: 'Designer de Interiores' },
  { id: 322, name: 'Designer de Moda' },
  { id: 318, name: 'Diretor Administrativo' },
  { id: 324, name: 'Diretor Comercial' },
  { id: 337, name: 'Diretor de Departamento Pessoal' },
  { id: 333, name: 'Diretor de Informática' },
  { id: 334, name: 'Diretor de Jornalismo' },
  { id: 336, name: 'Diretor de Logística' },
  { id: 331, name: 'Diretor de Marketing' },
  { id: 327, name: 'Diretor de Produção' },
  { id: 329, name: 'Diretor de Recursos Humanos' },
  { id: 328, name: 'Diretor' },
  { id: 330, name: 'Diretor de Tecnologia da Informação (TI)' },
  { id: 332, name: 'Diretor Financeiro' },
  { id: 1565, name: 'Do lar' },
  { id: 338, name: 'Ecologista' },
  { id: 339, name: 'Economista' },
  { id: 340, name: 'Editor' },
  { id: 341, name: 'Educador' },
  { id: 342, name: 'Eletricista' },
  { id: 344, name: 'Embalador' },
  { id: 345, name: 'Empregado (a) Doméstico (a)' },
  { id: 346, name: 'Encanador' },
  { id: 347, name: 'Encarregado de Almoxarifado' },
  { id: 348, name: 'Encarregado de PCP' },
  { id: 349, name: 'Encarregado' },
  { id: 350, name: 'Encarregado de Segurança' },
  { id: 351, name: 'Encarregado de Transporte' },
  { id: 355, name: 'Enfermeiro' },
  { id: 373, name: 'Engenheiro' },
  { id: 356, name: 'Engenheiro Aeronáutico' },
  { id: 358, name: 'Engenheiro Agrônomo' },
  { id: 359, name: 'Engenheiro Ambiental' },
  { id: 360, name: 'Engenheiro Civil' },
  { id: 364, name: 'Engenheiro da Computação' },
  { id: 365, name: 'Engenheiro de Alimentos' },
  { id: 366, name: 'Engenheiro de Engenharia e Segurança do Trabalho' },
  { id: 367, name: 'Engenheiro de Produção' },
  { id: 368, name: 'Engenheiro de Suprimentos' },
  { id: 369, name: 'Engenheiro de Telecomunicações' },
  { id: 370, name: 'Engenheiro Eletricista' },
  { id: 371, name: 'Engenheiro Metalúrgico' },
  { id: 376, name: 'Estagiário' },
  { id: 438, name: 'Esteticista' },
  { id: 439, name: 'Estilista' },
  { id: 440, name: 'Estoquista' },
  { id: 1566, name: 'Estudante' },
  { id: 443, name: 'Farmacêutico' },
  { id: 444, name: 'Feirante' },
  { id: 445, name: 'Filósofo' },
  { id: 446, name: 'Físico' },
  { id: 447, name: 'Fisioterapeuta' },
  { id: 448, name: 'Fonoaudiólogo' },
  { id: 449, name: 'Fotógrafo' },
  { id: 450, name: 'Fresador' },
  { id: 442, name: 'Fundidor' },
  { id: 453, name: 'Garçom' },
  { id: 454, name: 'Gari' },
  { id: 455, name: 'Gastrônomo' },
  { id: 456, name: 'Geofísico' },
  { id: 457, name: 'Geográfo' },
  { id: 458, name: 'Geólogo' },
  { id: 459, name: 'Gerente Administrativo' },
  { id: 460, name: 'Gerente Comercial' },
  { id: 464, name: 'Gerente de Atendimento' },
  { id: 465, name: 'Gerente de Banco' },
  { id: 466, name: 'Gerente de Cobrança' },
  { id: 467, name: 'Gerente de Comércio Exterior' },
  { id: 468, name: 'Gerente de Compras' },
  { id: 469, name: 'Gerente de Comunicação' },
  { id: 470, name: 'Gerente de Contabilidade' },
  { id: 471, name: 'Gerente' },
  { id: 472, name: 'Gerente de Controladoria' },
  { id: 473, name: 'Gerente de Controle de Qualidade' },
  { id: 475, name: 'Gerente de Departamento Pessoal' },
  { id: 476, name: 'Gerente de Desenvolvimento' },
  { id: 478, name: 'Gerente de Distribuição' },
  { id: 479, name: 'Gerente de E-commerce' },
  { id: 481, name: 'Gerente de Engenharia Elétrica' },
  { id: 482, name: 'Gerente de Estoque' },
  { id: 485, name: 'Gerente de Jornalismo' },
  { id: 486, name: 'Gerente de Logística' },
  { id: 487, name: 'Gerente de Loja' },
  { id: 488, name: 'Gerente de Manutenção' },
  { id: 490, name: 'Gerente de Marketing' },
  { id: 491, name: 'Gerente de PCP' },
  { id: 492, name: 'Gerente de Produção' },
  { id: 493, name: 'Gerente de Publicidade e Propaganda' },
  { id: 494, name: 'Gerente de Qualidade' },
  { id: 496, name: 'Gerente de Recursos Humanos' },
  { id: 497, name: 'Gerente de Representação Comercial' },
  { id: 498, name: 'Gerente de SAC' },
  { id: 499, name: 'Gerente de Salão' },
  { id: 500, name: 'Gerente de Segurança Patrimonial' },
  { id: 501, name: 'Gerente de Seguros' },
  { id: 502, name: 'Gerente de Sistemas' },
  { id: 503, name: 'Gerente de Suporte Técnico' },
  { id: 504, name: 'Gerente de Suprimentos' },
  { id: 505, name: 'Gerente de Tecnologia da Informação (TI)' },
  { id: 506, name: 'Gerente de Telecomunicações' },
  { id: 507, name: 'Gerente de Telemarketing' },
  { id: 510, name: 'Gerente de Transportes' },
  { id: 511, name: 'Gerente Financeiro' },
  { id: 512, name: 'Gerente Fiscal' },
  { id: 513, name: 'Gerente Químico' },
  { id: 515, name: 'Gestor Ambiental' },
  { id: 517, name: 'Governanta' },
  { id: 519, name: 'Historiador' },
  { id: 518, name: 'Homeopata' },
  { id: 520, name: 'Inspetor de Controle de Qualidade' },
  { id: 521, name: 'Instalador' },
  { id: 525, name: 'Instrutor de Treinamento' },
  { id: 527, name: 'Jardineiro' },
  { id: 528, name: 'Jornaleiro' },
  { id: 526, name: 'Jornalista' },
  { id: 530, name: 'Laboratorista' },
  { id: 534, name: 'Laminador' },
  { id: 539, name: 'Lavadeiro' },
  { id: 541, name: 'Lavador de Veículos' },
  { id: 542, name: 'Layoutista' },
  { id: 544, name: 'Letrista' },
  { id: 545, name: 'Líder Comercial' },
  { id: 546, name: 'Líder de Atendimento' },
  { id: 547, name: 'Líder de Caixa' },
  { id: 548, name: 'Líder de Cobrança' },
  { id: 549, name: 'Líder de Controle de Qualidade' },
  { id: 550, name: 'Líder de Equipe' },
  { id: 551, name: 'Líder de Expedição' },
  { id: 554, name: 'Líder de Logística' },
  { id: 555, name: 'Líder de Loja' },
  { id: 556, name: 'Líder de Manutenção' },
  { id: 558, name: 'Líder de Merchandising' },
  { id: 559, name: 'Líder de Montagem' },
  { id: 560, name: 'Líder de Pintura' },
  { id: 561, name: 'Líder de Portaria' },
  { id: 562, name: 'Líder de Produção' },
  { id: 563, name: 'Líder de Recepção' },
  { id: 564, name: 'Líder de Refrigeração' },
  { id: 566, name: 'Líder de Usinagem' },
  { id: 567, name: 'Líder de Vendas' },
  { id: 569, name: 'Limpador' },
  { id: 573, name: 'Locutor' },
  { id: 576, name: 'Maçariqueiro' },
  { id: 579, name: 'Maître' },
  { id: 581, name: 'Manicure e Pedicure' },
  { id: 583, name: 'Manipulador de Farmácia' },
  { id: 584, name: 'Manobrista' },
  { id: 587, name: 'Maquiador' },
  { id: 588, name: 'Maquinista de Trem' },
  { id: 589, name: 'Marceneiro' },
  { id: 590, name: 'Marinheiro' },
  { id: 591, name: 'Marmorista' },
  { id: 593, name: 'Masseiro' },
  { id: 594, name: 'Massoterapeuta' },
  { id: 595, name: 'Matemático' },
  { id: 596, name: 'Mecânico' },
  { id: 609, name: 'Mecânico de Veículos' },
  { id: 672, name: 'Médico' },
  { id: 615, name: 'Médico Acupunturista' },
  { id: 616, name: 'Médico Alergologista' },
  { id: 618, name: 'Médico Anestesista' },
  { id: 620, name: 'Médico Cardiologista' },
  { id: 621, name: 'Médico Cirurgião' },
  { id: 625, name: 'Médico Clínico Geral' },
  { id: 626, name: 'Médico Colonoscopista' },
  { id: 627, name: 'Médico Dermatologista' },
  { id: 629, name: 'Médico do Trabalho' },
  { id: 633, name: 'Médico Endocrinologista' },
  { id: 636, name: 'Médico Gastroenterologista' },
  { id: 637, name: 'Médico Gastropediatra' },
  { id: 638, name: 'Médico Geneticista' },
  { id: 639, name: 'Médico Geriatra' },
  { id: 640, name: 'Médico Ginecologista' },
  { id: 645, name: 'Médico Imunologista' },
  { id: 646, name: 'Médico Infectologista' },
  { id: 651, name: 'Médico Neurocirurgião' },
  { id: 652, name: 'Médico Neurologista' },
  { id: 653, name: 'Médico Nutrólogo' },
  { id: 654, name: 'Médico Obstetra' },
  { id: 655, name: 'Médico Oftalmologista' },
  { id: 656, name: 'Médico Oncologista' },
  { id: 658, name: 'Médico Ortopedista' },
  { id: 659, name: 'Médico Otorrinolaringologista' },
  { id: 661, name: 'Médico Pediatra' },
  { id: 665, name: 'Médico Psiquiatra' },
  { id: 666, name: 'Médico Radiologista' },
  { id: 668, name: 'Médico Reumatologista' },
  { id: 669, name: 'Médico Traumatologista' },
  { id: 671, name: 'Médico Urologista' },
  { id: 673, name: 'Medidor de Obras' },
  { id: 674, name: 'Meio Oficial Ajustador Mecânico' },
  { id: 675, name: 'Meio Oficial Carpinteiro' },
  { id: 676, name: 'Meio Oficial de Caldeiraria' },
  { id: 677, name: 'Meio Oficial de Ferramentaria' },
  { id: 679, name: 'Meio Oficial de Manutenção' },
  { id: 681, name: 'Meio Oficial de Pintura' },
  { id: 683, name: 'Meio Oficial Eletricista' },
  { id: 684, name: 'Meio Oficial Fresador' },
  { id: 686, name: 'Meio Oficial' },
  { id: 687, name: 'Meio Oficial Hidráulico' },
  { id: 688, name: 'Marceneiro' },
  { id: 689, name: 'Meio Oficial Mecânico' },
  { id: 691, name: 'Meio Oficial Montador' },
  { id: 692, name: 'Meio Oficial Serralheiro' },
  { id: 693, name: 'Meio Oficial Soldador' },
  { id: 694, name: 'Meio Oficial Torneiro Mecânico' },
  { id: 695, name: 'Mensageiro' },
  { id: 697, name: 'Merendeira' },
  { id: 698, name: 'Mestre Cervejeiro' },
  { id: 700, name: 'Mestre de Obras' },
  { id: 705, name: 'Modelo' },
  { id: 707, name: 'Moldador' },
  { id: 708, name: 'Moldureiro' },
  { id: 710, name: 'Monitor de Alunos' },
  { id: 711, name: 'Monitor de Informática' },
  { id: 712, name: 'Monitor de Qualidade' },
  { id: 713, name: 'Montador' },
  { id: 716, name: 'Montador de Equipamentos' },
  { id: 726, name: 'Mordomo' },
  { id: 727, name: 'Motoboy' },
  { id: 728, name: 'Motorista' },
  { id: 735, name: 'Motorista de Caminhão' },
  { id: 743, name: 'Museólogo' },
  { id: 744, name: 'Músico' },
  { id: 749, name: 'Nutricionista' },
  { id: 755, name: 'Oceanógrafo' },
  { id: 756, name: 'Office-boy' },
  { id: 757, name: 'Oficial de Manutenção' },
  { id: 759, name: 'Oficial de Redes' },
  { id: 760, name: 'Oficial de Refrigeração' },
  { id: 761, name: 'Oficial Eletricista' },
  { id: 762, name: 'Oficial Mecânico' },
  { id: 763, name: 'Oficial Serralheiro' },
  { id: 764, name: 'Operador Audiovisual' },
  { id: 765, name: 'Operador de Áudio' },
  { id: 766, name: 'Operador de Balança Rodoviária' },
  { id: 767, name: 'Operador de Bomba de Concreto' },
  { id: 768, name: 'Operador de Caixa' },
  { id: 769, name: 'Operador' },
  { id: 770, name: 'Operador de Caldeira' },
  { id: 771, name: 'Operador de Call Center' },
  { id: 772, name: 'Operador de Câmaras Frias' },
  { id: 787, name: 'Operador de Empilhadeira' },
  { id: 788, name: 'Operador de Equipamento' },
  { id: 789, name: 'Operador de Escavadeira' },
  { id: 796, name: 'Operador de Guincho' },
  { id: 797, name: 'Operador de Guindaste' },
  { id: 798, name: 'Operador de Injetora' },
  { id: 801, name: 'Operador de Logística' },
  { id: 802, name: 'Operador de Máquina' },
  { id: 809, name: 'Operador de Monitoramento' },
  { id: 821, name: 'Operador de Processos Químicos' },
  { id: 834, name: 'Operador de Telemarketing' },
  { id: 835, name: 'Operador de Televendas' },
  { id: 836, name: 'Operador de Torno' },
  { id: 843, name: 'Operador Mantenedor' },
  { id: 844, name: 'Orçamentista' },
  { id: 846, name: 'Orientador Educacional' },
  { id: 848, name: 'Ourives' },
  { id: 849, name: 'Padeiro' },
  { id: 850, name: 'Paisagista' },
  { id: 851, name: 'Palestrante' },
  { id: 852, name: 'Panfleteiro' },
  { id: 854, name: 'Pedagogo' },
  { id: 856, name: 'Pedreiro' },
  { id: 857, name: 'Peixeiro' },
  { id: 859, name: 'Perito Judicial' },
  { id: 860, name: 'Personal Stylist' },
  { id: 861, name: 'Personal Trainer' },
  { id: 863, name: 'Pesquisador' },
  { id: 865, name: 'Piloteiro' },
  { id: 866, name: 'Piloto' },
  { id: 870, name: 'Pintor' },
  { id: 876, name: 'Pizzaiolo' },
  { id: 877, name: 'Planejador de Produção' },
  { id: 878, name: 'Planejador de Projetos' },
  { id: 879, name: 'Podólogo' },
  { id: 883, name: 'Porteiro' },
  { id: 885, name: 'Preparador' },
  { id: 894, name: 'Produtor' },
  { id: 897, name: 'Produtor de Eventos' },
  { id: 904, name: 'Professor' },
  { id: 905, name: 'Professor Auxiliar' },
  { id: 906, name: 'Professor de Administração' },
  { id: 908, name: 'Professor de Agronomia' },
  { id: 912, name: 'Professor de Arquitetura e Urbanismo' },
  { id: 915, name: 'Professor de Artesanato' },
  { id: 917, name: 'Professor de Automação Industrial' },
  { id: 918, name: 'Professor de Biologia' },
  { id: 919, name: 'Professor de Biomedicina' },
  { id: 923, name: 'Professor de Ciência da Computação' },
  { id: 924, name: 'Professor de Ciências Contábeis' },
  { id: 926, name: 'Professor de Comércio Exterior' },
  { id: 927, name: 'Professor de Computação Gráfica' },
  { id: 928, name: 'Professor de Comunicação' },
  { id: 930, name: 'Professor de Construção Civil' },
  { id: 933, name: 'Professor de Dança' },
  { id: 934, name: 'Professor de Departamento Pessoal' },
  { id: 936, name: 'Professor de Design' },
  { id: 938, name: 'Professor de Direito' },
  { id: 943, name: 'Professor de Economia' },
  { id: 945, name: 'Professor de Educação Artística' },
  { id: 947, name: 'Professor de Educação Física' },
  { id: 948, name: 'Professor de Educação Infantil' },
  { id: 949, name: 'Professor de Elétrica' },
  { id: 950, name: 'Professor de Eletrônica' },
  { id: 953, name: 'Professor de Enfermagem' },
  { id: 954, name: 'Professor de Engenharia' },
  { id: 962, name: 'Professor de Ensino a Distância' },
  { id: 963, name: 'Professor de Ensino Fundamental' },
  { id: 964, name: 'Professor de Ensino Médio' },
  { id: 966, name: 'Professor de Ensino Técnico' },
  { id: 968, name: 'Professor de Estatística' },
  { id: 969, name: 'Professor de Estética e Cosmetologia' },
  { id: 973, name: 'Professor de Filosofia' },
  { id: 974, name: 'Professor de Física' },
  { id: 975, name: 'Professor de Fisioterapia' },
  { id: 978, name: 'Professor de Futebol' },
  { id: 979, name: 'Professor de Gastronomia' },
  { id: 980, name: 'Professor de Geografia' },
  { id: 981, name: 'Professor de Gestão' },
  { id: 986, name: 'Professor de Ginástica' },
  { id: 991, name: 'Professor de História' },
  { id: 994, name: 'Professor de Hotelaria e Turismo' },
  { id: 995, name: 'Professor de Idiomas' },
  { id: 996, name: 'Professor de Informática' },
  { id: 1002, name: 'Professor de Jornalismo' },
  { id: 1004, name: 'Professor de Libras' },
  { id: 1005, name: 'Professor de Literatura' },
  { id: 1006, name: 'Professor de Logística' },
  { id: 1008, name: 'Professor de Manicure' },
  { id: 1012, name: 'Professor de Matemática' },
  { id: 1014, name: 'Professor de Mecânica' },
  { id: 1019, name: 'Professor de Moda' },
  { id: 1020, name: 'Professor de Musculação' },
  { id: 1021, name: 'Professor de Música' },
  { id: 1022, name: 'Professor de Natação' },
  { id: 1025, name: 'Professor de Odontologia' },
  { id: 1027, name: 'Professor de Pedagogia' },
  { id: 1032, name: 'Professor de Podologia' },
  { id: 1034, name: 'Professor de Programação' },
  { id: 1040, name: 'Professor de Publicidade e Propaganda' },
  { id: 1041, name: 'Professor de Química' },
  { id: 1044, name: 'Professor de Recursos Humanos' },
  { id: 1046, name: 'Professor de Relações Públicas' },
  { id: 1050, name: 'Professor de Segurança do Trabalho' },
  { id: 1051, name: 'Professor de Serviço Social' },
  { id: 1052, name: 'Professor de Sistemas de Informação' },
  { id: 1057, name: 'Professor de Tecnologia da Informação' },
  { id: 1063, name: 'Professor de Veterinária' },
  { id: 1064, name: 'Professor de Violão' },
  { id: 1066, name: 'Professor de Web Design' },
  { id: 1069, name: 'Professor de Yoga' },
  { id: 1071, name: 'Professor Universitário' },
  { id: 1072, name: 'Programador' },
  { id: 1095, name: 'Programador de Produção' },
  { id: 1129, name: 'Projetista' },
  { id: 1149, name: 'Promotor' },
  { id: 1150, name: 'Promotor de Eventos' },
  { id: 1153, name: 'Promotor de Vendas' },
  { id: 1155, name: 'Propagandista' },
  { id: 1157, name: 'Psicólogo' },
  { id: 1165, name: 'Psicopedagogo' },
  { id: 1166, name: 'Publicitário' },
  { id: 1167, name: 'Químico' },
  { id: 1170, name: 'Radialista' },
  { id: 1174, name: 'Recebedor' },
  { id: 1175, name: 'Recepcionista' },
  { id: 1178, name: 'Recreador' },
  { id: 1179, name: 'Recuperador de Crédito' },
  { id: 1180, name: 'Redator' },
  { id: 1184, name: 'Relações Públicas' },
  { id: 1185, name: 'Relojoeiro' },
  { id: 1186, name: 'Repórter' },
  { id: 1187, name: 'Repositor' },
  { id: 1189, name: 'Representante Comercial' },
  { id: 1190, name: 'Retificador' },
  { id: 1195, name: 'Roteirista' },
  { id: 1197, name: 'Saladeiro' },
  { id: 1198, name: 'Salgadeiro' },
  { id: 1199, name: 'Salva Vidas' },
  { id: 1200, name: 'Sapateiro' },
  { id: 1203, name: 'Secretária' },
  { id: 1204, name: 'Secretária Comercial' },
  { id: 1206, name: 'Secretária Executiva' },
  { id: 1207, name: 'Secretária Financeira' },
  { id: 1208, name: 'Segurança' },
  { id: 1212, name: 'Serralheiro' },
  { id: 1213, name: 'Servente de Obras' },
  { id: 1214, name: 'Sinaleiro' },
  { id: 1215, name: 'Síndico' },
  { id: 1216, name: 'Sociólogo' },
  { id: 1217, name: 'Socorrista' },
  { id: 1218, name: 'Soldador' },
  { id: 1220, name: 'Sommelier' },
  { id: 1221, name: 'Sondador' },
  { id: 1222, name: 'Sonoplasta' },
  { id: 1223, name: 'Sorveteiro' },
  { id: 1225, name: 'Superintendente Comercial' },
  { id: 1226, name: 'Superintendente de Operações' },
  { id: 1227, name: 'Supervisor Administrativo' },
  { id: 1228, name: 'Supervisor Agrícola' },
  { id: 1229, name: 'Supervisor Comercial' },
  { id: 1230, name: 'Supervisor Contábil' },
  { id: 1231, name: 'Supervisor de Alimentos e Bebidas' },
  { id: 1232, name: 'Supervisor de Almoxarifado' },
  { id: 1234, name: 'Supervisor de Assistência Técnica' },
  { id: 1235, name: 'Supervisor de Atendimento ao Cliente' },
  { id: 1237, name: 'Supervisor de Automação' },
  { id: 1238, name: 'Supervisor de Back Office' },
  { id: 1239, name: 'Supervisor de Caixa' },
  { id: 1240, name: 'Supervisor de Caldeiraria' },
  { id: 1241, name: 'Supervisor de Call Center' },
  { id: 1243, name: 'Supervisor de Cobrança' },
  { id: 1244, name: 'Supervisor de Comércio Exterior' },
  { id: 1245, name: 'Supervisor de Compras' },
  { id: 1248, name: 'Supervisor de Contas a Pagar' },
  { id: 1249, name: 'Supervisor de Contas a Receber' },
  { id: 1250, name: 'Supervisor de Contratos' },
  { id: 1251, name: 'Supervisor de Controladoria' },
  { id: 1252, name: 'Supervisor de Controle de Qualidade' },
  { id: 1253, name: 'Supervisor de Costura' },
  { id: 1254, name: 'Supervisor de Crédito' },
  { id: 1255, name: 'Supervisor de Crédito e Cobrança' },
  { id: 1257, name: 'Supervisor de Custos' },
  { id: 1258, name: 'Supervisor de Departamento Pessoal' },
  { id: 1259, name: 'Supervisor de E-commerce' },
  { id: 1260, name: 'Supervisor de Elétrica' },
  { id: 1261, name: 'Supervisor de Engenharia' },
  { id: 1263, name: 'Supervisor de Eventos' },
  { id: 1264, name: 'Supervisor de Expedição' },
  { id: 1266, name: 'Supervisor de Farmácia' },
  { id: 1268, name: 'Supervisor de Ferramentaria' },
  { id: 1271, name: 'Supervisor de Frota' },
  { id: 1275, name: 'Supervisor de Infraestrutura' },
  { id: 1277, name: 'Supervisor de Instalação' },
  { id: 1279, name: 'Supervisor de Laboratório' },
  { id: 1281, name: 'Supervisor de Limpeza' },
  { id: 1283, name: 'Supervisor de Logística' },
  { id: 1284, name: 'Supervisor de Loja' },
  { id: 1285, name: 'Supervisor de Manutenção' },
  { id: 1292, name: 'Supervisor de Marketing' },
  { id: 1294, name: 'Supervisor de Merchandising' },
  { id: 1296, name: 'Supervisor de Monitoramento' },
  { id: 1298, name: 'Supervisor de Montagem' },
  { id: 1299, name: 'Supervisor de Negócios' },
  { id: 1301, name: 'Supervisor de Obras' },
  { id: 1302, name: 'Supervisor de Operações' },
  { id: 1303, name: 'Supervisor de Ouvidoria' },
  { id: 1304, name: 'Supervisor de Patrimônio' },
  { id: 1305, name: 'Supervisor de PCP' },
  { id: 1306, name: 'Supervisor de Peças' },
  { id: 1308, name: 'Supervisor de Planejamento' },
  { id: 1309, name: 'Supervisor de Pós-venda' },
  { id: 1310, name: 'Supervisor' },
  { id: 1311, name: 'Supervisor de Processos' },
  { id: 1312, name: 'Supervisor de Produção' },
  { id: 1313, name: 'Supervisor de Produtos' },
  { id: 1315, name: 'Supervisor de Projetos' },
  { id: 1316, name: 'Supervisor de Qualidade' },
  { id: 1317, name: 'Supervisor de Radiologia' },
  { id: 1320, name: 'Supervisor de Recursos Humanos' },
  { id: 1321, name: 'Supervisor de Refrigeração' },
  { id: 1324, name: 'Supervisor de Restaurante' },
  { id: 1325, name: 'Supervisor de SAC' },
  { id: 1326, name: 'Supervisor de Segurança do Trabalho' },
  { id: 1328, name: 'Supervisor de Seguros' },
  { id: 1329, name: 'Supervisor de Serviços Gerais' },
  { id: 1330, name: 'Supervisor de Suprimentos' },
  { id: 1331, name: 'Supervisor de Tecnologia da Informação' },
  { id: 1332, name: 'Supervisor de Tesouraria' },
  { id: 1334, name: 'Supervisor de Transportes' },
  { id: 1337, name: 'Supervisor de Vendas' },
  { id: 1338, name: 'Supervisor Financeiro' },
  { id: 1340, name: 'Supervisor Industrial' },
  { id: 1342, name: 'Supervisor Técnico' },
  { id: 1343, name: 'Supervisor Tributário' },
  { id: 1344, name: 'Suporte Técnico' },
  { id: 1345, name: 'Sushiman' },
  { id: 1346, name: 'Tapeceiro' },
  { id: 1348, name: 'Tecelão' },
  { id: 1349, name: 'Técnico Administrativo' },
  { id: 1350, name: 'Técnico Agrícola' },
  { id: 1351, name: 'Técnico Bancário' },
  { id: 1352, name: 'Técnico Comercial' },
  { id: 1353, name: 'Técnico Contábil' },
  { id: 1354, name: 'Técnico da Qualidade' },
  { id: 1356, name: 'Técnico de Assistência Técnica' },
  { id: 1357, name: 'Técnico de Atendimento' },
  { id: 1358, name: 'Técnico de Campo' },
  { id: 1359, name: 'Técnico de Celular' },
  { id: 1360, name: 'Técnico de Controle de Qualidade' },
  { id: 1361, name: 'Técnico de Equipamentos' },
  { id: 1365, name: 'Técnico de Gesso' },
  { id: 1367, name: 'Técnico de Iluminação' },
  { id: 1368, name: 'Técnico de Implantação' },
  { id: 1369, name: 'Técnico de Impressora' },
  { id: 1370, name: 'Técnico de Infraestrutura' },
  { id: 1371, name: 'Técnico de Instalação' },
  { id: 1372, name: 'Técnico de Laboratório' },
  { id: 1374, name: 'Técnico de Manutenção' },
  { id: 1378, name: 'Técnico de Materiais' },
  { id: 1379, name: 'Técnico de Monitoramento' },
  { id: 1380, name: 'Técnico de Montagem' },
  { id: 1381, name: 'Técnico de Planejamento' },
  { id: 1383, name: 'Técnico de Processos' },
  { id: 1384, name: 'Técnico de Produção' },
  { id: 1385, name: 'Técnico de Produto' },
  { id: 1386, name: 'Técnico de Redes' },
  { id: 1389, name: 'Técnico de Som' },
  { id: 1390, name: 'Técnico de Suporte' },
  { id: 1393, name: 'Técnico de Vendas' },
  { id: 1394, name: 'Técnico Eletricista' },
  { id: 1395, name: 'Técnico Eletroeletrônico' },
  { id: 1396, name: 'Técnico Eletromecânico' },
  { id: 1398, name: 'Técnico em Agropecuária' },
  { id: 1399, name: 'Técnico em Alimentos' },
  { id: 1400, name: 'Técnico em Análises Clínicas' },
  { id: 1401, name: 'Técnico em Áudio e Vídeo' },
  { id: 1402, name: 'Técnico em Automação' },
  { id: 1405, name: 'Técnico em Biblioteconomia' },
  { id: 1406, name: 'Técnico em Cabeamento' },
  { id: 1407, name: 'Técnico em CFTV' },
  { id: 1409, name: 'Técnico em Edificações' },
  { id: 1410, name: 'Técnico em Eletrocardiograma' },
  { id: 1412, name: 'Técnico em Eletrônica' },
  { id: 1414, name: 'Técnico em Enfermagem' },
  { id: 1419, name: 'Técnico em Farmácia' },
  { id: 1420, name: 'Técnico em Fibras Ópticas' },
  { id: 1421, name: 'Técnico em Gastronomia' },
  { id: 1422, name: 'Técnico em Geoprocessamento' },
  { id: 1423, name: 'Técnico em Hemoterapia' },
  { id: 1425, name: 'Técnico em Informática' },
  { id: 1426, name: 'Técnico em Inspeção de Equipamentos' },
  { id: 1427, name: 'Técnico em Instrumentação' },
  { id: 1428, name: 'Técnico em Licitação' },
  { id: 1429, name: 'Técnico em Logística' },
  { id: 1431, name: 'Técnico em Manutenção' },
  { id: 1434, name: 'Técnico em Mecânica' },
  { id: 1435, name: 'Técnico em Mecatrônica' },
  { id: 1436, name: 'Técnico em Medição' },
  { id: 1438, name: 'Técnico em Metalurgia' },
  { id: 1439, name: 'Técnico em Metrologia' },
  { id: 1440, name: 'Técnico em Mineração' },
  { id: 1441, name: 'Técnico em Nutrição' },
  { id: 1442, name: 'Técnico em Óptica' },
  { id: 1444, name: 'Técnico em Orientação e Mobilidade' },
  { id: 1445, name: 'Técnico em Panificação' },
  { id: 1446, name: 'Técnico em Plásticos' },
  { id: 1448, name: 'Técnico em Processos Industriais' },
  { id: 1449, name: 'Técnico em Projetos' },
  { id: 1450, name: 'Técnico em Química' },
  { id: 1451, name: 'Técnico em Radiologia' },
  { id: 1452, name: 'Técnico em Recursos Humanos' },
  { id: 1453, name: 'Técnico em Refrigeração' },
  { id: 1454, name: 'Técnico em Saneamento' },
  { id: 1455, name: 'Técnico em Saúde Bucal' },
  { id: 1456, name: 'Técnico em Secretariado' },
  { id: 1457, name: 'Técnico em Segurança do Trabalho' },
  { id: 1458, name: 'Técnico em Segurança e Meio Ambiente' },
  { id: 1460, name: 'Técnico em Seguros' },
  { id: 1461, name: 'Técnico em Tecnologia da Informação' },
  { id: 1462, name: 'Técnico em Telecomunicações' },
  { id: 1463, name: 'Técnico em Turismo' },
  { id: 1464, name: 'Técnico Financeiro' },
  { id: 1466, name: 'Técnico Hidráulico' },
  { id: 1467, name: 'Técnico Industrial' },
  { id: 1468, name: 'Técnico Mecânico de Manutenção' },
  { id: 1469, name: 'Técnico Operacional' },
  { id: 1470, name: 'Técnico Orçamentista' },
  { id: 1471, name: 'Técnico Projetista' },
  { id: 1472, name: 'Técnico Têxtil' },
  { id: 1473, name: 'Tecnólogo em Alimentos' },
  { id: 1474, name: 'Tecnólogo em Construção Civil' },
  { id: 1475, name: 'Tecnólogo em Mecânica' },
  { id: 1476, name: 'Tecnólogo em Radiologia' },
  { id: 1477, name: 'Tecnólogo em Redes de Computadores' },
  { id: 1478, name: 'Tecnólogo em Saneamento Ambiental' },
  { id: 1479, name: 'Telefonista' },
  { id: 1481, name: 'Telhadista' },
  { id: 1482, name: 'Terapeuta Ocupacional' },
  { id: 1483, name: 'Tesoureiro' },
  { id: 1484, name: 'Tintureiro' },
  { id: 1485, name: 'Topógrafo' },
  { id: 1486, name: 'Torneiro Ferramenteiro' },
  { id: 1487, name: 'Torneiro Mecânico' },
  { id: 1488, name: 'Tosador' },
  { id: 1490, name: 'Trader' },
  { id: 1491, name: 'Tradutor' },
  { id: 1502, name: 'Trainee' },
  { id: 1526, name: 'Tratador de Piscina' },
  { id: 1527, name: 'Tratorista' },
  { id: 1530, name: 'Turismólogo' },
  { id: 1531, name: 'Vendedor' },
  { id: 1546, name: 'Veterinário' },
  { id: 1549, name: 'Vidraceiro' },
  { id: 1550, name: 'Vigia' },
  { id: 1551, name: 'Vigilante' },
  { id: 1555, name: 'Vistoriador' },
  { id: 1557, name: 'Visual Merchandiser' },
  { id: 1558, name: 'Vitrinista' },
  { id: 1559, name: 'Web Designer' },
  { id: 1561, name: 'Web Developer' },
  { id: 1562, name: 'Webmaster' },
  { id: 1563, name: 'Zelador' },
  { id: 1564, name: 'Zootecnista' },
];

export default selectOccupationOptions;
