import React from 'react';
import { Box, Spinner } from 'grommet';

export const LoadingPage = () => {
  return (
    <Box
      direction='column'
      align='center'
      justify='center'
      margin={{ top: 'medium' }}
      height='95vh'
    >
      <Spinner size='medium' />
    </Box>
  )
}
