import axiosGlobalInstance from '../http-instance';

const start = ({ clientId, code }) => {
  return axiosGlobalInstance({
    method: 'POST',
    url: '/integration.calendar.php',
    data: {
      clientId,
      code,
    },
    headers: {
      action: 'integrationGoogleCalendar',
      authorized: true,
    }
  });
}

export default start;
