import TYPES from '../actions/types/login';

const INITIAL_STATE = {
  accounts: null,

  email: null,
  id: null,
  password: null,

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.changeField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.fillAccounts:
      return {
        ...state,
        accounts: action.payload,
      }

    case TYPES.fillSelectedAccount:
      return {
        ...state,
        accounts: null,

        id: action.payload.id,
        email: action.payload.email,
      }

    case TYPES.loadingFormPostToggle:
      return { ...state, loadingPostForm: !state.loadingPostForm }

    case TYPES.postError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
