import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';
import { create as c } from '../../../api/common';
import { formatDataFormReminder } from '../utils';

const loadingPostManager = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const create = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    c(params.formParams, 'task.php')
      .then(() => {
        dispatch(loadingPostManager());

        dispatch(
          showNotificationLayer('Lembrete criado!')
        );
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostManager());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
}

export const toggleFormReminderLayer = params => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.legislative,
      action: analytics.actions.legislativeReminder,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'reminder' },
      { type: COMMON_TYPES.formInitializeValues, payload: formatDataFormReminder(params) },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
};
