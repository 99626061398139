const selectJobRoleOptions = [
  {
      id: '1',
      productId: '1',
      name: 'Vereador(a)',
      orderIn: 0
  },
  {
      id: '6',
      productId: '2',
      name: 'Prefeito(a)',
      orderIn: 0
  },
  {
      id: '9',
      productId: '3',
      name: 'Deputado(a)',
      orderIn: 0
  },
  {
      id: '2',
      productId: '1',
      name: 'Chefe de Gabinete',
      orderIn: 1
  },
  {
      id: '7',
      productId: '2',
      name: 'Chefe de prefeitura',
      orderIn: 1
  },
  {
      id: '10',
      productId: '3',
      name: 'Chefe de Gabinete',
      orderIn: 1
  },
  {
      id: '3',
      productId: null,
      name: 'Secretário(a)',
      orderIn: 2
  },
  {
      id: '4',
      productId: null,
      name: 'Assessor(a)',
      orderIn: 3
  },
  {
      id: '5',
      productId: null,
      name: 'Profissional de TI',
      orderIn: 4
  },
  {
      id: '8',
      productId: null,
      name: 'Outros',
      orderIn: 5
  }
];

export default selectJobRoleOptions;
