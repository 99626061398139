import React from 'react';
import { Box, Text } from 'grommet';

const findByFieldName = (errorsList, fieldName) => {
  const actionResult = errorsList.find(a => a.fieldName === fieldName);
  return actionResult ? actionResult.message : null;
}

const findByFieldNameArray = (errorsList, fieldName) =>
  fieldName.reduce((acc, field) => {
    const actionResult = findByFieldName(errorsList, field);

    if(actionResult) {
      acc = actionResult;
    }

    return acc;
  }, null);


export const ErrorForm = ({ errorsList, fieldName }) => {
  if(!errorsList.length)
    return null;

  let message = null;

  if(fieldName) {
    if(!Array.isArray(fieldName))
      message = findByFieldName(errorsList, fieldName);
    else
      message = findByFieldNameArray(errorsList, fieldName);
  }
  else
    message = errorsList[0].message;

  return message ? (
    <Box margin={{ top: 'xsmall' }}>
      <Text color='status-critical' size='xsmall'>
        {message}
      </Text>
    </Box>
  ) : null;
}
