export default () => ({
  id: null,
  name: null,
  individualRegistration: null,
  nickName: null,
  dateOfBirth: null,
  genre: null,
  status: true,
  sonsIn: null,
  maritalStatusId: null,
  professionId: null,
  groupsId: null,
  note: null,
  email: null,
  allowCommunication: true,
  address: null,
  addressNumber: null,
  additionalAddress: null,
  districtId: null,
  cityId: null,
  stateId: null,
  zipcode: null,
  voterRegistration: null,
  electoralZone: null,
  votingSection: null,
  schoolId: null,
  district: null,
  city: null,
  state: null,
  school: null,
  withLocation: true,
  relatives: [],
  voteStatusId: 1,
  telephone: [],
  responsible: [],
});
