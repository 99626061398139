import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Anchor, TextInput, Button } from 'grommet';
import { AddCircle, Close } from 'grommet-icons';

import { addResponsibles, removeResponsibles } from '../../actions/form-create';

import selectResponsibleTypes from '../../../../constants/select-responsibles-types';

function Responsibles(props) {
  const [typeId, setTypeId] = useState(0);
  const [typeName, setTypeName] = useState('');

  const [name, setName] = useState('');

  const {
    responsible,
  } = props;

  const removeAction = (responsibleObj) => {
    const { removeResponsibles } = props;
    removeResponsibles(responsibleObj);
  }

  const addAction = () => {
    const { addResponsibles } = props;

    addResponsibles({
      responsibleId: typeId,
      responsibleType: typeName,
      name: name,
    });

    setTypeId(0);
    setTypeName('');
    setName('');
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box margin={{ bottom: 'medium' }} gap='xsmall'>
            <Text size='large'>Responsáveis</Text>
          </Box>

          <Box
            direction={size !== 'small' && size !== 'medium' ? 'row' : 'column-reverse'}
          >
            {/* coluna 1 - responsible */}
            <Box
              margin={{ right: 'large' }}
              width={size !== 'small' && size !== 'medium' ? '50vw' : '100vw'}
            >
              <Box
                round='xsmall'
                border={{ color: 'light-5' }}
                background='white'
              >
                {/* fields */}
                <Box
                  direction='row'
                  border={{ color: 'light-5', side: 'bottom' }}
                  pad={{
                    vertical: 'xsmall',
                    horizontal: 'xsmall',
                  }}
                >
                  <Box width='medium'>
                    <TextInput
                      plain
                      placeholder='cadastro, ...'
                      value={typeName}
                      onChange={event => setTypeId(event.target.value)}
                      suggestions={
                        selectResponsibleTypes.map(({ id, name }) => ({
                          label: (
                            <Box
                              pad='small'
                            >
                              <Text size='small' color='dark-5'>{name}</Text>
                            </Box>
                          ),
                          value: { id, name }
                        }))
                      }
                      onSuggestionSelect={event => {
                        const { id, name } = event.suggestion.value;

                        setTypeId(id);
                        setTypeName(name);
                      }}
                    />
                  </Box>

                  <Box width='large'>
                    <TextInput
                      plain
                      maxLength={30}
                      placeholder='nome'
                      onChange={event => {
                        const { value: newValue } = event.target;
                        setName(newValue);
                      }}
                      value={name}
                      /*suggestions={
                        votersOptions.map(({ id, name }) => ({
                          label: (
                            <Box
                              pad='small'
                            >
                              <Text size='small' color='dark-5'>{name}</Text>
                            </Box>
                          ),
                          value: { id, name }
                        }))
                      }
                      onSuggestionSelect={event => {
                        const { name } = event.suggestion.value;
                        setName(name);
                      }}*/
                    />
                  </Box>

                  <Button
                    icon={<AddCircle color='neutral-1' />}
                    disabled={!name || !typeId || name.length <= 2}
                    title={
                      (!name || !typeId) ? 'Selecione um tipo e defina um nome' :
                      (name && name.length <= 2 ? 'Defina um nome com mais de 2 caracteres' : 'Adicionar')
                    }
                    onClick={() => addAction()}
                  />
                </Box>

                {/* list */}
                {responsible && responsible.map((item, index) => (
                  <Box
                    key={index}
                    align='center'
                    direction='row'
                    pad={{
                      horizontal: 'small',
                    }}
                    border={{ color: 'light-5', side: 'bottom' }}
                    justify='between'
                  >
                    <Text size='small' color='dark-5'>{item.responsibleType}: {item.name}</Text>

                    <Anchor
                      icon={<Close size='small' />}
                      color='status-critical'
                      title='Remover'
                      onClick={() => removeAction(item)}
                    />
                  </Box>
                ))}
              </Box>
            </Box>

            {/* coluna 2 - responsibles */}
            <Box
              margin={{
                bottom: 'small',
                left: size !== 'small' && size !== 'medium' ? 'large' : 'none'
              }}
              width={
                size !== 'small' &&
                size !== 'medium' ? '40vw' : '100vw'
              }
            >
              <Text size='small' color='dark-5' margin={{ bottom: 'medium' }}>
                Você pode informar as pessoas responsáveis pelo cadastro, indicação, captação...
              </Text>
            </Box>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ votersReducer: { formManager } }) => ({
  responsible: formManager.responsible,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    addResponsibles,
    removeResponsibles,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Responsibles);
