export default object => ({
  id: null,
  categoryId: object.categoryId,
  statusId: object.statusId,
  value: `R$ ${object.value}`,
  isRefund: object.isRefund,
  name: object.name,
  nationalRegister: object.nationalRegister,
  telephone: object.telephone,
  dateRefund: object.dateRefund,
  dateExpense: object.dateExpense,
  description: object.description,

  isCopy: true,
  isEditing: false,
});
