import TYPES from '../actions/types';
import { formatDataCreateDoc } from '../utils';

const INITIAL_STATE = {
  id: null,
  processeId: null,
  text: null,

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    case TYPES.changeFormPrepareDocField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.setDataFormPrepareDoc:
      return {
        ...state,
        ...formatDataCreateDoc(action.payload),

        postDataError: { message: '', errors: [] }
      }

    case TYPES.clearFormPrepareDoc:
      return {
        ...state,
        ...INITIAL_STATE,
      }

    case TYPES.postLoadingFormPrepareDoc:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.postFormPrepareDocError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
