import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Button, TextInput, Text, ResponsiveContext, Keyboard, Anchor } from 'grommet';

import { ErrorForm, HeaderHtml } from '../../../components';

import { changeField, getAccounts, loginSystem, selectAccountLogin } from '../actions/login';

import urlRoutes from '../../../config/url-routes';

class Login extends Component {
  constructor(props) {
    super(props);

    const {
      userLogged: { token },
    } = this.props;

    if(token) {
      const isValid = new Date().getTime() < new Date(token.expires).getTime();

      if(isValid) {
        window.location = urlRoutes.dashboardSystemUrl;
      }
    }
  }

  callbackPostSuccess = () => {
    const { history } = this.props;
    history.push(urlRoutes.dashboardSystemUrl);
  };

  handlerLogin = formParams => {
    const { loginSystem } = this.props;

    loginSystem({
      formParams,
      callbackSuccess: this.callbackPostSuccess,
    });
  };

  handlerGetAccounts = formParams => {
    const { getAccounts } = this.props;
    getAccounts({ formParams });
  };

  handlerChooseAccount = formParams => {
    const { selectAccountLogin } = this.props;
    selectAccountLogin(formParams);
  };

  render() {
    const {
      accounts,

      email,
      id,
      password,

      loadingPostForm,
      changeField,

      postDataError,
    } = this.props;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <HeaderHtml title='Login' />

            <Box
              height='100vh'
              align='center'
              justify='center'
              gap='small'
              margin={{ horizontal: 'small' }}
              flex={{ shrink: 0 }}
            >
              <Box
                round='small'
                width='medium'
                pad={{
                  vertical: 'large',
                  horizontal: size === 'small' ? 'large' : 'medium',
                }}
                gap={ size === 'small' ? 'large' : 'small' }
                background='white'
                flex={{ shrink: 0 }}
                border
              >
                <Text
                  size='xsmall'
                  color='brand'
                  textAlign='center'
                  margin={{ bottom: 'small' }}
                >
                  <Anchor href={urlRoutes.site}>
                    Gerencia Meu Mandato
                  </Anchor>
                </Text>

                <Text size='large' textAlign='center'>
                  Fazer login
                </Text>


                {/* getAccount */}
                {!accounts && !id && (
                  <Box
                    gap='small'
                    flex={{ shrink: 0 }}
                  >
                    <Text
                      size='small'
                      color='dark-5'
                      textAlign='center'
                      margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                    >
                      Digite seu e-mail para nos ajudar a identificá-lo
                    </Text>

                    <Box width='medium'>
                      <Keyboard onEnter={() => this.handlerGetAccounts({ email })}>
                        <TextInput
                          placeholder='E-mail'
                          value={email ?? ''}
                          onChange={ event =>
                            changeField({ fieldName: 'email', value: event.target.value })
                          }
                        />
                      </Keyboard>

                      <ErrorForm errorsList={postDataError.errors} />
                    </Box>

                    <Box width='medium'>
                      <Button
                        primary
                        label={ !loadingPostForm ? 'Continuar' : 'Carregando...' }
                        disabled={ loadingPostForm }
                        onClick={() => {
                          this.handlerGetAccounts({ email });
                        }}
                      />
                    </Box>
                  </Box>
                )}

                {/* choose account */}
                {accounts && accounts.length > 0 && (
                  <Box
                    gap='small'
                    flex={{ shrink: 0 }}
                  >
                    <Text
                      size='small'
                      color='dark-5'
                      textAlign='center'
                      margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                    >
                      Escolha uma conta para logar
                    </Text>

                    {accounts.map((row, index) => {
                      return (
                        <Box
                          key={index}
                          round='xsmall'
                          hoverIndicator
                          pad={{ vertical: 'small', horizontal: 'small' }}
                          border={{ color: 'light-5' }}
                          onClick={() => {
                            this.handlerChooseAccount(row);
                          }}
                        >
                          <Text size='medium' color='dark-1'>{row.email}</Text>
                          <Text size='small' color='dark-4'>{row.clientName}</Text>
                        </Box>
                      )
                    })}

                  </Box>
                )}

                {/* selected account */}
                {id && (
                  <Box
                    gap='small'
                    flex={{ shrink: 0 }}
                  >
                    <Text
                      size='small'
                      color='dark-5'
                      textAlign='center'
                      margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                    >
                      {email}
                    </Text>

                    <Box width='medium'>
                      <Keyboard
                        onEnter={() =>
                          this.handlerLogin({
                            id,
                            password,
                          })
                        }
                      >
                        <TextInput
                          placeholder='Senha'
                          type='password'
                          value={password ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'password', value: event.target.value })
                          }
                        />
                      </Keyboard>

                      <ErrorForm errorsList={postDataError.errors} />
                    </Box>

                    <Box width='medium'>
                      <Button
                        primary
                        label={ !loadingPostForm ? 'Entrar' : 'Carregando...' }
                        disabled={ loadingPostForm }
                        onClick={() => {
                          this.handlerLogin({
                            id,
                            password,
                          });
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>

              <Box
                width='medium'
                direction='row'
                justify='between'
                pad={{ vertical: 'small', horizontal: 'xsmall' }}
              >
                <Box>
                  <Anchor
                    size='small'
                    label='Esqueceu a senha?'
                    color='dark-3'
                    href={urlRoutes.forgotPassword}
                  />
                </Box>

                <Box justify='end' gap='small' direction='row'>
                  <Anchor
                    size='small'
                    label='Ajuda'
                    color='dark-3'
                    href={urlRoutes.supportSite}
                  />

                  <Anchor
                    size='small'
                    label='Termos'
                    color='dark-3'
                    href={urlRoutes.termsOfUse}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ authReducer: { login, authenticated } }) => ({
  accounts: login.accounts,
  userLogged: authenticated,

  email: login.email,
  id: login.id,
  password: login.password,

  loadingPostForm: login.loadingPostForm,
  postDataError: login.postDataError,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changeField,
  selectAccountLogin,
  getAccounts,
  loginSystem,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Login)
);
