import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { ShareOption, BarChart, ChatOption, Search } from 'grommet-icons';

import { showFormManagerLayer } from '../../actions/form-manager';

import rulesName from '../../../../constants/rules';

const OnboardingComponent = (props) => {
  const {
    userLogged: {
      rules
    },
    showFormManagerLayer,
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='row'
          justify='center'
          margin={{ horizontal: 'medium', top: '100px' }}
        >
          <Box
            pad='small'
            width='xlarge'
            gap='medium'
          >

            {/* Cabeçalho */}
            <Box
              direction='row'
              gap='xsmall'
              align='baseline'
            >
              <Text size='large' color='dark-5'>Bem-vindo ao módulo de</Text>
              <Text size='xlarge' color='dark-1'>enquetes.</Text>
            </Box>

            {/* Conteúdo */}
            <Box
              gap='medium'
              direction={ size === 'small' || size === 'medium' ? 'column' : 'row' }
              justify='between'
            >
              {/* Primeira coluna */}
              <Box
                width={
                  size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge'
                }
              >
                <Box
                  margin={{
                    bottom: size === 'small' ? 'large' : 'medium'
                  }}
                >
                  <Text color='dark-2' size='small'>
                    Quer saber a opinião dos seus eleitores sobre algum assunto? Crie enquetes e envie para eles.
                  </Text>
                </Box>

                <Box
                  direction='row'
                  gap={ size === 'small' ? 'large' : 'medium' }
                  margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                >
                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <ChatOption size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Crie enquetes
                    </Text>

                    <Text size='small' color='dark-2'>
                      Crie em poucos passos. Defina a pergunta, as resposta, a data de encerramento e pronto!
                    </Text>
                  </Box>

                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <Search size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Busca avançada
                    </Text>

                    <Text size='small' color='dark-2'>
                      Filtre as enquestes por diversos filtros.
                    </Text>
                  </Box>
                </Box>

                <Box
                  direction='row'
                  gap={ size === 'small' ? 'large' : 'medium' }
                  margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                >
                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <ShareOption size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Compartilhe
                    </Text>

                    <Text size='small' color='dark-2'>
                      Compartilhe o link nas redes sociais ou envie por WhatsApp. É simples e rápido.
                    </Text>
                  </Box>

                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <BarChart size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Resultados
                    </Text>

                    <Text size='small' color='dark-2'>
                      Acompanhe os resultados em tempo real!
                    </Text>
                  </Box>
                </Box>

              </Box>

              {/* Separador coluna */}
              <Box
                width={ size === 'large' || size === 'xlarge' ? 'xsmall' : 'xxsmall'}
              />

              {/* Segunda coluna */}
              <Box
                gap='small'
                width={ size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge' }
              >
                <Box
                  gap='medium'
                  round={ size === 'small' ? 'small' : 'xsmall' }
                  border={{ color: 'light-4' }}
                  pad={{
                    vertical: size === 'small' ? 'large' : 'medium',
                    horizontal:  size === 'small' ? 'medium' : 'small',
                  }}
                >
                  <Text color='dark-2' size='small'>
                    Crie diversos tipos de enquete...
                  </Text>

                  <Box gap='xxsmall'>
                    <Text color='dark-2' size='small'>- Opinião sobre seu mandato ou campanha.</Text>
                    <Text color='dark-2' size='small'>- Avaliação sobre uma obra publica.</Text>
                    <Text color='dark-2' size='small'>- Qual rede social o eleitor mais acompanha seu trabalho.</Text>
                    <Text color='dark-2' size='small'>- Perguntar sobre um serviço público.</Text>
                    <Text color='dark-2' size='small'>- E muito mais.</Text>
                  </Box>

                  <Button
                    primary
                    label='Começar, Criar enquete'
                    color='neutral-1'
                    title={!rules.includes(rulesName.createSurvey) ? 'Você não tem permissão para criar enquetes.' : null }
                    disabled={!rules.includes(rulesName.createSurvey)}
                    onClick={() =>
                      showFormManagerLayer()
                    }
                  />
                </Box>

                {/*<Text color='dark-2' size='small'>
                  Caso tenha alguma dúvida entre em contato por WhatsApp ou e-mail.
                </Text>

                <Box>
                  <Text color='dark-2' size='small'>Suporte por WhatsApp</Text>
                </Box>*/}

              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    showFormManagerLayer,
  }, dispath)
};

export const Onboarding = withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingComponent));
