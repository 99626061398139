import axiosGlobalInstance from '../http-instance';

const getClientUseLicense = identifier => {
  return axiosGlobalInstance({
    method: 'GET',
    url: '/cliente.php',
    params: { identifier },
    headers: { action: 'getByIdentifier', authorized: true }
  });
}

export default getClientUseLicense;
