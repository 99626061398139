import React from 'react';
import { Text } from 'grommet';

function TitleField({ text, required }) {
  return (
    <Text size='small' color='dark-2' textAlign='start'>
      {text} {required ? '*' : ''}
    </Text>
  )
}

export default TitleField;
