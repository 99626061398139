import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, TextInput, Select, TextArea, Anchor } from 'grommet';

import { TitleField, SelectSearch } from '../../../../components/form';

import { changeField } from '../../actions/form-create';

import selectOccupationOptions from '../../../../constants/select-occupation-options';
import selectActiveInactiveOptions from '../../../../constants/select-active-inactive-options';
import selectMaritalStatusOptions from '../../../../constants/select-marital-status-options';

function Additional(props) {
  const {
    status,
    sonsIn,
    maritalStatusId,
    professionId,
    note,

    changeField,
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box margin={{ bottom: 'medium' }}>
            <Text size='large'>
              Dados adicionais
            </Text>
          </Box>

          <Box
            direction={size !== 'small' && size !== 'medium' ? 'row' : 'column-reverse'}
          >
            {/* coluna 1 - settings */}
            <Box
              margin={{ right: 'large' }}
              width={size !== 'small' && size !== 'medium' ? '50vw' : '100vw'}
            >

              {/* Status/Quantidade de filhos/Estado Civil */}
              <Box
                direction='row'
                gap='small'
              >
                <Box
                  margin={{ vertical: 'small' }}
                  width='30vw'
                >
                  <TitleField text={'Status'} />

                  <Select
                    options={selectActiveInactiveOptions}
                    value={status}
                    labelKey='name'
                    valueKey={{ key: 'id', reduce: true }}
                    onChange={({ value: nextValue }) => changeField({ fieldName: 'status', value: nextValue })}
                    emptySearchMessage='Selecione uma opção'
                  />
                </Box>

                <Box
                  margin={{ vertical: 'small' }}
                  width='20vw'
                >
                  <TitleField text={'Qtde de filhos'} />

                  <TextInput
                    type='number'
                    min={0}
                    value={sonsIn ?? ''}
                    onChange={event => changeField({ fieldName: 'sonsIn', value: event.target.value })}
                  />
                </Box>

                <Box
                  margin={{ vertical: 'small' }}
                  width='50vw'
                >
                  <Box direction='row' align='center' gap='xsmall'>
                    <TitleField text={'Estado Civil'} />

                    {maritalStatusId && (
                      <Anchor
                        size='small'
                        label='limpar'
                        onClick={() => changeField({ fieldName: 'maritalStatusId', value: null })}
                      />
                    )}
                  </Box>

                  <SelectSearch
                    options={selectMaritalStatusOptions}
                    value={maritalStatusId ?? ''}
                    labelKey='name'
                    valueKey={{ key: 'id', reduce: true }}
                    onChange={({ value: nextValue }) => changeField({ fieldName: 'maritalStatusId', value: nextValue })}
                    emptySearchMessage='Selecione uma opção'
                  />
                </Box>
              </Box>

              {/* Profissão */}
              <Box margin={{ vertical: 'small' }}>
                <Box direction='row' align='center' gap='xsmall'>
                  <TitleField text={'Profissão'} />

                  {professionId && (
                    <Anchor
                      size='small'
                      label='limpar'
                      onClick={() => changeField({ fieldName: 'professionId', value: null })}
                    />
                  )}
                </Box>

                <SelectSearch
                  options={selectOccupationOptions}
                  value={professionId ?? ''}
                  labelKey='name'
                  valueKey={{ key: 'id', reduce: true }}
                  onChange={({ value: nextValue }) => changeField({ fieldName: 'professionId', value: nextValue })}
                  emptySearchMessage='Selecione uma opção'
                />
              </Box>



              <Box margin={{ vertical: 'small' }}>
                <TitleField text={'Observações'} />

                <TextArea
                  maxLength={1000}
                  resize='vertical'
                  value={note ?? ''}
                  onChange={event => changeField({ fieldName: 'note', value: event.target.value })}
                />
              </Box>

            </Box>

            {/* coluna 2 - settings */}
            <Box
              margin={{
                bottom: 'small',
                left: size !== 'small' && size !== 'medium' ? 'large' : 'none'
              }}
              width={
                size !== 'small' &&
                size !== 'medium' ? '40vw' : '100vw'
              }
            />
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ votersReducer: { formManager } }) => ({
  name: formManager.name,
  individualRegistration: formManager.individualRegistration,
  nickName: formManager.nickName,
  genre: formManager.genre,
  status: formManager.status,
  sonsIn: formManager.sonsIn,
  maritalStatusId: formManager.maritalStatusId,
  professionId: formManager.professionId,
  groupsId: formManager.groupsId,
  note: formManager.note,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    changeField,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Additional);
