import TYPES from '../actions/types';
import { formatLoadOpenInvoice } from '../utils';

const INITIAL_STATE = {}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.openInvoice:
      return {
        ...state,
        ...formatLoadOpenInvoice(action.payload.data),
      }

    default:
      return state;
  }
}
