import TYPES from './types';
import { getVoters, getCost } from '../../../api/campaign';

const loading = () => ({
  type: TYPES.getVotersAndCostLoading,
});

export const searchVotersList = params => {
  return dispatch => {
    dispatch(loading());

    getVoters(params)
      .then(response => {
        dispatch({
          type: TYPES.getVotersSucsess,
          payload: response,
        });

        if(response.data.total > 0) {
          getCost({
            totalVoters: response.data.total,
            campaignType: params.campaignType,
          })
            .then(response => {
              dispatch({
                type: TYPES.getCostSuccess,
                payload: response,
              });
              dispatch(loading());
            })
            .catch(responseFail => {
              dispatch({ type: TYPES.getVotersAndCostError });
              dispatch(loading());
            })
        }
        else {
          dispatch([
            loading(),
            clearListVoters(),
            clearCost(),
          ]);
        }
      })
      .catch(responseFail => {
        dispatch({ type: TYPES.getVotersAndCostError });
        dispatch(loading());
      })
  }
}

export const clearListVoters = () => ({
  type: TYPES.clearVoters,
});

export const clearCost = () => ({
  type: TYPES.getCostClearResult,
});
