const TYPES = ({
  generateExcel: 'GENERATE_CSV_VOTER',

  // options voter
  getTagOptions: 'GET_TAG_MODULE_VOTER',
  getCityOptions: 'GET_CITY_MODULE_VOTER',
  getCityOptionsLoad: 'GET_CITY_LOADING_MODULE_VOTER',
  getDistrictOptions: 'GET_DISTRICT_MODULE_VOTER',
  getDistrictOptionsLoad: 'GET_DISTRICT_LOADING_MODULE_VOTER',
  getAddressOptions: 'GET_ADDRESS_MODULE_VOTER',
  getAddressOptionsLoad: 'GET_ADDRESS_LOADING_MODULE_VOTER',
  getSchoolOptions: 'GET_SCHOOL_MODULE_VOTER',

  // form create/update
  changeFormManagerField: 'CHANGE_FORM_MANAGER_FIELD_VOTER',
  changeFormManagerStateCity: 'CHANGE_FORM_MANAGER_STATE_CITY_VOTER',
  setAddressAfterSearch: 'SET_ADDRESS_AFTER_SEARCH_FORM_MANAGER_VOTER',
  clearAllAddress: 'CLEAR_ALL_ADDRESS_FORM_MANAGER_VOTER',
  changeFormManagerTelephone: 'CHANGE_MANAGER_VOTER_FORM_TELEPHONE',
  addTelephoneFormManager: 'ADD_MANAGER_VOTER_FORM_TELEPHONE',
  removeTelephoneFormManager: 'REMOVE_MANAGER_VOTER_FORM_TELEPHONE',
  clearFormCreate: 'CLEAR_FORM_CREATE_VOTER',
  loadingPostFormCreate: 'LOADING_POST_FORM_CREATE_VOTER',
  postFormManagerError: 'POST_FORM_CREATE_VOTER_ERROR',
  loadingFormManagerEditError: 'MANAGER_VOTER_EDIT_DATA_LOADING_FORM_ERROR',
  loadDataEdit: 'MANAGER_VOTER_EDIT_DATA_LOAD',
  loadingFormManagerEdit: 'MANAGER_VOTER_EDIT_DATA_LOADING_FORM',

  addRelativesFormManager: 'ADD_MANAGER_VOTER_FORM_RELATIVES',
  removeRelativesFormManager: 'REMOVE_MANAGER_VOTER_FORM_RELATIVES',

  addResponsiblesFormManager: 'ADD_MANAGER_VOTER_FORM_RESPONSIBLES',
  removeResponsiblesFormManager: 'REMOVE_MANAGER_VOTER_FORM_RESPONSIBLES',

  // list
  loadingFormSearch: 'LOADING_SEARCH_VOTER_FORM_TOGGLE',
  searchSuccess: 'SEARCH_VOTER_SUCCESS',
  searchError: 'SEARCH_VOTER_LIST_ERROR',

  // form search
  changeFormSearchField: 'CHANGE_SEARCH_VOTER_FORM_FIELD',
  setDefaultPage: 'SET_SEARCH_VOTER_DEFAULT_PAGE',
  clearFormSearchFields: 'CLEAR_FIELDS_VOTER_FORM',
  paginationFormSearch: 'SEARCH_VOTER_PAGINATION',

  // delete
  setDataFormDelete: 'SET_STATUS_VOTER_FORM_ID',
  loadingFormDelete: 'LOADING_POST_FORM_DELETE_VOTER',
  formPostDeleteError: 'POST_FORM_DELETE_VOTER_ERROR',

  // generate tag
  changeFormGenerateTagField: 'CHANGE_GENERATE_TAG_VOTER_FORM_FIELD',
  setDataFormGenerateTag: 'SET_DATA_FORM_GENERATE_TAG_VOTER',
  postLoadingFormGenerateTag: 'POST_LOADING_FORM_GENERATE_TAG_VOTER',

  // create service
  loadingPostFormCreateService: 'CREATE_SERVICE_FORM_LOADING',
  loadDataFormCreateService: 'LOAD_DATA_FORM_CREATE_SERVICE',
  clearFormCreateService: 'CLEAR_FORM_CREATE_SERVICE',
  postFormCreateServiceError: 'POST_CREATE_SERVICE_FORM_ERROR',
  changeFieldFormCreateService: 'CHANGE_CREATE_SERVICE_FORM_FIELD',
});

export default TYPES;
