import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';

const Simple = ({ title, value, description, width = { title: '30vw',  value: '70vw' }, truncateValue }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          margin={{
            top: size === 'small' ? 'small' : 'xsmall',
            bottom: size === 'small' ? 'small' : 'none',
            horizontal: 'large'
          }}
          pad={{ bottom: 'small' }}
          direction='row'
          align='center'
          flex={{ shrink: 0 }}
        >
          <Box width={width.title}>
            <Text size='medium' color='dark-2' textAlign='start'>
              {title}
            </Text>

            {description &&
              <Text size='xsmall' color='dark-4' textAlign='start'>
                {description}
              </Text>
            }
          </Box>

          <Box width={width.value}>
            <Text
              size='medium'
              color='dark-4'
              textAlign='end'
              truncate={truncateValue}
              title={truncateValue ? value : null}
            >
              {value}
            </Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Simple;
