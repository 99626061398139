import TYPES from '../actions/types';

const INITIAL_STATE = {
  districtOptions: [],
  districtOptionsLoad: false,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TYPES.getDistrictOptions:
      return { ...state, districtOptions: action.payload };

    case TYPES.getDistrictOptionsLoad:
      return { ...state, districtOptionsLoad: !state.districtOptionsLoad }

    default:
      return state;
  }
}
