import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';
import { createObject } from '../../../api/object';
import { saveArchiveService } from '../../../api/service';

const loadingPostArchiveToggle = () => ({
  type: TYPES.loadingFormArchive,
});

const validUpdateArchive = file => {
  const postDataError = { message: '', errors: [] };

  if(file.size > 1024 * 1024) {
    postDataError.errors = [{
      fieldName: 'size',
      message: 'Arquivo não pode passar de 1MB.',
    }]
  }

  return postDataError;
}

export const uploadFile = params => {
  return dispatch => {
    dispatch(loadingPostArchiveToggle());

    createObject(params.formParams.file)
      .then(response => {
        const { id } = params.formParams;
        const { name, url } = response.data;

        saveArchiveService({
          id,
          name,
          url,
        })
          .then(response => {
            dispatch(toggleFormCreateArchiveLayer());

            dispatch(
              showNotificationLayer('O arquivo foi salvo no atendimento!')
            );
          })
          .catch(responseFail => {
            dispatch(loadingPostArchiveToggle());
            dispatch({
              type: TYPES.formArchiveError,
              payload: responseFail.response
            });
          });
      })
      .catch(responseFail => {
        dispatch(loadingPostArchiveToggle());
        dispatch({
          type: TYPES.formArchiveError,
          payload: responseFail.response.data
        });
      });
  };
}

export const chooseFile = formFile => {
  return dispatch => {
    const actionResult = validUpdateArchive(formFile);

    if(actionResult && actionResult.errors.length > 0) {
      return dispatch({
        type: TYPES.formArchiveError,
        payload: actionResult
      });
    }

    dispatch({
      type: TYPES.setPreviewFormArchive,
      payload: formFile
    });
  }
}

export const toggleFormCreateArchiveLayer = params => {
  if(params && params.id) {
    analytics.registerEvent({
      category: analytics.categories.service,
      action: analytics.actions.serviceArchive,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'createArchive' },
      { type: TYPES.setDataFormArchive, payload: params },
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
};
