import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Text, Heading, ResponsiveContext } from 'grommet';

import { Placeholder, HeaderHtml } from '../../../components';

import { formattedDateTime } from '../../../utils';

import { getDetails } from '../actions/details-public';

const Title = ({ value }) => {
  return <Text size='medium' color='dark-2' margin={{ top: 'xsmall' }}>
    {value}
  </Text>
}

const Texts = ({ value }) => {
  return <Text size='small' color='dark-3' margin={{ top: 'xsmall' }}>
    {value}
  </Text>
}

const SubHeader = ({ value }) => {
  return <Heading level='3' size='small'>
    {value}
  </Heading>
}

class PublicAppointment extends Component {
  constructor(props) {
    super(props);

    const { code } = this.props.match.params;
    const { getDetails } = this.props;

    getDetails(code);
  }

  render() {
    const {
      clientId,
      subject,
      startDate,
      endDate,
      location,
      isExcluded,
      description,
      requester,
    } = this.props;

    if(!clientId) {
      return (
        <Placeholder
          title='Carregando...'
          message='Aguarde que estamos preparando as coisas :)'
        />
      );
    }

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <HeaderHtml title={subject} />

            <Box direction='row' justify='center'>
              <Box
                direction={ size === 'small' ? 'column' : 'row' }
                width='xlarge'
              >
                <Box
                  width='medium'
                  fill='horizontal'
                  pad={ size === 'small' ? 'large' : 'medium' }
                  margin={{ right: size === 'small' ? 'none' : 'medium' }}
                >
                  <Heading level='1' size='small'>
                    {subject}
                  </Heading>

                  <SubHeader value='Datas' />

                  <Box
                    border={{ color: 'light-3', side: 'bottom' }}
                    margin={{ top: 'xsmall' }}
                    pad={{ bottom: 'small' }}
                    direction='row'
                    justify='between'
                    align='center'
                  >
                    <Title value='Início' />
                    <Texts value={`${formattedDateTime(startDate, 'HH:mm')} - ${formattedDateTime(startDate, 'DD MMM YYYY')}`} />
                  </Box>

                  <Box
                    border={{ color: 'light-3', side: 'bottom' }}
                    margin={{ top: 'xsmall' }}
                    pad={{ bottom: 'small' }}
                    direction='row'
                    justify='between'
                    align='center'
                  >
                    <Title value='Previsão de término' />
                    <Texts value={`${formattedDateTime(endDate, 'HH:mm')} - ${formattedDateTime(endDate, 'DD MMM YYYY')}`} />
                  </Box>

                  <SubHeader value='Local' />

                  <Box
                    margin={{ top: 'xsmall' }}
                    pad={{ bottom: 'small' }}
                    direction='row'
                    justify='between'
                    align='center'
                  >
                    <Texts value={location.local} />
                  </Box>

                  {description && <>
                    <SubHeader value='Descrição' />

                    <Box
                      margin={{ top: 'xsmall' }}
                      pad={{ bottom: 'small' }}
                      direction='row'
                      justify='between'
                      align='center'
                    >
                      <Texts value={description} />
                    </Box>
                  </>}
                </Box>


                <Box
                  width='medium'
                  fill='horizontal'
                  pad={ size === 'small' ? 'large' : 'medium' }
                  margin={{ left: size === 'small' ? 'none' : 'medium' }}
                >
                  <SubHeader value='Outros detalhes' />

                  <Box
                    border={{ color: 'light-3', side: 'bottom' }}
                    margin={{ top: 'xsmall' }}
                    pad={{ bottom: 'small' }}
                    direction='row'
                    justify='between'
                    align='center'
                  >
                    <Title value='Status' />
                    <Texts value={isExcluded ? 'Cancelado' : 'Ativo'} />
                  </Box>

                  {(requester.name || requester.telephone) &&
                    <Box
                      border={{ color: 'light-3', side: 'bottom' }}
                      margin={{ top: 'xsmall' }}
                      pad={{ bottom: 'small', top: 'xsmall' }}
                      direction='row'
                      justify='between'
                      align='center'
                    >
                      <Title value='Solicitante' />
                      <Texts value={`${requester.name} ${requester.telephone}`} />
                    </Box>
                  }
                </Box>
              </Box>
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>

    )
  }
}

const mapStateToProps = ({ appointmentsReducer: { detailsPublic } }) => ({
  clientId: detailsPublic.clientId,
  subject: detailsPublic.subject,
  startDate: detailsPublic.startDate,
  endDate: detailsPublic.endDate,
  location: detailsPublic.location,
  isExcluded: detailsPublic.isExcluded,
  description: detailsPublic.description,
  requester: detailsPublic.requester,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getDetails,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicAppointment));
