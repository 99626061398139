const formatColor = (indexColor) => {
  const colors = new Map([
    [0, '#64C680'],
    [1, '#7264C6'],
    [2, '#C66487'],
    [3, '#7CA32B'],
    [4, '#B84040'],
    [5, '#DD7033'],
 ]);

 return colors.get(indexColor) || '#000000';
}

export default formatColor;
