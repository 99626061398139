import React from 'react';
import { ResponsiveContext, Box } from 'grommet';

const Wrapper = ({ value }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          margin={{
            top: size === 'small' ? 'small' : 'small',
            bottom: size === 'small' ? 'medium' : 'small',
            horizontal: 'large'
          }}
          flex={{ shrink: 0 }}
        >
          {value}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Wrapper;
