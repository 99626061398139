import { details } from '../../../api/common';
import { printOut } from '../components/print-doc/print-doc';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';

const _command = params =>
  async dispatch => {
    try
    {
      dispatch(
        showNotificationLayer('Gerando arquivo. Aguarde...')
      );

      const { data } = await details(params.id, 'legislative.processe.prepare.doc.php');

      if(data.text) {
        return printOut({
          data,
          fileName: `${data.typeName}-${data.number}-${data.year}_document.html`,
        });
      }

      dispatch(
        showNotificationLayer('Não foi elaborado documento para esse processo.')
      );
    }
    catch(error)
    {
      dispatch(
        showNotificationLayer('Ops... não conseguimos gerar o arquivo.')
      );
    }
  }

export const generatePrint = params => {
  //ga
  analytics.registerEvent({
    category: analytics.categories.legislative,
    action: analytics.actions.legislativePrintDoc,
  });

  return _command(params);
}
