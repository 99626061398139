import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';
import { formatFormCreate, formatFormEdit, formatFormCopy } from '../utils';
import { create as c, update as u, details } from '../../../api/common';
import { showNotificationLayer } from '../../../actions/notification';

const loadingPostManager = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const update = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    u(params.formParams, 'compromisso.php')
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(hideFormManagerLayer());

        dispatch({
          type: TYPES.updateItemListingManagerSuccess,
          payload: response,
        });

        dispatch(showNotificationLayer(
          `O compromisso AG-${response.data.id} foi atualizado!`
        ));
      })
      .catch(responseFail => {
        dispatch(loadingPostManager());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
};

export const create = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    c(params.formParams, 'compromisso.php')
      .then(response => {
        dispatch(loadingPostManager());

        dispatch(
          showNotificationLayer(`O compromisso AG-${response.data.id} foi criado!`)
        )
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostManager());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
};

export const copyLayer = params => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.appointment,
      action: analytics.actions.appointmentCopy,
    });

    return dispatch => {
      dispatch([
        { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
        { type: COMMON_TYPES.formGetWatingResponse, payload: true },
      ]);

      details(params.id, 'compromisso.php')
        .then(({ data }) => {
          dispatch([
              {
                type: COMMON_TYPES.formInitializeValues,
                payload: formatFormCopy(data),
              },
              { type: COMMON_TYPES.formGetWatingResponse, payload: false },
          ])
        })
        .catch(() =>
          dispatch({ type: COMMON_TYPES.formGetResultError })
        )
    }
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
    { type: COMMON_TYPES.formInitializeValues, payload: formatFormCreate() },
    { type: COMMON_TYPES.formGetWatingResponse, payload: false },
  ];
}

export const toggleFormManagerLayer = params => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.appointment,
      action: analytics.actions.appointmentEdit,
    });

    return dispatch => {
      dispatch([
        { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
        { type: COMMON_TYPES.formGetWatingResponse, payload: true },
      ]);

      details(params.id, 'compromisso.php')
        .then(({ data }) => {
          dispatch([{
              type: COMMON_TYPES.formInitializeValues,
              payload: formatFormEdit(data),
            },
            { type: COMMON_TYPES.formGetWatingResponse, payload: false },
          ])
        })
        .catch(() =>
          dispatch({ type: COMMON_TYPES.formGetResultError })
        )
    }
  }

  //ga
  analytics.registerEvent({
    category: analytics.categories.appointment,
    action: analytics.actions.appointmentCreate,
  });

  return [
    { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
    { type: COMMON_TYPES.formInitializeValues, payload: formatFormCreate() },
    { type: COMMON_TYPES.formGetWatingResponse, payload: false },
  ];
};

export const hideFormManagerLayer = () => {
  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
}
