import React from 'react';

import Home from '../../modules/home/home';
import Forbidden from '../../modules/forbidden/forbidden';
import { InvoiceOpen, InvoiceUseLicense } from '../../modules/invoice/pages';

import { Login, Logout, ForgotPassword, ResetPassword } from '../../modules/auth/pages';
import { OnBoardingStart, OnBoardingWelcome } from '../../modules/onboarding/pages';
import { Appointments, DashboardAppointment, PublicAppointment } from '../../modules/appointments/pages';
import { Phonecalls, DashboardPhonecall } from '../../modules/phonecall/pages';
import { Campaigns, CampaignsManager } from '../../modules/campaigns/pages';
import { Services } from '../../modules/service/pages';
import { Voters, VotersCreate, VotersEdit } from '../../modules/voter/pages';
import { LegislativeProcesses } from '../../modules/legislative-process/pages';
import { CustomerArea, CustomerAreaInvoices } from '../../modules/customer-area/pages';
import { SettingsOrigin } from '../../modules/settings-origin/pages';
import { SettingsGroup } from '../../modules/settings-group/pages';
import { SettingsCategory } from '../../modules/settings-category/pages';
import { SettingsServiceStatus } from '../../modules/settings-service-status/pages';
import { SettingsOffice } from '../../modules/settings-office/pages';
import { SettingsUser } from '../../modules/settings-user/pages';
import { SettingsLegislativeProcessStatus } from '../../modules/settings-legislative-process-status/pages';
import { SettingsIntegrationCalendar, SettingsGoogleCallback } from '../../modules/settings-integration-calendar/pages';
import { SettingsList } from '../../modules/settings/pages';
import { DashboardSystem } from '../../modules/dashboard/pages';
import { Tasks } from '../../modules/task/pages';
import { Expenses } from '../../modules/expense/pages';
import { Polls } from '../../modules/survey/pages';
import { Manifestations, DashboardManifestation } from '../../modules/manifestation/pages';

import { FillLocalStorageUserLogged, RequireAuth } from '../../components';

import urlRoutes from '../../config/url-routes';

const RoutesMap = ([
  {
    path: urlRoutes.onboardingStartUrl,
    component: <OnBoardingStart />,
  },
  {
    path: urlRoutes.onboardingWelcomeUrl,
    component: <OnBoardingWelcome />,
  },

  {
    path: urlRoutes.login,
    component: FillLocalStorageUserLogged(Login),
  },
  {
    path: urlRoutes.logout,
    component: <Logout />,
  },
  {
    path: urlRoutes.forgotPassword,
    component: <ForgotPassword />,
  },
  {
    path: urlRoutes.resetPassword,
    component: <ResetPassword />,
  },

  {
    path: urlRoutes.InvoiceOpen,
    component: <InvoiceOpen />,
  },
  {
    path: urlRoutes.InvoiceUseLicense,
    component: <InvoiceUseLicense />,
  },
  {
    path: urlRoutes.integrationCalendarCallback,
    component: <SettingsGoogleCallback />,
  },

  {
    path: urlRoutes.appointmentListUrl,
    component: FillLocalStorageUserLogged(RequireAuth(Appointments, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.appointmentDashUrl,
    component: FillLocalStorageUserLogged(RequireAuth(DashboardAppointment, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.appointmentPublicUrl,
    component: <PublicAppointment />,
  },

  {
    path: urlRoutes.phonecallListUrl,
    component: FillLocalStorageUserLogged(RequireAuth(Phonecalls, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.phonecallDashUrl,
    component: FillLocalStorageUserLogged(RequireAuth(DashboardPhonecall, urlRoutes.forbidden), urlRoutes.login),
  },

  {
    path: urlRoutes.campaignListUrl,
    component: FillLocalStorageUserLogged(RequireAuth(Campaigns, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.campaignCreateUrl,
    component: FillLocalStorageUserLogged(RequireAuth(CampaignsManager, urlRoutes.forbidden), urlRoutes.login),
  },

  {
    path: urlRoutes.serviceListUrl,
    component: FillLocalStorageUserLogged(RequireAuth(Services, urlRoutes.forbidden), urlRoutes.login),
  },

  {
    path: urlRoutes.voterListUrl,
    component: FillLocalStorageUserLogged(RequireAuth(Voters, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.voterCreateUrl,
    component: FillLocalStorageUserLogged(RequireAuth(VotersCreate, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.voterEditUrl,
    component: FillLocalStorageUserLogged(RequireAuth(VotersEdit, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.legislativeProcessesListUrl,
    component: FillLocalStorageUserLogged(RequireAuth(LegislativeProcesses, urlRoutes.forbidden), urlRoutes.login),
  },

  {
    path: urlRoutes.customerAreaUrl,
    component: FillLocalStorageUserLogged(RequireAuth(CustomerArea, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.customerAreaInvoicesUrl,
    component: FillLocalStorageUserLogged(RequireAuth(CustomerAreaInvoices, urlRoutes.forbidden), urlRoutes.login),
  },

  {
    path: urlRoutes.settingsOriginUrl,
    component: FillLocalStorageUserLogged(RequireAuth(SettingsOrigin, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.settingsGroupUrl,
    component: FillLocalStorageUserLogged(RequireAuth(SettingsGroup, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.settingsCategoryUrl,
    component: FillLocalStorageUserLogged(RequireAuth(SettingsCategory, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.settingsServiceStatusUrl,
    component: FillLocalStorageUserLogged(RequireAuth(SettingsServiceStatus, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.settingsOfficeUrl,
    component: FillLocalStorageUserLogged(RequireAuth(SettingsOffice, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.settingsUserUrl,
    component: FillLocalStorageUserLogged(RequireAuth(SettingsUser, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.settingsLegislativeProcesseStatusUrl,
    component: FillLocalStorageUserLogged(RequireAuth(SettingsLegislativeProcessStatus, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.settingsIntegrationCalendarUrl,
    component: FillLocalStorageUserLogged(RequireAuth(SettingsIntegrationCalendar, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.settingsUrl,
    component: FillLocalStorageUserLogged(RequireAuth(SettingsList, urlRoutes.forbidden), urlRoutes.login),
  },

  {
    path: urlRoutes.dashboardSystemUrl,
    component: FillLocalStorageUserLogged(RequireAuth(DashboardSystem, urlRoutes.forbidden), urlRoutes.login),
  },

  {
    path: urlRoutes.expensesListUrl,
    component: FillLocalStorageUserLogged(RequireAuth(Expenses, urlRoutes.forbidden), urlRoutes.login),
  },

  {
    path: urlRoutes.pollsListUrl,
    component: FillLocalStorageUserLogged(RequireAuth(Polls, urlRoutes.forbidden), urlRoutes.login),
  },

  {
    path: urlRoutes.tasksListUrl,
    component: FillLocalStorageUserLogged(RequireAuth(Tasks, urlRoutes.forbidden), urlRoutes.login),
  },

  {
    path: urlRoutes.manifestationsListUrl,
    component: FillLocalStorageUserLogged(RequireAuth(Manifestations, urlRoutes.forbidden), urlRoutes.login),
  },
  {
    path: urlRoutes.manifestationsDashUrl,
    component: FillLocalStorageUserLogged(RequireAuth(DashboardManifestation, urlRoutes.forbidden), urlRoutes.login),
  },

  {
    path: urlRoutes.forbidden,
    component: <Forbidden />,
  },

  {
    path: urlRoutes.home,
    component: <Home />,
  },
]);

export default RoutesMap;
