import TYPES from './types';
import { getClientUseLicense } from '../../../api/client';

export const getUseLicense = identity => {
  return dispatch => {
    getClientUseLicense(identity)
      .then(response => {
        dispatch({
          type: TYPES.openInvoice,
          payload: response,
        });
      })
      .catch(() => { })
  }
}
