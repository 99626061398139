import TYPES from './types/forgot-password';
import { postForgotPassword } from '../../../api/user';
import { accounts } from '../../../api/login';

export const getAccountForgotPassword = params => {
  return dispatch => {
    dispatch({ type: TYPES.loadFormPostToggle });

    accounts(params.formParams)
      .then(response => {
        const { id, email } = response.data[0];

        postForgotPassword({ userId: id, email: email })
          .then(() => {
            dispatch({ type: TYPES.formPostSuccess });
            dispatch({ type: TYPES.loadFormPostToggle });
          })
          .catch(responseFail => {
            dispatch({
              type: TYPES.formPostError,
              payload: responseFail.response
            });
            dispatch({ type: TYPES.loadFormPostToggle })
          });
      })
      .catch(responseFail => {
        dispatch({ type: TYPES.loadFormPostToggle });
        dispatch({ type: TYPES.formPostError, payload: responseFail.response });
      })
  }
}

export const changeEmail = params => ({
  type: TYPES.changeEmail,
  payload: params,
});
