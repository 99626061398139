import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  ResponsiveContext,
  Button,
  Layer,
  TextArea,
  Box,
  MaskedInput,
  Select,
  TextInput,
  Anchor,
  RadioButtonGroup,
  Text
} from 'grommet';
import { Clock, FormClose } from 'grommet-icons';

import { Placeholder, ErrorForm } from '../../../../components';
import { TitleField, DescriptionField, CalendarDropButton } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { searchPhonecalls } from '../../actions/form-search';
import { update, create, hideFormManagerLayer } from '../../actions/form-manager';
import { changeValues } from '../../../../actions/form';

import selectPhonecallStatusOptions from '../../../../constants/select-phonecall-status-options';
import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

class FormCreate extends Component {
  callbackPostSuccess = () => {
    const {
      searchPhonecalls,
      hideFormManagerLayer,
    } = this.props;

    searchPhonecalls();
    hideFormManagerLayer();
  };

  handlePost = formParams => {
    const { create, update } = this.props;

    if(formParams.id) {
      update({ formParams });
    }
    else {
      create({
        formParams,
        callbackSuccess: this.callbackPostSuccess
      });
    }
  };

  render() {
    const {
      usersOptions,

      id,
      name,
      telephone,
      subject,
      date,
      hour,
      userWantedId,
      status,

      getServerPresponseError,
      watingServerResponse,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      hideFormManagerLayer,
    } = this.props;


    if(!toggleLayer || toggleLayer !== 'manager')
      return null;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={hideFormManagerLayer}
            onEsc={hideFormManagerLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{ top: 'large', bottom: 'xsmall' }}
              overflow='auto'
              flex
            >
              {watingServerResponse && !id && !getServerPresponseError ? (
                <Placeholder
                  title='Carregando...'
                  message='Aguarde que estamos preparando as coisas :)'
                />
              ) : null}

              {getServerPresponseError ? (
                <Placeholder
                  title='Ops! Algo deu errado :('
                  message='Estamos constrangidos por isso.'
                  button={{
                    label: 'Fechar',
                    action: hideFormManagerLayer,
                  }}
                />
              ) : null}

              {!getServerPresponseError && (id || !watingServerResponse) ? (
                <>
                  <Box
                    direction='row'
                    justify='between'
                    align='baseline'
                    margin={{ horizontal: 'large' }}
                  >
                    <TitleDetail value={id ? 'Editar ligação' : 'Nova ligação'} />

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={hideFormManagerLayer}
                    />
                  </Box>


                  <Box flex overflow='auto'>
                    {/* Nome */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      direction={ size === 'small' ? 'column' : 'row' }
                      align='stretch'
                      flex={{ shrink: 0 }}
                      gap='small'
                    >
                      <Box
                        width={ size === 'small' ? '100vw' : '50vw'}
                        gap='xxsmall'
                      >
                        <TitleField text='Nome' required />

                        <DescriptionField
                          text='Nome da pessoa que esta ligando.'
                        />
                      </Box>

                      <Box
                        width={ size === 'small' ? '100vw' : '50vw'}
                      >
                        <TextInput
                          maxLength={255}
                          value={name ?? ''}
                          onChange={event =>
                            changeValues({ fieldName: 'name', value: event.target.value }, 'formChangeValues')
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='name' />
                      </Box>
                    </Box>


                    {/* Telefone */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      direction={ size === 'small' ? 'column' : 'row' }
                      align='stretch'
                      flex={{ shrink: 0 }}
                      gap='small'
                    >
                      <Box
                        width={ size === 'small' ? '100vw' : '50vw'}
                        gap='xxsmall'
                      >
                        <TitleField text='Telefone' />

                        <DescriptionField
                          text='Telefone da pessoa que esta ligando.'
                        />
                      </Box>

                      <Box
                        width={ size === 'small' ? '100vw' : '50vw'}
                      >
                        <MaskedInput
                          value={telephone ?? ''}
                          placeholder='(99) 9999-9999'
                          reverse={true}
                          onChange={event =>
                            changeValues({ fieldName: 'telephone', value: event.target.value }, 'formChangeValues')
                          }
                          mask={[
                            { fixed: '(' },
                            { length: 2, regexp: /\d/ },
                            { fixed: ')' },
                            { fixed: ' ' },
                            { length: 4, regexp: /\d/ },
                            { fixed: '-' },
                            { length: [4, 5], regexp: /\d/ }
                          ]}
                        />
                      </Box>
                    </Box>


                    {/* Data e horário */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Data e horário' required />

                      <Box
                        direction='row'
                        gap='small'
                      >
                        <Box width='50vw'>
                          <CalendarDropButton
                            date={date}
                            onSelect={(selectedDate) =>
                              changeValues({ fieldName: 'date', value: selectedDate }, 'formChangeValues')
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='date' />
                        </Box>

                        <Box width='50vw'>
                          <MaskedInput
                            value={hour ?? ''}
                            placeholder='hh:mm'
                            icon={<Clock />}
                            reverse={true}
                            onChange={event =>
                              changeValues({ fieldName: 'hour', value: event.target.value }, 'formChangeValues')
                            }
                            mask={[
                              { length: 2, regexp: /^0[1-9]$|^1[0-9]$|^2[0-3]$|^[0-9]$/ },
                              { fixed: ':' },
                              { length: 2, regexp: /^[0-5][0-9]$|^[0-9]$/ },
                            ]}
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='hour' />
                        </Box>
                      </Box>
                    </Box>


                    {/* Status */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Status' required />

                      <Box
                        round='xxsmall'
                        pad={{
                          vertical: 'small',
                        }}
                        gap='xsmall'
                      >
                        <RadioButtonGroup
                          name='status'
                          value={status ?? ''}
                          options={
                            selectPhonecallStatusOptions.map(({ name, id }) => ({
                              label: name,
                              id: `status_${id}`,
                              value: id,
                            }))
                          }
                          onChange={event => {
                            changeValues({ fieldName: 'status', value: event.target.value }, 'formChangeValues')
                          }}
                        >
                          {(option, { checked }) => {
                            let background = null;
                            let borderColor = 'dark-5';

                            if(checked) {
                              background = 'brand';
                              borderColor = 'brand';
                            }

                            return (
                              <Box
                                direction='row'
                                gap='small'
                                justify='center'
                                align='center'
                              >
                                <Box
                                  width={'20px'}
                                  height={'20px'}
                                  background={background}
                                  round='xxsmall'
                                  border={{
                                    color: borderColor
                                  }}
                                />
                                <Text color='dark-2' size='small'>{option.label}</Text>
                              </Box>
                            )
                          }}
                        </RadioButtonGroup>
                      </Box>

                      <ErrorForm errorsList={postDataError.errors} fieldName='status' />
                    </Box>


                    {/* Assunto */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Assunto' required />

                      <TextArea
                        resize='vertical'
                        maxLength={2000}
                        value={subject ?? ''}
                        onChange={event =>
                          changeValues({ fieldName: 'subject', value: event.target.value }, 'formChangeValues')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='subject' />
                    </Box>


                    {/* Pessoa procura */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Pessoa procura' />

                      <Select
                        options={usersOptions.filter(a => a.status || a.id === userWantedId)}
                        value={userWantedId}
                        labelKey='name'
                        valueKey={{ key: 'id', reduce: true }}
                        onChange={({ value: nextValue }) =>
                          changeValues({ fieldName: 'userWantedId', value: nextValue }, 'formChangeValues')
                        }
                      />
                    </Box>
                  </Box>

                  <Box
                    direction='row'
                    pad={{ vertical: 'medium' }}
                    justify='center'
                    align='center'
                    gap='small'
                  >
                    <Box width='xsmall'>
                      <Anchor
                        color='dark-3'
                        label='Cancelar'
                        disabled={ loadingPostForm }
                        onClick={hideFormManagerLayer}
                      />
                    </Box>

                    <Box width='small'>
                      <Button
                        color='brand'
                        primary
                        label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                        disabled={ loadingPostForm }
                        onClick={() =>
                          this.handlePost({
                            id,
                            name,
                            telephone,
                            subject,
                            date: formattedDateTime(date),
                            hour,
                            userWantedId,
                            status,
                          })
                        }
                      />
                    </Box>
                  </Box>
                </>
              ) : null}
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

const mapStateToProps = ({
  layerReducer,
  selectOptionsReducer,
  formCreateUpdateCopyGeneric,
}) => ({
    usersOptions: selectOptionsReducer.usersOptions,

    id: formCreateUpdateCopyGeneric.id,
    name: formCreateUpdateCopyGeneric.name,
    telephone: formCreateUpdateCopyGeneric.telephone,
    subject: formCreateUpdateCopyGeneric.subject,
    date: formCreateUpdateCopyGeneric.date,
    hour: formCreateUpdateCopyGeneric.hour,
    userWantedId: formCreateUpdateCopyGeneric.userWantedId,
    status: formCreateUpdateCopyGeneric.status,

    watingServerResponse: formCreateUpdateCopyGeneric.watingServerResponse,
    getServerPresponseError: formCreateUpdateCopyGeneric.getServerPresponseError,

    loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
    postDataError: formCreateUpdateCopyGeneric.postDataError,

    toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    update,
    create,
    changeValues,
    searchPhonecalls,
    hideFormManagerLayer,
  }, dispatch);

export const Create = connect(mapStateToProps, mapDispatchToProps)(FormCreate);
