export default object => ({
  id: object.id,
  menu: object.menu,
  number: object.number,
  proceeding: object.proceeding,
  status: object.status,
  areaInterest: object.areaInterest,
  type: object.type,
  year: object.year,
  authors: object.authors,
  region: object.region,
});
