import React, { Component } from 'react';
import { Box, Button, Text, ResponsiveContext, Layer } from 'grommet';
import { FormClose } from 'grommet-icons';

const defaultAutoDismiss = 3;
const defaultDelay = 1000;

const width = ({
  small: '90vw',
  medium: '40vw',
  large: '30vw',
  xlarge: '25vw'
});

export class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seconds: defaultAutoDismiss,
    }
  }

  closeNotification() {
    const { onClose } = this.props;
    onClose();
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.checkTimer();
      this.setState(({ seconds }) => ({ seconds: seconds - 1 }));
    }, defaultDelay);
  }

  checkTimer() {
    const { seconds } = this.state;

    if(seconds === 0) {
      this.stopTimer();
    }
  }

  stopTimer() {
    this.setState({ seconds: defaultAutoDismiss });
    this.closeNotification();

    clearInterval(this.timer);
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if(prevProps.isOpen !== isOpen) {
      isOpen ? this.startTimer() : this.stopTimer();
    }
  }

  componentWillUnmount() {
    this.setState({ seconds: defaultAutoDismiss });
    this.closeNotification();

    clearInterval(this.timer);
  }

  render() {
    const {
      description,
      isOpen,
      onClose,
    } = this.props;


    if(isOpen) {
      return (
        <ResponsiveContext.Consumer>
          {size => {
            return (
              <Layer
                position='bottom-left'
                modal={false}
                margin={{ vertical: 'large', horizontal: 'large' }}
                responsive={false}
                plain
              >
                <Box
                  width={width[size]}
                  direction='row'
                  align='start'
                  justify='between'
                  round='xsmall'
                  elevation='small'
                  background='dark-1'
                  flex={{ shrink: 0 }}
                >
                  <Box
                    margin={{
                      horizontal: 'medium',
                      vertical: 'medium'
                    }}
                  >
                    <Text size='small' color='white'>
                      {description || 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}
                    </Text>
                  </Box>

                  <Box
                    margin={{
                      horizontal: 'medium',
                      vertical: 'medium'
                    }}
                  >
                    <Button
                      icon={<FormClose color='dark-4' />}
                      onClick={onClose}
                      plain
                    />
                  </Box>
                </Box>
              </Layer>
            )
          }}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}
