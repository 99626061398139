import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import { showNotificationLayer } from '../../../actions/notification';
import { changeStatusManifestation } from '../../../api/manifestation';

const loadingPostChangeStatusToggle = () => ({
  type: TYPES.loadingFormChangeStatus,
});

const updateItemListing = (response) => ({
  type: TYPES.updateItemListing,
  payload: response,
})

export const changeStatus = params => {
  return dispatch => {
    dispatch(loadingPostChangeStatusToggle());

    changeStatusManifestation(params.formParams)
      .then(response => {
        dispatch(toggleFormChangeStatusLayer());
        dispatch(loadingPostChangeStatusToggle());

        dispatch(updateItemListing(response));

        dispatch(
          showNotificationLayer(`O status da manifestação ${response.data.protocol} foi alterado!`)
        );
      })
      .catch(responseFail => {
        dispatch(loadingPostChangeStatusToggle());
        dispatch({
          type: TYPES.formChangeStatusError,
          payload: responseFail.response
        });
      })
  }
}

export const changeField = (value, type) => ({
  type: TYPES[type],
  payload: value,
});

export const toggleFormChangeStatusLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'changeStatus' },
      { type: TYPES.setDataFormStatus, payload: params },
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
};
