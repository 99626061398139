import TYPES from './types';
import { createCampaign } from '../../../api/campaign';

const loadingPostCreate = () => ({
  type: TYPES.loadingPostFormCreate,
});

export const changeField = (value, type) => ({
  type: TYPES[type],
  payload: value,
});

export const changeCampaignType = value => {
  // email
  if(value === '1') {
    return [
      { type: TYPES.changeFormCreateType, payload: value },
      { type: TYPES.searchByEmail },
    ]
  }

  return [
    { type: TYPES.changeFormCreateType, payload: value },
    { type: TYPES.searchBySms },
  ]
}

export const clearForm = () => ({
  type: TYPES.clearFormCreate,
});

export const create = params => {
  return dispatch => {
    dispatch(loadingPostCreate());

    createCampaign(params.formParams)
      .then(response => {
        dispatch(loadingPostCreate());
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostCreate());
        dispatch({
          type: TYPES.postFormCreateCampaignError,
          payload: responseFail.response
        });
      })
  }
}
