import React from 'react';
import { Box, Text, ResponsiveContext } from 'grommet';

import { getPercent } from '../../utils';
import { formattedNumber } from '../../../../utils';

function CardVoter({ name, total, voters }) {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          round='xsmall'
          width={ size === 'small' ? 'large' : 'medium' }
          pad={{
            vertical: size === 'small' ? 'large' : 'medium',
            horizontal: size === 'small' ? 'large' : 'medium',
          }}
          border={{ color: 'light-4' }}
        >
          <Text truncate title={name} size='small'>{name}</Text>

          <Box
            direction='row'
            justify='between'
            align='end'
            margin={{ top: 'small' }}
          >
            <Text color='brand' size='large'>{formattedNumber(total)}</Text>
            <Text color='dark-4' size='small'>{getPercent(voters, total)}</Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default CardVoter;
