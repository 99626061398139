import React, { Component } from 'react';
import { Box, Text, Button, ResponsiveContext } from 'grommet';
import { Action } from 'grommet-icons';

import urlRoutes from '../../config/url-routes';

class Forbidden extends Component {
  reAuth = () => {
    window.location = urlRoutes.logout;
  };

  leftColumn = () => ({
    small: '90vw',
    medium: '20vw',
    large: '20vw',
    xlarge: '15vw'
  });

  rightColumn = () => ({
    small: '90vw',
    medium: '30vw',
    large: '30vw',
    xlarge: '20vw'
  });

  render() {
    const {
      title = 'Oops. Parece que você não esta mais logado!',
      message = 'Seu token de acesso é válido por um período de tempo é normal que ele expire. Acesse novamente para continuar trabalhando.',
    } = this.props;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            justify='center'
            align='center'
            height='100vh'
          >
            <Action size='large' color='status-warning' />

            <Box
              direction={size !== 'small' ? 'row' : 'column'}
              margin={{ vertical: 'medium' }}
            >
              <Box
                width={this.leftColumn()[size]}
                margin={{
                  top: 'none',
                  horizontal: 'medium',
                  bottom: 'small'
                }}
              >
                <Text
                  size='xlarge'
                  textAlign={size !== 'small' ? 'end' : 'center'}
                >
                  {title}
                </Text>
              </Box>

              <Box
                width={this.rightColumn()[size]}
                margin={{
                  top: 'xsmall',
                  horizontal: 'medium'
                }}
              >
                <Text
                  size='small'
                  color='dark-5'
                  textAlign={size !== 'small' ? 'start' : 'center'}
                >
                  {message}
                </Text>

                <Button
                  margin={{ vertical: 'medium' }}
                  label='Acessar novamente'
                  primary
                  onClick={this.reAuth}
                />
              </Box>
            </Box>
          </Box>

        )}
      </ResponsiveContext.Consumer>
    )
  }
}

export default Forbidden;
