import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Box, Button, Text, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';

import { Placeholder } from '../../../../components';
import { TitleDetail } from '../../../../components/detail';

import { formattedDateTime } from '../../../../utils';

import visibleWidth from '../../../../constants/layer-width';

import { updateStatus, toggleTodoLayer } from '../../actions/todo';

const TodoComponent = (props) => {

  const handlePost = formParams => {
    const { updateStatus } = props;
    updateStatus({ formParams });
  };

  const group = (rows) =>
    rows.reduce((acc, key) => {
      const groupFind = acc.find(a => a.taskDate === key.taskDate);

      if(groupFind) {
        groupFind.itens.push(key);
      }
      else {
        acc.push({
          taskDate: key.taskDate,
          itens: [key],
        });
      }

      return acc;
    }, []);

  const {
    loadingFormSearch,
    dataRows,
    totalOfRows,
    getListError,

    loadingPostForm,

    toggleLayer,
    toggleTodoLayer,
  } = props;


  if(!toggleLayer || toggleLayer !== 'todoLayer') {
    return null;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Layer
          responsive={false}
          full='vertical'
          position='left'
          onClickOutside={toggleTodoLayer}
          onEsc={toggleTodoLayer}
        >
          <Box
            fill='vertical'
            width={visibleWidth[size]}
            pad={{
              top: size === 'small' ? 'xlarge' : 'large',
              bottom: size === 'small' ? 'large' : null,
            }}
            overflow='auto'
            flex
          >
            {getListError ? (
              <Placeholder
                title='Ops! Algo deu errado :('
                message='Estamos constrangidos por isso.'
                button={{
                  label: 'Fechar',
                  action: toggleTodoLayer,
                }}
              />
            ) : null}

            {loadingFormSearch && !getListError ? (
              <Placeholder
                title='Carregando...'
                message='Aguarde que estamos preparando as coisas :)'
              />
            ) : null}

            {totalOfRows <= 0 && !loadingFormSearch ? (
              <Placeholder
                title='Oba! Nenhuma tarefa pendente :)'
                message='Por hoje esta tudo tranquilo.'
                button={{
                  label: 'Fechar',
                  action: toggleTodoLayer,
                }}
              />
            ) : null}

            {totalOfRows > 0 && !getListError ? (
              <>
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                  flex={{ shrink: 0 }}
                >
                  <TitleDetail value='Suas próximas tarefas' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={toggleTodoLayer}
                  />
                </Box>

                <Box flex overflow='auto'>
                  <Box
                    margin={{
                      top: 'xsmall',
                      horizontal: 'large',
                    }}
                    pad={{ bottom: 'small' }}
                    flex={{ shrink: 0 }}
                    gap='small'
                  >
                    {group(dataRows).map(({ taskDate, itens }) => (
                      <Box
                        key={taskDate}
                        gap='small'
                        margin={{ bottom: 'medium' }}
                      >
                        <Text size='medium' color='brand'>{formattedDateTime(taskDate, 'ddd, DD MMM YYYY')}</Text>

                        {
                          itens.map(({ id, description, participants }) => (
                            <Box
                              key={id}
                              width='large'
                              round={ size === 'small' ? 'small' : 'xsmall' }
                              pad={ size === 'small' ? 'medium' : 'small' }
                              gap={ size === 'small' ? 'small' : 'xsmall' }
                              border={{ color: 'light-3' }}
                            >
                              <Text size='medium' color='dark-2'>{description}</Text>

                              <Box
                                direction='row'
                                justify='between'
                                align='baseline'
                                gap='small'
                              >
                                <Text size='xsmall' color='dark-6'>{participants}</Text>

                                <Anchor
                                  label='Finalizar'
                                  disabled={loadingPostForm}
                                  size='small'
                                  onClick={() =>
                                    handlePost({
                                      id,
                                      status: 'finished',
                                    })
                                  }
                                />
                              </Box>
                            </Box>
                          ))
                        }
                      </Box>
                    ))}
                  </Box>
                </Box>
              </>
            ) : null}
          </Box>
        </Layer>
      )}
    </ResponsiveContext.Consumer>
  );
}

const mapStateToProps = ({ dashboardSystemReducer: { todo }, formCreateUpdateCopyGeneric, layerReducer }) => ({
  loadingFormSearch: todo.loadingFormSearch,
  totalOfRows: todo.totalOfRows,
  dataRows: todo.dataRows,
  getListError: todo.getListError,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    updateStatus,
    toggleTodoLayer,
  }, dispatch);

export const Todo = connect(mapStateToProps, mapDispatchToProps)(TodoComponent);
