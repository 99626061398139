import { saveAs } from 'file-saver';
import { formattedDateTime } from '../../../../utils';

const generateHtml = list => {
  const items = (list) => {
    let html = '';

    list.forEach(({ reference, startDate, endDate, subject }) => {
      html += `<tr>
        <td>${reference}</td>
        <td>${formattedDateTime(startDate)}</td>
        <td>${formattedDateTime(startDate, 'HH:mm')}</td>
        <td>${formattedDateTime(endDate, 'HH:mm')}</td>
        <td>${subject}</td>
      </tr>`;
    });

    return html;
  }

  return `<!DOCTYPE html>
    <html>
      <head>
        <title>Listagem de compromissos</title>

        <style>
          * { margin: 0; padding: 0; }

          body { margin: 0; padding: 0; font-size: 13px; color: #333333; font-family: sans-serif; }

          h1 { font-size: 18px; font-weight: normal; }

          table { border-collapse: collapse; width:100%; }
          table tr th { text-align: left; text-transform: uppercase; }

          table tr td,
          table tr th { border-bottom: 1px solid #cccccc; border-top: 0px solid #cccccc; padding: 10px 0px; }

          .content { padding: 16px; }
          .result-content { margin: 10px 0 10px 0; }
          </style>
      </head>

      <body>
        <div class='content'>
          <h1>Listagem de compromissos</h1>

          <div class='result-content'>
            <table>
              <tr>
                <th>Ref.</th>
                <th>Data</th>
                <th>Inicia</th>
                <th>Termina</th>
                <th>Assunto</th>
              </tr>

              ${items(list)}
            </table>
          </div>
        </div>
      </body>
    </html>`;
}

export const printOut = (args = {}) => {
  const { list, fileName } = args;
  const actionResultHtml = generateHtml(list);
  const blob = new Blob([actionResultHtml], { type: 'text/html;charset=utf-8' });

  saveAs(blob, fileName);
}
