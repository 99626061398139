import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

const shapeParamsFilter = (params) =>
  Object.keys(params).reduce((acc, key) => {
    if(params[key])
      acc[key] = params[key];

    return acc;
  }, {})

export default params => {
  const formattedParams = {
    ...params,
    filters: shapeParamsFilter(params.filters),
  };

  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'POST',
    data: formattedParams,
    url: '/tag.php',
    responseType: 'blob',
    headers: {
      token: decipher?.token?.value,
      action: 'generate',
    }
  });
}
