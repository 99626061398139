import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Details from '../components/details/details';
import BuyCommunBalance from '../components/buy-commun-balance/buy-commun-balance';
import Header from '../../../components/header/header';


import { searchDetails, searchCommunicationBalance } from '../actions/details';

class CustomerArea extends Component {
  componentDidMount() {
    const {
      userLogged: { user },
      searchCommunicationBalance,
      searchDetails,
    } = this.props;

    searchCommunicationBalance();

    searchDetails({
      id: user.clientId
    });
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <Details />

        <BuyCommunBalance />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    searchCommunicationBalance,
    searchDetails,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerArea);
