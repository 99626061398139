export default () => ({
  id: null,
  categoryId: null,
  statusId: 2,
  value: null,
  isRefund: false,
  name: null,
  nationalRegister: null,
  telephone: null,
  dateRefund: null,
  dateExpense: null,
  description: null,

  isEditing: false,
  isCopy: false,
});
