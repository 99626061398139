import axiosGlobalInstance from '../http-instance';

const postTrial = params => {
  const formattedParams = {
    name: params.name,
    telephone: params.telephone,
    city: params.city,
    state: params.state.id,
    mail: params.mail,
    password: params.password,
    product: params.product.id,
  }

  return axiosGlobalInstance({
    method: 'POST',
    url: '/trial.php',
    data: formattedParams,
    headers: {
      action: 'cadastrar',
      authorized: true,
    }
  });
}

export default postTrial;
