import TYPES from '../actions/types';

const INITIAL_STATE = {
  categoryId: null,
  description: null,
  documents: [],
  electorId: null,
  officeId: null,
  originId: null,
  requestDate: new Date(),
  responsibles: null,
  statusId: null,
  voterName: null,

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    // fields
    case TYPES.changeFieldFormCreateService:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.loadDataFormCreateService:
      return {
        ...state,
        electorId: action.payload.id,
        voterName: action.payload.name,
      }

    case TYPES.clearFormCreateService:
      return {
        ...state,
        ...INITIAL_STATE,
      }

    case TYPES.loadingPostFormCreateService:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.postFormCreateServiceError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
