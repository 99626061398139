import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Select, Anchor } from 'grommet';
import { Spinning } from 'grommet-controls';

import { TitleField, SelectSearch } from '../../../../components/form';
import { ErrorForm } from '../../../../components';

import CostCampaign from './cost';

import { changeField } from '../../actions/form-create';
import { searchVotersList, clearListVoters, clearCost } from '../../actions/search-voters';
import { searchCityOptions, searchDistrictOptions } from '../../actions/select-options';

import selectGenreOptions from '../../../../constants/select-genre-options';
import selectMaritalStatusOptions from '../../../../constants/select-marital-status-options';
import selectOccupationOptions from '../../../../constants/select-occupation-options';

class Recipients extends Component {
  componentDidUpdate(prevProps) {
    const {
      type,
      searchVoters,
      searchVoters: {
        genre,
        cityId,
        districtsId,
        groupsId,
        maritalStatusId,
        professionId,
      },

      searchVotersList,
      clearListVoters,
      clearCost,
    } = this.props;

    if(prevProps.searchVoters !== searchVoters) {
      const isSearch = this.enableSearchVotersList({
        genre,
        cityId,
        districtsId,
        groupsId,
        maritalStatusId,
        professionId,
      });

      if(isSearch) {
        searchVotersList({ campaignType: type, ...searchVoters });
      }
      else {
        clearListVoters();
        clearCost();
      }
    }
  };

  enableSearchVotersList = (props) =>
    Object.keys(props).some(a => {
      return props[a];
    });

  onSearchCities = text => {
    if (text.length < 2) return;

    const { searchCityOptions } = this.props;
    searchCityOptions({ name: text });
  };

  onSearchDistricts = text => {
    const {
      searchVoters: { cityId },
      searchDistrictOptions
    } = this.props;

    if(!cityId || text.length < 2) return;

    searchDistrictOptions({ cityId, name: text });
  };

  render() {
    const {
      searchVoters: {
        genre,
        cityId,
        districtsId,
        groupsId,
        maritalStatusId,
        professionId,
      },
      postDataError,

      totalVoters,
      costUnit,
      costTotal,
      costBankSlip,
      getCostLoading,
      getGroupOptions,
      getCityOptions,
      getDistrictOptions,

      getCityOptionsLoad,
      getDistrictOptionsLoad,
      //getGroupOptionsLoad,

      changeField,
    } = this.props;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <Box margin={{ bottom: 'medium' }}>
              <Text size='large'>
                Filtre abaixo quais eleitores deseja incluir nessa campanha
              </Text>
            </Box>

            <Box
              direction={size !== 'small' && size !== 'medium' ? 'row' : 'column'}
            >
              {/* coluna 1 - recipients */}
              <Box
                margin={{ right: 'large' }}
                width={size !== 'small' && size !== 'medium' ? '50vw' : '100vw'}
              >
                <Box direction='row'>
                  <Box margin={{ vertical: 'small', right: 'small' }} width='large'>
                    <Box direction='row' align='center'>
                      <TitleField text={'Gênero'} />

                      {genre && (
                        <Anchor
                          margin={{ horizontal: 'xxsmall' }}
                          size='xsmall'
                          label='limpar'
                          onClick={() => changeField(null, 'changeFormCreateSearchVoterGenre')}
                        />
                      )}
                    </Box>

                    <Select
                      options={selectGenreOptions}
                      value={genre ?? ''}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      onChange={({ value: nextValue }) => changeField(nextValue, 'changeFormCreateSearchVoterGenre')}
                      emptySearchMessage='Selecione uma opção'
                    />
                  </Box>

                  <Box margin={{ vertical: 'small', right: 'small' }} width='large'>
                    <Box direction='row' align='center'>
                      <TitleField text={'Estado civil'} />

                      {maritalStatusId && (
                        <Anchor
                          margin={{ horizontal: 'xxsmall' }}
                          size='xsmall'
                          label='limpar'
                          onClick={() => changeField(null, 'changeFormCreateSearchVoterMaritalStatus')}
                        />
                      )}
                    </Box>

                    <SelectSearch
                      options={selectMaritalStatusOptions}
                      value={maritalStatusId ?? ''}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      onChange={({ value: nextValue }) => changeField(nextValue, 'changeFormCreateSearchVoterMaritalStatus') }
                      emptySearchMessage='Selecione uma opção'
                    />
                  </Box>

                  <Box margin={{ vertical: 'small' }} width='large'>
                    <Box direction='row' align='center'>
                      <TitleField text={'Profissão'} />

                      {professionId && (
                        <Anchor
                          margin={{ horizontal: 'xxsmall' }}
                          size='xsmall'
                          label='limpar'
                          onClick={() => changeField(null, 'changeFormCreateSearchVoterProfession')}
                        />
                      )}
                    </Box>

                    <SelectSearch
                      options={selectOccupationOptions}
                      value={professionId ?? ''}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      onChange={({ value: nextValue }) => changeField(nextValue, 'changeFormCreateSearchVoterProfession') }
                      emptySearchMessage='Selecione uma opção'
                    />
                  </Box>
                </Box>

                <Box direction='row'>
                  <Box margin={{ vertical: 'small', right: 'small' }} width='medium'>
                    <Box direction='row' align='center'>
                      <TitleField text={'Cidade'} />

                      {cityId && (
                        <Anchor
                          margin={{ horizontal: 'xxsmall' }}
                          size='xsmall'
                          label='limpar'
                          onClick={() => {
                              changeField(null, 'changeFormCreateSearchVoterCity');
                              changeField(null, 'changeFormCreateSearchVoterDistricts');
                            }
                          }
                        />
                      )}
                    </Box>

                    <Select
                      searchPlaceholder='Digite para buscar...'
                      emptySearchMessage='Nenhum resultado encontrado.'
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      dropHeight='small'
                      closeOnChange
                      value={cityId ?? ''}
                      options={getCityOptions}
                      onChange={({ value: nextValue }) => changeField(nextValue, 'changeFormCreateSearchVoterCity')}
                      onSearch={text => this.onSearchCities(text)}
                      icon={
                        getCityOptionsLoad ? (
                          <Spinning size='medium' color='brand' kind='pulse' />
                        ) : (
                          true
                        )
                      }
                    />
                  </Box>

                  <Box margin={{ vertical: 'small' }} width='large'>
                    <Box direction='row' align='center'>
                      <TitleField text={'Bairro'} />

                      {districtsId && (
                        <Anchor
                          margin={{ horizontal: 'xxsmall' }}
                          size='xsmall'
                          label='limpar'
                          onClick={() => changeField(null, 'changeFormCreateSearchVoterDistricts')}
                        />
                      )}
                    </Box>

                    <Select
                      searchPlaceholder={cityId ? 'Digite para buscar...' : 'Selecione uma cidade antes...'}
                      emptySearchMessage={cityId ? 'Nenhum resultado encontrado.' : 'Você ainda não selecionou uma cidade...'}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      dropHeight='small'
                      closeOnChange={false}
                      value={districtsId ?? ''}
                      options={getDistrictOptions}
                      onChange={({ value: nextValue }) => changeField(nextValue, 'changeFormCreateSearchVoterDistricts')}
                      onSearch={text => this.onSearchDistricts(text)}
                      multiple
                      icon={
                        getDistrictOptionsLoad ? (
                          <Spinning size='medium' color='brand' kind='pulse' />
                        ) : (
                          true
                        )
                      }
                      valueLabel={
                        districtsId && districtsId.length > 1 ? (
                          <Box pad='small'>
                            <Text>{`${districtsId.length} selecionado(s)`}</Text>
                          </Box>
                        ) : null
                      }
                    />
                  </Box>
                </Box>

                <Box margin={{ vertical: 'small' }}>
                  <Box direction='row' align='center'>
                    <TitleField text={'Grupo'} />

                    {groupsId && groupsId.length > 0 && (
                      <Anchor
                        margin={{ horizontal: 'xxsmall' }}
                        size='xsmall'
                        label='limpar'
                        onClick={() => changeField(null, 'changeFormCreateSearchVoterGroups')}
                      />
                    )}
                  </Box>

                  <Select
                    options={getGroupOptions}
                    value={groupsId ?? ''}
                    labelKey='name'
                    valueKey={{ key: 'id', reduce: true }}
                    closeOnChange={false}
                    onChange={({ value: nextValue }) => changeField(nextValue, 'changeFormCreateSearchVoterGroups') }
                    emptySearchMessage='Selecione uma opção'
                    multiple
                    valueLabel={
                      groupsId && groupsId.length > 1 ? (
                        <Box pad='small'>
                          <Text>{`${groupsId.length} selecionado(s)`}</Text>
                        </Box>
                      ) : null
                    }
                  />
                </Box>
              </Box>

              {/* coluna 2 - recipients */}
              <Box
                margin={{
                  bottom: 'small',
                  left: size !== 'small' && size !== 'medium' ? 'large' : 'none'
                }}
                width={
                  size !== 'small' &&
                  size !== 'medium' ? '40vw' : '100vw'
                }
              >
                <CostCampaign
                  loading={getCostLoading}
                  totalVoters={totalVoters}
                  costUnit={costUnit}
                  costTotal={costTotal}
                  costBankSlip={costBankSlip}
                />
                <ErrorForm errorsList={postDataError.errors} fieldName='voters' />
              </Box>
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ campaignsReducer: { formCreate, selectOptions, cost }, selectOptionsReducer }) => ({
  type: formCreate.type,
  searchVoters: formCreate.searchVoters,
  postDataError: formCreate.postDataError,

  totalVoters: cost.totalVoters,
  costUnit: cost.costUnit,
  costTotal: cost.costTotal,
  costBankSlip: cost.costBankSlip,
  getCostLoading: cost.getCostLoading,
  getGroupOptions: selectOptionsReducer.groupsOptions,
  getCityOptions: selectOptions.getCityOptions,
  getDistrictOptions: selectOptions.getDistrictOptions,

  getCityOptionsLoad: selectOptions.getCityOptionsLoad,
  getDistrictOptionsLoad: selectOptions.getDistrictOptionsLoad,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    changeField,
    searchCityOptions,
    searchDistrictOptions,
    searchVotersList,
    clearListVoters,
    clearCost,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Recipients);
