import TYPES from '../actions/types';
import paginationDefault from '../../../constants/pagination';

const INITIAL_STATE = {
  page: paginationDefault.page,
  limit: paginationDefault.limit,
}

export default function(state = INITIAL_STATE, action) {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    case TYPES.changeFormSearchField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.initializeValueFormSearch:
      return {
        ...state,
        ...action.payload,
      };

    case TYPES.setDefaultPage:
      return {
        ...state,
        page: paginationDefault.page,
        limit: paginationDefault.limit,
      };

    case TYPES.paginationFormSearch:
      return { ...state, page: action.payload };

    default:
      return state;
  }
}
