const updateRow = ({ object, key, items }) => {
  const {
    subject,
    startDate,
    endDate,
  } = object;

  return items.map(item =>
    item.id === key ? { ...item, subject, startDate, endDate } : item
  );
}

export default updateRow;
