import TYPES from '../actions/types';

const INITIAL_STATE = {
  getCityOptions: [],
  getDistrictOptions: [],

  getCityOptionsLoad: false,
  getDistrictOptionsLoad: false,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TYPES.getCityOptions:
      return { ...state, getCityOptions: action.payload };

    case TYPES.getDistrictOptions:
      return { ...state, getDistrictOptions: action.payload };



    // load
    case TYPES.getCityOptionsLoad:
      return { ...state, getCityOptionsLoad: !state.getCityOptionsLoad };

    case TYPES.getDistrictOptionsLoad:
      return { ...state, getDistrictOptionsLoad: !state.getDistrictOptionsLoad };

    default:
      return state;
  }
}
