import { details } from '../../../api/common';
import { printOut } from '../components/print/print';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';

const _command = params =>
  async dispatch => {
    try
    {
      dispatch(
        showNotificationLayer('Gerando arquivo. Aguarde...')
      );

      const docDetails = await details(params.id, 'legislative.processe.php');

      printOut({
        data: docDetails.data,
        fileName: `${docDetails.data.typeName}-${docDetails.data.number}-${docDetails.data.year}.html`,
      });
    }
    catch(error)
    {
      dispatch(
        showNotificationLayer('Ops... não conseguimos gerar o arquivo.')
      );
    }
  }

export const generatePrint = params => {
  //ga
  analytics.registerEvent({
    category: analytics.categories.legislative,
    action: analytics.actions.legislativePrint,
  });

  return _command(params);
}
