import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = props => {
  const { value, onChange } = props;

  return (
    <Editor
      apiKey='zzg013bovz1q4tydtndhpvwjq35fc23zuscj2upbfes9v50b'
      value={value}
      onEditorChange={onChange}
      //initialValue='<p>This is the initial content of the editor.</p>'
      init={{
        // https://www.tiny.cloud/docs-4x/configure/editor-appearance/
        height: 500,
        //menubar: true,
        menubar: 'edit insert format table',
        statusbar: false,
        fontsize_formats: "8pt 10pt 12pt 14pt 18pt 20pt 24pt 26pt 36pt",
        menu: {
          edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall'},
          insert: { title: 'Insert', items: 'link media | template hr'},
          format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats'},
          table: { title: 'Table', items: 'inserttable tableprops deletetable | cell row column'},
        },
        plugins: [
          'table lists hr'
          //'advlist autolink lists link image charmap print preview anchor',
          //'searchreplace visualblocks code',
          //'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'fontselect | fontsizeselect | ' +
        'bold italic underline | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | removeformat',
        content_style: 'body { font-family: Times New Roman,sans-serif; font-size: 14pt }',
        language: 'pt_BR',

      }}
    />
  )

  /*return (
    <FroalaEditor
      tag='textarea'
      config={{
        placeholderText: 'Edit Your Content Here!',
        charCounterCount: false
      }}
      //model={this.state.model}
      //onModelChange={this.handleModelChange}
    />
  );*/

  /*return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      tabIndex={1} // tabIndex of textarea
      onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
      //onChange={newContent => {}}
    />
  );*/
}

export default TextEditor;
