import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Details from '../components/details/details';
import FormSearch from '../components/form-search/form-search';
import FormDelete from '../components/form-delete/form-delete';
import FormGenerateTag from '../components/form-generate-tag/form-generate-tag';
import FormCreateService from '../components/form-create-service/form-create-service';
import FormReminder from '../components/form-reminder/form-reminder';
import FormShortMessage from '../components/form-short-message/form-short-message';
import SearchResult from '../components/search-result/search-result';
import Communication from '../components/communication/communication';
import Header from '../../../components/header/header';

import { searchVoters, setDefaultPageRules } from '../actions/form-search';
import { getTagOptions } from '../actions/select-options';
import {
  getOfficeOptions,
  getStatusServiceOptions,
  getOriginsServiceOptions,
  getCategoriesServiceOptions,
  getUsersOptions,
  getGroupsOptions,
} from '../../../actions/select-options';

import { formattedDateTime } from '../../../utils';

import paginationDefault from '../../../constants/pagination';

class Voters extends Component {
  componentDidMount() {
    const {
      getGroupsOptions,
      getTagOptions,

      getStatusServiceOptions,
      getOriginsServiceOptions,
      getCategoriesServiceOptions,
      getOfficeOptions,
      getUsersOptions,

      searchVoters,
      setDefaultPageRules,

      reference,
      individualRegistration,
      name,
      genre,
      cityId,
      districtsId,
      address,
      zipcode,
      status,
      groupsId,
      maritalStatusId,
      professionId,
      withTelephone,
      withEmail,
      telephone,
      email,
      startBirthDate,
      endBirthDate,
      voteStatusId,
      responsibleId,
      responsibleName,
      schoolId,
      startCreatedDate,
      endCreatedDate,
    } = this.props;

    // voter
    getGroupsOptions();
    getTagOptions();

    // service - status, users, category, office, origin
    getStatusServiceOptions();
    getOriginsServiceOptions();
    getCategoriesServiceOptions();
    getOfficeOptions();
    getUsersOptions();

    setDefaultPageRules();

    searchVoters({
      reference,
      individualRegistration,
      name,
      genre,
      cityId,
      districtsId,
      address,
      zipcode,
      status,
      groupsId,
      maritalStatusId,
      professionId,
      withTelephone,
      withEmail,
      telephone,
      email,
      startBirthDate,
      endBirthDate,
      voteStatusId,
      responsibleId,
      responsibleName,
      schoolId,
      startCreatedDate: formattedDateTime(startCreatedDate),
      endCreatedDate: formattedDateTime(endCreatedDate),
      page: paginationDefault.page,
      limit: paginationDefault.limit,
    });
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <FormSearch />
        <FormDelete />
        <FormGenerateTag />
        <FormCreateService />
        <FormReminder />
        <Details />
        <FormShortMessage />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ votersReducer: { formSearch }, authReducer: { authenticated } }) => ({
  userLogged: authenticated,

  reference: formSearch.reference,
  individualRegistration: formSearch.individualRegistration,
  name: formSearch.name,
  genre: formSearch.genre,
  cityId: formSearch.cityId,
  districtsId: formSearch.districtsId,
  address: formSearch.address,
  zipcode: formSearch.zipcode,
  status: formSearch.status,
  groupsId: formSearch.groupsId,
  maritalStatusId: formSearch.maritalStatusId,
  professionId: formSearch.professionId,
  withTelephone: formSearch.withTelephone,
  withEmail: formSearch.withEmail,
  telephone: formSearch.telephone,
  email: formSearch.email,
  startBirthDate: formSearch.startBirthDate,
  endBirthDate: formSearch.endBirthDate,
  startCreatedDate: formSearch.startCreatedDate,
  endCreatedDate: formSearch.endCreatedDate,
  voteStatusId: formSearch.voteStatusId,
  responsibleId: formSearch.responsibleId,
  responsibleName: formSearch.responsibleName,
  schoolId: formSearch.schoolId,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getGroupsOptions,
    getTagOptions,

    getStatusServiceOptions,
    getOriginsServiceOptions,
    getCategoriesServiceOptions,
    getOfficeOptions,
    getUsersOptions,

    searchVoters,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Voters);
