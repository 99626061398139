import TYPES from '../actions/types';
import paginationDefault from '../../../constants/pagination';

const INITIAL_STATE = {
  reference: null,
  categoryId: null,
  statusId: null,
  startDateExpense: null,
  endDateExpense: null,
  recipient: null,
  isRefund: null,

  page: paginationDefault.page,
  limit: paginationDefault.limit,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.changeFormSearchField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.setDefaultPage:
      return {
        ...state,
        page: paginationDefault.page,
        limit: paginationDefault.limit,
      };

    case TYPES.clearFormSearchFields:
      return {
        ...state,
        reference: null,
        categoryId: null,
        statusId: null,
        startDateExpense: null,
        endDateExpense: null,
        recipient: null,
        isRefund: null,
      }

    case TYPES.paginationFormSearch:
      return { ...state, page: action.payload };

    default:
      return state;
  }
}
