import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Anchor, Text, Box, RadioButtonGroup } from 'grommet';
import { FormClose, Connect, Calendar, Robot, Clock, Checkmark } from 'grommet-icons';

import { ErrorForm } from '../../../../components';
import { TitleField } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { create, hideFormManagerLayer } from '../../actions/form-manager';
import { findPendingCalendars } from '../../actions/find-calendars';
import { changeValues } from '../../../../actions/form';

import { encode64 } from '../../../../utils';

import visibleWidth from '../../../../constants/layer-width';

const FormCreate = (props) => {
  const steps = {
    consent: 'consent',
    findCalendars: 'findCalendars',
    linkUserSystem: 'linkUserSystem',
    finish: 'finish',
  };

  const [integrationStep, setIntegrationStep] = useState(steps.consent);

  const handlePost = useCallback(formParams => {
    const { create } = props;
    create({ formParams });
  }, [props]);


  const openConsentPrompt = useCallback(() => {
    const { userLogged } = props;

    const {
      REACT_APP_GOOGLE_CLIENT_ID,
      REACT_APP_GOOGLE_CONSENT_CALLBACK,
    } = process.env;

    const state = encode64(
      JSON.stringify({
        clientId: userLogged?.user?.clientId,
      })
    );

    window.open(
      `https://accounts.google.com/o/oauth2/v2/auth?client_id=${REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${REACT_APP_GOOGLE_CONSENT_CALLBACK}&scope=https://www.googleapis.com/auth/calendar&response_type=code&state=${state}&access_type=offline&prompt=consent`,
      '_blank'
    );
  }, [props]);

  useEffect(() => {
    setIntegrationStep('consent');
  }, [props.toggleLayer]);


  const {
    calendars,
    errorCalendars,

    calendarId,
    chosenUserId,

    toggleLayer,
    loadingPostForm,
    postDataError,

    changeValues,
    hideFormManagerLayer,
    findPendingCalendars,

    usersOptions,
  } = props;


  if(!toggleLayer || toggleLayer !== 'manager')
    return null;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Layer
          responsive={false}
          full='vertical'
          position='left'
          onClickOutside={hideFormManagerLayer}
          onEsc={hideFormManagerLayer}
        >
          <Box
            fill='vertical'
            width={visibleWidth[size]}
            pad={{ top: 'large', bottom: 'xsmall' }}
            overflow='auto'
            flex
          >
            <>
              <Box
                direction='row'
                justify='between'
                align='baseline'
                margin={{ horizontal: 'large' }}
              >
                <TitleDetail value='Adicionar integração Google Agenda' />

                <Button
                  plain
                  title='Fechar'
                  icon={<FormClose />}
                  onClick={hideFormManagerLayer}
                />
              </Box>


              <Box flex overflow='auto'>
                {([steps.consent, steps.findCalendars, steps.linkUserSystem].includes(integrationStep)) && (
                  <>
                    {/* Sobre */}
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Text color='brand' size='medium'>Sobre a integração</Text>

                      <Text color='dark-2' size='small'>
                        Com a integração, os compromissos cadastrados no sistema são automaticamente cadastrados na
                        agenda do Google da conta vinculada.
                      </Text>
                    </Box>


                    {/* Beneficios */}
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Text color='brand' size='medium'>Benefícios</Text>

                      <Box
                        direction='row'
                        gap='small'
                        align='baseline'
                      >
                        <Connect size='16em' color='dark-1' />

                        <Text size='small' color='dark-2'>
                          Integração rápida e segura.
                        </Text>
                      </Box>

                      <Box
                        direction='row'
                        gap='small'
                        align='baseline'
                      >
                        <Robot size='16em' color='dark-1' />

                        <Text size='small' color='dark-2'>
                          Envio automatico para a agenda do Google.
                        </Text>
                      </Box>

                      <Box
                        direction='row'
                        gap='small'
                        align='baseline'
                      >
                        <Calendar size='16em' color='dark-1' />

                        <Text size='small' color='dark-2'>
                          Integre com mais de uma conta Google.
                        </Text>
                      </Box>
                    </Box>


                    {/* Passo a passo */}
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Text color='brand' size='medium'>Passo a passo</Text>

                      {/* Primeiro passo */}
                      <Box
                        round={ size === 'small' ? 'small' : 'xsmall'}
                        border={{
                          side: 'all',
                          color: 'light-4',
                        }}
                        pad={{
                          vertical: size === 'small' ? 'medium' : 'small',
                          horizontal: 'medium',
                        }}
                        align='center'
                        direction='row'
                        justify='between'
                      >
                        <Box
                          align='center'
                          direction='row'
                          gap='small'
                        >
                          {
                            integrationStep === steps.consent ?
                              <Clock size='16em' color='dark-1' /> :
                              <Checkmark size='16em' color='status-ok' />
                          }

                          <Text color='dark-2' size='small'>
                            Informe em qual conta do Google os compromissos serão criados.
                          </Text>
                        </Box>

                        <Box>
                          <Button
                            color='light-6'
                            primary
                            label={ <Text size='small'>Escolher conta</Text> }
                            disabled={
                              integrationStep === steps.findCalendars || integrationStep === steps.linkUserSystem
                            }
                            onClick={() => {
                              openConsentPrompt();
                              setIntegrationStep(steps.findCalendars);
                            }}
                          />
                        </Box>
                      </Box>

                      {/* Segundo passo */}
                      <Box
                        round={ size === 'small' ? 'small' : 'xsmall'}
                        border={{
                          side: 'all',
                          color: 'light-4',
                        }}
                        pad={{
                          vertical: size === 'small' ? 'medium' : 'small',
                          horizontal: 'medium',
                        }}
                        align='center'
                        direction='row'
                        justify='between'
                      >
                        <Box
                          align='center'
                          direction='row'
                          gap='small'
                        >
                          {
                            errorCalendars ? <FormClose size='16em' color='status-error' /> : (
                              !errorCalendars && calendars.length > 0 ? <Checkmark size='16em' color='status-ok' /> : <Clock size='16em' color='dark-1' />
                            )
                          }

                          <Text color='dark-2' size='small'>
                            Buscar agendas da conta vinculada.
                          </Text>
                        </Box>

                        <Box>
                          <Button
                            color='light-6'
                            primary
                            label={ <Text size='small'>Buscar agendas</Text> }
                            disabled={
                              integrationStep === steps.consent || integrationStep === steps.linkUserSystem
                            }
                            onClick={() => {
                              findPendingCalendars();
                              setIntegrationStep(steps.linkUserSystem);
                            }}
                          />
                        </Box>
                      </Box>


                      {/* Terceiro passo */}
                      <Box
                        round={ size === 'small' ? 'small' : 'xsmall'}
                        border={{
                          side: 'all',
                          color: 'light-4',
                        }}
                        pad={{
                          vertical: size === 'small' ? 'medium' : 'small',
                          horizontal: 'medium',
                        }}
                        align='center'
                        direction='row'
                        justify='between'
                      >
                        <Box
                          align='center'
                          direction='row'
                          gap='small'
                        >
                          {
                            integrationStep !== steps.finish ?
                              <Clock size='16em' color='dark-1' /> :
                              <Checkmark size='16em' color='status-ok' />
                          }

                          <Text color='dark-2' size='small'>
                            Vincular agenda com usuário do sistema.
                          </Text>
                        </Box>

                        <Box>
                          <Button
                            color='light-6'
                            primary
                            disabled={ calendars.length === 0 }
                            label={ <Text size='small'>Vincular com usuário do sistema</Text> }
                            onClick={() => {
                              setIntegrationStep(steps.finish);
                            }}
                          />
                        </Box>
                      </Box>

                    </Box>
                  </>
                )}


                {integrationStep === steps.finish && (
                  <>
                    <Box
                      margin={{
                        vertical: size === 'small' ? 'medium' : 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Selecione o calendário' required />

                      <Box
                        round='xxsmall'
                        pad={{
                          vertical: 'small',
                        }}
                        gap='xsmall'
                      >
                        <RadioButtonGroup
                          name='calendarId'
                          value={calendarId ?? ''}
                          options={
                            calendars.map(({ id }) => ({
                              label: id,
                              id: id,
                              value: id,
                            }))
                          }
                          onChange={event => {
                            changeValues({ fieldName: 'calendarId', value: event.target.value }, 'formChangeValues')
                          }}
                        >
                          {(option, { checked }) => {
                            let background = null;
                            let borderColor = 'dark-5';

                            if(checked) {
                              background = 'brand';
                              borderColor = 'brand';
                            }

                            return (
                              <Box
                                direction='row'
                                gap='small'
                                justify='center'
                                align='center'
                              >
                                <Box
                                  width={'20px'}
                                  height={'20px'}
                                  background={background}
                                  round='xxsmall'
                                  border={{
                                    color: borderColor
                                  }}
                                />
                                <Text color='dark-2' size='small' truncate>{option.label}</Text>
                              </Box>
                            )
                          }}
                        </RadioButtonGroup>
                      </Box>

                      <ErrorForm errorsList={postDataError.errors} fieldName='calendarId' />
                    </Box>

                    <Box
                      margin={{
                        vertical: size === 'small' ? 'medium' : 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Selecione o usuário do sistema' required />

                      <Box
                        round='xxsmall'
                        pad={{
                          vertical: 'small',
                        }}
                        gap='xsmall'
                      >
                        <RadioButtonGroup
                          name='chosenUserId'
                          value={chosenUserId ?? ''}
                          options={
                            usersOptions.filter(a => a.status).map(({ id, name }) => ({
                              label: name,
                              id: id.toString(),
                              value: id.toString(),
                            }))
                          }
                          onChange={event => {
                            changeValues({ fieldName: 'chosenUserId', value: event.target.value }, 'formChangeValues')
                          }}
                        >
                          {(option, { checked }) => {
                            let background = null;
                            let borderColor = 'dark-5';

                            if(checked) {
                              background = 'brand';
                              borderColor = 'brand';
                            }

                            return (
                              <Box
                                direction='row'
                                gap='small'
                                justify='center'
                                align='center'
                              >
                                <Box
                                  width={'20px'}
                                  height={'20px'}
                                  background={background}
                                  round='xxsmall'
                                  border={{
                                    color: borderColor
                                  }}
                                />
                                <Text color='dark-2' size='small'>{option.label}</Text>
                              </Box>
                            )
                          }}
                        </RadioButtonGroup>
                      </Box>

                      <ErrorForm errorsList={postDataError.errors} fieldName='chosenUserId' />
                    </Box>
                  </>
                )}
              </Box>

              <Box
                direction='row'
                pad={{ vertical: 'medium' }}
                justify='center'
                align='center'
                gap='small'
              >
                <Box width='xsmall'>
                  <Anchor
                    color='dark-3'
                    label='Cancelar'
                    disabled={ loadingPostForm }
                    onClick={hideFormManagerLayer}
                  />
                </Box>

                <Box width='small'>
                  <Button
                    color='brand'
                    primary
                    label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                    disabled={ loadingPostForm || integrationStep !== steps.finish }
                    onClick={() =>
                      handlePost({
                        calendarId,
                        chosenUserId,
                      })
                    }
                  />
                </Box>


              </Box>
            </>
          </Box>
        </Layer>
      )}
    </ResponsiveContext.Consumer>
  );
}

const mapStateToProps = ({
  layerReducer,
  formCreateUpdateCopyGeneric,
  selectOptionsReducer,
  settingsIntegrationCalendarReducer: { findCalendars },
  authReducer: { authenticated }
}) => ({
  userLogged: authenticated,

  usersOptions: selectOptionsReducer.usersOptions,

  calendarId: formCreateUpdateCopyGeneric.calendarId,
  chosenUserId: formCreateUpdateCopyGeneric.chosenUserId,

  calendars: findCalendars.list,
  loadingCalendars: findCalendars.loading,
  errorCalendars: findCalendars.error,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    create,
    changeValues,
    hideFormManagerLayer,

    findPendingCalendars,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);
