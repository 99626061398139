import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextInput, Box, Select, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';

import { TitleField } from '../../../../components/form';
import { Hr, TitleDetail } from '../../../../components/detail';

import { searchManifestations, clearFieldsFormSearch, hideLayer, setDefaultPageRules, changeField } from '../../actions/form-search';

import paginationDefault from '../../../../constants/pagination';
import visibleWidth from '../../../../constants/layer-width';

import selectManifestationStatusOptions from '../../../../constants/select-manifestation-status';

class FormSearch extends Component {
  searchAndSetPagination = formParams => {
    const { searchManifestations, hideLayer, setDefaultPageRules } = this.props;

    setDefaultPageRules();
    searchManifestations(formParams);
    hideLayer();
  };

  render() {
    const {
      protocolNumber,
      name,
      email,
      telephone,
      statusId,
      toggleLayer,

      hideLayer,
      clearFieldsFormSearch,
      changeField,
    } = this.props;

    if(toggleLayer && toggleLayer === 'search') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideLayer}
              onEsc={hideLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Filtrar' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={hideLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  {/* Protocolo */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Número protocolo' />

                    <TextInput
                      value={protocolNumber ?? ''}
                      onChange={event =>
                        changeField({ fieldName: 'protocolNumber', value: event.target.value })
                      }
                    />
                  </Box>

                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Status' />

                      {statusId && (
                        <Anchor
                          size='small'
                          label='limpar'
                          onClick={() => changeField({ fieldName: 'statusId', value: null })}
                        />
                      )}
                    </Box>

                    <Select
                      options={selectManifestationStatusOptions}
                      value={statusId ?? ''}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      onChange={({ value: nextValue }) =>
                        changeField({ fieldName: 'statusId', value: nextValue })
                      }
                    />
                  </Box>


                  <Hr />

                  {/* Nome */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Nome' />

                    <TextInput
                      value={name ?? ''}
                      onChange={event =>
                        changeField({ fieldName: 'name', value: event.target.value })
                      }
                    />
                  </Box>

                  {/* Email/telefone */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='large'>
                        <TitleField text='E-mail' />

                        <TextInput
                          value={email ?? ''}
                          onChange={event => changeField({ fieldName: 'email', value: event.target.value })}
                        />
                      </Box>

                      <Box width='large'>
                        <TitleField text='Telefone' />

                        <TextInput
                          value={telephone ?? ''}
                          onChange={event => changeField({ fieldName: 'telephone', value: event.target.value })}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  margin={{ horizontal: 'large' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='small'>
                    <Button
                      color='dark-3'
                      label='Limpar'
                      onClick={() => clearFieldsFormSearch()}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label='Buscar'
                      onClick={() =>
                        this.searchAndSetPagination({
                          protocolNumber,
                          name,
                          email,
                          telephone,
                          statusId,
                          page: paginationDefault.page,
                          limit: paginationDefault.limit,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ manifestationReducer: { formSearch }, layerReducer }) => ({
  protocolNumber: formSearch.protocolNumber,
  name: formSearch.name,
  email: formSearch.email,
  telephone: formSearch.telephone,
  statusId: formSearch.statusId,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    searchManifestations,
    clearFieldsFormSearch,
    setDefaultPageRules,
    hideLayer,
    changeField,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
