import TYPES from '../actions/types';
import { formatDataFormArchive } from '../utils';

const INITIAL_STATE = {
  id: null,
  file: null,

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.setDataFormArchive:
      return {
        ...state,
        ...formatDataFormArchive(action.payload),

        file: null,

        loadingPostForm: false,
        postDataError: {
          message: '',
          errors: []
        },
      }

    case TYPES.setPreviewFormArchive:
      return {
        ...state,
        file: action.payload
      }

    case TYPES.loadingFormArchive:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formArchiveError:
      return { ...state, postDataError: action.payload };

    default:
      return state;
  }
}
