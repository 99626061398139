import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { Trash, AddCircle } from 'grommet-icons';

import { Placeholder, ErrorsSearches } from '../../../../components';
import { toggleFormDeleteLayer } from '../../actions/form-delete';
import { showFormManagerLayer } from '../../actions/form-manager';

const SearchResult = (props) => {
  const {
    loadingFormSearch,
    dataRows,
    getListError,

    toggleFormDeleteLayer,
    showFormManagerLayer,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches />;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '100px' }}
          >
            <Box
              pad='small'
              width='xlarge'
              gap='medium'
            >
              {/* Cabeçalho */}
              <Box
                direction='row'
                gap='xsmall'
                align='baseline'
              >
                <Text size='large' color='dark-5'>Configure o status dos</Text>
                <Text size='xlarge' color='dark-1'>atendimentos.</Text>
              </Box>

              {/* Conteúdo */}
              <Box
                gap='medium'
                direction={ size === 'small' || size === 'medium' ? 'column' : 'row' }
                justify='between'
              >
                {/* Primeira coluna */}
                <Box
                  width={
                    size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge'
                  }
                  gap='medium'
                >
                  <Text color='dark-2' size='small'>
                    Os atendimentos são os pedidos feitos pelos clientes.
                    Configurar o status garante que você saiba em que etapa está cada atendimento.
                  </Text>

                  <Box
                    direction='row'
                    gap={ size === 'small' ? 'large' : 'medium' }
                    margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                  >
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <AddCircle size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Adicione e edite
                      </Text>

                      <Text size='small' color='dark-2'>
                        Adicione e altere quando precisar. É fácil e rápido!
                      </Text>
                    </Box>

                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <Trash size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Remova
                      </Text>

                      <Text size='small' color='dark-2'>
                        Errou no cadastro ou deixou de usar, você pode excluir.
                      </Text>
                    </Box>
                  </Box>

                  <Button
                    primary
                    label='Adicionar status'
                    color='neutral-1'
                    onClick={() => showFormManagerLayer() }
                  />
                </Box>

                {/* Separador coluna */}
                <Box
                  width={ size === 'large' || size === 'xlarge' ? 'xsmall' : 'xxsmall'}
                />

                {/* Segunda coluna */}
                <Box
                  gap='small'
                  width={ size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge' }
                >
                  <Box
                    round={ size === 'small' ? 'small' : 'xsmall' }
                    border={{ color: 'light-4' }}
                    background='white'
                    justify='between'
                  >
                    {dataRows.map((row, i) => (
                        <Box
                          key={row.id}
                          align='center'
                          direction='row'
                          pad={{
                            horizontal: size === 'small' ? 'large' : 'medium',
                            vertical: size === 'small' ? 'medium' : 'xsmall'
                          }}
                          border={{ color: 'light-3', side: 'bottom' }}
                          justify='between'
                          gap='small'
                        >
                          <Box
                            width='medium'
                          >
                            <Text color='dark-2' size='small' truncate>{row.name}</Text>

                            <Text
                              color='dark-4'
                              size='xsmall'
                            >
                              {row.serviceUsing > 0 ?
                                  <>Usando em {row.serviceUsing} atendimento(s)</>
                                : <>Não é utilizada ainda</>
                              }
                            </Text>
                          </Box>

                          <Box
                            direction='row'
                            align='center'
                            gap='small'
                          >
                            <Button
                              size='small'
                              label='Editar'
                              color='light-5'
                              onClick={() => showFormManagerLayer(row) }
                            />

                            <Button
                              title={row.serviceUsing ? 'Remova os atendimentos vinculados antes de excluir' : 'Excluir'}
                              disabled={!!row.serviceUsing}
                              icon={<Trash color='status-error' size='medium' />}
                              onClick={() => { toggleFormDeleteLayer(row) }}
                            />
                          </Box>
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ settingsServiceStatusReducer: { searchResult } }) => ({
  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleFormDeleteLayer,
    showFormManagerLayer,
  }, dispath)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResult)
);
