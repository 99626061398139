const updateRow = ({ object, key, items }) => {
  const {
    dateExpense,
    categoryName,
    value,
    isRefund,
    statusName,
  } = object;

  return items.map(item =>
    item.id === key ? { ...item, dateExpense, categoryName, value, isRefund, statusName } : item
  );
}

export default updateRow;
