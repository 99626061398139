import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Anchor, TextInput, Button } from 'grommet';
import { AddCircle, Close } from 'grommet-icons';

import { addRelative, removeRelative } from '../../actions/form-create';

import { getVotersOptions } from '../../../../actions/select-options';
import selectKinshipOptions from '../../../../constants/select-kinship-options';

function Relatives(props) {
  const [name, setName] = useState('');
  const [kinshipType, setKinshipType] = useState('');

  const [voterId, setId] = useState(0);
  const [kinshipTypeId, setkinshipTypeId] = useState(0);

  const {
    relatives,
    votersOptions,
  } = props;

  const removeKinship = (relativeObj) => {
    const { removeRelative } = props;
    removeRelative(relativeObj);
  }

  const addKinship = () => {
    const { addRelative } = props;

    addRelative({
      kinshipName: kinshipType,
      electorName: name,

      kinship: kinshipTypeId,
      electorId: voterId,
    });

    setName('');
    setKinshipType('');

    setId(0);
    setkinshipTypeId(0);
  }

  const onSearchVoters = text => {
    if (text.length < 2) return;

    const { getVotersOptions } = props;
    getVotersOptions({ name: text });
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box margin={{ bottom: 'medium' }}>
            <Text size='large'>Família</Text>
          </Box>

          <Box
            direction={size !== 'small' && size !== 'medium' ? 'row' : 'column-reverse'}
          >
            {/* coluna 1 - relatives */}
            <Box
              margin={{ right: 'large' }}
              width={size !== 'small' && size !== 'medium' ? '50vw' : '100vw'}
            >
              <Box
                round='xsmall'
                border={{ color: 'light-5' }}
                background='white'
              >
                {/* fields */}
                <Box
                  direction='row'
                  border={{ color: 'light-5', side: 'bottom' }}
                  pad={{
                    vertical: 'xsmall',
                    horizontal: 'xsmall',
                  }}
                >
                  <Box width='medium'>
                    <TextInput
                      plain
                      placeholder='pai, filho, ...'
                      value={kinshipType}
                      onChange={event => setKinshipType(event.target.value)}
                      suggestions={
                        selectKinshipOptions.map(({ id, name }) => ({
                          label: (
                            <Box
                              pad='small'
                            >
                              <Text size='small' color='dark-5'>{name}</Text>
                            </Box>
                          ),
                          value: { id, name }
                        }))
                      }
                      onSuggestionSelect={event => {
                        const { id, name } = event.suggestion.value;

                        setkinshipTypeId(id);
                        setKinshipType(name);
                      }}
                    />
                  </Box>

                  <Box width='large'>
                    <TextInput
                      plain
                      placeholder='buscar eleitor'
                      onChange={event => {
                        const { value: newValue } = event.target;

                        setName(newValue);
                        onSearchVoters(newValue);
                      }}
                      value={name}
                      suggestions={
                        votersOptions.map(({ id, name }) => ({
                          label: (
                            <Box
                              pad='small'
                            >
                              <Text size='small' color='dark-5'>{name}</Text>
                            </Box>
                          ),
                          value: { id, name }
                        }))
                      }
                      onSuggestionSelect={event => {
                        const { id, name } = event.suggestion.value;

                        setId(id);
                        setName(name);
                      }}
                    />
                  </Box>

                  <Button
                    icon={<AddCircle color='neutral-1' />}
                    disabled={!voterId || !kinshipTypeId}
                    title={!voterId || !kinshipTypeId ? 'Selecione um eleitor' : 'Adicionar relacionamento'}
                    onClick={() => addKinship()}
                  />
                </Box>

                {/* list */}
                {relatives && relatives.map((item, index) => (
                  <Box
                    key={index}
                    align='center'
                    direction='row'
                    pad={{
                      horizontal: 'small',
                    }}
                    border={{ color: 'light-5', side: 'bottom' }}
                    justify='between'
                  >
                    <Text size='small' color='dark-5'>{item.kinshipName} de {item.electorName}</Text>

                    <Anchor
                      icon={<Close size='small' />}
                      color='status-critical'
                      title='Remover relacionamento'
                      onClick={() => removeKinship(item)}
                    />
                  </Box>
                ))}
              </Box>
            </Box>

            {/* coluna 2 - relatives */}
            <Box
              margin={{
                bottom: 'small',
                left: size !== 'small' && size !== 'medium' ? 'large' : 'none'
              }}
              width={
                size !== 'small' &&
                size !== 'medium' ? '40vw' : '100vw'
              }
            >
              <Text size='small' color='dark-5' margin={{ bottom: 'medium' }}>
                Você pode vincular esse eleitor com outros cadastros.
              </Text>
            </Box>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ votersReducer: { formManager }, selectOptionsReducer }) => ({
  votersOptions: selectOptionsReducer.votersOptions,
  relatives: formManager.relatives,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getVotersOptions,
    addRelative,
    removeRelative,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Relatives);
