const updateRow = ({ object, key, items }) => {
  const {
    status,
    name,
    email,
    profile,
  } = object;

  return items.map(item =>
    item.id === key ? { ...item, status, name, email, profile } : item
  );
}

export default updateRow;
