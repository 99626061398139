export default () => ({
  id: null,
  name: null,
  email: null,
  officeId: null,
  jobRoleId: null,
  profileId: null,
  telephone: null,
  rules: [],
});
