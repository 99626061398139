import { formattedDateTime } from '../../../utils';

export default object => ({
  id: parseInt(object.id),
	name: object.name,
	telephone: object.telephone,
	subject: object.subject,
	date: object.createdAt,
	hour: formattedDateTime(object.createdAt, 'HH:mm'),
	userWantedId: object.userWantedId,
	status: object.statusId.toString(),
});
