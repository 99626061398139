import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Details from '../components/details/details';
import FormSearch from '../components/form-search/form-search';
import FormDelete from '../components/form-delete/form-delete';
import FormChangeStatus from '../components/form-change-status/form-change-status';
import SearchResult from '../components/search-result/search-result';
import Communication from '../components/communication/communication';
import Header from '../../../components/header/header';

import { searchManifestations, setDefaultPageRules } from '../actions/form-search';

import paginationDefault from '../../../constants/pagination';

class Manifestations extends Component {
  componentDidMount() {
    const {
      searchManifestations,
      setDefaultPageRules,

      protocolNumber,
      name,
      email,
      telephone,
      statusId,
    } = this.props;

    setDefaultPageRules();

    searchManifestations({
      protocolNumber,
      name,
      email,
      telephone,
      statusId,
      page: paginationDefault.page,
      limit: paginationDefault.limit,
    });
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <FormSearch />
        <FormDelete />
        <FormChangeStatus />
        <Details />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ manifestationReducer: { formSearch }, authReducer: { authenticated } }) => ({
  userLogged: authenticated,

  protocolNumber: formSearch.protocolNumber,
  name: formSearch.name,
  email: formSearch.email,
  telephone: formSearch.telephone,
  statusId: formSearch.statusId,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    searchManifestations,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Manifestations);
