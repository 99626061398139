import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';
import { Tag } from '../tag/tag';

const TagStatus = ({ title, value, description, color, width = { title: '30vw',  value: '70vw' }}) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          margin={{
            top: size === 'small' ? 'small' : 'xsmall',
            bottom: size === 'small' ? 'xsmall' : 'none',
            horizontal: 'large'
          }}
          pad={{ bottom: 'small' }}
          direction='row'
          justify='between'
          align='center'
          flex={{ shrink: 0 }}
        >
          <Box width={width.title}>
            {title &&
              <Text size='medium' color='dark-2'>
                {title}
              </Text>
            }

            {description &&
              <Text size='xsmall' color='dark-4' textAlign='start'>
                {description}
              </Text>
            }
          </Box>

          <Box
            //width={width.value}
          >
            <Tag text={value} background={color ?? 'status-critical'} />
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default TagStatus;
