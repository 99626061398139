const selectExpenseCategory = [
  { id: 1, name: 'Hospedagem' },
  { id: 2, name: 'Refeição' },
  { id: 3, name: 'Combustível' },
  { id: 4, name: 'Alguel de carro' },
  { id: 5, name: 'Passagem' },
  { id: 6, name: 'Taxi' },
  { id: 7, name: 'App de mobilidade' },
  { id: 8, name: 'Correios' },
  { id: 9, name: 'Frete' },
  { id: 10, name: 'Pegádio' },
  { id: 11, name: 'Estacionamento' },
  { id: 12, name: 'Material de escritório' },
  { id: 13, name: 'Material de limpeza' },
  { id: 14, name: 'Despesas gerais' },
  { id: 15, name: 'Internet' },
  { id: 16, name: 'Copa' },
  { id: 17, name: 'Manutenção' },
  { id: 18, name: 'Outros' },
];

export default selectExpenseCategory;
