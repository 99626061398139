import React from 'react';
import { ResponsiveContext, Box, Text, Anchor } from 'grommet';
import items from './options-menu-user';

function DropUser(props) {
  const {
    userLogged: { user }
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          gap='large'
          direction='row-reverse'
          background='light-1'
          pad={{ vertical: 'large', horizontal: 'large' }}
        >
          <Box gap='xsmall' align='end'>
            <Text
              size='small'
              truncate={true}
            >
              {user.name}
            </Text>

            <Text
              size='small'
              color='dark-3'
              truncate={true}
            >
              {user.email}
            </Text>
          </Box>

          <Box border={{ side: 'right', color: 'light-4' }} />

          <Box gap={ size === 'small' ? 'medium' : 'small' }>
            {items(user.id).map(item => (
              <Anchor
                key={item.label}
                label={item.label}
                href={item.click}
                size='small'
                color='brand'
              />
            ))}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default DropUser;
