const TYPES = ({
  getDetailsError: 'GET_DETAILS_MODULE_DASHBOARD_ERROR',

  getDetailService: 'GET_SERVICE_MODULE_DASHBOARD',
  getDetailVoter: 'GET_VOTER_MODULE_DASHBOARD',
  getDetailVoterDistrict: 'GET_VOTER_DISTRICT_MODULE_DASHBOARD',
  getTotalPendingTask: 'GET_TOTAL_PENDING_TASK_MODULE_DASHBOARD',

  todoSuccess: 'TODO_SUCCESS_MODULE_DASHBOARD',
  todoError: 'TODO_ERROR_MODULE_DASHBOARD',
  todoLoading: 'TODO_LOADING_MODULE_DASHBOARD',
  updateList: 'TODO_UPDATE_LIST_MODULE_DASHBOARD',
});

export default TYPES;
