import TYPES from '../actions/types';
import { formatDataFormEdit } from '../utils';

const INITIAL_STATE = {
  id: null,
  menu: null,
  number: null,
  proceeding: null,
  status: null,
  areaInterest: null,
  type: null,
  year: null,
  authors: [],
  region: null,

  isEditing: false,
  getDataFormEditError: false,

  loadingPostForm: false,
  postDataSuccess: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    case TYPES.changeFormManagerField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.addOrRemoveinList:
      if(action.payload.ac === 'add') {
        return {
          ...state,
          [action.payload.fieldName]: [ ...state[action.payload.fieldName], action.payload.value ],
        }
      }

      return {
        ...state,
        [action.payload.fieldName]: state[action.payload.fieldName].filter(item => item !== action.payload.value)
      }

    case TYPES.loadingFormManagerEdit:
      return {
        ...state,
        isEditing: true,
      }

    case TYPES.loadingFormManagerEditError:
      return {
        ...state,
        getDataFormEditError: true,
      }

    case TYPES.loadDataEdit:
      return {
        ...state,
        ...formatDataFormEdit(action.payload.data),
      }

    case TYPES.clearFormManager:
      return {
        ...state,
        ...INITIAL_STATE,
      }

    case TYPES.loadingPostFormManager:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formManagerPostError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
