import React from 'react';
import { useHistory } from 'react-router-dom';

import { ResponsiveContext, Box, Anchor, Avatar, DropButton } from 'grommet';
import { CircleQuestion } from 'grommet-icons';

import DropHelp from './drop-help';
import DropModule from './drop-modules';
import DropUser from './drop-user';

import urlRoutes from '../../config/url-routes';

const Header = (props) => {
  const history = useHistory();

  const {
    userLogged,
  } = props;

  const ishelp = true;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          pad='xsmall'
          direction={ size === 'small' ? 'column' : 'row' }
          justify='center'
          margin={{
            bottom: size === 'small' ? 'large' : 'medium'
          }}
          background='#2f5878'
          elevation='xsmall'
          width='100vw'
          height='60px'
          style={{
            zIndex: 1,
            position: 'fixed',
            top: 0,
          }}
        >
          <Box
            direction='row'
            align='center'
            width={ size === 'small' ? '100vw' : '95vw' }
            pad={{ horizontal: 'medium' }}
            justify='between'
          >
            <Box
              direction='row'
              align='center'
              gap='medium'
            >
              {size !== 'small' && (
                <Anchor
                  size='medium'
                  label='Gerencia Meu Mandato'
                  color='white'
                  onClick={() => history.push(urlRoutes.dashboardSystemUrl)}
                />
              )}

              <DropModule userLogged={userLogged} />
            </Box>

            <Box
              direction='row'
              align='center'
              gap='medium'
            >
              {ishelp && <DropButton
                plain
                icon={<CircleQuestion />}
                dropAlign={{ top: 'top', right: 'right' }}
                dropContent={<DropHelp />}
              />}

              {userLogged && <DropButton
                plain
                icon={
                  <Avatar
                    size='small'
                    background='dark-2'
                    src={userLogged.user.image}
                  />
                }
                dropAlign={{ top: 'top', right: 'right' }}
                dropContent={<DropUser userLogged={userLogged} />}
              />}
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Header;
