const updateRow = ({ object, key, items }) => {
  const {
    name,
  } = object;

  return items.map(item =>
    item.id === key ? { ...item, name } : item
  );
}

export default updateRow;
