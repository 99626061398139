import TYPES from '../actions/types';

const INITIAL_STATE = {
  list: null,
}

const groupList = (templates) => {
  if(!templates)
    return null;

  return templates.reduce((acc, key) => {
    const groupFind = acc.find(a => a.groupName === key.type);

    if(groupFind) {
      groupFind.listItens.push(key);
    }
    else {
      acc.push({
        groupName: key.type,
        listItens: [key],
      });
    }

    return acc;
  }, []);
}

export default function(state = INITIAL_STATE, action) {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    case TYPES.listTemplarePrepareDoc:
      return {
        ...state,
        list: groupList(action.payload.data),
      }

    default:
      return state;
  }
}
