const TYPES = ({
  generateExcel: 'GENERATE_CSV_MANIFESTATION',

  // dash
  summaryData: 'SUMMARY_MANIFESTATION_DATA',
  summaryRequestError: 'SUMMARY_MANIFESTATION_ERROR',

  // list
  loadingFormSearch: 'LOADING_SEARCH_MANIFESTATION_FORM_TOGGLE',
  searchSuccess: 'SEARCH_MANIFESTATION_SUCCESS',
  searchError: 'SEARCH_MANIFESTATION_LIST_ERROR',

  // change status
  setDataFormStatus: 'SET_DATA_MANIFESTATION_FORM_CHANGE_STATUS',
  changeFormStatusField: 'CHANGE_STATUS_MANIFESTATION_FORM_FIELD',
  loadingFormChangeStatus: 'LOADING_STATUS_MANIFESTATION_FORM_TOGGLE',
  formChangeStatusError: 'POST_STATUS_MANIFESTATION_FORM_ERROR',
  updateItemListing: 'STATUS_MANIFESTATION_SUCCESS_UPDATE_LISTING',

  // form search
  changeFormSearchField: 'CHANGE_SEARCH_MANIFESTATION_FORM_FIELD',
  setDefaultPage: 'SET_SEARCH_MANIFESTATION_DEFAULT_PAGE',
  clearFormSearchFields: 'CLEAR_FIELDS_MANIFESTATION_FORM',
  paginationFormSearch: 'SEARCH_MANIFESTATION_PAGINATION',
});

export default TYPES;
