import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';
import { Spinning } from 'grommet-controls';
import { formattedCurrency } from '../../../../utils';

function CostCampaign(props) {
  const {
    loading,
    totalVoters,
    costUnit,
    costTotal,
    costBankSlip,
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          border={{ color: 'light-4' }}
          height={{ min: '40vh' }}
          round='xsmall'
          pad='medium'
          background='white'
          elevation='xsmall'
          margin={{ vertical: 'xsmall' }}
          justify='center'
        >
          {loading ? (
            <Box
              align='center'
              pad={ size === 'small' ? 'large' : 'small' }
            >
              <Box margin={{ top: 'small', bottom: 'small' }}>
                <Spinning size='medium' color='brand' kind='pulse' />
              </Box>

              <Box margin={{ top: 'none', bottom: 'medium' }}>
                <Text size='medium' color='dark-5' textAlign='center'>
                  Aguarde, estamos buscando os eleitores e o custo dessa campanha.
                </Text>
              </Box>
            </Box>) : null
          }

          {!loading ? (
            <>
              <Box
                border={{ color: 'light-3', side: 'bottom' }}
                margin={{ top: 'xsmall' }}
                pad={{ bottom: 'small' }}
                direction='row'
                justify='between'
                align='center'
              >
                <Text size='small' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                  Total de eleitores
                </Text>

                <Text size='small' color='dark-4' margin={{ top: 'xsmall', bottom: 'none' }}>
                  {totalVoters}
                </Text>
              </Box>

              <Box
                border={{ color: 'light-3', side: 'bottom' }}
                margin={{ top: 'xsmall' }}
                pad={{ bottom: 'small' }}
                direction='row'
                justify='between'
                align='center'
              >
                <Text size='small' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                  Valor unitário
                </Text>

                <Text size='small' color='dark-4' margin={{ top: 'xsmall', bottom: 'none' }}>
                  {formattedCurrency(costUnit)}
                </Text>
              </Box>

              <Box
                border={{ color: 'light-3', side: 'bottom' }}
                margin={{ top: 'xsmall' }}
                pad={{ bottom: 'small' }}
                direction='row'
                justify='between'
                align='center'
              >
                <Text size='small' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                  Valor emissão PIX
                </Text>

                <Text size='small' color='dark-4' margin={{ top: 'xsmall', bottom: 'none' }}>
                  {formattedCurrency(costBankSlip)}
                </Text>
              </Box>

              <Box
                margin={{ top: 'xsmall' }}
                pad={{ bottom: 'small' }}
                direction='row'
                justify='between'
                align='center'
              >
                <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                  Custo estimado
                </Text>

                <Text size='medium' color='dark-4' margin={{ top: 'xsmall', bottom: 'none' }}>
                  {formattedCurrency(costTotal)}
                </Text>
              </Box>
            </>) : null
          }
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default CostCampaign;
