import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box, Text, ResponsiveContext } from 'grommet';

import { HeaderHtml } from '../../../components';

import { logoutSystem } from '../actions/logout';

import urlRoutes from '../../../config/url-routes';

class Logout extends Component {
  callbackPostSuccess = () => {
    window.location = urlRoutes.site;
  };

  componentDidMount() {
    const { logoutSystem } = this.props;
    logoutSystem({ callbackSuccess: this.callbackPostSuccess });
  }

  render() {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <HeaderHtml title='Logout' />

            <Box
              height='100vh'
              align='center'
              justify='center'
              gap='small'
              margin={{ horizontal: 'small' }}
              flex={{ shrink: 0 }}
            >
              <Box
                round='small'
                width='medium'
                pad={{
                  vertical: 'large',
                  horizontal: size === 'small' ? 'large' : 'medium',
                }}
                gap={ size === 'small' ? 'large' : 'small' }
                background='white'
                flex={{ shrink: 0 }}
                border
              >
                <Text
                  size='xsmall'
                  color='brand'
                  textAlign='center'
                  margin={{ bottom: 'small' }}
                >
                  Gerencia Meu Mandato
                </Text>

                <Text size='large' textAlign='center'>
                  Fazer logout
                </Text>

                <Text
                  size='small'
                  color='dark-5'
                  textAlign='center'
                >
                  Estamos saindo da sua conta, aguarde...
                </Text>
              </Box>
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logoutSystem,
}, dispatch);

export default connect(null, mapDispatchToProps)(Logout);
