import axiosGlobalInstance from '../http-instance';

export default params => {
  return axiosGlobalInstance({
    method: 'POST',
    url: '/conta.php',
    data: params,
    headers: {
      action: 'forgot_password'
    }
  });
}
