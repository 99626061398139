import { combineReducers } from 'redux';
import removeReducer from './form-delete';
import searchResultReducer from './search-result';

const settingsGroupReducer = combineReducers({
  remove: removeReducer,
  searchResult: searchResultReducer,
});

export default settingsGroupReducer;
