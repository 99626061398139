const TYPES = ({

  // start integration
  startIntegration: 'START_INTEGRATION_CALENDAR_STATUS',
  loadStartIntegration: 'LOADING_START_INTEGRATION_CALENDAR',

  listCalendars: 'LIST_CALENDARS_INTEGRATION_CALENDAR',
  loadingListCalendars: 'LOADING_LIST_CALENDARS_INTEGRATION_CALENDAR',
  errorListCalendars: 'ERROR_LIST_CALENDARS_INTEGRATION_CALENDAR',
  cleanListCalendars: 'CLEAN_LIST_CALENDARS_INTEGRATION_CALENDAR',

  // list
  loadingFormSearch: 'LOADING_SEARCH_SETTINGS_INTEGRATION_CALENDAR_FORM_TOGGLE',
  searchSuccess: 'SEARCH_SETTINGS_INTEGRATION_CALENDAR_SUCCESS',
  searchError: 'SEARCH_SETTINGS_INTEGRATION_CALENDAR_LIST_ERROR',

  // form create
  insertItemList: 'MANAGER_SETTINGS_INTEGRATION_CALENDAR_SUCCESS_INSERT_LISTING',
  deleteItemList: 'MANAGER_SETTINGS_INTEGRATION_CALENDAR_SUCCESS_DELETE_LISTING',

  // form search
  setDefaultPage: 'SET_SEARCH_SETTINGS_INTEGRATION_CALENDAR_DEFAULT_PAGE',
});

export default TYPES;
