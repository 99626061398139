import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';
import { createService, updateService } from '../../../api/service';
import { details } from '../../../api/common';

const searchDetails = id =>
  details(id, 'atendimento.php')
    .then(response => ({
        type: TYPES.loadDataEdit,
        payload: response,
      })
    )
    .catch(() => ({ type: TYPES.loadingFormManagerEditError }))

const loadingPostManager = () => ({
  type: TYPES.loadingPostFormManager,
});

export const update = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    updateService(params.formParams)
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(hideFormManagerLayer());

        dispatch({
          type: TYPES.updateItemListingManagerSuccess,
          payload: response,
        });

        dispatch(
          showNotificationLayer(`O atendimento ${response.data.reference} foi atualizado!`)
        );
      })
      .catch(responseFail => {
        dispatch(loadingPostManager());

        dispatch({
          type: TYPES.formManagerPostError,
          payload: responseFail.response,
        });
      })
  }
}

export const create = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    createService(params.formParams)
      .then(response => {
        dispatch(loadingPostManager());

        dispatch(
          showNotificationLayer(`O atendimento ${response.data.reference} foi criado!`)
        );
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostManager());

        dispatch({
          type: TYPES.formManagerPostError,
          payload: responseFail.response,
        });
      })
  }
}

// documents
export const addDocument = params => ({
  type: TYPES.addDocumentFormManager,
  payload: params,
});

export const removeDocument = params => ({
  type: TYPES.removeDocumentFormManager,
  payload: params,
});

export const changeField = (value, type) => ({
  type: TYPES[type],
  payload: value,
});

export const toggleFormManagerLayer = params => {
  if(params && params.id) {
    analytics.registerEvent({
      category: analytics.categories.service,
      action: analytics.actions.serviceEdit,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
      { type: TYPES.loadingFormManagerEdit },
      searchDetails(params.id),
    ];
  }

  analytics.registerEvent({
    category: analytics.categories.service,
    action: analytics.actions.serviceCreate,
  });

  return { type: COMMON_TYPES.toggleLayer, payload: 'manager' };
};

export const hideFormManagerLayer = () => [
  { type: COMMON_TYPES.toggleLayer, payload: null },
  { type: TYPES.clearFormManager }
];
