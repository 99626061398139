import { listServicePrint } from '../../../api/service';
import { formattedDateTime } from '../../../utils';
import { saveAs } from 'file-saver';
import { showNotificationLayer } from '../../../actions/notification';

export const generatePrintList = params =>
  dispatch => {
    dispatch(
      showNotificationLayer('Gerando arquivo. Aguarde...')
    );

    listServicePrint(params)
      .then(response => {
        saveAs(
          response.data,
          `listagem-atendimentos-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.pdf`
        );
      })
      .catch(() =>
        dispatch(
          showNotificationLayer('Ops... não conseguimos gerar o arquivo.')
        )
      );
  }
