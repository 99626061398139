const selectCampaignCost = [
  // E-mail
  {
    typeCommunicationId: 1,
    amountMin: 0,
    amountMax: 2000,
    costUnit: 0.0300,
    costBankSlip: 1.50,
  },
  {
    typeCommunicationId: 1,
    amountMin: 2001,
    amountMax: 4000,
    costUnit: 0.0250,
    costBankSlip: 1.50,
  },
  {
    typeCommunicationId: 1,
    amountMin: 4001,
    amountMax: 6000,
    costUnit: 0.0200,
    costBankSlip: 1.50,
  },
  {
    typeCommunicationId: 1,
    amountMin: 6001,
    amountMax: 999999999,
    costUnit: 0.0150,
    costBankSlip: 1.50,
  },

  // SMS
  {
    typeCommunicationId: 2,
    amountMin: 0,
    amountMax: 500,
    costUnit: 0.1500,
    costBankSlip: 1.50,
  },
  {
    typeCommunicationId: 2,
    amountMin: 501,
    amountMax: 5000,
    costUnit: 0.1400,
    costBankSlip: 1.50,
  },
  {
    typeCommunicationId: 2,
    amountMin: 5001,
    amountMax: 10000,
    costUnit: 0.1300,
    costBankSlip: 1.50,
  },
  {
    typeCommunicationId: 2,
    amountMin: 10001,
    amountMax: 50000,
    costUnit: 0.1200,
    costBankSlip: 1.50,
  },
  {
    typeCommunicationId: 2,
    amountMin: 50001,
    amountMax: 100000,
    costUnit: 0.1100,
    costBankSlip: 1.50,
  },
  {
    typeCommunicationId: 2,
    amountMin: 100001,
    amountMax: 999999999,
    costUnit: 0.1000,
    costBankSlip: 1.50,
  }
];

export default selectCampaignCost;
