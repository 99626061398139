import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';
import { formatFormCreate, formatFormEdit } from '../utils';
import { createPhonecall, updatePhonecall } from '../../../api/phonecall';
import { details } from '../../../api/common';

const loadingPostManager = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const update = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    updatePhonecall(params.formParams)
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(hideFormManagerLayer());

        dispatch({
          type: TYPES.updateItemListingManagerSuccess,
          payload: response,
        });

        dispatch(
          showNotificationLayer(`A ligação LI-${response.data.id} foi atualizada!`)
        );
      })
      .catch(responseFail => {
        dispatch(loadingPostManager());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
};

export const create = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    createPhonecall(params.formParams)
      .then(response => {
        dispatch(loadingPostManager());

        dispatch(
          showNotificationLayer(`A ligação LI-${response.data.id} foi criada!`)
        )
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostManager());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
};

export const showFormManagerLayer = params => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.phonecall,
      action: analytics.actions.phonecallEdit,
    });

    return dispatch => {
      dispatch([
        { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
        { type: COMMON_TYPES.formGetWatingResponse, payload: true },
      ]);

      details(params.id, 'telefonema.php')
        .then(({ data }) => {
          dispatch([{
              type: COMMON_TYPES.formInitializeValues,
              payload: formatFormEdit(data),
            },
            { type: COMMON_TYPES.formGetWatingResponse, payload: false },
          ])
        })
        .catch(() =>
          dispatch({ type: COMMON_TYPES.formGetResultError })
        )
    }
  }

  //ga
  analytics.registerEvent({
    category: analytics.categories.phonecall,
    action: analytics.actions.phonecallCreate,
  });

  return [
    { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
    { type: COMMON_TYPES.formInitializeValues, payload: formatFormCreate() },
    { type: COMMON_TYPES.formGetWatingResponse, payload: false },
  ];
};

export const hideFormManagerLayer = () => {
  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
};
