import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { AddCircle, System } from 'grommet-icons';

import urlRoutes from '../../../../config/url-routes';

const SearchResult = (props) => {
  const configsService = [
    {
      name: 'Status dos atendimentos',
      url: urlRoutes.settingsServiceStatusUrl,
    },
    {
      name: 'Origem dos atendimentos',
      url: urlRoutes.settingsOriginUrl,
    },
    {
      name: 'Categoria dos atendimentos',
      url: urlRoutes.settingsCategoryUrl,
    },
    {
      name: 'Escritórios dos atendimentos',
      url: urlRoutes.settingsOfficeUrl,
    }
  ];

  const configsVoter = [
    {
      name: 'Grupos dos eleitores',
      url: urlRoutes.settingsGroupUrl,
    }
  ];

  const configsDocument = [
    {
      name: 'Status dos documentos',
      url: urlRoutes.settingsLegislativeProcesseStatusUrl,
    }
  ];

  const configsUser = [
    {
      name: 'Usuários',
      //description: 'Cadastre, altere e inative',
      url: urlRoutes.settingsUserUrl,
    }
  ];

  const configsIntegrationCalendar = [
    {
      name: 'Integração com Google Agenda',
      //description: 'Configure a integração com o Google Calendar.',
      url: urlRoutes.settingsIntegrationCalendarUrl,
    }
  ];

  const redirect = useCallback((url) => {
    props.history.push(url);
  }, [props.history]);

  const renderItemConfig = useCallback(({ name, url }, size, i) => (
    <Box
      key={i}
      align='center'
      direction='row'
      pad={{
        horizontal: size === 'small' ? 'medium' : 'medium',
        vertical: size === 'small' ? 'medium' : 'small',
      }}
      border={{
        color: 'light-3',
        side: 'top',
      }}
      justify='between'
      gap={ size === 'small' ? 'medium' : 'small' }
    >
      <Text color='dark-1' size='small'>
        {name}
      </Text>

      <Button
        title='Configurar'
        label='Configurar'
        size='xsmall'
        primary
        onClick={() => redirect(url) }
      />
    </Box>
  ), [redirect]);

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '100px' }}
          >
            <Box
              pad='small'
              width='xlarge'
              gap='medium'
            >

              {/* Cabeçalho */}
              <Box
                direction='row'
                gap='xsmall'
                align='baseline'
              >
                <Text size='large' color='dark-5'>Personalize o</Text>
                <Text size='xlarge' color='dark-1'>sistema.</Text>
              </Box>


              {/* Conteúdo */}
              <Box
                gap='medium'
                direction={ size === 'small' || size === 'medium' ? 'column' : 'row' }
                justify='between'
              >
                {/* Primeira coluna */}
                <Box
                  width={
                    size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge'
                  }
                >
                  <Box
                    margin={{
                      bottom: size === 'small' ? 'large' : 'medium'
                    }}
                  >
                    <Text color='dark-2' size='small'>
                      Configure o sistema para atender as suas necessidades.
                    </Text>
                  </Box>

                  <Box
                    direction='row'
                    gap={ size === 'small' ? 'large' : 'medium' }
                    margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                  >
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <System size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Gerencie
                      </Text>

                      <Text size='small' color='dark-2'>
                        Configure status, origem, grupos e muito mais.
                      </Text>
                    </Box>

                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <AddCircle size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Sem limites
                      </Text>

                      <Text size='small' color='dark-2'>
                        Faça quantas mudanças precisar! Aqui você tem total controle.
                      </Text>
                    </Box>

                  </Box>
                </Box>

                {/* Separador coluna */}
                <Box
                  width={ size === 'large' || size === 'xlarge' ? 'xsmall' : 'xxsmall'}
                />

                {/* Segunda coluna */}
                <Box
                  gap='small'
                  width={ size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge' }
                >

                  { /* Atendimento */}
                  <Box
                    round={ size === 'small' ? 'small' : 'xsmall' }
                    background='white'
                    border={{ color: 'light-4' }}
                  >
                    {configsService.map((row, i) => {
                      return renderItemConfig(
                        {
                          name: row.name,
                          url: row.url,
                        }, size, i
                      );
                    })}
                  </Box>

                  { /* Eleitor */}
                  <Box
                    round='xsmall'
                    background='white'
                    border={{ color: 'light-4' }}
                    //margin={{ bottom: 'large' }}
                  >
                    {/*<Box
                      pad={{
                        horizontal: size === 'small' ? 'large' : 'medium',
                        vertical: 'medium',
                      }}
                    >
                      <Text size='medium' color='dark-1'>Eleitor</Text>
                    </Box>*/}

                    {configsVoter.map((row, i) => {
                      return renderItemConfig(
                        {
                          name: row.name,
                          url: row.url,
                        }, size, i
                      );
                    })}
                  </Box>

                  { /* Documentos */}
                  <Box
                    round='xsmall'
                    background='white'
                    border={{ color: 'light-4' }}
                  >
                    {configsDocument.map((row, i) => {
                      return renderItemConfig(
                        {
                          name: row.name,
                          url: row.url,
                        }, size, i
                      );
                    })}
                  </Box>

                  { /* Usuario */}
                  <Box
                    round='xsmall'
                    background='white'
                    border={{ color: 'light-4' }}
                  >
                    {configsUser.map((row, i) => {
                      return renderItemConfig(
                        {
                          name: row.name,
                          url: row.url,
                        }, size, i
                      );
                    })}
                  </Box>


                  { /* Integrações */}
                  {<Box
                    round='xsmall'
                    background='white'
                    border={{ color: 'light-4' }}
                  >
                    {configsIntegrationCalendar.map((row, i) => {
                      return renderItemConfig(
                        {
                          name: row.name,
                          url: row.url,
                        }, size, i
                      );
                    })}
                  </Box>}
                </Box>
              </Box>
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

export default withRouter(SearchResult);
