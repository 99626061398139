import React, { Component } from 'react';

class DashboardAppointment extends Component {
  render() {
    return (
      <div>DashboardAppointment</div>
    )
  }
}

export default DashboardAppointment;
