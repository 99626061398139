const selectKinshipOptions = [
  { id: 3, name: 'Avô(ó)' },
  { id: 4, name: 'Cunhado(a)' },
  { id: 5, name: 'Esposo(a)' },
  { id: 6, name: 'Filho(a)' },
  { id: 17, name: 'Genro' },
  { id: 7, name: 'Irmão(ã)' },
  { id: 15, name: 'Madrasta' },
  { id: 8, name: 'Mãe' },
  { id: 9, name: 'Neto(a)' },
  { id: 16, name: 'Nora' },
  { id: 14, name: 'Padrasto' },
  { id: 10, name: 'Pai' },
  { id: 11, name: 'Primo(a)' },
  { id: 12, name: 'Sobrinho(a)' },
  { id: 13, name: 'Sogro(a)' },
  { id: 18, name: 'Tio(a)' },
];

export default selectKinshipOptions;
