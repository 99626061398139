import TYPES from './types';
import { listDistricts } from '../../../api/location';

export const searchDistrictOptions = params => {
  return dispatch => {
    dispatch({ type: TYPES.getDistrictOptionsLoad });

    listDistricts(params)
      .then(({ data }) => {
        dispatch({
          type: TYPES.getDistrictOptions,
          payload: data.data.map(result => {
            const {
              id,
              district,
              city,
            } = result;

            return {
              id,
              name: `${district}/${city}`,
            }
          }),
        })
      })
      .then(() => dispatch({ type: TYPES.getDistrictOptionsLoad }))
      .catch(() => dispatch({ type: TYPES.getDistrictOptionsLoad }))
  }
};
