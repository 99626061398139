const TYPES = ({
  // list
  loadFormSearchAppointment: 'LOADING_SEARCH_APPOINTMENT_FORM_TOGGLE',
  searchAppointmentSuccess: 'SEARCH_APPOINTMENT_SUCCESS',
  searchError: 'SEARCH_APPOINTMENT_LIST_ERROR',

  updateItemListingManagerSuccess: 'MANAGER_APPOINTMENT_SUCCESS_UPDATE_LISTING',

  // form search
  changeFormSearchFields: 'CHANGE_SEARCH_APPOINTMENT_FORM_FIELDS',
  setDefaultPage: 'SET_SEARCH_APPOINTMENT_DEFAULT_PAGE',
  clearFormSearchFields: 'CLEAR_FIELDS_APPOINTMENT_FORM',
  paginationFormSearch: 'SEARCH_APPOINTMENT_PAGINATION',

  // public details
  openPublicDetails: 'LOAD_OPEN_APPOINTMENT_PUBLIC_DETAILS',
});

export default TYPES;
