import TYPES from '../actions/types';
import paginationDefault from '../../../constants/pagination';

const INITIAL_STATE = {
  reference: null,
  individualRegistration: null,
  name: null,
  genre: null,
  cityId: null,
  districtsId: null,
  address: null,
  zipcode: null,
  status: null,
  groupsId: null,
  maritalStatusId: null,
  professionId: null,
  withTelephone: null,
  withEmail: null,
  telephone: null,
  email: null,
  startBirthDate: null,
  endBirthDate: null,
  startCreatedDate: null,
  endCreatedDate: null,
  voteStatusId: null,
  responsibleId: null,
  responsibleName: null,
  schoolId: null,

  page: paginationDefault.page,
  limit: paginationDefault.limit,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    // fields
    case TYPES.changeFormSearchField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.setDefaultPage:
      return {
        ...state,
        page: paginationDefault.page,
        limit: paginationDefault.limit,
      };

    case TYPES.clearFormSearchFields:
      return {
        ...state,
        reference: null,
        individualRegistration: null,
        name: null,
        genre: null,
        cityId: null,
        districtsId: null,
        address: null,
        zipcode: null,
        status: null,
        groupsId: null,
        maritalStatusId: null,
        professionId: null,
        withTelephone: null,
        withEmail: null,
        telephone: null,
        email: null,
        startBirthDate: null,
        endBirthDate: null,
        startCreatedDate: null,
        endCreatedDate: null,
        voteStatusId: null,
        responsibleId: null,
        responsibleName: null,
        schoolId: null,
      }

    case TYPES.paginationFormSearch:
      return { ...state, page: action.payload };

    default:
      return state;
  }
}
