import TYPES from '../actions/types';
import { formatDataFormChangeStatus } from '../utils';

const INITIAL_STATE = {
  id: null,
  status: null,
  type: null,
  number: null,
  year: null,

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    // fields
    case TYPES.changeFormStatusField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.setDataFormStatus:
      return {
        ...state,
        ...formatDataFormChangeStatus(action.payload),

        postDataError: {
          message: '',
          errors: []
        },
      }

    case TYPES.loadingFormChangeStatus:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formChangeStatusError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
