import COMMON_TYPES from '../../../actions/types';
import TYPES from '../actions/types';
import * as analytics from '../../../analytics';
import { changeStatus } from '../../../api/task';
import { list } from '../../../api/common';

export const updateStatus = params => {
  return dispatch => {
    dispatch({ type: COMMON_TYPES.formPostWatingResponse });

    changeStatus(params.formParams)
      .then(() =>
        dispatch([
          { type: COMMON_TYPES.formPostWatingResponse },
          { type: TYPES.updateList, payload: params.formParams }
        ])
      )
      .catch(() =>
        dispatch({ type: COMMON_TYPES.formPostWatingResponse })
      )
  }
};

export const toggleTodoLayer = params => {
  if(params && params.id) {
    analytics.registerEvent(
      { category: analytics.categories.task, action: analytics.actions.taskDash }
    );

    return dispatch => {
      dispatch([
        { type: COMMON_TYPES.toggleLayer, payload: 'todoLayer' },
        { type: TYPES.todoLoading },
      ]);

      list(
        { status: 'pending', participants: [params.id] }, 'task.php'
      )
        .then(({ data }) =>
          dispatch([
            { type: TYPES.todoSuccess, payload: data },
            { type: TYPES.todoLoading },
          ])
        )
        .catch(() => dispatch({ type: TYPES.todoError }))
    }
  }

  return [
    {
      type: COMMON_TYPES.toggleLayer,
      payload: null
    },
    { type: COMMON_TYPES.formClearValues },

    {
      type: TYPES.todoSuccess,
      payload: { data: [] }
    }
  ];
};
