import TYPES from '../actions/types';
import paginationDefault from '../../../constants/pagination';

const INITIAL_STATE = {
  reference: null,
  electorReference: null,
  electorName: null,
  groupId: null,
  statusId: null,
  originId: null,
  categoryId: null,
  officeId: null,
  responsibleId: null,
  description: null,
  startCreatedDate: null,
  endCreatedDate: null,
  documentId: null,
  documentYear: null,
  documentNumber: null,
  districtIds: null,

  page: paginationDefault.page,
  limit: paginationDefault.limit,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    // fields
    case TYPES.changeFormSearchField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.setDefaultPage:
      return {
        ...state,
        page: paginationDefault.page,
        limit: paginationDefault.limit,
      };

    case TYPES.clearFormSearchFields:
      return {
        ...state,
        reference: null,
        electorReference: null,
        electorName: null,
        groupId: null,
        statusId: null,
        originId: null,
        categoryId: null,
        officeId: null,
        responsibleId: null,
        description: null,
        startCreatedDate: null,
        endCreatedDate: null,
        documentId: null,
        documentYear: null,
        documentNumber: null,
        districtIds: null,
      }

    case TYPES.paginationFormSearch:
      return { ...state, page: action.payload };

    default:
      return state;
  }
}
