import { combineReducers } from 'redux';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';
//import notificationReducer from './notification';

const expenseReducer = combineReducers({
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
  //notification: notificationReducer,
});

export default expenseReducer;
