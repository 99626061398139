import TYPES from '../actions/types';

const INITIAL_STATE = {
	type: '',
	name: '',
	dateStart: '',
  hourStart: '',

  searchVoters: {
    genre: null,
    withEmail: null,
    withTelephone: null,
    telephoneTypeId: null,
    cityId: null,
    districtsId: null,
    groupsId: null,
    maritalStatusId: null,
    professionId: null,
  },

  voters: [],

  composerMessage: {
    subject: '',
    greeting: '',
    content: '',
    signature: '',
  },

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    // dados base
    case TYPES.changeFormCreateType:
      return { ...state, type: action.payload }

    case TYPES.changeFormCreateName:
      return { ...state, name: action.payload }

    case TYPES.changeFormCreateDateStart:
      return { ...state, dateStart: action.payload }

    case TYPES.changeFormCreateHourStart:
      return { ...state, hourStart: action.payload }

    // searchvoter
    case TYPES.changeFormCreateSearchVoterGenre:
      return {
        ...state,
        searchVoters: { ...state.searchVoters, genre: action.payload }
      }

    case TYPES.changeFormCreateSearchVoterCity:
      return {
        ...state,
        searchVoters: {
          ...state.searchVoters,
          cityId: action.payload,
        }
      }

    case TYPES.changeFormCreateSearchVoterDistricts:
      return {
        ...state,
        searchVoters: {
          ...state.searchVoters,
          districtsId: action.payload && action.payload.length ? action.payload : null,
        }
      }

    case TYPES.changeFormCreateSearchVoterGroups:
      return {
        ...state,
        searchVoters: {
          ...state.searchVoters,
          groupsId: action.payload && action.payload.length ? action.payload : null,
        }
      }

    case TYPES.changeFormCreateSearchVoterMaritalStatus:
      return {
        ...state,
        searchVoters: {
          ...state.searchVoters,
          maritalStatusId: action.payload,
        }
      }

    case TYPES.changeFormCreateSearchVoterProfession:
      return {
        ...state,
        searchVoters: {
          ...state.searchVoters,
          professionId: action.payload,
        }
      }


    // compose message
    case TYPES.changeFormCreateComposeMessageSubject:
      return { ...state, composerMessage: { ...state.composerMessage, subject: action.payload } }

    case TYPES.changeFormCreateComposeMessageGreeting:
      return { ...state, composerMessage: { ...state.composerMessage, greeting: action.payload } }

    case TYPES.changeFormCreateComposeMessageContent:
      return { ...state, composerMessage: { ...state.composerMessage, content: action.payload } }

    case TYPES.changeFormCreateComposeMessageSignature:
      return { ...state, composerMessage: { ...state.composerMessage, signature: action.payload } }

    case TYPES.searchByEmail:
      return {
        ...state,
        searchVoters: {
          ...state.searchVoters,
          withEmail: true,
          withTelephone: null,
          telephoneTypeId: null,
        }
      }

    case TYPES.searchBySms:
      return {
        ...state,
        searchVoters: {
          ...state.searchVoters,
          withEmail: null,
          withTelephone: true,
          telephoneTypeId: 2,
        }
      }

    case TYPES.clearFormCreate:
      return { ...state, ...INITIAL_STATE }

    case TYPES.postFormCreateCampaignError:
      return { ...state, postDataError: action.payload.data };

    case TYPES.loadingPostFormCreate:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.getVotersSucsess:
      return { ...state, voters: action.payload.data.data.map(a => a.id) }

    case TYPES.clearVoters:
      return { ...state, voters: [] }

    default:
      return state;
  }
}
