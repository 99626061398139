const style = 'currency';
const currency = 'BRL';
const locale = 'pt-BR';

export default (value, fracDigits = 2) => {
  if(value === undefined || value === null)
    return value;

  return new Intl.NumberFormat(locale, { style, currency, minimumFractionDigits: fracDigits }).format(value);
}
