import TYPES from './types/reset-password';
import { getTokenRecoverPassword, postResetPassword } from '../../../api/user';

export const changePassword = params => ({
  type: TYPES.changePassword,
  payload: params,
});

export const loadFormPostToggle = () => ({
  type: TYPES.loadFormPostToggle
});

export const getToken = identity => {
  return dispatch => {
    getTokenRecoverPassword(identity)
      .then(response => {
        dispatch({
          type: TYPES.tokenRecoverPassword,
          payload: response,
        });
      })
      .catch(responseFail => { })
  }
}

export const resetPassword = params => {
  return dispatch => {
    dispatch({ type: TYPES.loadFormPostToggle });

    postResetPassword(params.formParams)
      .then(response => {
        dispatch({
          type: TYPES.formPostSuccess,
          payload: response,
        });
      })
      .then(() => {
        dispatch({ type: TYPES.loadFormPostToggle });
      })
      .catch(responseFail => {
        dispatch({ type: TYPES.loadFormPostToggle });
        dispatch({
          type: TYPES.formPostError,
          payload: responseFail.response
        });
      })
  }
}
