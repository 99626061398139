import React, { Component } from 'react';

class DashboardPhonecall extends Component {
  render() {
    return (
      <div>DashboardPhonecall</div>
    )
  }
}

export default DashboardPhonecall;
