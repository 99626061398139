import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { generateTag } from '../../../api/tag';
import { formattedDateTime } from '../../../utils';
import { saveAs } from 'file-saver';

export const createTag = params => {
  return dispatch => {
    // Origem solution download file remote server:
    // https://github.com/eligrey/FileSaver.js/wiki/Saving-a-remote-file

    dispatch({ type: TYPES.postLoadingFormGenerateTag });

    generateTag(params)
      .then(response => {
        saveAs(
          response.data,
          `etiquetas-eleitores-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.pdf`
        );

        dispatch({
          type: TYPES.postLoadingFormGenerateTag
        });
      })
      .catch(responseFail => {
        dispatch({ type: TYPES.postLoadingFormGenerateTag });
      })
  };
}

export const changeField = (value) => ({
  type: TYPES.changeFormGenerateTagField,
  payload: value,
});

export const openGenerateTagLayer = params => {
  if(params) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.voter,
      action: analytics.actions.voterGenerateTag,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'createTag' },
      { type: TYPES.setDataFormGenerateTag, payload: params },
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
}

export const closeGenerateTagLayer = () => ({
  type: COMMON_TYPES.toggleLayer, payload: null
});
