import TYPES from '../actions/types';

const INITIAL_STATE = {
  id: null,
  name: null,
  protocolNumber: null,
  statusId: null,

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    // fields
    case TYPES.changeFormStatusField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.setDataFormStatus:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        protocolNumber: action.payload.protocolNumber,
        statusId: action.payload.statusId,

        postDataError: { message: '', errors: [] },
      }

    case TYPES.loadingFormChangeStatus:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formChangeStatusError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
