import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { HeaderHtml } from '../../../components';
import { Result } from '../components/result/result';
import { Todo } from '../components/task/task';

import Header from '../../../components/header/header';

import { getServices, getVoters, getVotersDistricts, getTotalPendingTask } from '../actions/details';

class DashboardSystem extends Component {
  componentDidMount() {
    const {
      getServices,
      getVoters,
      getVotersDistricts,
      getTotalPendingTask,
    } = this.props;

    getServices();
    getVoters();
    getVotersDistricts();
    getTotalPendingTask();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <HeaderHtml title='Gerencia Meu Mandato' />

        <Header userLogged={userLogged} />
        <Result />
        <Todo />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getServices,
    getVoters,
    getVotersDistricts,
    getTotalPendingTask,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSystem);
