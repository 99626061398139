import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FormDelete from '../components/form-delete/form-delete';
import FormCreate from '../components/form-manager/form-manager';
import SearchResult from '../components/search-result/search-result';
import Communication from '../components/communication/communication';
import Header from '../../../components/header/header';

import { search, setDefaultPageRules } from '../actions/form-search';

class SettingsOffice extends Component {
  componentDidMount() {
    const {
      search,
      setDefaultPageRules,
    } = this.props;

    setDefaultPageRules();
    search();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <FormDelete />
        <FormCreate />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    search,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsOffice);
