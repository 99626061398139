import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Box, Button } from 'grommet';
import { FormClose } from 'grommet-icons';

import { Placeholder } from '../../../../components';
import MessageTemplate from '../../components/message-template/message-template';
import { SimpleDetail, TagStatus, TitleDetail } from '../../../../components/detail';

import { formattedDateTime } from '../../../../utils';
import visibleWidth from '../../../../constants/layer-width';

import { toggleDetailsLayer } from '../../actions/details';

import statusColor from '../../constants/status-color';

class DetailsCampaign extends Component {
  render() {
    const {
      detail,
      getDetailError,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'details') {
      const messageTemplateProps =
        detail &&
        detail.contentMessage.reduce((acc, currentValue) => {
          acc[currentValue.name] = currentValue.value;
          return acc;
        }, {});

      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleDetailsLayer}
              onEsc={toggleDetailsLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{
                  top: size === 'small' ? 'xlarge' : 'large',
                  bottom: size === 'small' ? 'large' : null,
                }}
                overflow='auto'
                flex
              >
                {getDetailError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: toggleDetailsLayer,
                    }}
                  />
                ) : null}

                {!detail && !getDetailError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {detail ? (
                  <>
                    <Box
                      direction='row'
                      justify='between'
                      align='baseline'
                      margin={{ horizontal: 'large' }}
                    >
                      <TitleDetail value={detail.name} />

                      <Button
                        plain
                        title='Fechar'
                        icon={<FormClose />}
                        onClick={toggleDetailsLayer}
                      />
                    </Box>


                    <Box flex overflow='auto'>
                      <SimpleDetail
                        title='Tipo'
                        value={detail.typeName}
                      />

                      <SimpleDetail
                        title='Eleitores'
                        value={detail.totalVoters}
                      />

                      <SimpleDetail
                        title='Data de envio'
                        value={formattedDateTime(detail.dateSend, 'DD MMM YYYY')}
                      />

                      <SimpleDetail
                        title='Horário de envio'
                        value={formattedDateTime(detail.dateSend, 'HH:mm')}
                      />

                      <TagStatus
                        title='Status'
                        value={detail.statusName}
                        color={statusColor[detail.statusId] || 'dark-2'}
                      />

                      <Box
                        margin={{ top: 'medium', bottom: 'none', horizontal: 'large' }}
                        pad={{ bottom: 'small' }}
                        flex={{ shrink: 0 }}
                      >
                        <MessageTemplate
                          typeId={detail.typeId}
                          {...messageTemplateProps}
                        />
                      </Box>
                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  getDetailError: layerDetailsReducer.getDetailError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch);

export const Details = connect(mapStateToProps, mapDispatchToProps)(DetailsCampaign);
