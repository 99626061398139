export default object => ({
  id: object.id,
  categoryId: object.categoryId,
  statusId: object.statusId,
  value: `R$ ${object.value}`,
  isRefund: object.isRefund,
  name: object.name,
  nationalRegister: object.nationalRegister,
  telephone: object.telephone,
  dateRefund: object.dateRefund,
  dateExpense: object.dateExpense,
  description: object.description,

  isCopy: false,
  isEditing: true,
});
