import TYPES from '../actions/types/forgot-password';

const INITIAL_STATE = {
  mail: '',

  loadingPostForm: false,

  postDataSuccess: null,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.clearAllForm:
      return { ...state, ...INITIAL_STATE }

    case TYPES.changeEmail:
      return { ...state, mail: action.payload };

    case TYPES.loadFormPostToggle:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formPostError:
      return { ...state, postDataError: action.payload.data };

    case TYPES.formPostSuccess:
      return { ...state, postDataSuccess: true }

    default:
      return state;
  }
}
