import TYPES from '../actions/types';
import { updateRow } from '../utils';

const INITIAL_STATE = {
  loadingFormSearch: false,
  dataRows: [],
  totalOfRows: 0,
  getListError: false,
};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.searchSuccess:
      return {
        ...state,
        dataRows: action.payload.data.data,
        totalOfRows: action.payload.data.total,
        getListError: false,
      };

    case TYPES.searchError:
      return {
        ...state,
        getListError: true,
        loadingFormSearch: !state.loadingFormSearch,
      }

    case TYPES.loadingFormSearch:
      return {
        ...state,
        getListError: false,
        loadingFormSearch: !state.loadingFormSearch,
      };

    case TYPES.updateItemListing:
      return {
        ...state,
        dataRows: updateRow({
          object: action.payload.data,
          key: action.payload.data.id,
          items: state.dataRows,
        }),
      }

    default:
      return state;
  }
};
