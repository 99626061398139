import TYPES from '../actions/types';
import { formatDataFormEdit } from '../utils';

const INITIAL_STATE = {
  categoryId: null,
  description: null,
  documents: [],
  electorId: null,
  id: null,
  officeId: null,
  originId: null,
  requestDate: new Date(),
  responsibles: null,
  statusId: null,
  voterName: null,

  isEditing: false,
  getDataFormEditError: false,

  loadingPostForm: false,
  postDataSuccess: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    // fields
    case TYPES.changeFormManagerField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    // documents
    case TYPES.addDocumentFormManager:
      return {
        ...state,
        documents: [ ...state.documents, action.payload ],
      }

    case TYPES.removeDocumentFormManager:
      return {
        ...state,
        documents: state.documents.filter(item => item !== action.payload)
      }

    case TYPES.changeFormManagerDocument:
      const documents = state.documents;
      const documentUpdated = {
        ...state.documents[action.payload.index],
        [action.payload.item]: action.payload.value,
      }

      documents.splice(action.payload.index, 1, documentUpdated);

      return { ...state, documents: [ ...documents ] }


    //
    case TYPES.loadingFormManagerEdit:
      return {
        ...state,
        isEditing: true,
      }

    case TYPES.loadingFormManagerEditError:
      return {
        ...state,
        getDataFormEditError: true,
      }

    case TYPES.loadDataEdit:
      return {
        ...state,
        ...formatDataFormEdit(action.payload.data),
      }

    case TYPES.clearFormManager:
      return {
        ...state,
        ...INITIAL_STATE,
      }

    case TYPES.loadingPostFormManager:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formManagerPostError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
