import TYPES from './types';
import { start } from '../../../api/integration';
import { decode64 } from '../../../utils';

export const startIntegration = ({ stateEncoded, code, error }) => {
  return dispatch => {
    if (error) {
      return dispatch([
        {
          type: TYPES.startIntegration,
          payload: { success: false, loading: false }
        }
      ]);
    }


    const { clientId } = JSON.parse(decode64(stateEncoded));

    if(!clientId || !code) {
      return dispatch([
        {
          type: TYPES.startIntegration,
          payload: { success: false, loading: false }
        }
      ]);
    }


    dispatch({
      type: TYPES.loadStartIntegration
    });

    start({ clientId, code })
      .then(() => {
        dispatch([
          {
            type: TYPES.startIntegration,
            payload: { success: true, loading: false }
          }
        ]);
      })
      .catch(() => {
        dispatch([
          {
            type: TYPES.startIntegration,
            payload: { success: false, loading: false }
          }
        ]);
      })
  }
}
