import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Button, Text, Anchor, ResponsiveContext } from 'grommet';
import { MailOption, Support } from 'grommet-icons';

import { HeaderHtml } from '../../../components';
import urlRoutes from '../../../config/url-routes';

class OnBoardingWelcome extends Component {
  nextStep = () => {
    const { history } = this.props;
    history.push(urlRoutes.login);
  };

  render() {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <HeaderHtml title='Olá, agora você faz parte do Gerencia Meu Mandato!' />

            <Box
              height='100vh'
              align='center'
              justify='center'
              flex={{ shrink: 0 }}
            >
              <Box
                direction={size === 'small' ? 'column' : 'row'}
                pad='large'
                gap='large'
              >
                <Box
                  gap={size === 'small' ? 'large' : 'medium'}
                  width='medium'
                >
                  <Box>
                    <Text color='dark-1' size='xxlarge'>Olá,</Text>
                    <Text color='dark-2' size='xlarge'>agora você faz parte do <br /> Gerencia Meu Mandato!</Text>
                  </Box>

                  <Box>
                    <Button
                      primary
                      label='Legal, Vamos Começar!'
                      onClick={this.nextStep}
                    />
                  </Box>
                </Box>

                <Box
                  gap={size === 'small' ? 'large' : 'medium'}
                  width='medium'
                >
                  <Box>
                    <Text size='small' color='dark-5'>
                      Caso tenha alguma dúvida entre em contato por WhatsApp ou e-mail.
                    </Text>
                  </Box>

                  <Box
                    gap='xsmall'
                  >
                    <Anchor
                      href='https://api.whatsapp.com/send?phone=5519988508644'
                      label='(19) 98850-8644'
                      icon={<Support color='dark-5' />}
                      target='_blank'
                    />

                    <Anchor
                      href='mailto:contato@gerenciameumandato.com.br'
                      label='contato@gerenciameumandato.com.br'
                      icon={<MailOption color='dark-5' />}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

export default withRouter(OnBoardingWelcome);
