import { getCookie, decryptAes, dateInterfere } from '../../../utils';

export default () => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  const taskDate = dateInterfere(new Date(), 2, 'days');

  return {
    id: null,
    sourceId: null,
    source: null,
    status: 'pending',
    description: null,
    taskDate,
    participants: [decipher?.user?.id],

    isCopy: false,
    isEditing: false,
  }
};
