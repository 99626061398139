const insertRow = ({ object, items }) => [
  {
    id: object.id,
    clientId: object.clientId,
    name: object.name,
    description: object.description,
  },
  ...items
];

export default insertRow;
