import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button, Menu, Anchor } from 'grommet';
import { Notes, Edit, More, Search, Trash } from 'grommet-icons';

import { EmptySearches, Placeholder, ErrorsSearches, Pagination } from '../../../../components';
import { Onboarding } from '../onboarding/onboarding';

import { toggleDetailsLayer } from '../../actions/details';
import { openFormSearchLayer, goToNextPage, goToPrevPage } from '../../actions/form-search';
import { showFormManagerLayer } from '../../actions/form-manager';
import { toggleFormDeleteLayer } from '../../actions/form-delete';

import { formattedDateTime, formattedNumber } from '../../../../utils';
import rulesName from '../../../../constants/rules';

const SearchResultComponent = (props) => {
  const {
    userLogged: { rules, client },

    loadingFormSearch,
    dataRows,
    totalOfRows,
    getListError,

    reference,
    question,
    startDate,
    endDate,
    page,
    limit,

    toggleDetailsLayer,
    toggleFormDeleteLayer,
    showFormManagerLayer,
    openFormSearchLayer,
    goToNextPage,
    goToPrevPage,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches
      button={{
        label: 'Abrir filtros',
        action: openFormSearchLayer
      }}
    />;
  }

  if(!dataRows.length && client.trl) {
  //if(dataRows.length && !client.trl) {
    return <Onboarding />;
  }

  if(!dataRows.length) {
    return <EmptySearches
      buttonFilters={{
        label: 'Abrir filtros',
        action: openFormSearchLayer,
      }}

      buttonCreate={
        rules.includes(rulesName.createSurvey) ?
          { label: 'Nova enquete', action: showFormManagerLayer } : null
      }
    />;
  }

  const optionsMenu = (row) => {
    const options = [];

    if(rules.includes(rulesName.editSurvey) || true) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Edit />
            <Text size='small'>Editar</Text>
          </Box>
        ),
        onClick: () => showFormManagerLayer(row)
      });
    }

    if(rules.includes(rulesName.deleteSurvey) || true) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Trash color='status-error' />
            <Text size='small'>Excluir</Text>
          </Box>
        ),
        onClick: () => toggleFormDeleteLayer({ ...row, actionToDelete: 'survey' })
      });
    }

    if(rules.includes(rulesName.deleteSurvey) || true) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Trash color='status-error' />
            <Text size='small'>Excluir respostas</Text>
          </Box>
        ),
        onClick: () => toggleFormDeleteLayer({ ...row, actionToDelete: 'answer' })
      });
    }

    return options;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width='xlarge'
            >
              <Box
                align='center'
                direction='row'
                justify='between'
                margin={{ bottom: 'small' }}
              >
                <Box
                  direction={size === 'small' ? 'column' : 'row'}
                  align={size === 'small' ? 'baseline' : 'center'}
                  gap={size === 'small' ? null : 'small'}
                >
                  <Text size='xlarge'>{formattedNumber(totalOfRows)}</Text>

                  <Text
                    size={size === 'small' ? 'xsmall' : 'medium'}
                    color='dark-6'
                  >
                    {totalOfRows > 1 ? 'Enquetes' : 'Enquete'}
                  </Text>
                </Box>

                <Box direction='row' align='center'>
                  <Button
                    title='Filtrar'
                    icon={<Search />}
                    size='small'
                    onClick={() => { openFormSearchLayer() }}
                  />

                  {(true || rules.includes(rulesName.createSurvey)) &&
                    <Button
                      primary
                      color='neutral-1'
                      size='small'
                      label='Nova'
                      onClick={() => { showFormManagerLayer() }}
                      margin={{ left: 'small' }}
                    />
                  }
                </Box>
              </Box>

              <Box
                direction='row'
                pad={{ vertical: 'medium' }}
                border={{ side: 'bottom', color: 'light-5' }}
                gap='small'
              >
                {size !== 'small' && (
                  <Box width='xsmall'>
                    <Text color='dark-2'>Ref.</Text>
                  </Box>
                )}

                {size !== 'small' && <Box width='medium' fill>
                  <Text color='dark-2'>Pergunta</Text>
                </Box>}

                {size !== 'small' && (<Box width='small'>
                  <Text color='dark-2'>Encerramento</Text>
                </Box>)}

                <Box width='small' />
              </Box>

              {dataRows.map(row => {
                const menu = optionsMenu(row);

                return (
                  <Box
                    key={row.id}
                    align='center'
                    direction='row'
                    pad={{ vertical: size === 'small' ? 'small' : 'xsmall' }}
                    border={{ side: 'bottom', color: 'light-5' }}
                    gap='small'
                  >
                    {size !== 'small' && <Box width='xsmall'>
                      <Anchor
                        title='Abrir detalhes'
                        label={row.reference}
                        onClick={() => { toggleDetailsLayer(row) }}
                      />
                    </Box>}

                    <Box width='medium' fill justify='center'>
                      <Text color='dark-2' size='small'>{row.question}</Text>
                    </Box>

                    {size !== 'small' && <Box width='small'>
                      <Text color='dark-2' size='small'>{formattedDateTime(row.endDate, 'ddd, DD MMM YYYY')}</Text>
                    </Box>}

                    <Box align='end' width='small'>
                      <Box direction='row'>
                        <Button
                          title='Abrir detalhes'
                          icon={<Notes color='dark-3' size='medium' />}
                          onClick={() => { toggleDetailsLayer(row) }}
                        />

                        {menu.length > 0 && (<Menu
                          dropProps={{ align: { right: 'right', top: 'top' } }}
                          icon={<More />}
                          items={menu}
                        />)}
                      </Box>
                    </Box>
                  </Box>
                )
              })}

              <Pagination
                totalOfRows={totalOfRows}
                nextPage={goToNextPage}
                prevPage={goToPrevPage}
                filters={{
                  reference,
                  question,
                  startDate: formattedDateTime(startDate),
                  endDate: formattedDateTime(endDate),
                  page,
                  limit,
                }}
              />
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ authReducer: { authenticated }, surveyReducer: { searchResult, formSearch } }) => ({
  userLogged: authenticated,

  reference: formSearch.reference,
  question: formSearch.question,
  startDate: formSearch.startDate,
  endDate: formSearch.endDate,
  page: formSearch.page,
  limit: formSearch.limit,

  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  totalOfRows: searchResult.totalOfRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleDetailsLayer,
    toggleFormDeleteLayer,
    showFormManagerLayer,
    openFormSearchLayer,
    goToNextPage,
    goToPrevPage,
  }, dispath)
};

export const SearchResult = connect(mapStateToProps, mapDispatchToProps)(SearchResultComponent);
