import React from 'react';
import { Helmet } from 'react-helmet';

export const HeaderHtml = (props) => {
  const {
    title = null,
  } = props;

  return (
    <Helmet>
      {title && (
        <title>{title}</title>
      )}
    </Helmet>
  )
}
