import urlRoutes from '../../config/url-routes';

export default [{
    label: 'Ajuda e suporte',
    click: urlRoutes.supportSystem,
    location: 'primary',
  },
  {
    label: 'Entre em contato',
    click: urlRoutes.supportSite,
    location: 'primary',
  },
  {
    label: 'Política de privacidade',
    click: urlRoutes.termsOfUse,
    location: 'second',
  },
  {
    label: 'Termos de uso',
    click: urlRoutes.termsOfUse,
    location: 'second',
  }
];
