import { combineReducers } from 'redux';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';
import detailsPublicReducer from './details-public';

const appointmentsReducer = combineReducers({
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
  detailsPublic: detailsPublicReducer,
});

export default appointmentsReducer;
