import React, { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, TextInput, Text, Button, Heading, ResponsiveContext, MaskedInput, RadioButton } from 'grommet';

import Header from '../components/header/header';
import { Placeholder, ErrorForm, HeaderHtml, Tag } from '../../../components';

import { formattedCurrency, anonymize } from '../../../utils';

import urlRoutes from '../../../config/url-routes';

import { getUseLicense, createBilling, changeField } from '../actions/create-billing';

const InvoiceUseLicense = (props) => {
  const prevClientIdRef = useRef();

  const [totalMonths, setTotalMonths] = useState(null);
  const [totalValue, setTotalValue] = useState(null);

  const existOpenInvoice = () => {
    const { identifier, openInvoice, history } = props;

    if(openInvoice && openInvoice.id) {
      history.push(urlRoutes.InvoiceOpen.replace(/:identity/gi, identifier));
    }
  };

  const getDiscount = period => {
    const discounts = {
      1: 0,
      3: 0.05,
      6: 0.1,
      12: 0.15,
    };

    return discounts[period];
  };

  const getTotalValue = ({ value, period }) => {
    period = parseInt(period);

    return value * period * (1 - getDiscount(period));
  };

  useEffect(() => {
    const { identity } = props.match.params;
    const { getUseLicense, clientId } = props;

    if(!clientId) {
      getUseLicense(identity);
    }
  });

  useEffect(() => {
    const { clientId } = props;

    if(clientId && prevClientIdRef.current !== clientId) {
      prevClientIdRef.current = clientId;
      existOpenInvoice();
    }
  });

  useEffect(() => {
    setTotalMonths(
      {
        1: { value: props.value },
        3: { value: props.value * 3 * (1 - getDiscount(3)) / 3 },
        6: { value: props.value * 6 * (1 - getDiscount(6)) / 6 },
        12: { value: props.value * 12 * (1 - getDiscount(12)) / 12 }
      }
    );
    }, [props.value]
  );

  const callbackPostSuccess = () => {
    const { identifier, history } = props;
    history.push(urlRoutes.InvoiceOpen.replace(/:identity/gi, identifier));
  };

  const handlePost = formParams => {
    const { createBilling } = props;
    createBilling({ formParams, callbackSuccess: callbackPostSuccess });
  };

  const {
    loadingPostForm,
    postDataError,
    identifier,
    clientId,
    clientName,

    value,

    newClient,
    inputBillingName,
    inputBillingDocument,
    period,
    changeField,
  } = props;

  if(!clientId) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <HeaderHtml title='Pagamento licença de uso' />
          <Header />

          <Box
            direction='column'
            align='center'
          >
            {newClient && <Box
              width='xlarge'
              pad={ size === 'small' ? 'large' : 'small' }
            >
              <Heading level='2' size='small' margin={{ bottom: 'none' }}>
                Olá {clientName}.
              </Heading>

              <Text size='small' color='dark-1' margin={{ top: 'none', bottom: 'none' }}>
                Complete seu cadastro pra continuar gerenciando seu mandato.
              </Text>
            </Box>}


            <Box
              direction={ size === 'small' ? 'column' : 'row' }
              width='xlarge'
            >
              {/* Primeira coluna */}
              <Box
                width='medium'
                fill='horizontal'
                pad={ size === 'small' ? 'large' : 'small' }
                margin={{ right: size === 'small' ? 'none' : 'medium' }}
              >
                <Heading level='3' size='small'>
                  Dados do pagador
                </Heading>

                {newClient ?? (<Text
                  color='dark-2'
                  size='small'
                  margin={{ bottom: 'small' }}
                >
                  Informe abaixo o nome completo e o CPF do pagador.
                </Text>)}

                <Box
                  gap='small'
                  margin={{ bottom: 'medium' }}
                >
                  <Box>
                    <TextInput
                      disabled={!newClient}
                      value={inputBillingName ?? ''}
                      placeholder='Nome completo *'
                      onChange={event =>
                        changeField(event.target.value, 'changeInputField', 'inputBillingName')
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='billingName' />
                  </Box>

                  <Box>
                    <MaskedInput
                      mask={[
                        { length: 3, regexp: /\d/ },
                        { fixed: '.' },
                        { length: 3, regexp: /\d/ },
                        { fixed: '.' },
                        { length: 3, regexp: /\d/ },
                        { fixed: '-' },
                        { length: 2, regexp: /\d/ }
                      ]}
                      disabled={!newClient}
                      placeholder='Cpf *'
                      value={
                        !newClient
                          ? anonymize(inputBillingDocument, /(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/g, '$1.XXX.XXX-$4')
                          : inputBillingDocument
                      }
                      onChange={ event =>
                        changeField(event.target.value, 'changeInputField', 'inputBillingDocument')
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='billingDocument' />
                  </Box>
                </Box>

                <Heading level='3' size='small'>
                  Planos
                </Heading>

                <Box
                  border={{ color: 'light-3' }}
                  round='xsmall'
                  background='white'
                  flex={{ shrink: 0 }}
                >
                  <Box
                    pad={{
                      vertical: 'small',
                      horizontal: 'medium',
                    }}
                    border={{ side: 'bottom', color: 'light-3' }}
                    direction='row'
                    justify='between'
                  >
                    <RadioButton
                      name='period'
                      label={
                        <Text size='small' color='dark-2'>Mensal</Text>
                      }
                      value='1'
                      checked={period === '1'}
                      onChange={
                        event => {
                          changeField(event.target.value, 'changeInputField', 'period');

                          setTotalValue(
                            getTotalValue({
                              value: value,
                              period: event.target.value
                            })
                          );
                        }
                      }
                    />

                    <Box
                      direction='row'
                      align='center'
                      gap='small'
                    >
                      <Text color='dark-6' size='xsmall'>{formattedCurrency(value)}/mês</Text>
                    </Box>
                  </Box>

                  <Box
                    pad={{
                      vertical: 'small',
                      horizontal: 'medium',
                    }}
                    border={{ side: 'bottom', color: 'light-3' }}
                    direction='row'
                    justify='between'
                  >
                    <RadioButton
                      name='period'
                      label={
                        <Text size='small' color='dark-2'>Trimestral</Text>
                      }
                      value='3'
                      checked={period === '3'}
                      onChange={
                        event => {
                          changeField(event.target.value, 'changeInputField', 'period');

                          setTotalValue(
                            getTotalValue({
                              value: value,
                              period: event.target.value
                            })
                          );
                        }
                      }
                    />

                    <Box
                      direction='row'
                      align='center'
                      gap='small'
                    >
                      <Tag
                        text='5% desconto'
                        background='dark-1'
                        textSize='xsmall'
                      />

                      <Text color='dark-6' size='xsmall'>{
                        formattedCurrency(totalMonths[3]?.value)

                      }/mês</Text>
                    </Box>
                  </Box>

                  <Box
                    pad={{
                      vertical: 'small',
                      horizontal: 'medium',
                    }}
                    border={{ side: 'bottom', color: 'light-3' }}
                    direction='row'
                    justify='between'
                  >
                    <RadioButton
                      name='period'
                      label={
                        <Text size='small' color='dark-2'>Semestral</Text>
                      }
                      value='6'
                      checked={period === '6'}
                      onChange={
                        event => {
                          changeField(event.target.value, 'changeInputField', 'period');

                          setTotalValue(
                            getTotalValue({
                              value: value,
                              period: event.target.value
                            })
                          );
                        }
                      }
                    />

                    <Box
                      direction='row'
                      align='center'
                      gap='small'
                    >
                      <Tag
                        text='10% desconto'
                        background='status-ok'
                        textSize='xsmall'
                      />

                      <Text color='dark-6' size='xsmall'>{
                        formattedCurrency(totalMonths[6]?.value)

                      }/mês</Text>
                    </Box>
                  </Box>

                  <Box
                    pad={{
                      vertical: 'small',
                      horizontal: 'medium',
                    }}
                    border={{ side: 'bottom', color: 'light-3' }}
                    direction='row'
                    justify='between'
                  >
                    <RadioButton
                      name='period'
                      label={
                        <Text size='small' color='dark-2'>Anual</Text>
                      }
                      value='12'
                      checked={period === '12'}
                      onChange={
                        event => {
                          changeField(event.target.value, 'changeInputField', 'period');

                          setTotalValue(
                            getTotalValue({
                              value: value,
                              period: event.target.value
                            })
                          );
                        }
                      }
                    />

                    <Box
                      direction='row'
                      align='center'
                      gap='small'
                    >
                      <Tag
                        text='15% desconto'
                        background='status-ok'
                        textSize='xsmall'
                      />

                      <Text color='dark-6' size='xsmall'>{
                        formattedCurrency(totalMonths[12]?.value)

                      }/mês</Text>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Segunda coluna */}
              <Box
                width='medium'
                fill='horizontal'
                pad={ size === 'small' ? 'large' : 'small' }
                margin={{ left: size === 'small' ? 'none' : 'medium' }}
              >
                <Heading level='3' size='small'>
                  Resumo
                </Heading>

                <Box
                  border={{ color: 'light-3', side: 'bottom' }}
                  margin={{ top: 'xsmall' }}
                  pad={{ bottom: 'small' }}
                  direction='row'
                  justify='between'
                  align='center'
                >
                  <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                    Produto
                  </Text>

                  <Text size='small' color='dark-4' margin={{ top: 'xsmall', bottom: 'none' }}>
                    Licença de uso
                  </Text>
                </Box>

                {newClient && (<Box
                  border={{ color: 'light-3', side: 'bottom' }}
                  margin={{ top: 'xsmall' }}
                  pad={{ bottom: 'small', top: 'xsmall' }}
                  direction='row'
                  justify='between'
                  align='center'
                >
                  <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                    Taxa de adesão, cancelamento
                  </Text>

                  <Text size='small' color='dark-4' margin={{ top: 'xsmall', bottom: 'none' }}>
                    R$ 0,00
                  </Text>
                </Box>)}

                <Box
                  border={{ color: 'light-3', side: 'bottom' }}
                  margin={{ top: 'xsmall' }}
                  pad={{ bottom: 'small', top: 'xsmall' }}
                  direction='row'
                  justify='between'
                  align='center'
                >
                  <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                    Mensal
                  </Text>

                  <Text size='small' color='dark-4' margin={{ top: 'xsmall', bottom: 'none' }}>
                    {formattedCurrency(value)}
                  </Text>
                </Box>

                <Box
                  border={{ color: 'light-3', side: 'bottom' }}
                  margin={{ top: 'xsmall' }}
                  pad={{ bottom: 'small', top: 'xsmall' }}
                  direction='row'
                  justify='between'
                  align='center'
                >
                  <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                    Total a pagar
                  </Text>

                  <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                    {formattedCurrency(totalValue ?? value)}
                  </Text>
                </Box>

                <ErrorForm errorsList={postDataError.errors} fieldName='createBilling' />


                <Box
                  margin={{ top: 'medium' }}
                  pad={{ bottom: 'small', top: 'xsmall' }}
                >
                  <Button
                    primary
                    label={ !loadingPostForm ? 'Gerar Boleto' : 'Gerando Boleto...' }
                    disabled={ loadingPostForm }
                    onClick={() => {
                      handlePost({
                        clientToken: identifier,
                        billingName: inputBillingName,
                        billingDocument: inputBillingDocument,
                        period: period,
                        firstBuy: newClient,
                      })
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
}

const mapStateToProps = ({ invoiceReducer: { clientBilling } }) => ({
  loadingPostForm: clientBilling.loadingPostForm,
  postDataError: clientBilling.postDataError,

  identifier: clientBilling.identifier,
  clientId: clientBilling.clientId,
  clientName: clientBilling.clientName,
  openInvoice: clientBilling.openInvoice,

  value: clientBilling.value,

  newClient: clientBilling.newClient,
  inputBillingName: clientBilling.inputBillingName,
  inputBillingDocument: clientBilling.inputBillingDocument,
  period: clientBilling.period,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getUseLicense,
  createBilling,
  changeField,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceUseLicense)
);
