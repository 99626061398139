import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../../../components/header/header';
import DasboardResult from '../components/dashboard-result/dashboard-result';

import { getSummary } from '../actions/dashboard';

class DashboardManifestation extends Component {
  componentDidMount() {
    const {
      getSummary,
    } = this.props;

    getSummary();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <DasboardResult />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getSummary,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardManifestation);
