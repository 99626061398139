import TYPES from '../actions/types/reset-password';

const INITIAL_STATE = {
  password: '',
  confirmPassword: '',
  tokenRecoverPassword: null,
  loadingPostForm: false,
  postDataSuccess: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.changePassword:
      return { ...state, password: action.payload, confirmPassword: action.payload }

    case TYPES.loadFormPostToggle:
      return { ...state, loadingPostForm: !state.loadingPostForm }

    case TYPES.tokenRecoverPassword:
      return { ...state, tokenRecoverPassword: action.payload.data }

    case TYPES.formPostSuccess:
      return { ...state, postDataSuccess: true }

    case TYPES.formPostError:
      return { ...state, postDataError: action.payload.data }

    default:
      return state;
  }
}
