import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FormCreate from '../components/form-create/form-create';
import Header from '../../../components/header/header';

import { getGroupsOptions } from '../../../actions/select-options';

class CampaignsManager extends Component {
  componentDidMount() {
    const {
      getGroupsOptions,
    } = this.props;

    getGroupsOptions();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <FormCreate />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getGroupsOptions,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsManager);
