import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

export const toggleFormShortMessageLayer = params => {
  if(params && params.id) {
    analytics.registerEvent({
      category: analytics.categories.voter,
      action: analytics.actions.layerFakedoorSendSms,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'sendShortMessage' },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
  ];
};
