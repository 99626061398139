const TYPES = ({
  // list
  loadingFormSearch: 'LOADING_SEARCH_SETTINGS_USER_FORM_TOGGLE',
  searchSuccess: 'SEARCH_SETTINGS_USER_SUCCESS',
  searchError: 'SEARCH_SETTINGS_USER_LIST_ERROR',

  // form search
  setDefaultPage: 'SET_SEARCH_SETTINGS_USER_DEFAULT_PAGE',
  updateItemListing: 'SETTINGS_USER_SUCCESS_UPDATE_LISTING',
  insertItemListing: 'SETTINGS_USER_SUCCESS_INSERT_LISTING',
});

export default TYPES;
