import urlRoutes from '../config/url-routes';

const shouldRetryToken = (error) => error && error.response && error.response.status === 403;

const interceptorResponse = (originalRequest) => originalRequest;

const interceptorResponseError = (error) => {
  if(shouldRetryToken(error)) {
    window.location = urlRoutes.logout;
  }

  return Promise.reject(error);
}

export default (instance) => {
  instance.interceptors.response.use(
    originalRequest => interceptorResponse(originalRequest),
    error => interceptorResponseError(error)
  )
}
