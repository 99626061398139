import React from 'react';
import { Box, ResponsiveContext } from 'grommet';

const Hr = () => {
  return (
    <ResponsiveContext.Consumer>
       {size => (
        <Box
          border={{ color: 'light-3', side: 'bottom', size: 'small' }}
          id={size}
          margin={{
            top: size === 'xsmall' || size === 'small' ? 'large' : 'medium',
            bottom: size === 'xsmall' || size === 'small' ? 'large' : 'medium',
          }}
        />
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Hr;

