import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import { showNotificationLayer } from '../../../actions/notification';
import { remove as d } from '../../../api/common';

const loadingPostDeleteToggle = () => ({
  type: TYPES.loadingFormDelete,
});

export const remove = params => {
  return dispatch => {
    dispatch(loadingPostDeleteToggle());

    d(params.formParams.id, 'legislative.processe.status.php')
      .then(() => {
        dispatch(loadingPostDeleteToggle());
        dispatch(toggleFormDeleteLayer());

        dispatch({
          type: TYPES.deleteItemListingManagerSuccess,
          payload: params.formParams,
        });

        dispatch(
          showNotificationLayer('O status foi removido!')
        );
      })
      .catch(responseFail => {
        dispatch(loadingPostDeleteToggle());

        dispatch({
          type: TYPES.formPostDeleteError,
          payload: responseFail.response,
        });
      })
  }
}

export const toggleFormDeleteLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'delete' },
      { type: TYPES.setDataFormDelete, payload: params },
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
};
