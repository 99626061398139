import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { Calendar, Copy, Currency, Search } from 'grommet-icons';

import { toggleFormManagerLayer } from '../../actions/form-manager';

import rulesName from '../../../../constants/rules';

const OnboardingComponent = (props) => {
  const {
    userLogged: {
      rules
    },
    toggleFormManagerLayer,
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='row'
          justify='center'
          margin={{ horizontal: 'medium', top: '100px' }}
        >
          <Box
            pad='small'
            width='xlarge'
            gap='medium'
          >

            {/* Cabeçalho */}
            <Box
              direction='row'
              gap='xsmall'
              align='baseline'
            >
              <Text size='large' color='dark-5'>Bem-vindo ao módulo de</Text>
              <Text size='xlarge' color='dark-1'>despesas.</Text>
            </Box>

            {/* Conteúdo */}
            <Box
              gap='medium'
              direction={ size === 'small' || size === 'medium' ? 'column' : 'row' }
              justify='between'
            >
              {/* Primeira coluna */}
              <Box
                width={
                  size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge'
                }
              >
                <Box
                  margin={{
                    bottom: size === 'small' ? 'large' : 'medium'
                  }}
                >
                  <Text color='dark-2' size='small'>
                    Sabe aquela despesa com papelaria, material de escritório, etc?
                    É aqui que você cria e gerencia todas elas.
                  </Text>
                </Box>

                <Box
                  direction='row'
                  gap={ size === 'small' ? 'large' : 'medium' }
                  margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                >
                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <Currency size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Crie despesas
                    </Text>

                    <Text size='small' color='dark-2'>
                      Registre todas as despesas do seu mandato ou campanha.
                    </Text>
                  </Box>

                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <Search size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Busca avançada
                    </Text>

                    <Text size='small' color='dark-2'>
                      Filtre as despesas por tipo, status e muito mais.
                    </Text>
                  </Box>
                </Box>

                <Box
                  direction='row'
                  gap={ size === 'small' ? 'large' : 'medium' }
                  margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                >
                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <Calendar size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Crie lembretes
                    </Text>

                    <Text size='small' color='dark-2'>
                      Veja lembrado de alguma despesa importante.
                    </Text>
                  </Box>

                  <Box
                    gap={ size === 'small' ? 'medium' : 'small' }
                    align='baseline'
                    width='50vw'
                  >
                    <Copy size='medium' color='brand' />

                    <Text size='small' color='dark-2' weight='bold'>
                      Copie
                    </Text>

                    <Text size='small' color='dark-2'>
                      Crie um despesa nova a partir de uma já existente.
                    </Text>
                  </Box>
                </Box>

              </Box>

              {/* Separador coluna */}
              <Box
                width={ size === 'large' || size === 'xlarge' ? 'xsmall' : 'xxsmall'}
              />

              {/* Segunda coluna */}
              <Box
                gap='small'
                width={ size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge' }
              >
                <Box
                  gap='medium'
                  round={ size === 'small' ? 'small' : 'xsmall' }
                  border={{ color: 'light-4' }}
                  pad={{
                    vertical: size === 'small' ? 'large' : 'medium',
                    horizontal:  size === 'small' ? 'medium' : 'small',
                  }}
                >
                  <Text color='dark-2' size='small'>
                    Crie diversos tipos de despesas...
                  </Text>

                  <Box gap='xxsmall'>
                    <Text color='dark-2' size='small'>- Material de papelaria.</Text>
                    <Text color='dark-2' size='small'>- Manutenção de equipamentos.</Text>
                    <Text color='dark-2' size='small'>- Pagamento de pessoal.</Text>
                    <Text color='dark-2' size='small'>- E muito mais.</Text>
                  </Box>

                  <Button
                    primary
                    label='Começar, Criar despesa'
                    color='neutral-1'
                    title={!rules.includes(rulesName.createExpense) ? 'Você não tem permissão para criar despesas.' : null }
                    disabled={!rules.includes(rulesName.createExpense)}
                    onClick={() =>
                      toggleFormManagerLayer()
                    }
                  />
                </Box>

                {/*<Text color='dark-2' size='small'>
                  Caso tenha alguma dúvida entre em contato por WhatsApp ou e-mail.
                </Text>

                <Box>
                  <Text color='dark-2' size='small'>Suporte por WhatsApp</Text>
                </Box>*/}

              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleFormManagerLayer,
  }, dispath)
};

export const Onboarding = withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingComponent));
