import TYPES from '../actions/types';

const INITIAL_STATE = {
  name: '',
  telephone: '',
  city: '',
  state: {},
  product: {},
  mail: '',
  password: '',

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {

    case TYPES.clearAllForm:
      return { ...state, ...INITIAL_STATE }

    case TYPES.changeField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.loadFormPostToggle:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formPostError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
