const urlRoutes = ({
  home: '/',
  login: '/auth/login',
  logout: '/auth/logout',

  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password/:identity',

  onboardingStartUrl: '/onboarding/start',
  onboardingWelcomeUrl: '/onboarding/welcome',

  InvoiceOpen: '/invoice/open/:identity',
  InvoiceUseLicense: '/invoice/use-license/:identity',

  forbidden: '/forbidden',

  appointmentDashUrl: '/appointments',
  appointmentPublicUrl: '/appointment/:code',
  appointmentListUrl: '/appointments/list',

  phonecallDashUrl: '/phonecalls',
  phonecallListUrl: '/phonecalls/list',

  campaignListUrl: '/campaigns/list',
  campaignCreateUrl: '/campaigns/create',

  serviceListUrl: '/services/list',

  voterListUrl: '/voters/list',
  voterCreateUrl: '/voters/create',
  voterEditUrl: '/voters/edit/:id',

  legislativeProcessesListUrl: '/legislative-processes/list',

  customerAreaUrl: '/customer-area/account',
  customerAreaInvoicesUrl: '/customer-area/invoices/list',

  settingsUrl: '/settings',
  settingsOriginUrl: '/settings/service-origin',
  settingsGroupUrl: '/settings/voter-group',
  settingsCategoryUrl: '/settings/service-category',
  settingsServiceStatusUrl: '/settings/service-status',
  settingsOfficeUrl: '/settings/office',
  settingsUserUrl: '/settings/user',
  settingsLegislativeProcesseStatusUrl: '/settings/legislative-processe-status',
  settingsIntegrationCalendarUrl: '/settings/integration-calendar',

  dashboardSystemUrl: '/dashboard',

  tasksListUrl: '/tasks/list',
  expensesListUrl: '/expenses/list',
  pollsListUrl: '/polls/list',

  manifestationsDashUrl: '/manifestations',
  manifestationsListUrl: '/manifestations/list',

  integrationCalendarCallback: '/integration-calendar/callback',

  site: 'https://gerenciameumandato.com.br',
  supportSite: 'https://gerenciameumandato.com.br/#contato',
  supportSystem: 'https://suporte.gerenciameumandato.com.br/',
  termsOfUse: 'https://gerenciameumandato.com.br/termo-servico',
  pollTerms: 'https://suporte.gerenciameumandato.com.br/regras-recomendacoes-para-utilizacao-funcionalidade-enquetes/41',
  app: 'https://gerenciameumandato.com.br/aplicativo',
  analyticsUrl: 'https://analytics.gerenciameumandato.com.br/system',
  electionsUrl: 'https://analytics.gerenciameumandato.com.br/elections',

  printDoc: 'https://suporte.gerenciameumandato.com.br/como-imprimir-documento-elaborado/43',
  createDoc: 'https://suporte.gerenciameumandato.com.br/elaborar-documento-uma-propositura-documento-administrativo/42',

  voterRegistrationForm: 'https://gerenciameumandato.com.br/ficha_captacao_eleitor.pdf',
});

export default urlRoutes;
