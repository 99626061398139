import TYPES from './types';
import { getAppointmentsPublic } from '../../../api/appointment';

export const getDetails = code =>
  getAppointmentsPublic(code)
    .then(response => ({
      type: TYPES.openPublicDetails,
      payload: response.data,
    }))
    .catch(() => { })
