
const TYPES = ({
  fillAccounts: 'FILL_ACCOUNTS_LOGIN_SYSTEM',
  fillSelectedAccount: 'FILL_SELECTED_ACCOUNT_LOGIN_SYSTEM',
  changeField: 'CHANGE_FIELD_LOGIN_SYSTEM',
  loadingFormPostToggle: 'LOAD_LOGIN_FORM_TOGGLE',
  postError: 'POST_ERROR_LOGIN_SYSTEM',
});

export default TYPES;
