import TYPES from './types';
import { queryCommon } from '../../../api/queries';

import { listCities, listDistricts, listAddresses } from '../../../api/location';
import { listSchool } from '../../../api/school';

export const getTagOptions = () => {
  return dispatch => {
    queryCommon('tagList')
      .then(({ data }) => {
        dispatch({
          type: TYPES.getTagOptions,
          payload: data.map(result => ({
            id: result.id,
            perLine: result.perLine,
            perPage: result.perPage,
            name: `${result.name} - ${result.code}`,
          })),
        })
      })
  }
};

export const searchCityOptions = params => {
  return dispatch => {
    dispatch({ type: TYPES.getCityOptionsLoad });

    listCities(params.name)
      .then(({ data }) => {
        dispatch({
          type: TYPES.getCityOptions,
          payload: data.map(result => {
            const {
              id,
              name,
              stateAbbrev,
              stateId,
            } = result;

            return {
              id,
              stateId,
              name: `${name}/${stateAbbrev}`,
            }
          }),
        })
      })
      .then(() => dispatch({ type: TYPES.getCityOptionsLoad }))
      .catch(() => dispatch({ type: TYPES.getCityOptionsLoad }))
  }
};

export const searchDistrictOptions = params => {
  return dispatch => {
    dispatch({ type: TYPES.getDistrictOptionsLoad });

    listDistricts(params)
      .then(({ data }) => {
        dispatch({
          type: TYPES.getDistrictOptions,
          payload: data.data.map(result => {
            const {
              id,
              district,
            } = result;

            return {
              id,
              name: district,
            }
          }),
        })
      })
      .then(() => dispatch({ type: TYPES.getDistrictOptionsLoad }))
      .catch(() => dispatch({ type: TYPES.getDistrictOptionsLoad }))
  }
};

export const searchSchoolOptions = params => {
  return dispatch => {
    listSchool(params)
      .then(({ data }) => {
        dispatch({
          type: TYPES.getSchoolOptions,
          payload: data.data.map(result => {
            const {
              id,
              school,
              city,
              state
            } = result;

            return {
              id,
              name: school,
              city,
              state
            }
          }),
        })
      })
  }
};

export const searchAddressesOptions = ({ method, text }) => {
  return dispatch => {
    dispatch({ type: TYPES.getAddressOptionsLoad });

    listAddresses({ method, text })
      .then(({ data }) => {
        dispatch({
          type: TYPES.getAddressOptions,
          payload: data.data,
        })
      })
      .then(() => dispatch({ type: TYPES.getAddressOptionsLoad }))
      .catch(() => dispatch({ type: TYPES.getAddressOptionsLoad }))
  }
};
