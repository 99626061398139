
const TYPES = ({
  changePassword: 'CHANGE_RESET_PASSWORD_FORM_PASSWORD',
  loadFormPostToggle: 'LOAD_RESET_PASSWORD_FORM_TOGGLE',
  tokenRecoverPassword: 'TOKEN_RESET_PASSWORD',
  formPostSuccess: 'POST_RESET_PASSWORD_FORM_SUCCESS',
  formPostError: 'POST_RESET_PASSWORD_FORM_ERROR',
});

export default TYPES;
