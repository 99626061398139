import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

const buyCommunication = params => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'POST',
    data: params,
    url: '/buy.communication.php',
    headers: {
      token: decipher?.token?.value,
      action: 'buy',
    }
  });
}

export default buyCommunication;
